import React, { useRef } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import {
  cx,
  useAutoResizedTextarea,
  useTranslations,
  useToast,
} from "@jugl-web/utils";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { Segment } from "@jugl-web/ui-components/cross-platform/forms/Segment";
import { Select } from "@jugl-web/ui-components/cross-platform/forms/Select";
import { ImagesMultiSelect } from "@jugl-web/ui-components/web/ImagesMultiSelect";
import { InventoryItemParams } from "@jugl-web/rest-api";
import { TextAreaInput } from "@jugl-web/ui-components/cross-platform/forms/TextAreaInput";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { SectionHeader } from "../SectionHeader";
import { useInventoryFormOptions } from "../../hooks/useInventoryFormOptions";
import {
  NAME_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  MAX_PRICE_VALUE,
} from "./consts";

export const MainInfoSection: React.FC<{
  errors: FieldErrors<InventoryItemParams>;
  internalFileState: (File | string)[];
  control: Control<InventoryItemParams, unknown>;
  isService: boolean;
  name: string;
  description: string;
  setInternalFileState: (files: (File | string)[]) => void;
  register: UseFormRegister<InventoryItemParams>;
}> = ({
  errors,
  internalFileState,
  control,
  isService,
  name,
  description,
  setInternalFileState,
  register,
}) => {
  const { t } = useTranslations();
  const { toast } = useToast();
  const descriptionTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { textareaProps: descriptionTextareaProps } = useAutoResizedTextarea(
    descriptionTextareaRef
  );

  const { categories, units } = useInventoryFormOptions();
  const { entity } = useEntitySelectedProvider();

  const handleInternalFileStateChange = (files: (File | string)[]) => {
    const filteredFiles = files.filter((file) => {
      if (typeof file === "string") {
        return file;
      }
      const type = file.type.toLowerCase();
      return (
        type.includes("image/png") ||
        type.includes("image/jpg") ||
        type.includes("image/jpeg")
      );
    });
    if (filteredFiles.length !== files.length) {
      onIncorrectFileType();
      return;
    }
    setInternalFileState(files.map((file) => file));
  };

  const onIncorrectFileType = () => {
    toast(
      t({
        id: "feedback.only-img-allowed",
        defaultMessage: "Incorrect file format. Please upload IMG or PNG files",
      }),
      {
        variant: "error",
      }
    );
  };
  return (
    <div className="flex w-[1120px] flex-col gap-8 px-[60px]">
      <SectionHeader
        number={1}
        title={t({
          id: "inventory-item-form-page.main-info",
          defaultMessage: "Main Info",
        })}
      />
      <div className="flex flex-col gap-10">
        <div className="flex gap-10">
          <div className="w-[652px]">
            <div className="grid grid-cols-2 gap-10">
              <TextInput
                placeholder={t({
                  id: "common.enter",
                  defaultMessage: "Enter",
                })}
                {...register("inventoryItemName", {
                  maxLength: {
                    value: NAME_MAX_LENGTH,
                    message: `${name.length}/${NAME_MAX_LENGTH}`,
                  },
                  setValueAs: (value) => value.trim(),
                  required: {
                    value: true,
                    message: t({
                      id: "inventory-item-form-page.mandatory-field",
                      defaultMessage: "Mandatory field",
                    }),
                  },
                  validate: (value) => !!value.trim(),
                })}
                isRequired
                isInvalid={!!errors.inventoryItemName}
                label={
                  isService
                    ? t({
                        id: "inventory-item-form-page.service-name",
                        defaultMessage: "Service name",
                      })
                    : t({
                        id: "inventory-item-form-page.item-name",
                        defaultMessage: "Item name",
                      })
                }
                errorMessage={
                  (name.length > NAME_MAX_LENGTH - 11 &&
                    `${name.length}/${NAME_MAX_LENGTH}`) ||
                  errors.inventoryItemName?.message
                }
              />
              <Controller
                control={control}
                name="category"
                render={({ field: { value, onChange } }) => (
                  <Segment
                    label={t({
                      id: "inventory-item-form-page.category",
                      defaultMessage: "Category",
                    })}
                    classNames={{
                      wrapperClassName: "h-[60px]",
                    }}
                    isRequired
                    selectedItem={value}
                    onChange={onChange}
                    items={categories.map((category) => ({
                      id: category.value,
                      title: category.label,
                    }))}
                  />
                )}
              />
            </div>

            <div
              className={cx(
                "flex justify-evenly gap-10",
                internalFileState.length ? "mt-10" : "mt-9"
              )}
            >
              {!isService && (
                <div className="w-full">
                  <Controller
                    control={control}
                    name="unit"
                    rules={{
                      required: {
                        value: true,
                        message: t({
                          id: "inventory-item-form-page.mandatory-field",
                          defaultMessage: "Mandatory field",
                        }),
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        isRequired
                        isInvalid={!!errors.unit}
                        errorMessage={errors.unit?.message}
                        items={units.map((unit) => ({
                          name: unit.value,
                          value: unit.id,
                          triggerClassName:
                            "px-2 py-0 h-[38px] flex items-center leading-[22px]",
                        }))}
                        placeholder={t({
                          id: "common.select",
                          defaultMessage: "Select",
                        })}
                        label={t({
                          id: "inventory-item-form-page.unit",
                          defaultMessage: "Unit",
                        })}
                        onSelect={onChange}
                        value={value || undefined}
                      />
                    )}
                  />
                </div>
              )}
              <div className="w-full">
                <Controller
                  control={control}
                  name="price"
                  rules={{
                    validate: (value) => {
                      if (Number(value.value) > MAX_PRICE_VALUE) {
                        return t(
                          {
                            id: "inventory-item-form-page.price-too-high",
                            defaultMessage: "Max value is {maxPriceValue}",
                          },
                          {
                            maxPriceValue: MAX_PRICE_VALUE,
                          }
                        );
                      }
                      if (!value.value) {
                        return t({
                          id: "inventory-item-form-page.mandatory-field",
                          defaultMessage: "Mandatory field",
                        });
                      }
                      return true;
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      placeholder={t({
                        id: "common.enter",
                        defaultMessage: "Enter",
                      })}
                      onKeyDown={(e) => {
                        const notAllowedCharacters = ["e", "E", "+", "-"];
                        if (notAllowedCharacters.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      type="number"
                      min={0}
                      max={MAX_PRICE_VALUE}
                      step={0.5}
                      onBlur={() => {
                        const isNaN = Number.isNaN(value.value);
                        if (value.value) {
                          onChange({
                            value: isNaN
                              ? undefined
                              : parseFloat(value.value).toFixed(2),
                          });
                        }
                      }}
                      isInvalid={!!errors.price}
                      label={
                        isService
                          ? t({
                              id: "inventory-item-form-page.price",
                              defaultMessage: "Price",
                            })
                          : t({
                              id: "inventory-item-form-page.price-unit",
                              defaultMessage: "Price/unit",
                            })
                      }
                      onChange={(e) =>
                        onChange({
                          value: e.target.value,
                        })
                      }
                      isRequired
                      errorMessage={errors.price?.message}
                      value={value.value}
                      endContent={
                        <div className="font-secondary text-dark text-base">
                          {entity.currency}
                        </div>
                      }
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <Controller
            control={control}
            name="files"
            render={() => (
              <div
                className={cx("h-[216px] pt-[30px]", {
                  "pt-[0px]": internalFileState.length,
                })}
              >
                <ImagesMultiSelect
                  imagesLimit={10}
                  files={internalFileState}
                  onFilesChange={(files) =>
                    handleInternalFileStateChange(files)
                  }
                  onIncorrectFileType={onIncorrectFileType}
                  className={
                    internalFileState.length ? "h-[160px]" : "h-[184px]"
                  }
                />
              </div>
            )}
          />
        </div>

        <div>
          <TextAreaInput
            placeholder={t({
              id: "common.enter",
              defaultMessage: "Enter",
            })}
            label={t({
              id: "inventory-item-form-page.description",
              defaultMessage: "Description",
            })}
            {...descriptionTextareaProps}
            isInvalid={!!errors.description}
            isRequired
            errorMessage={
              (description.length > DESCRIPTION_MAX_LENGTH - 11 &&
                `${description.length}/${DESCRIPTION_MAX_LENGTH}`) ||
              errors.description?.message
            }
            {...register("description", {
              maxLength: {
                value: DESCRIPTION_MAX_LENGTH,
                message: `${description?.length}/${DESCRIPTION_MAX_LENGTH}`,
              },
              setValueAs: (value) => value.trim(),
              required: {
                value: true,
                message: t({
                  id: "inventory-item-form-page.mandatory-field",
                  defaultMessage: "Mandatory field",
                }),
              },
              validate: (value) => !!value.trim(),
            })}
          />
        </div>
      </div>
    </div>
  );
};

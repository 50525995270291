export const moveArrayElement = <T>(
  arr: T[],
  fromIndex: number,
  toIndex: number
): T[] => {
  const array = [...arr];
  const [element] = array.splice(fromIndex, 1);
  array.splice(toIndex, 0, element);
  return array;
};

import React, {
  useMemo,
  createContext,
  PropsWithChildren,
  useContext,
} from "react";
import { HookOutOfContextError } from "@jugl-web/utils";
import { useLocalStorage } from "@jugl-web/utils/hooks/useStorage";
import { INVENTORY_TABLE_SETTINGS_KEY } from "@jugl-web/utils/storage";

export type ColumnParams = {
  visible: boolean;
  order: number;
  id: string;
};

interface InventoryTableContextValue {
  tableSettings: { columns: Record<string, ColumnParams> };
  setTableSettings: (
    settings: InventoryTableContextValue["tableSettings"]
  ) => void;
}

const InventoryTableContext = createContext<InventoryTableContextValue | null>(
  null
);

const InventoryTableProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [tableSettings, setTableSettings] = useLocalStorage<
    InventoryTableContextValue["tableSettings"]
  >(INVENTORY_TABLE_SETTINGS_KEY, {
    columns: {
      name: {
        visible: true,
        order: 1,
        id: "name",
      },
      category: {
        visible: true,
        order: 2,
        id: "category",
      },
      unit: {
        visible: true,
        order: 3,
        id: "unit",
      },
      price: {
        visible: true,
        order: 4,
        id: "price",
      },
      sku: {
        visible: true,
        order: 5,
        id: "sku",
      },
      dimensions: {
        visible: true,
        order: 6,
        id: "dimensions",
      },
      weight: {
        visible: true,
        order: 7,
        id: "weight",
      },
      upc: {
        visible: true,
        order: 8,
        id: "upc",
      },
      ean: {
        visible: true,
        order: 9,
        id: "ean",
      },
      mpn: {
        visible: true,
        order: 10,
        id: "mpn",
      },
      isbn: {
        visible: true,
        order: 11,
        id: "isbn",
      },
      description: {
        visible: true,
        order: 12,
        id: "description",
      },
      settings: {
        visible: true,
        order: 13,
        id: "settings",
      },
    },
  });

  const contextValue = useMemo(
    () => ({
      tableSettings,
      setTableSettings,
    }),
    [tableSettings, setTableSettings]
  );

  return (
    <InventoryTableContext.Provider value={contextValue}>
      {children}
    </InventoryTableContext.Provider>
  );
};

export const useInventoryTable = () => {
  const context = useContext(InventoryTableContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useInventoryTable",
      "InventoryTableContext"
    );
  }

  return context;
};

export { InventoryTableContext, InventoryTableProvider };

import { cx } from "@jugl-web/utils";
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
} from "react";

type NativeButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
interface TabContentButtonProps extends Omit<NativeButtonProps, "children"> {
  label: string;
  icon: ReactNode;
}

export const TabContentButton = forwardRef<
  HTMLButtonElement,
  TabContentButtonProps
>(({ label, icon, className, ...props }, ref) => (
  <button
    ref={ref}
    type="button"
    className={cx(
      "bg-grey-200 hover:bg-dark-100 flex h-10 cursor-pointer items-center justify-center gap-1.5 rounded-[10px] border-0 px-[14px] transition",
      className
    )}
    {...props}
  >
    <div className="shrink-0 leading-[0]">{icon}</div>
    <span className="text-dark-600 font-secondary truncate text-sm">
      {label}
    </span>
  </button>
));

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Checkbox, PlainButton, Radio } from "@jugl-web/ui-components";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import React from "react";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as BinIcon } from "./assets/bin.svg";
import { ReactComponent as ReorderIcon } from "./assets/reorder.svg";
import { ORDER_FORM_DROPDOWN_OPTION_MAX_LENGTH } from "./consts";

export const DropdownOption: React.FC<{
  id: string;
  index: number;
  label?: string;
  isExpanded: boolean;
  isDisabled?: boolean;
  isFieldRequired?: boolean;
  value: string;
  isError?: boolean;
  choiceMode?: "single" | "multi";
  onLabelChange?: (value: string) => void;
  onDelete?: () => void;
}> = ({
  id,
  index,
  value,
  isError,
  label,
  isExpanded,
  isFieldRequired,
  choiceMode,
  isDisabled,
  onLabelChange,
  onDelete,
}) => {
  const { t } = useTranslations();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      data: {
        index,
      },
      disabled: isDisabled,
    });

  return (
    <div
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      ref={!isDisabled ? setNodeRef : undefined}
    >
      <TextInput
        label={label || ""}
        isRequired={isFieldRequired}
        isDisabled={!isExpanded || isDisabled}
        classNames={{
          inputClassName: !isExpanded ? "pointer-events-none" : "",
          startContentWrapperClassName: "bg-transparent pr-0",
          endContentWrapperClassName: "bg-transparent",
        }}
        lengthIndicator={{
          max: ORDER_FORM_DROPDOWN_OPTION_MAX_LENGTH,
        }}
        value={value}
        onChange={(e) => onLabelChange?.(e.target.value.trimStart())}
        endContent={
          onDelete && isExpanded ? (
            <PlainButton onClick={onDelete}>
              <BinIcon />
            </PlainButton>
          ) : undefined
        }
        startContent={
          <div className="flex items-center gap-4">
            {!isDisabled && isExpanded && (
              <ReorderIcon
                className="cursor-pointer"
                {...listeners}
                {...attributes}
              />
            )}
            {choiceMode === "single" && <Radio isChecked={false} />}
            {choiceMode === "multi" && <Checkbox isChecked={false} />}
          </div>
        }
        isInvalid={isError}
        errorMessage={
          isError && !value
            ? t({
                id: "order-form-wizard-page.field-cant-be-empty",
                defaultMessage: "This field cannot be left empty",
              })
            : undefined
        }
      />
    </div>
  );
};

import { FC, useState } from "react";
import { OrderFormSubmission } from "@jugl-web/domain-resources/orders/components/OrderFormSubmission";
import { PlainButton, Tooltip } from "@jugl-web/ui-components";
import { cx, useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { ReactComponent as BackIcon } from "./assets/back-icon.svg";
import { useOrderForm } from "../../../../providers/OrderFormProvider";

export const OrderFormPreview: FC = () => {
  const {
    title,
    description,
    image,
    allFields,
    setIsPreviewMode,
    inventoryItemsCache,
  } = useOrderForm();
  const { t } = useTranslations();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { entity } = useEntitySelectedProvider();

  return (
    <div className="relative flex gap-2 pr-14">
      <Tooltip
        placement="bottom"
        renderTrigger={({ props, ref }) => (
          <PlainButton
            className={cx(
              "border-grey-300 hover:border-primary-200 sticky top-4 flex h-12 w-12 items-center justify-center rounded-xl border border-solid bg-white transition-all hover:border-2"
            )}
            onClick={() => setIsPreviewMode(false)}
            ref={ref}
            {...props}
          >
            <BackIcon />
          </PlainButton>
        )}
      >
        {t({
          id: "order-form-wizard-page.back-to-form-creation",
          defaultMessage: "Back to Form Creation",
        })}
      </Tooltip>
      <div className="flex-1 overflow-hidden">
        <OrderFormSubmission
          title={title}
          description={description}
          bannerImg={
            image && typeof image === "object"
              ? URL.createObjectURL(image)
              : image
          }
          fields={allFields}
          services={inventoryItemsCache}
          onSubmit={() => setIsSubmitted(true)}
          isSubmitted={isSubmitted}
          isPreview
          onAddAnotherAnswer={() => setIsSubmitted(false)}
          currency={entity.currency}
        />
      </div>
    </div>
  );
};

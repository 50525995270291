import React from "react";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import { Button, EmptyListContent } from "@jugl-web/ui-components";
import { InventoryTabsLayout } from "../components/InventoryTabsLayout";
import { ReactComponent as AddIcon } from "./assets/add.svg";

export const InventoryDiscountsPage: React.FC = () => {
  const { t } = useTranslations();
  const { inputProps, reset } = useSearchInput();

  return (
    <InventoryTabsLayout
      selectedTab="inventory-discounts"
      rightContent={
        <div className="flex items-center gap-4">
          <SearchInput
            className="w-[280px]"
            variant="filled"
            color="white"
            onClear={reset}
            {...inputProps}
          />
          <Button className="h-10" iconEnd={<AddIcon />}>
            {t({
              id: "inventory-discounts-page.add-item",
              defaultMessage: "Add Item",
            })}
          </Button>
        </div>
      }
    >
      <EmptyListContent
        type="custom"
        className="w-[400px]"
        customTitle={t({
          id: "inventory-discounts-page.discounts",
          defaultMessage: "Discounts ✨",
        })}
        customSubtitle={t({
          id: "inventory-discounts-page.services-benefits-message",
          defaultMessage:
            "Indicate what Services or Goods you can provide to you're Clients",
        })}
        customButton={{
          text: t({
            id: "inventory-discounts-page.add-item",
            defaultMessage: "Add Item",
          }),
          iconEnd: <AddIcon />,
          fullWidth: false,
          className: "h-10",
          onClick: () => {},
        }}
      />
    </InventoryTabsLayout>
  );
};

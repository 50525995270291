import {
  taskNameValidator,
  TITLE_MAX_LENGTH,
} from "@jugl-web/domain-resources/tasks";
import { RecentlyUsedTaskTemplatesSection } from "@jugl-web/domain-resources/tasks/components/RecentlyUsedTaskTemplatesSection";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { useCreateTask } from "@jugl-web/domain-resources/tasks/hooks/useCreateTask";
import { useRecentlyUsedTaskTemplates } from "@jugl-web/domain-resources/tasks/hooks/useRecentlyUsedTaskTemplates";
import {
  getTasksSourceSpecificDefaults,
  TaskFormState,
  useTaskFormState,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { DetailedTask } from "@jugl-web/rest-api/tasks";
import {
  Button,
  Input,
  Popover,
  PopoverProps,
} from "@jugl-web/ui-components/cross-platform";
import { TaskPropertyButton } from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { cx, useTranslations } from "@jugl-web/utils";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useTimeZone } from "@web-src/modules/settings/providers/TimeZoneProvider";
import { FC, FormEvent } from "react";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";

export interface NewTaskPopoverProps
  extends Pick<PopoverProps, "renderTrigger" | "placement" | "isDisabled"> {
  entityId: string;
  initialState?: Partial<TaskFormState>;
  hasBackdrop?: boolean;
  /**
   * Can be used to perform additional actions before creating a task,
   * e.g. sending analytics events
   */
  onBeforeCreateTask?: () => void;
  /**
   * Can be used to perform additional actions after creating a task,
   * e.g. completing an onboarding step
   */
  onAfterCreateTask?: (task: DetailedTask) => void;
}

export const NewTaskPopover: FC<NewTaskPopoverProps> = ({
  entityId,
  initialState,
  hasBackdrop = false,
  onBeforeCreateTask,
  onAfterCreateTask,
  ...popoverProps
}) => {
  const { formState, updateFormState, resetFormState, isDirty } =
    useTaskFormState(initialState);

  const {
    tasksSource,
    tasksSourceInfo,
    openNewTaskDialog,
    navigateToTaskDetailsPage,
  } = useTasksPageContext();

  const { timeZone } = useTimeZone();

  const { createTask, showTaskCreationToast, isLoading } = useCreateTask({
    entityId,
    onNavigate: (taskId) => navigateToTaskDetailsPage(taskId),
  });

  const { me } = useMe();

  const { recentlyUsedTaskTemplates, hasMoreTemplates } =
    useRecentlyUsedTaskTemplates({ entityId, meId: me?.id ?? "" });

  const hasRecentTemplatesSection = recentlyUsedTaskTemplates.length > 0;

  const { t } = useTranslations();

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>,
    closePopover: () => void
  ) => {
    event.preventDefault();
    onBeforeCreateTask?.();

    try {
      const createdTask = await createTask(formState, tasksSource);
      showTaskCreationToast(createdTask.id);
      onAfterCreateTask?.(createdTask);
      closePopover();
    } catch {
      // Do nothing
    }
  };

  // Extend supplied initial state with task-source-specific defaults
  // If `customerId` or `boardId` are supplied via `initialState`, they will have priority
  const resetFormStateWithTasksSourceSpecificDefaults = () => {
    resetFormState({
      ...getTasksSourceSpecificDefaults(tasksSource),
      ...initialState,
    });
  };

  return (
    <Popover
      className={cx(hasRecentTemplatesSection ? "w-[368px]" : "min-w-[300px]")}
      style={{
        boxShadow:
          "0px 0px 24px 0px rgba(0, 0, 0, 0.05), 0px 5px 16px 0px rgba(0, 0, 0, 0.16)",
      }}
      hasBackdrop={hasBackdrop}
      backdropStyle="blur"
      onOverlayClickCapture={(event) => {
        if (isDirty()) {
          event.stopPropagation();
        }
      }}
      onMount={resetFormStateWithTasksSourceSpecificDefaults}
      {...popoverProps}
    >
      {({ onClose }) => (
        <div>
          <form
            className={cx(
              "flex flex-col gap-4",
              hasRecentTemplatesSection ? "px-4 pt-4" : "p-4"
            )}
            onSubmit={(event) => handleSubmit(event, onClose)}
          >
            <Input
              autoFocus
              required
              className="text-dark placeholder:text-grey-background h-[27px] px-0 text-sm font-medium"
              value={formState.title}
              placeholder={t({ id: "common.title", defaultMessage: "Title" })}
              onChange={(e) => updateFormState("title", e.target.value)}
              maxLength={TITLE_MAX_LENGTH}
            />
            <div className="flex gap-2.5">
              <TaskPropertiesPanel
                entityId={entityId}
                config={{
                  dueDate: {
                    state: formState.dueDate,
                    defaultTimezone: timeZone.name,
                    isLabelHidden: true,
                    displayAs: "date",
                    withAdvancedOptions: true,
                    onChange: (state) => updateFormState("dueDate", state),
                  },
                  assignees: {
                    onlyReportees: tasksSourceInfo.isTeamTasks,
                    isLabelHidden: true,
                    onChange: (assigneeIds) =>
                      updateFormState("assigneeIds", assigneeIds),
                    assigneeIds: formState.assigneeIds,
                  },
                }}
              />
              <TaskPropertyButton
                hint={t({
                  id: "tasks-page.add-more-details",
                  defaultMessage: "Add more details",
                })}
                className="flex h-8 w-8 justify-center"
                startIcon={<PlusIcon />}
                onClick={() =>
                  openNewTaskDialog({ initialFormState: formState })
                }
              />
            </div>
            <div className="flex items-center gap-3">
              <Button
                type="submit"
                fullWidth
                size="small"
                isDisabled={isLoading || !taskNameValidator(formState.title)}
              >
                {t({ id: "common.create", defaultMessage: "Create" })}
              </Button>
              <TaskPropertyButton
                startIcon={<CloseIcon />}
                onClick={onClose}
                className="flex h-8 w-8 justify-center rounded-lg"
              />
            </div>
          </form>
          {hasRecentTemplatesSection && (
            <>
              <div className="bg-dark-100 my-6 h-px" />
              <RecentlyUsedTaskTemplatesSection
                templates={recentlyUsedTaskTemplates}
                className="px-4 pb-4"
                onTemplateSelect={(template) =>
                  openNewTaskDialog({
                    initialFormState: { ...formState, templateId: template.id },
                  })
                }
                onShowAllTemplates={
                  hasMoreTemplates
                    ? () =>
                        openNewTaskDialog({
                          initialFormState: formState,
                          shouldOpenTemplateList: true,
                        })
                    : undefined
                }
              />
            </>
          )}
        </div>
      )}
    </Popover>
  );
};

import { cx, useUniqueDOMId } from "@jugl-web/utils";
import { useMemo, useRef } from "react";
import { FormGroup, FormGroupProps } from "../FormGroup";
import { Checkbox } from "../../Checkbox";

export type CheckboxGroupValue = {
  selectedOptions?: string[];
  isOtherSelected?: boolean;
  otherValue?: string;
};

export type CheckboxGroupProps = FormGroupProps & {
  items: { name: string; value: string }[];
  classNames?: {
    wrapperClassName?: string;
  };
  onSelect: (value: CheckboxGroupValue) => void;
  value?: CheckboxGroupValue;
  hasOtherOption?: boolean;
};

export const CheckboxGroup = (props: CheckboxGroupProps) => {
  const uniqueId = useUniqueDOMId();
  const { items, value, onSelect, isDisabled, hasOtherOption, isInvalid } =
    props;
  const inputId = useMemo(() => uniqueId, [uniqueId]);
  const handleCheckboxChange = (isChecked: boolean, checkboxValue: string) => {
    if (isChecked) {
      onSelect({
        ...value,
        selectedOptions: [...(value?.selectedOptions || []), checkboxValue],
      });
    } else {
      onSelect({
        ...value,
        selectedOptions: value?.selectedOptions?.filter(
          (v) => v !== checkboxValue
        ),
      });
    }
  };
  const $inputRef = useRef<HTMLInputElement>(null);
  return (
    <FormGroup {...{ ...props, labelFor: inputId }}>
      <div className="space-y-4 pt-2">
        {items.map((item) => (
          <label
            key={item.value}
            className="flex cursor-pointer items-center gap-2 px-[10px] py-2"
          >
            <Checkbox
              value={item.value}
              isChecked={value?.selectedOptions?.includes(item.value)}
              isDisabled={isDisabled}
              onChange={(e) =>
                handleCheckboxChange(e.target.checked, item.value)
              }
            />
            <div className="text-md font-secondary">{item.name}</div>
          </label>
        ))}
        {hasOtherOption && (
          <label
            key="other"
            className="flex cursor-pointer items-center gap-2 px-[10px] py-2"
          >
            <Checkbox
              value="other"
              isChecked={value?.isOtherSelected}
              isDisabled={isDisabled}
              onChange={(e) => {
                if (e.target.checked) {
                  $inputRef.current?.focus();
                }
                onSelect({
                  selectedOptions: value?.selectedOptions,
                  otherValue: value?.otherValue,
                  isOtherSelected: e.target.checked,
                });
              }}
            />
            <div className="w-full flex-1">
              <input
                ref={$inputRef}
                className={cx(
                  "focus:border-primary-500 font-secondary w-full flex-1 appearance-none border-0 border-b border-solid border-transparent bg-transparent text-base font-normal focus:outline-none",
                  value?.isOtherSelected && "border-gray-400",
                  value?.isOtherSelected &&
                    isInvalid &&
                    "border-gradients-danger"
                )}
                placeholder="Other"
                onChange={(e) =>
                  onSelect({
                    selectedOptions: value?.selectedOptions,
                    otherValue: e.target.value,
                    isOtherSelected: value?.isOtherSelected,
                  })
                }
                onClick={() =>
                  onSelect({
                    selectedOptions: value?.selectedOptions,
                    otherValue: value?.otherValue,
                    isOtherSelected: true,
                  })
                }
                value={value?.otherValue}
              />
            </div>
          </label>
        )}
      </div>
    </FormGroup>
  );
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import { assignRefs, cx, useToast, useTranslations } from "@jugl-web/utils";
import { TaskFormDialog } from "@web-src/features/tasks/TaskFormDialog";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TaskExtraPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskExtraPropertiesPanel";
import {
  TaskChecklist,
  TaskChecklistHandle,
  taskNameValidator,
} from "@jugl-web/domain-resources/tasks";
import { useTaskFormStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { useLocalTaskChecklistHandlers } from "@jugl-web/domain-resources/tasks/hooks/useLocalTaskChecklistHandlers";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { TaskTemplatePickerPopover } from "@web-src/features/tasks/TaskTemplatePickerPopover";
import { Button, LoadingSpinner } from "@jugl-web/ui-components";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { PreviewTaskTemplate } from "@jugl-web/rest-api/tasks-templates";
import { OverrideFormValuesConfirmationAlert } from "@jugl-web/domain-resources/tasks/components/OverrideFormValuesConfirmationAlert";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { mapTaskTemplateToFormState } from "@jugl-web/domain-resources/tasks/utils/mapTaskTemplateToFormState";
import { OrderFormStepper } from "../OrderFormStepper";
import { ReactComponent as SelectTemplateIcon } from "./assets/select-template.svg";

export const OrderFormTaskDetailsStep: React.FC<{
  onOrderFormCreate: () => void;
}> = ({ onOrderFormCreate }) => {
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();
  const meId = useSelector(selectUserId);
  const { tasksTemplatesApi } = useRestApiProvider();
  const { toast } = useToast({ variant: "web" });
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const {
    formState,
    updateFormState,
    updateChecklistItems,
    isDirty,
    resetFormState,
  } = useTaskFormStateContext();
  const $taskChecklistRef = useRef<TaskChecklistHandle | null>(null);
  const templatePickerButtonRef = useRef<HTMLButtonElement | null>(null);

  const overrideFormValuesConfirmationDialogState =
    useConfirmationDialogState();

  const taskChecklistHandlers = useLocalTaskChecklistHandlers({
    itemsSetter: updateChecklistItems,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const isDescriptionBoxVisible = formState.description || isEditingDescription;
  const isValid = taskNameValidator(formState.title);
  const [showTitleError, setShowTitleError] = useState(false);

  const { currentData: detailedTemplate, isFetching: isTemplateLoading } =
    tasksTemplatesApi.useGetTemplateQuery(
      formState.templateId
        ? { entityId, templateId: formState.templateId }
        : skipToken
    );

  const handleCreateOrderForm = useCallback(() => {
    if (!isValid || !formState.assigneeIds.length || !formState.boardId) {
      if (!isValid) {
        setShowTitleError(true);
        inputRef.current?.focus();
      }
      if (!formState.assigneeIds.length || !formState.boardId) {
        toast(
          t({
            id: "order-form-wizard-page.ad-task-assignees-and-select-board",
            defaultMessage:
              "To proceed, add Task Assignees and select Board first",
          }),
          { variant: "error" }
        );
      }
      return;
    }
    onOrderFormCreate();
  }, [formState, isValid, toast, t, onOrderFormCreate]);

  const formStateRef = useRef(formState);

  useEffect(() => {
    formStateRef.current = formState;
  }, [formState]);

  const { templateId } = formState;

  useEffect(() => {
    const shouldLoadTemplateData = templateId && detailedTemplate;

    if (!shouldLoadTemplateData) {
      return;
    }

    const actualFormState = formStateRef.current;
    const templateBasedFormState = mapTaskTemplateToFormState(detailedTemplate);
    const overriddenFormState = {
      ...actualFormState,
      ...templateBasedFormState,
    };

    resetFormState(overriddenFormState);
  }, [templateId, resetFormState, detailedTemplate]);

  const handleSelectTemplate = (template: PreviewTaskTemplate) => {
    if (isDirty()) {
      overrideFormValuesConfirmationDialogState.open({
        onConfirm: () => {
          updateFormState("templateId", template.id);
        },
      });

      return;
    }

    updateFormState("templateId", template.id);
  };

  return (
    <OrderFormStepper onCreateForm={handleCreateOrderForm} className="pb-0">
      <div className="grow overflow-hidden rounded-t-3xl bg-white">
        <TaskFormDialog.Content>
          <TaskFormDialog.TitleSection>
            <div className="text-grey-700 mb-3 text-xs">
              {t({
                id: "order-form-create-page.task-details",
                defaultMessage: "Task Details",
              })}
            </div>
            <div className="flex items-start justify-between gap-4 [&>:first-child]:w-full">
              <TitleBox
                title={formState.title}
                classes={{
                  titleText: "py-3",
                  editingBox: cx(
                    "border-solid border",
                    showTitleError
                      ? "border-gradients-danger"
                      : "border-grey-100"
                  ),
                }}
                onInternalValueChange={(title) => {
                  updateFormState("title", title);
                  if (showTitleError && taskNameValidator(title)) {
                    setShowTitleError(false);
                  }
                }}
                inputRef={inputRef}
              />
              <TaskTemplatePickerPopover
                entityId={entityId}
                meId={meId || ""}
                renderTrigger={({ Trigger, triggerRef }) => (
                  <Trigger
                    ref={assignRefs([triggerRef, templatePickerButtonRef])}
                    as={Button}
                    variant="contained"
                    color="primary"
                    className="font-secondary h-8 w-[180px] shrink-0 font-medium"
                    isDisabled={isTemplateLoading}
                    iconStart={
                      !isTemplateLoading ? (
                        <SelectTemplateIcon className="shrink-0" />
                      ) : undefined
                    }
                  >
                    {isTemplateLoading ? (
                      <LoadingSpinner size="xs" />
                    ) : (
                      <span className="truncate">
                        {detailedTemplate
                          ? t({
                              id: "tasks-page.template",
                              defaultMessage: "Template",
                            })
                              .concat(": ")
                              .concat(detailedTemplate.name)
                          : t({
                              id: "tasks-page.select-template",
                              defaultMessage: "Select Template",
                            })}
                      </span>
                    )}
                  </Trigger>
                )}
                selectedTemplateId={formState.templateId || undefined}
                onSelect={handleSelectTemplate}
              />
            </div>
            {isDescriptionBoxVisible && (
              <DescriptionBox
                description={formState.description}
                isEditing={isEditingDescription}
                classes={{
                  editingBox: "mt-2",
                  descriptionText: "mt-2 py-2",
                }}
                onChange={(value) => updateFormState("description", value)}
                onStartEditing={() => setIsEditingDescription(true)}
                onFinishEditing={() => {
                  setIsEditingDescription(false);
                }}
              />
            )}
            <TaskPropertiesPanel
              entityId={entityId}
              config={{
                board: {
                  boardId: formState.boardId,
                  onChange: (boardId) => updateFormState("boardId", boardId),
                  isHidden: formState.isPrivate,
                },
                description: {
                  isHidden: isEditingDescription || !!formState.description,
                  onClick: () => setIsEditingDescription(true),
                },
                dueDate: {
                  state: formState.dueDate,
                  displayAs: "days",
                  onChange: (state) => updateFormState("dueDate", state),
                },
                assignees: {
                  assigneeIds: formState.assigneeIds,
                  onChange: (assigneeIds) =>
                    updateFormState("assigneeIds", assigneeIds),
                  isHidden: formState.isPrivate,
                },
                priority: {
                  priority: formState.priority,
                  onChange: (priority) => updateFormState("priority", priority),
                },
                label: {
                  labelId: formState.labelId,
                  onChange: (labelId) => updateFormState("labelId", labelId),
                },
              }}
              className="mt-8"
            />
            <TaskExtraPropertiesPanel
              entityId={entityId}
              config={{
                completeChecklistInOrder: {
                  isChecked: formState.completeChecklistInOrder,
                  onChange: (isChecked) =>
                    updateFormState("completeChecklistInOrder", isChecked),
                },
              }}
              className="mt-8"
            />
          </TaskFormDialog.TitleSection>
          <TaskFormDialog.ChecklistSection
            onAddItem={() => $taskChecklistRef.current?.addItem()}
          >
            <TaskChecklist
              ref={$taskChecklistRef}
              entityId={entityId}
              meId={meId || ""}
              items={formState.checklistItems}
              isCompletable={false}
              displayDueDateAs="days"
              {...taskChecklistHandlers}
            />
          </TaskFormDialog.ChecklistSection>
        </TaskFormDialog.Content>
      </div>
      <OverrideFormValuesConfirmationAlert
        isOpen={overrideFormValuesConfirmationDialogState.isOpen}
        onConfirm={() =>
          overrideFormValuesConfirmationDialogState.confirm({
            closeOnceConfirmed: true,
          })
        }
        onClose={overrideFormValuesConfirmationDialogState.close}
      />
    </OrderFormStepper>
  );
};

import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DataLoadingWrapper,
  Alert,
} from "@jugl-web/ui-components/cross-platform";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { ShortLinkDataModel } from "@jugl-web/rest-api/links/models/shortLinkData/shortLinkDataModel";
import Lottie from "react-lottie";
import { useTranslations } from "@jugl-web/utils";
import useEntity from "@web-src/features/app/hooks/useEntity";
import {
  ENTITY_LINK_INVITATION,
  saveItemToStorage,
} from "@jugl-web/utils/storage";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import NotFoundAnimationData from "./assets/404-animation.json";

export const ShortLinkRedirectPage = () => {
  const { list: entityList, isEntitiesFetching } = useEntity();
  const { shortLinkId } = useParams();
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();
  const [showAlert, setShowAlert] = useState(false);

  const { linksApi } = useRestApiProvider();

  const [getShortLinkData, { isLoading, isError }] =
    linksApi.useLazyGetShortLinkDataQuery();

  const redirectBasedOnShortLinkData = useCallback(
    (data: ShortLinkDataModel, isPartOfEntity: boolean) => {
      const { module, entityId } = data;
      switch (module) {
        case "entity_link":
          saveItemToStorage(ENTITY_LINK_INVITATION, data, sessionStorage);
          navigateToPage("entitySelect");
          break;
        case "drive": {
          if (!isPartOfEntity) {
            navigateToPage("entitySelect", undefined, {
              queryParams: {
                error: "access_denied",
                resourceName: data.type === "dir" ? data.folderName : data.name,
              },
            });
            return;
          }
          navigateToPage("driveDrive", undefined, {
            entityId,
            queryParams: {
              full_path: data.full_path,
              path: data.path,
              openedFile: data.type === "file" ? data.id : undefined,
            },
          });
          break;
        }
        case "task": {
          if (!isPartOfEntity) {
            navigateToPage("entitySelect", undefined, {
              queryParams: {
                error: "access_denied",
                resourceName: data.name || "",
              },
            });
            return;
          }
          navigateToPage("taskDetails", { taskId: data.id }, { entityId });
          break;
        }
        default:
          break;
      }
    },
    [navigateToPage]
  );

  const loadShortLinkDataAndRedirect = useCallback(async () => {
    if (!shortLinkId || isEntitiesFetching) {
      return;
    }

    const response = await getShortLinkData({ shortLinkId });
    if (response.data) {
      const isPartOfEntity = entityList.find(
        (entity) => entity.id === response.data?.entityId
      );
      redirectBasedOnShortLinkData(response.data, Boolean(isPartOfEntity));
    }
  }, [
    getShortLinkData,
    redirectBasedOnShortLinkData,
    shortLinkId,
    isEntitiesFetching,
    entityList,
  ]);

  useEffect(() => {
    loadShortLinkDataAndRedirect();
  }, [loadShortLinkDataAndRedirect]);

  useEffect(() => {
    setShowAlert(isError);
  }, [isError]);
  return (
    <>
      <DataLoadingWrapper
        className="h-screen w-screen"
        isLoading={isLoading || isEntitiesFetching}
        isError={isError}
        onRetry={loadShortLinkDataAndRedirect}
        animationSize="2xl"
      />
      <Alert
        isOpen={showAlert}
        buttons={[
          {
            text: t({
              id: "common.okay",
              defaultMessage: "Okay",
            }),
            color: "primary",
            role: "close",
            onClick: () => {
              setShowAlert(false);
              navigateToPage("entitySelect");
            },
          },
        ]}
        img={
          <Lottie
            options={{
              animationData: NotFoundAnimationData,
            }}
            width={300}
            height={262}
          />
        }
        title={t({
          id: "short-links-page.page-not-found",
          defaultMessage: "Page not found",
        })}
        content={t({
          id: "short-links-page.page-not-found-description",
          defaultMessage:
            "It looks like page was deleted or you don't have access to it",
        })}
      />
    </>
  );
};

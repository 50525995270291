import { cx, useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { InteractiveContainer, ListBoxItem } from "@jugl-web/ui-components";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { ReactComponent as UserIcon } from "./assets/user.svg";
import {
  SubmitterContact,
  useOrderForm,
} from "../../../../providers/OrderFormProvider";

export const OrderFormSubmitterInfoSection: React.FC = () => {
  const { t } = useTranslations();
  const { submitterContact, onSubmitterContactChange } = useOrderForm();

  const pickerItems: ListBoxItem<string>[] = useMemo(
    () => [
      {
        id: SubmitterContact.mobile,
        value: t({
          id: "order-form-wizard-page.phone-number",
          defaultMessage: "Phone Number",
        }),
      },
      {
        id: SubmitterContact.email,
        value: t({
          id: "order-form-wizard-page.email",
          defaultMessage: "Email",
        }),
      },
    ],
    [t]
  );

  return (
    <div className="flex flex-col gap-4 overflow-hidden rounded-xl border-l-[6px] border-solid border-white bg-white p-6 transition-colors focus-within:border-[rgba(33,150,243,0.90)]">
      <div className="flex items-center gap-2">
        <UserIcon />
        <span className="text-dark font-secondary text-lg font-medium leading-[27px]">
          {t({
            id: "order-form-wizard-page.form-submitter-info",
            defaultMessage: "Form Submitter Info ",
          })}
        </span>
      </div>
      <TextInput
        label={
          submitterContact === SubmitterContact.email
            ? t({
                id: "order-form-wizard-page.your-email",
                defaultMessage: "Your Email",
              })
            : t({
                id: "order-form-wizard-page.your-phone-number",
                defaultMessage: "Your Phone Number",
              })
        }
        isRequired
        isDisabled
        endContent={
          <ResourcePickerPopover
            placement="bottom-end"
            items={pickerItems}
            selectionBehavior={{ mode: "single", canToggle: false }}
            defaultSelectedIds={[submitterContact]}
            itemSize="sm"
            spaceBetweenItems="compact"
            renderTrigger={({ Trigger, triggerRef, isOpen }) => (
              <Trigger
                ref={triggerRef}
                as={InteractiveContainer}
                className="flex h-full items-center gap-2.5"
              >
                {pickerItems.find((el) => el.id === submitterContact)?.value}
                <ArrowIcon
                  className={cx(
                    "shrink-0 transition-transform",
                    isOpen && "rotate-180"
                  )}
                />
              </Trigger>
            )}
            renderLabel={(item) => item.value}
            onSelect={({ item, onClose }) => {
              onSubmitterContactChange(item.id as SubmitterContact);
              onClose();
            }}
          />
        }
        placeholder={
          submitterContact === SubmitterContact.email
            ? t({
                id: "order-form-wizard-page.enter-your-email",
                defaultMessage: "Enter your email",
              })
            : t({
                id: "order-form-wizard-page.enter-your-phone-number",
                defaultMessage: "Enter your phone number",
              })
        }
      />
      <TextInput
        label={t({
          id: "order-form-wizard-page.your-full-name",
          defaultMessage: "Your full name",
        })}
        placeholder={t({
          id: "order-form-wizard-page.enter-your-full-name",
          defaultMessage: "Enter your full name",
        })}
        isRequired
        isDisabled
      />
    </div>
  );
};

/* eslint-disable react/destructuring-assignment */
import { TaskPropertyButton } from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useState } from "react";
import { PrivateTaskHintDialog } from "../../../PrivateTaskHintDialog";
import { FieldComponentProps } from "../../types";
import { TaskPropertyToggleButton } from "../TaskPropertyToggleButton";
import { ReactComponent as LockIcon } from "./assets/lock.svg";

type PrivateTaskFieldProps = FieldComponentProps<"privateTask">;

export const PrivateTaskField: FC<PrivateTaskFieldProps> = (props) => {
  const [isHintDialogOpen, setIsHintDialogOpen] = useState(false);

  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  const hint = (() => {
    if (isMobile) {
      return undefined;
    }

    if (props.type === "presentational") {
      return t(
        {
          id: "tasks-page.private-task-hint-presentational",
          defaultMessage:
            "You are the <b>One and Only Assignee</b> of this Task. No one, including your Manager, can't see Private Tasks 🙈",
        },
        {
          b: (chunks: (string | JSX.Element)[]) => (
            <span className="font-semibold">{chunks}</span>
          ),
        }
      );
    }

    return t(
      {
        id: "tasks-page.private-task-hint",
        defaultMessage:
          "You will become <b>One and Only Assignee</b> of this Task. No one, including your Manager, can't see Private Tasks 🙈",
      },
      {
        b: (chunks: (string | JSX.Element)[]) => (
          <span className="font-semibold">{chunks}</span>
        ),
      }
    );
  })();

  if (props.type === "presentational") {
    const { isHidden } = props;

    if (isHidden) {
      return null;
    }

    return (
      <>
        <TaskPropertyButton
          hint={hint}
          startIcon={<LockIcon />}
          className={cx(
            "bg-primary-50 text-primary-900",
            !isMobile && "cursor-help"
          )}
          onClick={() => {
            if (isMobile) {
              setIsHintDialogOpen(true);
            }
          }}
        >
          {t({
            id: "tasks-page.private-task",
            defaultMessage: "Private Task",
          })}
        </TaskPropertyButton>
        {isMobile && (
          <PrivateTaskHintDialog
            isOpen={isHintDialogOpen}
            onRequestClose={() => setIsHintDialogOpen(false)}
            isInTaskCreationContext={false}
          />
        )}
      </>
    );
  }

  const { isChecked, isHidden, onChange } = props;

  if (isHidden) {
    return null;
  }

  return (
    <TaskPropertyToggleButton
      label={t({
        id: "tasks-page.private-task",
        defaultMessage: "Private Task",
      })}
      hint={hint}
      isChecked={isChecked}
      onChange={onChange}
    />
  );
};

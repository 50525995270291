import { Alert } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import React from "react";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import orderImage from "./assets/order.png";
import { ReactComponent as OrderFormIcon } from "./assets/order-form.svg";

export const OrderOldVersionAlert: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      img={<img src={orderImage} alt="" />}
      title={t({
        id: "order-forms-page.outdated-form",
        defaultMessage: "Oops! Outdated Form 🫣",
      })}
      isCloseButtonVisible
      header={
        <div className="flex items-center gap-3">
          <OrderFormIcon />
          <span className="font-secondary text-sm font-semibold text-[#383838]">
            {t({
              id: "order-forms-page.updated-order-forms",
              defaultMessage: "Updated Order Forms",
            })}
          </span>
        </div>
      }
      className="w-[750px]"
      content={t(
        {
          id: "order-forms-page.order-form-old-version-description",
          defaultMessage:
            "Form was created in Previous App Version. Outdated Form cannot be edited. We recommend to continue with <highlightedText>our improved Version of Order Forms</highlightedText>, by creating a new form that replicates your existing fields while adding enhanced features 🚀",
        },
        {
          highlightedText: (text: (string | JSX.Element)[]) => (
            <span className="text-primary-800">{text}</span>
          ),
        }
      )}
      buttonsAlignment="vertical"
      buttonsContainerClassName="flex items-center justify-center [&>button]:w-[200px]"
      buttons={[
        {
          text: t({
            id: "order-forms-page.create-form",
            defaultMessage: "Create Form",
          }),
          className: "h-10",
          onClick: () => navigateToPage("orderFormCreate"),
        },
      ]}
    />
  );
};

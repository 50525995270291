import {
  LiveUpdateEvent,
  TASK_CLIENT_FEEDBACK_MODULE,
  TASK_COMMENT_MODULE,
  TASK_MODULE,
} from "../../../live-updates";
import {
  ARCHIVED_ACTION,
  ATTACHMENT_ADDED_ACTION,
  ATTACHMENT_DELETED_ACTION,
  ATTACHMENT_RENAMED_ACTION,
  CREATED_ACTION,
  DELETED_ACTION,
  RESTORED_ACTION,
  TaskClientFeedbackLiveUpdateCreatedEvent,
  TaskClientFeedbackLiveUpdateDeletedEvent,
  TaskClientFeedbackLiveUpdateEvent,
  TaskClientFeedbackLiveUpdateUpdatedEvent,
  TaskCommentLiveUpdateCreatedEvent,
  TaskCommentLiveUpdateDeletedEvent,
  TaskCommentLiveUpdateEvent,
  TaskCommentLiveUpdateUpdatedEvent,
  TaskLiveUpdateArchivedEvent,
  TaskLiveUpdateAttachmentAddedEvent,
  TaskLiveUpdateAttachmentDeletedEvent,
  TaskLiveUpdateAttachmentRenamedEvent,
  TaskLiveUpdateCreatedEvent,
  TaskLiveUpdateDeletedEvent,
  TaskLiveUpdateEvent,
  TaskLiveUpdateRestoredEvent,
  TaskLiveUpdateUpdatedEvent,
  UPDATED_ACTION,
} from "./types";

// #region Task module type guards
export const isTaskLiveUpdateEvent = (
  event: LiveUpdateEvent
): event is TaskLiveUpdateEvent => event.module === TASK_MODULE;

export const isTaskCreatedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateCreatedEvent => event.action === CREATED_ACTION;

export const isTaskRestoredEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateRestoredEvent => event.action === RESTORED_ACTION;

export const isTaskUpdatedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateUpdatedEvent => event.action === UPDATED_ACTION;

export const isTaskDeletedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateDeletedEvent => event.action === DELETED_ACTION;

export const isTaskArchivedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateArchivedEvent => event.action === ARCHIVED_ACTION;

export const isTaskAttachmentAddedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateAttachmentAddedEvent =>
  event.action === ATTACHMENT_ADDED_ACTION;

export const isTaskAttachmentRenamedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateAttachmentRenamedEvent =>
  event.action === ATTACHMENT_RENAMED_ACTION;

export const isTaskAttachmentDeletedEvent = (
  event: TaskLiveUpdateEvent
): event is TaskLiveUpdateAttachmentDeletedEvent =>
  event.action === ATTACHMENT_DELETED_ACTION;
// #endregion

// #region Task comment module type guards
export const isTaskCommentLiveUpdateEvent = (
  event: LiveUpdateEvent
): event is TaskCommentLiveUpdateEvent => event.module === TASK_COMMENT_MODULE;

export const isTaskCommentCreatedEvent = (
  event: TaskCommentLiveUpdateEvent
): event is TaskCommentLiveUpdateCreatedEvent =>
  event.action === CREATED_ACTION;

export const isTaskCommentUpdatedEvent = (
  event: TaskCommentLiveUpdateEvent
): event is TaskCommentLiveUpdateUpdatedEvent =>
  event.action === UPDATED_ACTION;

export const isTaskCommentDeletedEvent = (
  event: TaskCommentLiveUpdateEvent
): event is TaskCommentLiveUpdateDeletedEvent =>
  event.action === DELETED_ACTION;
// #endregion

// #region Task client feedback module type guards
export const isTaskClientFeedbackLiveUpdateEvent = (
  event: LiveUpdateEvent
): event is TaskClientFeedbackLiveUpdateEvent =>
  event.module === TASK_CLIENT_FEEDBACK_MODULE;

export const isTaskClientFeedbackCreatedEvent = (
  event: TaskClientFeedbackLiveUpdateEvent
): event is TaskClientFeedbackLiveUpdateCreatedEvent =>
  event.action === CREATED_ACTION;

export const isTaskClientFeedbackUpdatedEvent = (
  event: TaskClientFeedbackLiveUpdateEvent
): event is TaskClientFeedbackLiveUpdateUpdatedEvent =>
  event.action === UPDATED_ACTION;

export const isTaskClientFeedbackDeletedEvent = (
  event: TaskClientFeedbackLiveUpdateEvent
): event is TaskClientFeedbackLiveUpdateDeletedEvent =>
  event.action === DELETED_ACTION;
// #endregion

import { User } from "@jugl-web/rest-api";
import { TaskActivity } from "@jugl-web/rest-api/tasks";
import {
  addSearchParamsToURL,
  assert,
  getUniqueId,
  useTranslations,
} from "@jugl-web/utils";
import { ReactNode, useCallback } from "react";
import { useTaskActivityMessageRenderers } from "./useTaskActivityMessageRenderers";

export interface ParsedTaskActivity {
  id: string;
  message: ReactNode;
  rating?: { rate: number; tags: string[] };
  raw: TaskActivity;
  user: {
    username: string;
    avatarUrl: string | null;
    isExternalClient?: boolean;
  };
}

interface UseTaskActivityParserOptions {
  entityId: string | undefined;
  meUser: User | undefined;
}

export const useTaskActivityParser = ({
  entityId,
  meUser,
}: UseTaskActivityParserOptions) => {
  const { t } = useTranslations();

  const renderers = useTaskActivityMessageRenderers({ entityId });

  const parseTaskActivity = useCallback(
    (activity: TaskActivity): ParsedTaskActivity | null => {
      const id = getUniqueId();

      const user =
        activity.action_by.user_id === meUser?.id
          ? meUser?.profile
          : activity.action_by;

      const username =
        [user?.first_name, user?.last_name]
          .filter((item) => !!item)
          .join(" ") ||
        t({ id: "common.jugl-user", defaultMessage: "Jugl User" });

      const guestAvatarUrl =
        user && "logo" in user && user.logo
          ? addSearchParamsToURL(user.logo, { t: user.updated_at })
          : null;

      const { action } = activity.action_details;

      if (action === "guest_feedback") {
        return {
          id,
          message: renderers.taskCommentRenderer(
            activity.action_details?.changes.content.feedback
          ),
          rating: {
            rate: activity.action_details?.changes.content.rating,
            tags: activity.action_details?.changes.content.hashtag,
          },
          raw: activity,
          user: {
            username,
            avatarUrl: guestAvatarUrl,
          },
        };
      }

      if (
        action === "viewed" ||
        action === "commented" ||
        action === "guest_comment" ||
        action === "guest_comment_reply"
      ) {
        return {
          id,
          message:
            action === "viewed"
              ? renderers.taskViewedMessageRenderer(username)
              : renderers.taskCommentRenderer(
                  activity.action_details?.changes?.content
                ),
          raw: activity,
          user: {
            username,
            avatarUrl:
              action === "guest_comment"
                ? guestAvatarUrl
                : user?.img
                ? addSearchParamsToURL(user.img, {
                    t: user.updated_at,
                  })
                : null,
          },
        };
      }

      if (action === "guest_opened") {
        return {
          id,
          message: renderers.taskViewedMessageRenderer(username),
          raw: activity,
          user: {
            username,
            avatarUrl: guestAvatarUrl,
          },
        };
      }

      if (
        action === "created" &&
        activity?.action_by.user_id === "00000000-0000-0000-0000-000000000000"
      ) {
        return {
          id,
          message: t(
            {
              id: "tasks-page.external-user-created-task-activity",
              defaultMessage:
                "{username} created this task by submitting the order form",
            },
            {
              username: (
                <b>
                  {t({
                    id: "common.external-client",
                    defaultMessage: "External client",
                  })}
                </b>
              ),
            }
          ),
          raw: activity,
          user: {
            username,
            avatarUrl: null,
            isExternalClient: true,
          },
        };
      }

      if (action === "downloaded" || action === "guest_downloaded") {
        return {
          id,
          message: renderers.taskDownloadedMessageRenderer(
            username,
            activity.action_details?.file_name
          ),
          raw: activity,
          user: {
            username,
            avatarUrl:
              guestAvatarUrl ||
              (user?.img
                ? addSearchParamsToURL(user.img, {
                    t: user.updated_at,
                  })
                : null),
          },
        };
      }

      if (action === "created" || action === "deleted") {
        return {
          id,
          message:
            action === "created"
              ? renderers.taskCreatedMessageRenderer(username)
              : renderers.taskDeletedMessageRenderer(username),
          raw: activity,
          user: {
            username,
            avatarUrl: user?.img
              ? addSearchParamsToURL(user.img, {
                  t: user.updated_at,
                })
              : null,
          },
        };
      }

      if (action === "restored" || action === "archived") {
        return {
          id,
          message:
            action === "restored"
              ? renderers.taskRestoredMessageRenderer(username)
              : renderers.taskArchivedMessageRenderer(username),
          raw: activity,
          user: {
            username,
            avatarUrl: user?.img
              ? addSearchParamsToURL(user.img, {
                  t: user.updated_at,
                })
              : null,
          },
        };
      }

      assert(action === "updated");

      return {
        id,
        message: renderers.taskUpdatedMessageRenderer(
          username,
          activity.action_details.changes
        ),
        raw: activity,
        user: {
          username,
          avatarUrl: user?.img
            ? addSearchParamsToURL(user.img, {
                t: user.updated_at,
              })
            : null,
        },
      };
    },
    [meUser, renderers, t]
  );

  const parseTaskActivities = useCallback(
    (activities: TaskActivity[]) =>
      activities.reduce<ParsedTaskActivity[]>((acc, activity) => {
        const output = parseTaskActivity(activity);

        if (output) {
          return [...acc, output];
        }

        return acc;
      }, []),
    [parseTaskActivity]
  );

  return { parseTaskActivity, parseTaskActivities };
};

export enum OrderFormStep {
  form = "form",
  details = "details",
}

export interface OrderFormValues {
  image: File | null;
  title: string;
  description: string;
}

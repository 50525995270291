import { RegisterOptions } from "react-hook-form";

type ReactHookFormRules = Omit<
  RegisterOptions,
  "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
>;

export const useFormFieldsValidators = () => {
  const requiredValidator = (): ReactHookFormRules => ({
    required: "This field is required",
    validate: (value) =>
      value.trim() !== "" ? true : "This field is required",
  });

  const emailValidator = (): ReactHookFormRules => ({
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      message: "Invalid email address",
    },
  });

  const lengthValidator = (maxLength: number): ReactHookFormRules => ({
    maxLength: {
      value: maxLength,
      message: "The input exceeds the maximum allowed length",
    },
  });

  return { requiredValidator, emailValidator, lengthValidator };
};

import { useRestApiProvider } from "@jugl-web/rest-api";
import { PlainButton, Popover, PopoverProps } from "@jugl-web/ui-components";
import { cx, useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { FC, forwardRef, HTMLAttributes, ReactNode, useCallback } from "react";
import { MoveToTaskPopover } from "../../MoveToTaskPopover";
import { useTaskChecklistContext } from "../../TaskChecklistProvider";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as MoveIcon } from "./assets/move.svg";
import { ReactComponent as SelectItemsIcon } from "./assets/select-items.svg";

interface MenuItemButtonProps extends HTMLAttributes<HTMLButtonElement> {
  label: string;
  isActive?: boolean;
  startSlot?: ReactNode;
  endSlot?: ReactNode;
}

const MenuItemButton = forwardRef<HTMLButtonElement, MenuItemButtonProps>(
  ({ label, isActive, startSlot, endSlot, className, ...props }, ref) => (
    <PlainButton
      ref={ref}
      className={cx(
        "transition-color hover:bg-grey-100 focus:bg-grey-100 flex h-full w-full items-center gap-2.5 px-4 py-3 outline-none",
        isActive && "bg-grey-100",
        className
      )}
      {...props}
    >
      {startSlot}
      <span
        className={cx(
          "text-dark font-secondary grow text-left text-sm leading-[16px]"
        )}
      >
        {label}
      </span>
      {endSlot}
    </PlainButton>
  )
);

interface TaskChecklistItemMenuPopoverProps
  extends Pick<PopoverProps, "placement" | "renderTrigger"> {
  itemId: string;
  onDelete: (itemId: string) => void;
}

export const TaskChecklistItemMenuPopover: FC<
  TaskChecklistItemMenuPopoverProps
> = ({ itemId, onDelete, ...popoverProps }) => {
  const {
    entityId,
    meId,
    taskId,
    isSelectable,
    onSelectModeChange,
    onSelectedChecklistIdsChange,
    onNavigateToTask,
  } = useTaskChecklistContext();

  const { isWeb } = useAppVariant();
  const { toast, closeToast } = useToast();
  const { t } = useTranslations();

  const { tasksApi } = useRestApiProvider();

  const [moveChecklistItems] = tasksApi.useMoveChecklistItemsMutation();

  const handleEnterSelectMode = () => {
    onSelectModeChange(true);
    onSelectedChecklistIdsChange([itemId]);
  };

  const handleMoveChecklistItems = useCallback(
    async (targetTaskId: string) => {
      if (!taskId) {
        return;
      }

      const response = await moveChecklistItems({
        entityId,
        itemIds: [itemId],
        targetTaskId,
        taskId,
      });

      if (!("data" in response) || !onNavigateToTask) {
        return;
      }

      if (!onNavigateToTask) {
        return;
      }

      const toastId = toast(
        t(
          {
            id: "tasks-page.subtasks-has-been-moved-tap-to-open",
            defaultMessage:
              "{count, plural, one {Subtask} other {{count} Subtasks}} has been moved. Tap to open it",
          },
          { count: 1 }
        ),
        {
          SnackbarProps: {
            style: {
              cursor: "pointer",
              userSelect: "none",
            },
            onClick: () => {
              onNavigateToTask(targetTaskId);
              closeToast(toastId);
            },
          },
        }
      );
    },
    [
      taskId,
      moveChecklistItems,
      entityId,
      itemId,
      onNavigateToTask,
      toast,
      t,
      closeToast,
    ]
  );

  return (
    <Popover
      className="max-h-[300px] w-[242px] overflow-y-auto rounded-xl bg-white shadow-[0px_8px_16px_rgba(0,0,0,0.12)] outline-none"
      {...popoverProps}
    >
      {({ onClose }) => (
        <>
          {isSelectable && isWeb && (
            <MoveToTaskPopover
              entityId={entityId}
              meId={meId}
              taskId={taskId as string}
              placement="left-start"
              renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                <Trigger
                  ref={triggerRef}
                  as={MenuItemButton}
                  label={t({
                    id: "tasks-page.move-subtask",
                    defaultMessage: "Move Subtask",
                  })}
                  isActive={isOpen}
                  startSlot={<MoveIcon className="shrink-0" />}
                  endSlot={<ChevronRightIcon className="shrink-0" />}
                />
              )}
              onSelect={(targetTask) => {
                handleMoveChecklistItems(targetTask.id);
                onClose();
              }}
            />
          )}
          {isSelectable && (
            <MenuItemButton
              label={t({
                id: "tasks-page.select-subtasks",
                defaultMessage: "Select Subtasks",
              })}
              startSlot={<SelectItemsIcon className="shrink-0" />}
              onClick={(event) => {
                event.stopPropagation();
                handleEnterSelectMode();
              }}
            />
          )}
          <MenuItemButton
            label={t({
              id: "common.delete",
              defaultMessage: "Delete",
            })}
            startSlot={<DeleteIcon className="shrink-0" />}
            onClick={(event) => {
              event.stopPropagation();
              onDelete(itemId);
            }}
          />
        </>
      )}
    </Popover>
  );
};

import { RtkEmptySplitApi } from "../../types";
import { OrderFormLinkDataModel } from "./models/OrderFormLinkModel";
import { ShortLinkDataDto } from "./models/shortLinkData/shortLinkDataDto";
import { ShortLinkDataModel } from "./models/shortLinkData/shortLinkDataModel";
import { transformShortLinkDataDtoToModel } from "./models/shortLinkData/transformers";

export const addLinksApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [],
  });
  const linksApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      getShortLinkData: build.query<
        ShortLinkDataModel,
        {
          shortLinkId: string;
        }
      >({
        query: ({ shortLinkId }) => ({
          url: `/L/${shortLinkId}`,
        }),
        transformResponse: (data: ShortLinkDataDto) =>
          transformShortLinkDataDtoToModel(data),
      }),
      getFormShortLinkData: build.query<
        OrderFormLinkDataModel,
        {
          shortLinkId: string;
        }
      >({
        query: ({ shortLinkId }) => ({
          url: `/F/${shortLinkId}`,
        }),
      }),
    }),
  });

  return linksApi;
};

export type LinksApi = ReturnType<typeof addLinksApi>;

import { TaskPropertyButton } from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { FC } from "react";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as OrderIcon } from "../../assets/order.svg";
import { FieldComponentProps } from "../../types";

export const OrderField: FC<FieldComponentProps<"order">> = ({
  isHidden,
  onClick,
}) => {
  const { t } = useTranslations();

  if (isHidden) {
    return null;
  }

  return (
    <TaskPropertyButton
      startIcon={<OrderIcon />}
      onClick={onClick}
      className="bg-messages-chatBubbleSender"
    >
      <span className="font-medium text-white">
        {t({
          id: "tasks-page.order-details",
          defaultMessage: "Order Details",
        })}
      </span>
    </TaskPropertyButton>
  );
};

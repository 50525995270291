import { InventoryItemCategory } from "@jugl-web/rest-api";
import { cx, useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";

export const OrderCategoryTile: React.FC<{
  category: InventoryItemCategory | null;
}> = ({ category }) => {
  const { t } = useTranslations();

  const { classNames, label } = useMemo(() => {
    switch (category) {
      case InventoryItemCategory.item:
        return {
          classNames: "bg-primary-50 text-primary-800",
          label: t({
            id: "common.item",
            defaultMessage: "Item",
          }),
        };
      case InventoryItemCategory.service:
        return {
          classNames: "bg-secondary-50 text-secondary-800",
          label: t({
            id: "common.service",
            defaultMessage: "Service",
          }),
        };
      default:
        return {
          label: category,
        };
    }
  }, [category, t]);

  return (
    <div
      className={cx(
        "flex h-full w-full items-center justify-center text-sm",
        classNames
      )}
    >
      {label}
    </div>
  );
};

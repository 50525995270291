import React, { useCallback, useEffect, useState } from "react";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { useTranslations } from "@jugl-web/utils";
import { OrderFormFieldType } from "@jugl-web/rest-api/orders/types";
import { OrderFormField } from "../OrderFormField";
import { useOrderForm } from "../../../../providers/OrderFormProvider";
import { useOrderFormValidators } from "../../hooks/useOrderFormValidators";

export const ShortTextField: React.FC<{ id: string; index: number }> = ({
  id,
  index,
}) => {
  const { t } = useTranslations();
  const { getFieldById, updateFieldById, triggerValidate } = useOrderForm();
  const [shouldShowError, setShouldShowError] = useState(false);
  const {
    isRequired,
    property: { value, type },
  } = getFieldById(id);
  const { checkIsOrderTextFieldValid } = useOrderFormValidators();

  if (type !== OrderFormFieldType.text) throw new Error("Bad order field type");

  const [isExpanded, setIsExpanded] = useState(
    !checkIsOrderTextFieldValid(value)
  );

  const handleValidateField = useCallback(() => {
    setShouldShowError(!checkIsOrderTextFieldValid(value));
  }, [value, checkIsOrderTextFieldValid]);

  useEffect(() => {
    const subscription = triggerValidate.subscribe(() => {
      handleValidateField();
    });
    return () => subscription.unsubscribe();
  }, [triggerValidate, handleValidateField]);

  useEffect(() => {
    if (shouldShowError) {
      handleValidateField();
    }
  }, [value, shouldShowError, handleValidateField]);

  return (
    <OrderFormField
      id={id}
      index={index}
      isExpanded={isExpanded}
      onClick={() => setIsExpanded(true)}
      onClickAway={() => {
        if (checkIsOrderTextFieldValid(value)) {
          setIsExpanded(false);
        }
      }}
    >
      {isExpanded ? (
        <>
          <TextInput
            label={t({
              id: "order-form-wizard-page.field-label",
              defaultMessage: "Field label",
            })}
            placeholder={t({
              id: "order-form-wizard-page.enter-field-label",
              defaultMessage: "Enter field label",
            })}
            onChange={(e) =>
              updateFieldById({
                id,
                type,
                value: { ...value, label: e.target.value.trimStart() },
              })
            }
            isRequired
            value={value.label}
            isInvalid={shouldShowError && !value.label}
            autoFocus
            errorMessage={
              shouldShowError && !value.label
                ? t({
                    id: "order-form-wizard-page.field-cant-be-empty",
                    defaultMessage: "This field cannot be left empty",
                  })
                : undefined
            }
          />
          <TextInput
            label={t({
              id: "order-form-wizard-page.placeholder",
              defaultMessage: "Placeholder",
            })}
            placeholder={t({
              id: "order-form-wizard-page.enter-placeholder-text",
              defaultMessage: "Enter placeholder text",
            })}
            onChange={(e) =>
              updateFieldById({
                id,
                type,
                value: { ...value, placeholder: e.target.value.trimStart() },
              })
            }
            value={value.placeholder}
          />
        </>
      ) : (
        <TextInput
          label={value.label}
          placeholder={
            value.placeholder ||
            t({
              id: "common.enter",
              defaultMessage: "Enter",
            })
          }
          isRequired={isRequired}
          isDisabled
          classNames={{
            inputClassName: "pointer-events-none",
          }}
        />
      )}
    </OrderFormField>
  );
};

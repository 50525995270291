import {
  InteractiveContainer,
  Menu,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as ListMoreIcon } from "../../assets/list-more.svg";
import { AttachmentLayoutItemProps } from "../../types";

export const AttachmentListItem: FC<AttachmentLayoutItemProps> = ({
  image,
  name,
  highlightedName,
  updatedAtLabel,
  sizeLabel,
  menuSections,
  isEditable,
  onClick,
}) => (
  <InteractiveContainer
    title={name}
    onClick={onClick}
    className="bg-grey-100 flex h-[72px] items-center justify-start gap-4 rounded-[10px] px-6 transition"
  >
    <div className="relative h-10 w-10 shrink-0 text-center">{image}</div>
    <span className="text-dark font-secondary truncate text-sm font-medium">
      {highlightedName}
    </span>
    <span className="text-grey shrink-0 text-[13px] font-medium">
      {updatedAtLabel}
    </span>
    <span className="text-grey shrink-0 text-[13px] font-medium">
      {sizeLabel}
    </span>
    <div className="grow" />
    {isEditable && (
      <div className="shrink-0">
        <Menu
          placement="bottom"
          sections={menuSections}
          className="min-w-[unset]"
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger
              as={PlainButton}
              ref={triggerRef}
              onClick={(event) => event.stopPropagation()}
              className={cx(
                "flex h-8 w-8 items-center justify-center rounded-md transition",
                isOpen ? "bg-grey-200" : "hover:bg-grey-200"
              )}
            >
              <ListMoreIcon />
            </Trigger>
          )}
        />
      </div>
    )}
  </InteractiveContainer>
);

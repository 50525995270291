import { InventoryItem, InventoryItemCategory } from "@jugl-web/rest-api";
import { Checkbox, InteractiveContainer } from "@jugl-web/ui-components";
import { cx, priceDisplay, useTranslations } from "@jugl-web/utils";
import React, { useMemo } from "react";
import Highlighter from "react-highlight-words";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { ReactComponent as ImageIcon } from "./assets/image.svg";

const IS_STOCK_HIDDEN = true;

export const ServiceDrawerItem: React.FC<{
  isChecked: boolean;
  item: InventoryItem;
  searchQuery?: string;
  onChange: (isChecked: boolean) => void;
}> = ({ isChecked, item, searchQuery, onChange }) => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();

  const itemImage = useMemo(
    () => item.img?.find((el) => el.order === 1)?.path || item.img?.[0]?.path,
    [item.img]
  );
  return (
    <InteractiveContainer
      className={cx(
        "flex w-full items-center gap-4 overflow-hidden rounded-2xl py-4 px-5 transition-colors",
        isChecked && "bg-[#F1F9FF]"
      )}
      onClick={() => onChange(!isChecked)}
    >
      <div className="flex w-full items-center gap-4 overflow-hidden">
        <Checkbox isChecked={isChecked} readOnly />
        <div className="flex w-full items-center gap-5 overflow-hidden">
          <div className="border-grey-400 bg-grey-200 flex h-[72px] w-[72px] shrink-0 items-center justify-center overflow-hidden rounded-[10px] border border-solid">
            {itemImage ? (
              <img
                src={itemImage}
                className="h-full w-full object-cover"
                alt={item.name}
              />
            ) : (
              <ImageIcon />
            )}
          </div>
          <div className="flex w-full flex-col gap-0.5 overflow-hidden">
            <span className="text-dark font-secondary w-full break-words text-sm font-medium leading-[140%] tracking-[0.14px]">
              <Highlighter
                autoEscape
                highlightClassName="bg-primary-200"
                searchWords={[searchQuery || ""]}
                textToHighlight={item.name}
              />
            </span>
            <span className="text-grey-800 font-secondary break-words text-xs leading-[140%]">
              {item.desc}
            </span>
            {item.category === InventoryItemCategory.item &&
              !IS_STOCK_HIDDEN && (
                <span className="text-grey-800 font-secondary mt-[14px] text-xs leading-[140%] tracking-[0.12px]">
                  {t({
                    id: "order-form-wizard-page.stock-on-hand",
                    defaultMessage: "Stock on hand",
                  })}
                  {": "}
                  {item.stock.stock === 0 ? (
                    <span className="text-gradients-danger text-sm leading-[140%] tracking-[0.14px]">
                      {t({
                        id: "order-form-wizard-page.out-of-stock",
                        defaultMessage: "Out of Stock",
                      })}
                    </span>
                  ) : (
                    <span className="text-primary-700 text-sm leading-[140%] tracking-[0.14px]">
                      {item.stock.stock} {item.unit}
                    </span>
                  )}
                </span>
              )}
          </div>
        </div>
      </div>
      <span className="text-dark flex shrink-0 items-center text-sm font-medium leading-[140%] tracking-[0.14px]">
        {priceDisplay(parseFloat(item.price) * 100, entity.currency)}
        <span className="text-grey-700 font-normal">
          /
          {item.category === InventoryItemCategory.item
            ? item.unit
            : t({
                id: "order-form-wizard-page.service",
                defaultMessage: "service",
              })}
        </span>
      </span>
    </InteractiveContainer>
  );
};

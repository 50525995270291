import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import { cx } from "@jugl-web/utils";
import { FC, useState } from "react";
import { ReactComponent as PlayIcon } from "../../assets/play.svg";
import { AttachmentsLayout } from "../../types";
import { getFileImagePath, getFileType } from "../../utils";

export interface TaskAttachmentItemProps {
  attachment: TaskAttachment;
  layout: AttachmentsLayout;
  isEditable: boolean;
  highlightedText?: string;
  onPreview: () => void;
  onRename?: (fileName: string) => void;
  onRemove?: () => void;
  onDownload?: () => void;
}

interface AttachmentImageProps {
  attachment: TaskAttachment;
  layout: AttachmentsLayout;
}

export const AttachmentImage: FC<AttachmentImageProps> = ({
  attachment,
  layout,
}) => {
  const [hasImageLoadingError, setHasImageLoadingError] = useState(false);

  return (
    <>
      {attachment.preview_available && !hasImageLoadingError ? (
        <>
          <img
            src={attachment.preview_url}
            alt={attachment.name}
            className={cx(
              "h-full w-full object-cover",
              layout === "list" && "border-grey-200 rounded border border-solid"
            )}
            onError={() => setHasImageLoadingError(true)}
          />
          {getFileType(attachment.name) === "video" && (
            <PlayIcon className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
          )}
        </>
      ) : (
        <img
          src={getFileImagePath(
            attachment.name,
            layout === "grid" ? "md" : "sm"
          )}
          className="h-full"
          alt={attachment.name}
        />
      )}
    </>
  );
};

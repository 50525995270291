import { PreviewTaskTemplate } from "@jugl-web/rest-api/tasks-templates";
import { useLocalStorage } from "@jugl-web/utils/hooks/useStorage";
import { RECENTLY_USED_TASK_TEMPLATES_KEY } from "@jugl-web/utils/storage";
import { useCallback, useMemo } from "react";
import { useTaskTemplates } from "./useTaskTemplates";

interface UseRecentlyUsedTaskTemplatesOptions {
  entityId: string;
  meId: string;
}

const RECENTLY_USED_TEMPLATES_LIMIT = 3;

export const useRecentlyUsedTaskTemplates = ({
  entityId,
  meId,
}: UseRecentlyUsedTaskTemplatesOptions) => {
  const [cachedIds, setCachedIds] = useLocalStorage<string[]>(
    RECENTLY_USED_TASK_TEMPLATES_KEY,
    []
  );

  const { templates } = useTaskTemplates({
    entityId,
    meId,
    sorting: "createdAtDesc",
  });

  const recentlyUsedTaskTemplates = useMemo(() => {
    const matchingTemplates = cachedIds
      .map((id) => templates.find((template) => template.id === id))
      .filter(Boolean) as PreviewTaskTemplate[];

    if (matchingTemplates.length === RECENTLY_USED_TEMPLATES_LIMIT) {
      return matchingTemplates;
    }

    const matchingTemplateIds = matchingTemplates.map(
      (template) => template.id
    );

    const remainingTemplates = templates.filter(
      (template) => !matchingTemplateIds.includes(template.id)
    );

    return [
      ...matchingTemplates,
      ...remainingTemplates.slice(
        0,
        RECENTLY_USED_TEMPLATES_LIMIT - matchingTemplates.length
      ),
    ];
  }, [cachedIds, templates]);

  const hasMoreTemplates = templates.length > recentlyUsedTaskTemplates.length;

  const addRecentlyUsedTaskTemplate = useCallback(
    (templateId: string) => {
      setCachedIds((previousIds) =>
        [templateId, ...previousIds.filter((id) => id !== templateId)].slice(
          0,
          RECENTLY_USED_TEMPLATES_LIMIT
        )
      );
    },
    [setCachedIds]
  );

  return {
    recentlyUsedTaskTemplates,
    hasMoreTemplates,
    addRecentlyUsedTaskTemplate,
  };
};

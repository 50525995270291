import { useTranslations, cx, priceDisplay } from "@jugl-web/utils";
import {
  TableGrid,
  Menu,
  PlainButton,
  Checkbox,
  Popover,
} from "@jugl-web/ui-components";
import { InventoryItemDataDto } from "@jugl-web/rest-api/inventory/models/InventoryItem";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useState } from "react";
import { InventoryItem } from "@jugl-web/rest-api";
import { DndContext, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import Highlighter from "react-highlight-words";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { ReactComponent as MoreIcon } from "./assets/settings-toggle.svg";
import { ReactComponent as EditIcon } from "./assets/settings-edit.svg";
import { ReactComponent as DeleteIcon } from "./assets/settings-delete.svg";
import { ReactComponent as ColumnFilterToggleIcon } from "./assets/column-filter-toggle.svg";
import { ReactComponent as NoImageIndicatorIcon } from "./assets/no-image-indicator.svg";
import { useInventoryTable } from "../../InventoryTableContext";
import { ManageColumnsPopoverContent } from "./components/ManageColumnsPopoverContent";
import { ConfirmRemoveItemsAlert } from "../ConfirmRemoveItemsAlert";
import { parseDimensionsString } from "../../../InventoryItemFormPage/utils";

export const InventoryItemsTable = ({
  data,
  checkedList,
  searchQuery,
  isLoading,
  handleCheckboxChange,
  onItemDetailsOpen,
  onItemDelete,
  onReachEnd,
}: {
  data: InventoryItemDataDto[];
  checkedList: Record<string, boolean>;
  searchQuery?: string;
  isLoading?: boolean;
  handleCheckboxChange: (id: string) => void;
  onItemDetailsOpen: (id: string) => void;
  onItemDelete: (id: string) => void;
  onReachEnd: () => void;
}) => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();
  const { navigateToPage } = useNavigation();
  const { tableSettings } = useInventoryTable();
  const [itemIdToDelete, setItemIdToDelete] = useState<string>();
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor);

  return (
    <div className="p-8">
      <TableGrid
        unstyled
        data={data}
        className="min-w-fit rounded-xl border-[1px] border-solid border-[#EEF2F5]"
        headerCellClassName="px-4 py-3 text-xs text-dark-700 font-normal leading-[140%] border-0 border-r border-solid border-[#EEF2F5] flex items-center"
        cellClassName="px-4 text-dark-800 font-normal flex items-center text-sm py-4 border-0 border-t border-r border-solid border-[#EEF2F5] leading-[140%] tracking-[0.14px] hover:bg-grey-200"
        rowHoverClassName="bg-grey-100"
        onReachEnd={onReachEnd}
        columns={[
          {
            title: (
              <DndContext sensors={sensors}>
                <Popover
                  placement="bottom-start"
                  className="p-4"
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger as={PlainButton} ref={triggerRef}>
                      <ColumnFilterToggleIcon />
                    </Trigger>
                  )}
                >
                  <ManageColumnsPopoverContent />
                </Popover>
              </DndContext>
            ),
            grow: false,
            width: 50,
            order: -2,
            className: "p-0",
            content: ({ id }: InventoryItem) => (
              <div
                className="flex h-full w-full cursor-pointer items-center justify-center"
                onClick={() => {
                  handleCheckboxChange(id);
                }}
              >
                <Checkbox size="sm" isChecked={checkedList[id]} />
              </div>
            ),
          },
          {
            title: t({
              id: "inventory-items-page.table-item/service-name",
              defaultMessage: "Item / service name",
            }),
            grow: false,
            width: 320,
            order: tableSettings.columns.name.order,
            isHidden: false,
            className: "truncate p-0",
            content: ({ name, img, id }: InventoryItem) => {
              const mainImage =
                img.find((image) => image.order === 1) || img[0];
              return (
                <div
                  className="flex h-full w-full cursor-pointer items-center gap-3 truncate px-4"
                  onClick={() => onItemDetailsOpen(id)}
                >
                  {img.length ? (
                    <img
                      src={mainImage.path}
                      alt=""
                      className="h-8 w-8 rounded-[10px]"
                    />
                  ) : (
                    <div className="bg-grey-200 flex h-8 w-8  items-center justify-center  rounded-[10px]">
                      <NoImageIndicatorIcon />
                    </div>
                  )}
                  <div
                    className="font-secondary text-dark w-3/4 truncate text-base font-medium leading-[22px]"
                    title={name}
                  >
                    <Highlighter
                      autoEscape
                      highlightClassName="text-primary"
                      highlightTag="span"
                      textToHighlight={name}
                      searchWords={[searchQuery || ""]}
                    />
                  </div>
                </div>
              );
            },
          },
          {
            title: t({
              id: "common.category",
              defaultMessage: "Category",
            }),
            grow: false,
            width: 90,
            className: "truncate p-[2px]",
            order: tableSettings.columns.category.order,
            isHidden: !tableSettings.columns.category.visible,
            content: ({ category }: InventoryItem) => (
              <div
                className={cx(
                  "flex h-full w-full items-center justify-center text-sm",
                  {
                    "bg-primary-50 text-primary-800": category === "item",
                    "bg-secondary-50 text-secondary-800":
                      category === "service",
                  }
                )}
              >
                {category === "item" &&
                  t({
                    id: "common.item",
                    defaultMessage: "Item",
                  })}
                {category === "service" &&
                  t({
                    id: "common.service",
                    defaultMessage: "Service",
                  })}
              </div>
            ),
          },
          {
            title: t({
              id: "common.unit",
              defaultMessage: "Unit",
            }),
            grow: false,
            width: 110,
            className: "truncate",
            order: tableSettings.columns.unit.order,
            isHidden: !tableSettings.columns.unit.visible,
            content: ({ category, unit }: InventoryItem) =>
              category === "item" ? <div>{unit}</div> : <></>,
          },
          {
            title: t({
              id: "common.price",
              defaultMessage: "Price",
            }),
            grow: false,
            width: 200,
            className: "truncate",
            order: tableSettings.columns.price.order,
            isHidden: !tableSettings.columns.price.visible,
            content: ({ category, price }: InventoryItem) => (
              <div className="text-dark text-sm">
                {priceDisplay(parseFloat(price) * 100, entity.currency)}
                <span className="text-grey-700 pl-[2px]">
                  {category === "item" &&
                    `/${t({
                      id: "common.unit",
                      defaultMessage: "Unit",
                    }).toLocaleLowerCase()}`}
                </span>
              </div>
            ),
          },
          {
            title: t({
              id: "common.description",
              defaultMessage: "Description",
            }),
            className: "truncate",
            width: 500,
            order: tableSettings.columns.description.order,
            isHidden: !tableSettings.columns.description.visible,
            content: ({ desc }: InventoryItem) => (
              <div className="w-11/12 truncate" title={desc}>
                <Linkify>{desc}</Linkify>
              </div>
            ),
          },
          {
            title: t({
              id: "common.sku",
              defaultMessage: "SKU",
            }),
            grow: false,
            width: 120,
            className: "truncate",
            order: tableSettings.columns.sku.order,
            isHidden: !tableSettings.columns.sku.visible,
            content: ({ category, info }: InventoryItem) =>
              category === "item" ? (
                <div className="w-11/12 truncate" title={info.sku || ""}>
                  {info.sku}
                </div>
              ) : (
                <></>
              ),
          },
          {
            title: t({
              id: "common.weight",
              defaultMessage: "Weight",
            }),
            grow: false,
            width: 110,
            className: "truncate",
            order: tableSettings.columns.weight.order,
            isHidden: !tableSettings.columns.weight.visible,
            content: ({ info, category }: InventoryItem) => {
              if (category === "service") {
                return <></>;
              }
              const [weight, unit] = (info.weight || "").split(" ");

              return (
                <div className="w-11/12 truncate" title={info.weight || ""}>
                  {weight} <span className="text-grey-700">{unit}</span>
                </div>
              );
            },
          },
          {
            title: t({
              id: "common.upc",
              defaultMessage: "UPC",
            }),
            grow: false,
            width: 150,
            className: "truncate",
            order: tableSettings.columns.upc.order,
            isHidden: !tableSettings.columns.upc.visible,
            content: ({ category, info }: InventoryItem) =>
              category === "item" ? (
                <div className="w-11/12 truncate" title={info.upc || ""}>
                  {info.upc}
                </div>
              ) : (
                <></>
              ),
          },
          {
            title: t({
              id: "common.ean",
              defaultMessage: "EAN",
            }),
            grow: false,
            width: 150,
            className: "truncate",
            order: tableSettings.columns.ean.order,
            isHidden: !tableSettings.columns.ean.visible,
            content: ({ category, info }: InventoryItem) =>
              category === "item" ? (
                <div className="w-11/12 truncate" title={info.ean || ""}>
                  {info.ean}
                </div>
              ) : (
                <></>
              ),
          },
          {
            title: t({
              id: "common.mpn",
              defaultMessage: "MPN",
            }),
            grow: false,
            width: 150,
            className: "truncate",
            order: tableSettings.columns.mpn.order,
            isHidden: !tableSettings.columns.mpn.visible,
            content: ({ category, info }: InventoryItem) =>
              category === "item" ? (
                <div className="w-11/12 truncate" title={info.mpn || ""}>
                  {info.mpn}
                </div>
              ) : (
                <></>
              ),
          },
          {
            title: t({
              id: "common.isbn",
              defaultMessage: "ISBN",
            }),
            grow: false,
            width: 150,
            className: "truncate",
            order: tableSettings.columns.isbn.order,
            isHidden: !tableSettings.columns.isbn.visible,
            content: ({ category, info }: InventoryItem) =>
              category === "item" ? (
                <div className="w-11/12 truncate" title={info.isbn || ""}>
                  {info.isbn}
                </div>
              ) : (
                <></>
              ),
          },
          {
            title: t({
              id: "common.dimensions",
              defaultMessage: "Dimensions",
            }),
            width: 140,
            grow: false,
            className: "truncate",
            order: tableSettings.columns.dimensions.order,
            isHidden: !tableSettings.columns.dimensions.visible,
            content: ({ info, category }: InventoryItem) => {
              if (category === "service") {
                return <></>;
              }
              const parsedDimensions =
                info.dim && parseDimensionsString(info?.dim);
              const isHidden =
                !info.dim ||
                (parsedDimensions &&
                  Object.entries(parsedDimensions.value).every(
                    ([, value]) => !value
                  ));
              if (!isHidden && info.dim && parsedDimensions) {
                return (
                  <div
                    className="w-11/12 truncate"
                    title={`${parsedDimensions.value.width || 0} x ${
                      parsedDimensions.value.height
                    } x ${parsedDimensions.value.depth} ${
                      parsedDimensions.unit
                    }`}
                  >
                    <>
                      {parsedDimensions.value.width || 0}{" "}
                      <span className="text-grey-400">x</span>{" "}
                      {parsedDimensions.value.height || 0}{" "}
                      <span className="text-grey-400">x</span>{" "}
                      {parsedDimensions.value.depth || 0}{" "}
                      {parsedDimensions.unit}
                    </>
                  </div>
                );
              }
              return <></>;
            },
          },
          {
            title: t({
              id: "order-forms-page.settings",
              defaultMessage: "Settings",
            }),
            className: "flex justify-center w-full border-r-0 p-0",
            width: 100,
            grow: false,
            headerClassName: "border-r-0",
            order: tableSettings.columns.settings.order,
            isHidden: !tableSettings.columns.settings.visible,
            content: ({ id }: InventoryItemDataDto) => (
              <Menu
                placement="bottom-end"
                autoClose
                renderTrigger={({ Trigger, triggerRef }) => (
                  <Trigger
                    as={PlainButton}
                    ref={triggerRef}
                    className="flex h-[55px] w-[100px] items-center justify-center"
                  >
                    <MoreIcon />
                  </Trigger>
                )}
                className="py-2"
                sections={[
                  [
                    {
                      id: "edit",
                      label: t({
                        id: "common.edit",
                        defaultMessage: "Edit",
                      }),
                      icon: <EditIcon />,
                      onSelect: () =>
                        navigateToPage("inventoryItemEditForm", { id }),
                    },
                    {
                      id: "delete",
                      label: t({
                        id: "common.delete",
                        defaultMessage: "Delete",
                      }),
                      onSelect: () => setItemIdToDelete(id),
                      icon: <DeleteIcon />,
                    },
                  ],
                ]}
              />
            ),
          },
        ]}
      />
      {!isLoading && data.length === 0 && (
        <div className="mt-[80px] flex justify-center">
          {t({
            id: "inventory-items-page.no-results",
            defaultMessage: "No results 😔",
          })}
        </div>
      )}
      <ConfirmRemoveItemsAlert
        isOpen={!!itemIdToDelete}
        itemIds={itemIdToDelete ? [itemIdToDelete] : []}
        onClose={() => setItemIdToDelete("")}
        onRemove={() => onItemDelete(itemIdToDelete || "")}
      />
    </div>
  );
};

import { InventoryItemDimensionUnit } from "@jugl-web/rest-api";

export const parseDimensionsString = (dimensions: string) => {
  const [width, height, depth, unit] = dimensions.split(";");
  return {
    value: {
      width,
      height,
      depth,
    },
    unit: unit as InventoryItemDimensionUnit,
  };
};

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Portal,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { assert, HookOutOfContextError } from "@jugl-web/utils";

import {
  createContext,
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MediaPanel } from "./components/MediaPanel";
import { TopPanel } from "./components/TopPanel";
import { FilePreviewConfig } from "./types";
import { inferTypeFromMimeType } from "./utils";
import { FileTile } from "./components/FileTile";

export interface FilePreviewContextValue {
  isOpen: boolean;
  previewFile: (config: FilePreviewConfig) => void;
  previewFiles: (configs: FilePreviewConfig[], initialFileUrl?: string) => void;
  closePreview: () => void;
}

const FilePreviewContext = createContext<FilePreviewContextValue | null>(null);

type MediaState = "ready" | "loading" | "error";

interface FilePreviewProviderProps {
  children: JSX.Element;
}

export const FilePreviewProvider: FC<FilePreviewProviderProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [mediaState, setMediaState] = useState<MediaState>("loading");
  const [currentConfig, setCurrentConfig] = useState<FilePreviewConfig | null>(
    null
  );

  const [currentConfigs, setCurrentConfigs] = useState<
    FilePreviewConfig[] | null
  >(null);

  const getPreviewType = useCallback(() => {
    assert(!!currentConfig, "No file to preview");
    return inferTypeFromMimeType(currentConfig.mimeType);
  }, [currentConfig]);

  const previewFile = useCallback((config: FilePreviewConfig) => {
    setIsOpen(true);
    setCurrentConfig(config);
  }, []);

  const previewFiles = useCallback(
    (configs: FilePreviewConfig[], initialFileUrl?: string) => {
      setIsOpen(true);
      setCurrentConfigs(configs);
      const initialFile = configs.find(
        (config) => config.url === initialFileUrl
      );
      setCurrentConfig(initialFile || configs[0]);
    },
    []
  );

  useEffect(() => {
    if (!isOpen) {
      setCurrentConfigs(null);
    }
  }, [isOpen]);

  const contextValue = useMemo<FilePreviewContextValue>(
    () => ({
      isOpen,
      previewFile,
      previewFiles,
      closePreview: () => setIsOpen(false),
    }),
    [isOpen, previewFile, previewFiles]
  );

  useEffect(() => {
    if (currentConfig) {
      setMediaState(getPreviewType() !== "other" ? "loading" : "ready");
    }
    if (currentConfigs) {
      setMediaState("ready");
    }
  }, [currentConfig, currentConfigs, getPreviewType]);

  useEffect(() => {
    if (isOpen) {
      document?.body?.classList.add("overflow-hidden");
    } else {
      document?.body?.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  return (
    <FilePreviewContext.Provider value={contextValue}>
      {children}
      <Portal>
        <Transition show={isOpen} as={Fragment}>
          <Dialog
            unmount
            as="div"
            className="jugl__border-box-component"
            onClose={() => !currentConfig?.isCloseDisabled && setIsOpen(false)}
          >
            <TransitionChild
              as={Fragment}
              enter="transition-opacity duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogBackdrop className="fixed inset-0 z-50 bg-[rgba(14,14,14,0.66)] backdrop-blur-[1px]" />
            </TransitionChild>

            <DialogPanel className="fixed top-0 left-0 bottom-0 right-0 z-[1300] flex h-screen w-screen flex-col">
              <TransitionChild
                as={Fragment}
                enter="transition duration-200"
                enterFrom="opacity-0 -translate-y-full"
                enterTo="opacity-100 translate-y-0"
                leave="transition duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-full"
              >
                {currentConfig && (
                  <TopPanel
                    fileName={currentConfig.name}
                    onDownload={currentConfig.onDownload}
                    onClose={() => setIsOpen(false)}
                    fileAuthorName={currentConfig.username}
                    isCloseDisabled={currentConfig.isCloseDisabled}
                  />
                )}
              </TransitionChild>
              <TransitionChild
                as={Fragment}
                enter="transition duration-200"
                enterFrom="opacity-0 scale-90"
                enterTo="opacity-100 scale-100"
                leave="transition duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-90"
              >
                {currentConfig && (
                  <MediaPanel
                    config={currentConfig}
                    previewType={getPreviewType()}
                    isLoading={mediaState === "loading"}
                    hasError={mediaState === "error"}
                    onLoad={() => setMediaState("ready")}
                    onError={() => setMediaState("error")}
                    onDownload={currentConfig.onDownload}
                    onClose={() =>
                      !currentConfig.isCloseDisabled && setIsOpen(false)
                    }
                    inMultipleFilesMode={Boolean(currentConfigs?.length)}
                  />
                )}
              </TransitionChild>
              {currentConfigs?.length && (
                <TransitionChild
                  as={Fragment}
                  enter="transition duration-200"
                  enterFrom="opacity-0 scale-90"
                  enterTo="opacity-100 scale-100"
                >
                  <div className="mx-auto my-[calc(100vh_*_0.065)] flex w-max max-w-[90%] flex-col gap-2.5 rounded-2xl bg-white px-[41px] py-[13px]">
                    <div className="jugl__custom-scrollbar flex items-center gap-2.5 overflow-x-auto">
                      {currentConfigs?.map((file) => (
                        <FileTile
                          key={file.url}
                          file={file.url}
                          isSelected={currentConfig?.url === file.url}
                          mimeType={file.mimeType}
                          onClick={() => setCurrentConfig(file)}
                        />
                      )) || ""}
                    </div>
                  </div>
                </TransitionChild>
              )}
            </DialogPanel>
          </Dialog>
        </Transition>
      </Portal>
    </FilePreviewContext.Provider>
  );
};

export const useFilePreview = () => {
  const context = useContext(FilePreviewContext);

  if (!context) {
    throw new HookOutOfContextError("useFilePreview", "FilePreviewContext");
  }

  return context;
};

import { Alert } from "@jugl-web/ui-components/cross-platform/Alert";
import { ConfirmationPopup } from "@jugl-web/ui-components/cross-platform/ConfirmationPopup";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import Lottie from "react-lottie";
import alertAnimation from "./assets/alert-animation.json";

interface DiscardChangesConfirmationAlertProps {
  isOpen: boolean;
  reason?: "leave" | "clear";
  onConfirm: () => void;
  onClose: () => void;
}

export const DiscardChangesConfirmationAlert: FC<
  DiscardChangesConfirmationAlertProps
> = ({ isOpen, reason, onConfirm, onClose }) => {
  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const title = t({
    id: "tasks-page.discard-changes-warning-title",
    defaultMessage: "Discard changes",
  });

  const discardChangesDescription = t({
    id: "tasks-page.discard-changes-description",
    defaultMessage: "If you discard, entered info will not be saved",
  });

  const clearChangesDescription = t({
    id: "tasks-page.clear-changes-description",
    defaultMessage: "If you proceed, entered info will be cleared",
  });

  const cancelLabel = t({ id: "common.cancel", defaultMessage: "Cancel" });

  const discardLabel = t({ id: "common.discard", defaultMessage: "Discard" });

  if (isMobile) {
    return (
      <ConfirmationPopup
        variant="mobile"
        isOpen={isOpen}
        title={title}
        message={
          reason === "leave"
            ? discardChangesDescription
            : clearChangesDescription
        }
        hasCancelButton
        buttons={[
          {
            label: discardLabel,
            variant: "contained",
            color: "tertiary",
            onClick: onConfirm,
          },
        ]}
        onRequestClose={onClose}
      />
    );
  }

  return (
    <Alert
      isOpen={isOpen}
      img={
        <Lottie
          options={{ animationData: alertAnimation }}
          width={70}
          height={70}
        />
      }
      title={title}
      content={
        reason === "leave" ? discardChangesDescription : clearChangesDescription
      }
      buttons={[
        {
          text: cancelLabel,
          role: "close",
        },
        {
          text: discardLabel,
          color: "primary",
          onClick: onConfirm,
        },
      ]}
      onRequestClose={onClose}
    />
  );
};

export const TASK_MODULE = "task";
export const TASK_COMMENT_MODULE = "comment";
export const TASK_CLIENT_FEEDBACK_MODULE = "feedback";

type SupportedLiveUpdateModule =
  | typeof TASK_MODULE
  | typeof TASK_COMMENT_MODULE
  | typeof TASK_CLIENT_FEEDBACK_MODULE;

export interface LiveUpdateEvent<
  TAction extends string = string,
  TData extends object = object,
  TModule extends string = SupportedLiveUpdateModule | string
> {
  action: TAction;
  action_by: string;
  data: TData;
  module: TModule;
}

export const isLiveUpdateEvent = (event: unknown): event is LiveUpdateEvent =>
  !!(
    event &&
    typeof event === "object" &&
    "action" in event &&
    "data" in event &&
    "module" in event
  );

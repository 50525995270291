import { cx, useUniqueDOMId } from "@jugl-web/utils";
import {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormGroupProps } from "../FormGroup";
import { UtilityTextInputBase } from "../UtilityTextInputBase";

export interface DimensionInputProps
  extends Pick<
      InputHTMLAttributes<HTMLInputElement>,
      | "autoComplete"
      | "value"
      | "onBlur"
      | "onChange"
      | "onKeyDown"
      | "type"
      | "id"
      | "autoFocus"
      | "min"
      | "max"
      | "step"
      | "readOnly"
    >,
    FormGroupProps {
  mask?: string;
  classNames?: FormGroupProps["classNames"] & {
    inputClassName?: string;
    inputWrapperClassName?: string;
    endContentWrapperClassName?: string;
    startContentWrapperClassName?: string;
  };
  endContent?: ReactNode;
  startContent?: ReactNode;
}

export const isNumeric = (value: string) => {
  const regex = /^[0-9.,]*$/;
  if (!regex.test(value) || !value) {
    return false;
  }

  const dotCount = (value.match(/\./g) || []).length;
  const commaCount = (value.match(/,/g) || []).length;

  return (
    (dotCount <= 1 && commaCount === 0) || (commaCount <= 1 && dotCount === 0)
  );
};

export const DimensionsInput = forwardRef<
  HTMLInputElement,
  DimensionInputProps
>((props, ref) => {
  const uniqueId = useUniqueDOMId();
  const {
    label,
    isInvalid,
    isRequired,
    mask,
    tooltip,
    errorMessage,
    isDisabled,
    autoFocus,
    endContent,
    startContent,
    classNames: {
      inputClassName,
      inputWrapperClassName,
      endContentWrapperClassName,
      startContentWrapperClassName,
    } = {},
    ...htmlInputProps
  } = props;
  const inputId = useMemo(
    () => htmlInputProps.id || uniqueId,
    [htmlInputProps.id, uniqueId]
  );

  // width;height;depth like this 10;20;12
  const [internalInputValue, setInternalInputValue] = useState<string>(";;");
  useEffect(() => {
    if (typeof htmlInputProps.value === "string") {
      setInternalInputValue(htmlInputProps.value);
    }
  }, [htmlInputProps.value]);

  const getNewValues = (
    newValue: string,
    index: "width" | "height" | "depth"
  ) => {
    const [width, height, depth] = internalInputValue?.split(";") || [];
    const values = {
      width,
      height,
      depth,
    };

    values[index] = newValue;
    return `${values.width || ""};${values.height || ""};${values.depth || ""}`;
  };
  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: "width" | "height" | "depth"
  ) => {
    const newValue = e.target.value;
    const newString = getNewValues(newValue, index);

    if (!isNumeric(newValue) && newValue !== "") {
      e.preventDefault();
      return;
    }
    if (htmlInputProps.onChange) {
      e.target.value = newString;
      htmlInputProps.onChange(e);
    }
    setInternalInputValue(getNewValues(newValue, index));
  };

  return (
    <UtilityTextInputBase
      {...{
        ...props,
      }}
      inputWrapperClassName={inputWrapperClassName}
      inputId={inputId}
    >
      {startContent && (
        <div
          className={cx(
            "flex h-[56px] items-center bg-[#F5F5F7] px-4",
            startContentWrapperClassName
          )}
        >
          {startContent}
        </div>
      )}
      <div className="flex h-[56px] items-center justify-center gap-1.5 px-4">
        <input
          ref={ref}
          disabled={isDisabled}
          autoFocus={autoFocus}
          id={inputId}
          {...{
            ...htmlInputProps,
            className: cx(
              "w-full flex-1 font-secondary text-base leading-4 outline-none border-t-0 border-x-0 border-b-[1px] border-solid border-dark-100 text-dark disabled:bg-transparent text-center",
              inputClassName
            ),
            onChange: (e) => handleOnChange(e, "width"),
          }}
          value={internalInputValue?.split(";")[0]}
        />

        <span className="text-grey-400">x</span>
        <input
          ref={ref}
          disabled={isDisabled}
          autoFocus={autoFocus}
          id={inputId}
          {...{
            ...htmlInputProps,
            className: cx(
              "w-full flex-1 font-secondary text-base leading-4 outline-none border-t-0 border-x-0 border-b-[1px] border-solid border-dark-100 text-dark disabled:bg-transparent text-center",
              inputClassName
            ),
            onChange: (e) => handleOnChange(e, "height"),
          }}
          value={internalInputValue?.split(";")[1]}
        />
        <span className="text-grey-400">x</span>
        <input
          ref={ref}
          disabled={isDisabled}
          autoFocus={autoFocus}
          id={inputId}
          {...{
            ...htmlInputProps,
            className: cx(
              "w-full flex-1 font-secondary text-base leading-4 outline-none border-t-0 border-x-0 border-b-[1px] border-solid border-dark-100 text-dark disabled:bg-transparent text-center",
              inputClassName
            ),
            onChange: (e) => handleOnChange(e, "depth"),
          }}
          value={internalInputValue?.split(";")[2]}
        />
      </div>
      {endContent && (
        <div
          className={cx(
            "flex h-[56px] items-center bg-[#F5F5F7] px-4",
            endContentWrapperClassName
          )}
        >
          {endContent}
        </div>
      )}
    </UtilityTextInputBase>
  );
});

import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import React from "react";
import { ReactComponent as ImageIcon } from "./assets/image.svg";

export const MainImageButton: React.FC<{
  isMain: boolean;
  className?: string;
  onSetAsMain: () => void;
}> = ({ isMain, className, onSetAsMain }) => {
  const { t } = useTranslations();

  return (
    <InteractiveContainer
      className={cx(
        "flex h-10 w-max items-center justify-center gap-1 rounded-[10px] px-3",
        className
      )}
      isDisabled={isMain}
      onClick={(e) => {
        e.stopPropagation();
        onSetAsMain();
      }}
    >
      <ImageIcon />
      <span className="text-dark font-secondary text-sm leading-[150%]">
        {isMain
          ? t({
              id: "images-multi-select-component.main",
              defaultMessage: "Main",
            })
          : t({
              id: "images-multi-select-component.set-as-main",
              defaultMessage: "Set as Main",
            })}
      </span>
    </InteractiveContainer>
  );
};

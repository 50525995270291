import { InternalTaskCustomField } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { useCallback } from "react";

export const useFormattedCustomFieldValue = () => {
  const { formatDateTimeLabel } = useFormattedDate();
  const { t } = useTranslations();

  const formatCustomFieldValue = useCallback(
    (customField: InternalTaskCustomField, rawValue: string) => {
      if (customField.type === "date") {
        return formatDateTimeLabel(new Date(rawValue));
      }

      if (customField.type === "dropdown") {
        return (
          customField.values?.find((value) => value.id === rawValue)?.value ||
          t({ id: "common.unknown", defaultMessage: "Unknown" })
        );
      }

      return rawValue;
    },
    [formatDateTimeLabel, t]
  );

  return { formatCustomFieldValue };
};

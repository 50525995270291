import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import React from "react";

export const ImageTile: React.FC<{
  file: File | string;
  isSelected: boolean;
  hasOverlay?: boolean;
  overlayText?: string;
  onClick: () => void;
}> = ({ isSelected, file, hasOverlay, overlayText, onClick }) => (
  <InteractiveContainer
    className={cx(
      "border-grey-400 relative !box-content h-[46px] w-[46px] shrink-0 overflow-hidden rounded-[10px] border border-solid bg-white transition-colors",
      isSelected && "border-primary border-[3px]"
    )}
    onClick={onClick}
  >
    <img
      src={typeof file === "string" ? file : URL.createObjectURL(file)}
      alt={typeof file === "string" ? "" : file.name}
      className="h-full w-full object-cover object-center"
    />
    {hasOverlay && (
      <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-black/50">
        <span className="font-secondary text-sm font-semibold leading-[150%] tracking-[0.14px] text-white">
          {overlayText}
        </span>
      </div>
    )}
  </InteractiveContainer>
);

import { cx } from "@jugl-web/utils";
import { useCallback, useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";

export type TableGridProps<TData> = {
  columns: {
    title: string | JSX.Element;
    content: string | JSX.Element | ((data: TData) => JSX.Element);
    width?: number;
    className?: string;
    headerClassName?: string;
    grow?: boolean;
    titleCellClassName?: string;
    order?: number;
    isHidden?: boolean;
  }[];
  data: TData[];

  unstyled?: boolean;
  inline?: boolean;

  onRowClick?: (row: TData) => void;
  className?: string;
  headerCellClassName?: string;
  rowHoverClassName?: string;
  cellClassName?: string;

  emptyContent?: JSX.Element;
  bottomContent?: JSX.Element;
  onReachEnd?: () => void;
};

export const TableGrid = <TData,>({
  columns,
  emptyContent,
  data,
  unstyled,
  className,
  headerCellClassName,
  rowHoverClassName,
  cellClassName,
  onRowClick,
  onReachEnd,
  bottomContent,
  inline,
}: TableGridProps<TData>) => {
  const { ref: bottomAnchorRef, inView: bottomAnchorInView } = useInView();

  useEffect(() => {
    if (bottomAnchorInView) {
      onReachEnd?.();
    }
  }, [bottomAnchorInView, onReachEnd]);

  const reachEndAnchorVisible = data.length > 0 && !!onReachEnd;
  const sortColumns = useCallback(
    (items: TableGridProps<TData>["columns"]) =>
      items
        .map((item, index) => ({
          ...item,
          virtualOrder: item.order || index,
        }))
        .sort((a, b) => a.virtualOrder - b.virtualOrder)
        .map(({ virtualOrder, ...item }) => item),
    []
  );

  const sortedVisibleColumns = useMemo(
    () => sortColumns(columns).filter((col) => !col.isHidden),
    [columns, sortColumns]
  );

  return (
    <div
      className={cx(
        "grid",
        {
          "h-full overflow-auto": !inline && !unstyled,
          "bg-white": !unstyled,
        },
        className
      )}
      style={{
        gridTemplateRows: `min-content ${
          data.length ? data.map(() => `min-content`).join(" ") : "1fr"
        } ${bottomContent ? "auto" : ""} ${
          reachEndAnchorVisible ? "auto" : ""
        }`,
        gridTemplateColumns: sortedVisibleColumns
          .map((item, idx) =>
            item.width
              ? `${item.width}px`
              : item.grow ||
                (!sortedVisibleColumns.find((column) => column.grow) &&
                  idx === sortedVisibleColumns.length - 1)
              ? "auto"
              : `min-content`
          )
          .join(" "),
      }}
    >
      {sortedVisibleColumns.map((col, idx) => (
        <div
          key={+idx}
          className={cx(
            !unstyled &&
              "z-10 flex h-20 items-center self-start whitespace-nowrap border-x-0 border-b-[16px] border-t-[16px] border-solid border-gray-50 bg-white px-2 text-xs font-[500] text-[#93949A]",
            !unstyled && !inline && "grid-col sticky  top-0",
            !unstyled && idx === 0 && "pl-8",
            !unstyled && idx === sortedVisibleColumns.length - 1 && "pr-8",
            headerCellClassName,
            col.headerClassName
          )}
        >
          {col.title}
        </div>
      ))}
      {data.map((item, rowIdx) =>
        sortedVisibleColumns.map((col, idx) => (
          <div
            data-table-row={rowIdx}
            key={`${+rowIdx}-${+idx}`}
            onMouseEnter={() =>
              rowHoverClassName &&
              document
                .querySelectorAll(`[data-table-row='${rowIdx}']`)
                .forEach((element) => element.classList.add(rowHoverClassName))
            }
            onMouseLeave={() =>
              rowHoverClassName &&
              document
                .querySelectorAll(`[data-table-row='${rowIdx}']`)
                .forEach((element) =>
                  element.classList.remove(rowHoverClassName)
                )
            }
            className={cx(
              cellClassName,
              !unstyled && "bg-white px-2 py-4",
              !unstyled && idx === 0 && "pl-8",
              !unstyled && idx === sortedVisibleColumns.length - 1 && "pr-8",
              !unstyled &&
                rowIdx !== data.length - 1 &&
                "grid-col border-x-0 border-b-2 border-t-0 border-solid border-gray-50",
              col.className
            )}
            onClick={() => onRowClick?.(item)}
          >
            {typeof col.content === "function"
              ? col.content(item)
              : col.content}
          </div>
        ))
      )}
      {data.length === 0 && emptyContent ? (
        <div
          style={{
            gridColumn: `span ${sortedVisibleColumns.length} / span ${sortedVisibleColumns.length}`,
          }}
        >
          {emptyContent}
        </div>
      ) : null}
      {bottomContent ? (
        <div
          style={{
            gridColumn: `span ${sortedVisibleColumns.length} / span ${sortedVisibleColumns.length}`,
          }}
        >
          {bottomContent}
        </div>
      ) : null}
      {reachEndAnchorVisible && (
        <div
          ref={bottomAnchorRef}
          style={{
            gridColumn: `span ${sortedVisibleColumns.length} / span ${sortedVisibleColumns.length}`,
          }}
          className="h-[1px]"
        />
      )}
    </div>
  );
};

import {
  TaskFiltersStateProvider,
  useTaskFiltersState,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { InternalTaskFilterSet } from "@jugl-web/rest-api/tasks";
import { Alert } from "@jugl-web/ui-components/cross-platform/Alert";
import { ScreenTransitionWrapper } from "@jugl-web/ui-components/cross-platform/ScreenTransitionWrapper";
import {
  BottomCenteredDrawer,
  BottomCenteredDrawerProps,
} from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import {
  Breadcrumb,
  DRAWER_HEADER_HEIGHT_PX,
} from "@jugl-web/ui-components/web/DrawerHeader";
import { useTranslations } from "@jugl-web/utils";
import {
  Screen,
  ScreenTransitionManagerProvider,
  useScreenTransitionManager,
} from "@jugl-web/utils/utils/ScreenTransitionManager";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { EntryScreen } from "./components/EntryScreen";
import { SelectFiltersScreen } from "./components/SelectFiltersScreen";
import { ManageFilterSetDialogScreenToParametersMap } from "./types";

interface ManageFilterSetDialogProps {
  entityId: string;
  isOpen: boolean;
  editingFilterSet: InternalTaskFilterSet | null;
  onClose: () => void;
}

const INITIAL_SCREEN: Screen<ManageFilterSetDialogScreenToParametersMap> = {
  name: "entry",
};

export const InnerManageFilterSetDialog: FC<ManageFilterSetDialogProps> = ({
  entityId,
  isOpen,
  editingFilterSet,
  onClose,
}) => {
  const [filterSetName, setFilterSetName] = useState("");
  const { filtersStateContext, isDirty, resetFiltersState } =
    useTaskFiltersState();

  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    useState(false);

  const { screen, transitionTo, renderContent } =
    useScreenTransitionManager<ManageFilterSetDialogScreenToParametersMap>();

  const { t } = useTranslations();

  const isEditing = !!editingFilterSet;

  const handleSafeClose = useCallback(() => {
    const isNameDirty =
      filterSetName !== (isEditing ? editingFilterSet.name : "");

    if (isDirty() || isNameDirty) {
      setIsDiscardChangesDialogOpen(true);
      return;
    }

    onClose();
  }, [editingFilterSet, filterSetName, isDirty, isEditing, onClose]);

  const handleResetState = () => {
    if (!isOpen) {
      transitionTo(INITIAL_SCREEN, { force: true });
    }
  };

  const header = useMemo<BottomCenteredDrawerProps["header"]>(() => {
    const rootBreadcrumb: Breadcrumb = {
      id: "root",
      title: isEditing
        ? t({
            id: "tasks-page.edit-filter-set",
            defaultMessage: "Edit filter set",
          })
        : t({
            id: "tasks-page.create-filter-set",
            defaultMessage: "Create filter set",
          }),
      onClick:
        screen.name !== "entry"
          ? () => transitionTo(INITIAL_SCREEN)
          : undefined,
    };

    return renderContent<BottomCenteredDrawerProps["header"]>({
      entry: {
        type: "breadcrumbs",
        breadcrumbs: [rootBreadcrumb],
      },
      selectFilters: {
        type: "breadcrumbs",
        breadcrumbs: [
          rootBreadcrumb,
          {
            id: "select-filters",
            title: t({
              id: "tasks-page.select-filters",
              defaultMessage: "Select filters",
            }),
          },
        ],
      },
    });
  }, [isEditing, renderContent, screen.name, t, transitionTo]);

  const content = useMemo<JSX.Element>(
    () =>
      renderContent({
        entry: (
          <EntryScreen
            entityId={entityId}
            filterSetName={filterSetName}
            editingFilterSet={editingFilterSet}
            onChangeFilterSetName={setFilterSetName}
            onDialogClose={onClose}
          />
        ),
        selectFilters: (
          <SelectFiltersScreen
            entityId={entityId}
            filterSetName={filterSetName}
            editingFilterSet={editingFilterSet}
            onDialogClose={onClose}
          />
        ),
      }),
    [editingFilterSet, entityId, filterSetName, onClose, renderContent]
  );

  useEffect(() => {
    if (isOpen) {
      setFilterSetName(isEditing ? editingFilterSet.name : "");
      resetFiltersState(isEditing ? editingFilterSet.filters : undefined);
    }
  }, [editingFilterSet, isEditing, isOpen, resetFiltersState]);

  return (
    <>
      <BottomCenteredDrawer
        isOpen={isOpen}
        header={header}
        onClose={handleSafeClose}
        onTransitionEnd={handleResetState}
      >
        <TaskFiltersStateProvider context={filtersStateContext}>
          <ScreenTransitionWrapper
            screenName={screen.name}
            style={{ height: `calc(100% - ${DRAWER_HEADER_HEIGHT_PX}px)` }}
          >
            {content}
          </ScreenTransitionWrapper>
        </TaskFiltersStateProvider>
      </BottomCenteredDrawer>
      <Alert
        isOpen={isDiscardChangesDialogOpen}
        title={t({
          id: "common.discard-changes-warning-title",
          defaultMessage: "Discard changes",
        })}
        content={t({
          id: "common.discard-changes-warning-description",
          defaultMessage: "If you discard, all changes will be lost",
        })}
        buttons={[
          { text: "Cancel", role: "close" },
          {
            text: "Discard",
            color: "primary",
            onClick: (_, actions) => {
              onClose();
              actions.closeAlert();
            },
          },
        ]}
        onRequestClose={() => setIsDiscardChangesDialogOpen(false)}
      />
    </>
  );
};

export const ManageFilterSetDialog: FC<ManageFilterSetDialogProps> = (
  props
) => (
  <ScreenTransitionManagerProvider initialScreen={INITIAL_SCREEN}>
    <InnerManageFilterSetDialog {...props} />
  </ScreenTransitionManagerProvider>
);

import { useRestApiProvider } from "@jugl-web/rest-api";
import { DetailedTask } from "@jugl-web/rest-api/tasks";
import { omit } from "@jugl-web/utils";
import { useCallback } from "react";
import {
  TaskChecklistHandlers,
  taskChecklistItemAdapters,
} from "../components/TaskChecklist";
import { useUpdateTask } from "./useUpdateTask";

interface UseRemoteTaskChecklistHandlersOptions {
  entityId: string;
  taskId: string;
  checklistItems?: DetailedTask["checklist"];
}

export const useRemoteTaskChecklistHandlers = ({
  entityId,
  taskId,
}: UseRemoteTaskChecklistHandlersOptions): TaskChecklistHandlers => {
  const { tasksApi } = useRestApiProvider();

  const [addChecklistItemMutation] = tasksApi.useAddChecklistItemMutation();

  const [updateChecklistItemMutation] =
    tasksApi.useUpdateChecklistItemMutation();

  const [deleteChecklistItemMutation] =
    tasksApi.useDeleteChecklistItemMutation();

  const updateTask = useUpdateTask({ entityId, taskId });

  const handleAddItem = useCallback<TaskChecklistHandlers["onAddItem"]>(
    async (item) => {
      const itemAsBackendModel = taskChecklistItemAdapters.toBackendModel(item);

      await addChecklistItemMutation({
        entityId,
        taskId,
        item: omit(itemAsBackendModel, "id", "completed_at"),
      });
    },
    [entityId, taskId, addChecklistItemMutation]
  );

  const handleUpdateItem = useCallback<TaskChecklistHandlers["onUpdateItem"]>(
    async (updatedItem) => {
      const itemAsBackendModel =
        taskChecklistItemAdapters.toBackendModel(updatedItem);

      await updateChecklistItemMutation({
        entityId,
        taskId,
        itemId: updatedItem.id,
        attributes: omit(itemAsBackendModel, "id", "completed_at"),
      });
    },
    [entityId, taskId, updateChecklistItemMutation]
  );

  const handleDeleteItem = useCallback<TaskChecklistHandlers["onDeleteItem"]>(
    async (itemId) => {
      await deleteChecklistItemMutation({
        entityId,
        taskId,
        itemId,
      });
    },
    [deleteChecklistItemMutation, entityId, taskId]
  );

  const handleReorderItems = useCallback<
    TaskChecklistHandlers["onReorderItems"]
  >(
    async (newItems) => {
      const itemsAsBackendModel = newItems.map(
        taskChecklistItemAdapters.toBackendModel
      );

      try {
        await updateTask({ checklist: itemsAsBackendModel });
      } catch {
        // Do nothing
      }
    },
    [updateTask]
  );

  return {
    onAddItem: handleAddItem,
    onUpdateItem: handleUpdateItem,
    onDeleteItem: handleDeleteItem,
    onReorderItems: handleReorderItems,
  };
};

import React, { useRef, useState } from "react";
import { cx, useTranslations } from "@jugl-web/utils";
import { moveArrayElement } from "@jugl-web/utils/utils/moveArrayElement";
import { ImagePreviewDialog } from "./components/ImagePreviewDialog";
import { DeleteButton } from "./components/DeleteButton";
import { MainImageButton } from "./components/MainImageButton";
import { AddImageTile } from "./components/AddImageTile";
import { ImageTile } from "./components/ImageTile";
import { ReactComponent as ImageIcon } from "./assets/image.svg";

export interface ImagesMultiSelectProps {
  imagesLimit: number;
  files: (File | string)[];
  className?: string;
  onFilesChange: (files: (File | string)[]) => void;
  onIncorrectFileType?: () => void;
}

export const ImagesMultiSelect: React.FC<ImagesMultiSelectProps> = ({
  imagesLimit,
  files,
  className,
  onFilesChange,
  onIncorrectFileType,
}) => {
  const { t } = useTranslations();

  const droppableInputRef = useRef<HTMLInputElement>(null);
  const buttonInputRef = useRef<HTMLInputElement>(null);

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isImagePreviewDialogOpen, setIsImagePreviewDialogOpen] =
    useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(false);

    if (files.length === 0) {
      setSelectedImageIndex(0);
    }
    const droppedFiles = Array.from(event.dataTransfer.files)
      .filter((file) => file.type.startsWith("image"))
      .slice(0, imagesLimit);
    if (droppedFiles.length === 0) {
      onIncorrectFileType?.();
    }
    if (files.length >= imagesLimit) {
      return;
    }
    const filesToAdd = droppedFiles.slice(0, imagesLimit - files.length);
    onFilesChange([...files, ...filesToAdd]);
  };

  const handleDroppableFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedImageIndex(0);
    const selectedFiles = Array.from(event.target.files || []).slice(
      0,
      imagesLimit
    );
    onFilesChange(selectedFiles);
  };

  const handleButtonFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      onFilesChange([...files, selectedFile]);
    }
  };

  const handleDeleteImage = () => {
    onFilesChange(files.filter((_, idx) => idx !== selectedImageIndex));
    setSelectedImageIndex(0);
  };

  const handleSetAsMain = () => {
    onFilesChange(moveArrayElement(files, selectedImageIndex, 0));
    setSelectedImageIndex(0);
  };

  const selectedImage = files[selectedImageIndex];

  return (
    <>
      <div
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={() => setIsDraggingOver(true)}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        {!files.length ? (
          <>
            <input
              type="file"
              ref={droppableInputRef}
              hidden
              multiple={imagesLimit > 1}
              accept="image/*"
              onChange={handleDroppableFileInputChange}
            />
            <div
              className={cx(
                "border-grey-400 hover:bg-grey-100 flex h-[184px] w-[308px] cursor-pointer flex-col items-center justify-center gap-3 rounded-[10px] border border-solid bg-white text-center transition-colors",
                isDraggingOver && "bg-grey-100",
                className
              )}
              onClick={() => droppableInputRef.current?.click()}
            >
              <ImageIcon />
              <span className="text-dark-600 font-secondary leading-[150%] tracking-[0.14px]">
                {t(
                  {
                    id: "images-multi-select-component.browse-or-drag-and-drop-multi-images",
                    defaultMessage:
                      "<highlightedText>Browse</highlightedText> or drag and {br} drop {imagesLimit, plural, one {image} other {up to {imagesLimit} images}}",
                  },
                  {
                    br: <br />,
                    highlightedText: (text: (string | JSX.Element)[]) => (
                      <span className="text-primary underline">{text}</span>
                    ),
                    imagesLimit,
                  }
                )}
              </span>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-1.5">
            <div
              className={cx(
                "border-grey-400 relative flex h-[184px] w-[308px] cursor-pointer overflow-hidden rounded-[10px] border border-solid bg-white",
                className
              )}
              onClick={() => setIsImagePreviewDialogOpen(true)}
            >
              {selectedImage && (
                <>
                  <img
                    src={
                      typeof selectedImage === "string"
                        ? selectedImage
                        : URL.createObjectURL(selectedImage)
                    }
                    alt={
                      typeof selectedImage === "string"
                        ? ""
                        : selectedImage.name
                    }
                    className="h-full w-full object-cover object-center"
                  />
                  <MainImageButton
                    isMain={selectedImageIndex === 0}
                    onSetAsMain={handleSetAsMain}
                    className="absolute left-1 bottom-1 bg-white/90"
                  />
                  <DeleteButton
                    onDelete={handleDeleteImage}
                    className="absolute right-1 bottom-1 bg-white/90"
                  />
                </>
              )}
            </div>
            <div className="flex items-center gap-1.5">
              {files.slice(0, 4).map((file, idx) => {
                const shouldShowOverlay = files.length > 4 && idx === 3;

                return (
                  <ImageTile
                    key={+idx}
                    file={file}
                    hasOverlay={shouldShowOverlay}
                    isSelected={
                      selectedImageIndex === idx ||
                      (idx === 3 && selectedImageIndex > 3)
                    }
                    overlayText={`+${files.length - 3}`}
                    onClick={() => {
                      setSelectedImageIndex(idx);
                      if (shouldShowOverlay) {
                        setIsImagePreviewDialogOpen(true);
                      }
                    }}
                  />
                );
              })}
              {files.length < imagesLimit && (
                <>
                  <input
                    type="file"
                    ref={buttonInputRef}
                    hidden
                    accept="image/*"
                    onChange={handleButtonFileInputChange}
                  />
                  <AddImageTile
                    onClick={() => buttonInputRef.current?.click()}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <ImagePreviewDialog
        isOpen={isImagePreviewDialogOpen}
        onRequestClose={() => {
          setIsImagePreviewDialogOpen(false);
          setSelectedImageIndex(0);
        }}
        files={files}
        selectedImageIndex={selectedImageIndex}
        onAddImage={() => buttonInputRef.current?.click()}
        onDelete={handleDeleteImage}
        onSelectImageIndex={(index) => setSelectedImageIndex(index)}
        onSetAsMain={handleSetAsMain}
        imagesLimit={imagesLimit}
      />
    </>
  );
};

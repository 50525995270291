import { InteractiveContainer } from "@jugl-web/ui-components";
import React, { useRef, useState } from "react";
import { CropImageModal } from "@jugl-web/ui-components/web/AvatarSelect/components/CropImageModal/CropImageModal";
import { useTranslations } from "@jugl-web/utils";
import { useOrderForm } from "../../../../providers/OrderFormProvider";
import formBg from "./assets/form-bg.webp";

export const OrderFormCoverSelector: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslations();

  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState<File>();
  const { image, onImageChange } = useOrderForm();

  return (
    <>
      <InteractiveContainer
        className="relative h-[120px] overflow-hidden rounded-xl bg-white bg-cover bg-center"
        style={{ backgroundImage: `url(${formBg})` }}
        onClick={() => inputRef.current?.click()}
      >
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          hidden
          onChange={(e) => {
            const file = e.target?.files?.[0];
            if (file) {
              setImageToCrop(file);
              setIsCropModalOpen(true);
            }
            e.target.value = "";
          }}
        />
        {image ? (
          <img
            src={typeof image === "object" ? URL.createObjectURL(image) : image}
            className="h-full w-full object-cover object-center"
            alt=""
          />
        ) : (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-[rgba(255,255,255,0.60)] py-2.5 px-3 backdrop-blur-[1px]">
            <span className="text-dark-700 font-secondary leading-2 text-xs">
              {t({
                id: "order-form-wizard-page.click-to-add-cover",
                defaultMessage: "Click to add Cover",
              })}
            </span>
          </div>
        )}
      </InteractiveContainer>
      <CropImageModal
        isOpen={isCropModalOpen}
        onChangeImage={() => inputRef.current?.click()}
        onClose={() => setIsCropModalOpen(false)}
        onSave={(file) => {
          onImageChange(file);
          setIsCropModalOpen(false);
        }}
        image={imageToCrop}
        horizontal
      />
    </>
  );
};

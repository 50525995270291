import {
  Dialog as HeadlessUIDialog,
  DialogPanel as HeadlessUIDialogPanel,
  Transition,
  TransitionChild,
  DialogBackdrop,
} from "@headlessui/react";
import { cx, useResizeObserver } from "@jugl-web/utils";
import {
  Fragment,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export type DrawerPlacement = "top" | "right" | "bottom" | "left";
export type DrawerSize = "auto" | "md" | "xl" | "full-screen";

type DrawerInitialFocus =
  | "firstElement"
  | "none"
  | MutableRefObject<HTMLElement | null>;

const placementToPositionClasses: Record<DrawerPlacement, string> = {
  top: "inset-x-0 top-0 w-full",
  right: "inset-y-0 right-0 h-full",
  bottom: "inset-x-0 bottom-0 w-full",
  left: "inset-y-0 left-0 h-full",
};

const placementToTransitionClasses: Record<
  DrawerPlacement,
  { from: string; to: string }
> = {
  top: { from: "-translate-y-full", to: "translate-y-0" },
  right: { from: "translate-x-full", to: "translate-x-0" },
  bottom: { from: "translate-y-full", to: "translate-y-0" },
  left: { from: "-translate-x-full", to: "translate-x-0" },
};

const sizeAndPlacementToClasses: Record<
  DrawerSize,
  Record<DrawerPlacement, string>
> = {
  auto: { top: "", right: "", bottom: "", left: "" },
  md: {
    top: "h-[480px]",
    right: "w-[480px]",
    bottom: "h-[480px]",
    left: "w-[480px]",
  },
  xl: {
    top: "h-[calc(100%_-_40px)]",
    right: "w-[480px]",
    bottom: "h-[calc(100%_-_40px)]",
    left: "w-[480px]",
  },
  "full-screen": {
    top: "h-full",
    right: "w-full",
    bottom: "h-full",
    left: "w-full",
  },
};

export interface DrawerProps {
  children: ReactNode;
  isOpen: boolean;
  placement: DrawerPlacement;
  size?: DrawerSize;
  animateHeight?: boolean;
  hasBackdrop?: boolean;
  initialFocus?: DrawerInitialFocus;
  className?: string;
  onClose: () => void;
  onTransitionEnd?: () => void;
}

const DRAWER_TRANSITION_DURATION_CLASS_NAME = "duration-200";

export const DRAWER_TRANSITION_DURATION_MS = parseInt(
  DRAWER_TRANSITION_DURATION_CLASS_NAME.match(/\d+/)?.[0] || "0",
  10
);

export const Drawer = ({
  children,
  isOpen,
  placement,
  size = "auto",
  animateHeight = false,
  hasBackdrop = true,
  initialFocus = "none",
  className,
  onClose,
  onTransitionEnd,
}: DrawerProps) => {
  const [panelHeight, setPanelHeight] = useState<number>();
  const panelRef = useRef<HTMLDivElement | null>(null);

  const shouldAnimateHeight = size === "auto" && animateHeight;

  const handleContentResize = useCallback(
    (entry: ResizeObserverEntry) => {
      if (!shouldAnimateHeight) {
        return;
      }

      const height = entry.borderBoxSize[0].blockSize;

      setPanelHeight(height);
    },
    [shouldAnimateHeight]
  );

  const { ref: contentWrapperRef } = useResizeObserver<HTMLDivElement>({
    onResize: handleContentResize,
  });

  const getInitialFocus = () => {
    // Return undefined as focusing the first focusable element is the default HeadlessUI behavior
    if (initialFocus === "firstElement") {
      return undefined;
    }

    // Focus the root panel to prevent from focusing the first focusable element,
    // it's useful to avoid unexpected virtual keyboard opening on mobile when moving between screens
    if (initialFocus === "none") {
      return panelRef;
    }

    return initialFocus;
  };

  const blockedTimeoutRef = useRef<number | null>(null);
  const [isBlocked, setIsBlocked] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setIsBlocked(true);
    }
    blockedTimeoutRef.current = window.setTimeout(() => {
      setIsBlocked(false);
    }, 1000);
  }, [isOpen]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <HeadlessUIDialog
        onClose={isBlocked ? () => null : onClose}
        initialFocus={getInitialFocus()}
        className="jugl__border-box-component"
        // Prevents the HeadlessUIDialog area from being selected on Apple devices (e.g. while long press interaction)
        style={{
          WebkitUserSelect: "none",
          WebkitTouchCallout: "none",
        }}
        onTransitionEnd={() => {
          setIsBlocked(false);
          onTransitionEnd?.();
          if (blockedTimeoutRef.current) {
            clearTimeout(blockedTimeoutRef.current);
            blockedTimeoutRef.current = null;
          }
        }}
      >
        <TransitionChild
          as={Fragment}
          enter={cx(
            "transition-opacity",
            DRAWER_TRANSITION_DURATION_CLASS_NAME
          )}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave={cx(
            "transition-opacity",
            DRAWER_TRANSITION_DURATION_CLASS_NAME
          )}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogBackdrop
            className={cx(
              "fixed inset-0 z-50",
              hasBackdrop && "bg-[rgba(14,14,14,0.66)] backdrop-blur-[1px]"
            )}
          />
        </TransitionChild>
        <TransitionChild
          as={Fragment}
          enter={cx(
            "transition-transform",
            DRAWER_TRANSITION_DURATION_CLASS_NAME
          )}
          enterFrom={placementToTransitionClasses[placement].from}
          enterTo={placementToTransitionClasses[placement].to}
          leave={cx(
            "transition-transform",
            DRAWER_TRANSITION_DURATION_CLASS_NAME
          )}
          leaveFrom={placementToTransitionClasses[placement].to}
          leaveTo={placementToTransitionClasses[placement].from}
        >
          <HeadlessUIDialogPanel
            ref={panelRef}
            className={cx(
              placementToPositionClasses[placement],
              sizeAndPlacementToClasses[size][placement],
              "fixed z-50 bg-white",
              shouldAnimateHeight && "transition-[height] will-change-[height]",
              className
            )}
            style={{
              boxShadow:
                size !== "full-screen"
                  ? "0px 4px 6px rgba(0, 0, 0, 0.04)"
                  : undefined,

              height: shouldAnimateHeight ? panelHeight : undefined,
            }}
          >
            {shouldAnimateHeight ? (
              <div ref={contentWrapperRef}>{children}</div>
            ) : (
              children
            )}
          </HeadlessUIDialogPanel>
        </TransitionChild>
      </HeadlessUIDialog>
    </Transition>
  );
};

import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import {
  getDueDateInDays,
  TaskChecklist,
  TaskChecklistHandle,
  taskNameValidator,
  templateChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks";
import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { DiscardChangesConfirmationAlert } from "@jugl-web/domain-resources/tasks/components/DiscardChangesConfirmationAlert";
import { OverrideFormValuesConfirmationAlert } from "@jugl-web/domain-resources/tasks/components/OverrideFormValuesConfirmationAlert";
import {
  TaskAttachments,
  TaskAttachmentsHandle,
} from "@jugl-web/domain-resources/tasks/components/TaskAttachments";
import { adaptFilesToTaskAttachmentsComponentItems } from "@jugl-web/domain-resources/tasks/components/TaskAttachments/adapters";
import { TaskAttachmentsUploadScreen } from "@jugl-web/domain-resources/tasks/components/TaskAttachmentsUploadScreen";
import { TaskExtraPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskExtraPropertiesPanel";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { useCreateTask } from "@jugl-web/domain-resources/tasks/hooks/useCreateTask";
import { useLocalTaskChecklistHandlers } from "@jugl-web/domain-resources/tasks/hooks/useLocalTaskChecklistHandlers";
import { useRecentlyUsedTaskTemplates } from "@jugl-web/domain-resources/tasks/hooks/useRecentlyUsedTaskTemplates";
import { useTaskFormState } from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { mapTaskTemplateToFormState } from "@jugl-web/domain-resources/tasks/utils/mapTaskTemplateToFormState";
import { useRestApiProvider, UserRole } from "@jugl-web/rest-api";
import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import { PreviewTaskTemplate } from "@jugl-web/rest-api/tasks-templates";
import {
  Dialog,
  DRAWER_TRANSITION_DURATION_MS,
  LoadingSpinner,
} from "@jugl-web/ui-components/cross-platform";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { assignRefs, getUniqueId, useTranslations } from "@jugl-web/utils";
import { useAppVariant, useToast } from "@jugl-web/utils/hooks";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { TaskFormDialog } from "@web-src/features/tasks/TaskFormDialog";
import { AddNewCustomerDialog } from "@web-src/modules/customers/components/AddNewCustomerDialog";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTimeZone } from "../../../modules/settings/providers/TimeZoneProvider";
import { useTasksPageContext } from "../TasksPageContext/TasksPageContext";
import { TaskTemplatePickerPopover } from "../TaskTemplatePickerPopover";
import { ReactComponent as AddCustomerIcon } from "./assets/add-customer.svg";
import { ReactComponent as SelectTemplateIcon } from "./assets/select-template.svg";

export interface NewTaskDialogProps {
  entityId: string;
  meId: string;
}

export const NewTaskDialog: FC<NewTaskDialogProps> = ({ entityId, meId }) => {
  const [hasDialogEnterTransitionEnded, setHasDialogEnterTransitionEnded] =
    useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const discardChangesConfirmationDialogState = useConfirmationDialogState<{
    reason: "leave" | "clear";
  }>();

  const overrideFormValuesConfirmationDialogState =
    useConfirmationDialogState();

  const [isAddNewCustomerDialogOpen, setIsAddNewCustomerDialogOpen] =
    useState(false);

  const [createdTaskId, setCreatedTaskId] = useState<string>();

  const templatePickerButtonRef = useRef<HTMLButtonElement | null>(null);

  const {
    tasksSource,
    tasksSourceInfo,
    newTaskDialogState,
    closeNewTaskDialog,
    navigateToTaskDetailsPage,
  } = useTasksPageContext();

  const { isOpen, initialFormState, shouldOpenTemplateList } =
    newTaskDialogState;

  const { timeZone } = useTimeZone();
  const { tasksTemplatesApi } = useRestApiProvider();
  const { variant } = useAppVariant();
  const { toast } = useToast({ variant });
  const { me } = useMe();

  const { createTask, showTaskCreationToast, isLoading } = useCreateTask({
    entityId,
    onNavigate: (taskId) => navigateToTaskDetailsPage(taskId),
  });

  const { addRecentlyUsedTaskTemplate } = useRecentlyUsedTaskTemplates({
    entityId,
    meId,
  });

  const [createTemplate] = tasksTemplatesApi.useCreateTemplateMutation();

  const $titleInputRef = useRef<HTMLInputElement | null>(null);
  const $taskChecklistRef = useRef<TaskChecklistHandle | null>(null);
  const $taskAttachmentsRef = useRef<TaskAttachmentsHandle | null>(null);

  const {
    formState,
    updateFormState,
    updateChecklistItems,
    resetFormState,
    isDirty,
    isEmpty,
  } = useTaskFormState();

  const { currentData: detailedTemplate, isFetching: isTemplateLoading } =
    tasksTemplatesApi.useGetTemplateQuery(
      formState.templateId
        ? { entityId, templateId: formState.templateId }
        : skipToken
    );

  const taskChecklistHandlers = useLocalTaskChecklistHandlers({
    itemsSetter: updateChecklistItems,
  });

  const { t } = useTranslations();
  const { logEvent } = useFCM();

  const { isOpen: isFilePreviewOpen, previewFile } = useFilePreview();
  const { completeOnboardingStep, isOnboardingActive } = useOnboarding();

  const isValid = taskNameValidator(formState.title);
  const isDescriptionBoxVisible = formState.description || isEditingDescription;

  const adaptedAttachments = useMemo(
    () =>
      formState.attachments &&
      adaptFilesToTaskAttachmentsComponentItems(formState.attachments),
    [formState.attachments]
  );

  const handleSubmit = () => {
    const runTaskCreation = async () => {
      try {
        const createdTask = await createTask(formState, tasksSource);
        const isBasedOnTemplate = !!formState.templateId;

        if (isBasedOnTemplate) {
          addRecentlyUsedTaskTemplate(formState.templateId as string);
        }

        if (formState.attachments.length > 0) {
          setCreatedTaskId(createdTask.id);
          setIsUploadDialogOpen(true);
        } else {
          showTaskCreationToast(
            createdTask.id,
            isBasedOnTemplate
              ? t({
                  id: "feedback.task-created-from-template-tap-to-open",
                  defaultMessage:
                    "Task has been created based on the template. Tap to open it",
                })
              : undefined
          );
          closeNewTaskDialog();
          if (isOnboardingActive) {
            completeOnboardingStep("task");
          }
        }
        logEvent("action_task_create_send");
      } catch {
        // Do nothing
      }
    };

    const runTemplateCreation = async () => {
      const response = await createTemplate({
        entityId,
        template: {
          name: formState.title,
          due_in: formState.dueDate
            ? getDueDateInDays(formState.dueDate.date)
            : null,
          checklist: formState.checklistItems.map(
            templateChecklistItemAdapters.toBackendModel
          ),
          priority: formState.priority,
          desc: formState.description,
          board_id: formState.boardId || undefined,
          label_id: formState.labelId || undefined,
          folder_id: formState.saveToTemplateFolder
            ? formState.saveToTemplateFolder.id
            : undefined,
          custom_fields: formState.customFields,
        },
      });

      if ("data" in response) {
        toast(
          t({
            id: "feedback.template-created",
            defaultMessage: "Template has been created",
          })
        );
      }
    };

    runTaskCreation();

    if (formState.saveToTemplateFolder) {
      runTemplateCreation();
    }
  };

  const handleSelectTemplate = (template: PreviewTaskTemplate) => {
    if (isDirty()) {
      overrideFormValuesConfirmationDialogState.open({
        onConfirm: () => {
          updateFormState("templateId", template.id);
        },
      });

      return;
    }

    updateFormState("templateId", template.id);
  };

  const handleSafeClose = () => {
    if (isFilePreviewOpen || isUploadDialogOpen) {
      return;
    }

    if (isDirty()) {
      discardChangesConfirmationDialogState.open({
        onConfirm: closeNewTaskDialog,
        metadata: { reason: "leave" },
      });
      return;
    }

    closeNewTaskDialog();
  };

  const handlePreviewAttachment = (attachment: TaskAttachment) =>
    previewFile({
      url: attachment.stream_url,
      name: attachment.name,
      mimeType: attachment.content_type,
    });

  const handleFilesAdd = (files: File[]) => {
    if (!files) return;

    const filesWithId = files.map((file) => ({
      id: getUniqueId(),
      file,
    }));
    updateFormState("attachments", formState.attachments.concat(filesWithId));
  };

  const handleFileRename = (file: TaskAttachment, newName: string) => {
    const attachmentsNewState = formState.attachments.map((item) => {
      if (item.id === file.id) {
        return {
          ...item,
          file: new File([item.file], newName, { type: item.file.type }),
        };
      }
      return item;
    });
    updateFormState("attachments", attachmentsNewState);
  };

  const handleFileRemove = (id: string) => {
    const attachmentsNewState = formState.attachments.filter(
      (item) => item.id !== id
    );
    updateFormState("attachments", [...attachmentsNewState]);
  };

  const formStateRef = useRef(formState);

  // Keep in sync the form state reference, so it can be used later
  // in the useEffect without the need to add it to the dependencies
  useEffect(() => {
    formStateRef.current = formState;
  }, [formState]);

  useEffect(() => {
    if (isOpen) {
      // Initialize form state with the initial state coming from the context
      resetFormState(initialFormState);
    } else {
      // Reset form state when the dialog leave transition ends
      window.setTimeout(() => {
        resetFormState();
      }, DRAWER_TRANSITION_DURATION_MS);
    }
  }, [isOpen, initialFormState, resetFormState]);

  const { templateId } = formState;

  // Load template data when `templateId` changes
  useEffect(() => {
    const shouldLoadTemplateData = templateId && detailedTemplate;

    if (!shouldLoadTemplateData) {
      return;
    }

    const actualFormState = formStateRef.current;
    const templateBasedFormState = mapTaskTemplateToFormState(detailedTemplate);
    const overriddenFormState = {
      ...actualFormState,
      ...templateBasedFormState,
    };

    resetFormState(overriddenFormState);
  }, [templateId, resetFormState, detailedTemplate]);

  // Open template picker popover once the dialog has been opened with proper flag
  useLayoutEffect(() => {
    if (isOpen && shouldOpenTemplateList && hasDialogEnterTransitionEnded) {
      templatePickerButtonRef.current?.click();
    }
  }, [hasDialogEnterTransitionEnded, isOpen, shouldOpenTemplateList]);

  return (
    <>
      <Dialog
        isOpen={isUploadDialogOpen}
        onClose={() => {}}
        className="w-[480px]"
      >
        <TaskAttachmentsUploadScreen
          taskId={createdTaskId || ""}
          entityId={entityId}
          files={formState.attachments}
          onClose={() => {
            setIsUploadDialogOpen(false);
            closeNewTaskDialog();
            if (isOnboardingActive) {
              completeOnboardingStep("task");
            }
          }}
        />
      </Dialog>
      <TaskFormDialog
        header={{
          type: "title",
          title: t({
            id: "tasks-page.create-task",
            defaultMessage: "Create task",
          }),
        }}
        isOpen={isOpen && !isUploadDialogOpen}
        initialFocus={$titleInputRef}
        onClose={handleSafeClose}
        onTransitionEnd={() => setHasDialogEnterTransitionEnded(isOpen)}
      >
        <TaskFormDialog.Content>
          <TaskFormDialog.TitleSection>
            <div className="flex items-start justify-between gap-4 [&>:first-child]:w-full">
              <TitleBox
                title={formState.title}
                inputRef={$titleInputRef}
                classes={{ titleText: "py-3" }}
                onInternalValueChange={(title) =>
                  updateFormState("title", title)
                }
              />
              <TaskTemplatePickerPopover
                entityId={entityId}
                meId={meId}
                renderTrigger={({ Trigger, triggerRef }) => (
                  <Trigger
                    ref={assignRefs([triggerRef, templatePickerButtonRef])}
                    as={Button}
                    variant="contained"
                    color="primary"
                    className="font-secondary h-8 w-[180px] shrink-0 font-medium"
                    isDisabled={isTemplateLoading}
                    iconStart={
                      !isTemplateLoading ? (
                        <SelectTemplateIcon className="shrink-0" />
                      ) : undefined
                    }
                  >
                    {isTemplateLoading ? (
                      <LoadingSpinner size="xs" />
                    ) : (
                      <span className="truncate">
                        {detailedTemplate
                          ? t({
                              id: "tasks-page.template",
                              defaultMessage: "Template",
                            })
                              .concat(": ")
                              .concat(detailedTemplate.name)
                          : t({
                              id: "tasks-page.select-template",
                              defaultMessage: "Select Template",
                            })}
                      </span>
                    )}
                  </Trigger>
                )}
                selectedTemplateId={formState.templateId || undefined}
                onSelect={handleSelectTemplate}
              />
            </div>
            {isDescriptionBoxVisible && (
              <DescriptionBox
                description={formState.description}
                isEditing={isEditingDescription}
                classes={{ editingBox: "mt-2", descriptionText: "mt-2 py-2" }}
                onChange={(value) => updateFormState("description", value)}
                onStartEditing={() => setIsEditingDescription(true)}
                onFinishEditing={() => {
                  setIsEditingDescription(false);
                }}
              />
            )}
            <TaskPropertiesPanel
              entityId={entityId}
              config={{
                description: {
                  isHidden: isEditingDescription || !!formState.description,
                  onClick: () => setIsEditingDescription(true),
                },
                board: {
                  boardId: formState.boardId,
                  onChange: (boardId) => updateFormState("boardId", boardId),
                  isHidden: formState.isPrivate,
                },
                status: {
                  statusId: formState.statusId,
                  onChange: (value) => updateFormState("statusId", value),
                  hideCompleted: true,
                },
                dueDate: {
                  state: formState.dueDate,
                  defaultTimezone: timeZone.name,
                  displayAs: "date",
                  withAdvancedOptions: true,
                  onChange: (state) => updateFormState("dueDate", state),
                },
                assignees: {
                  assigneeIds: formState.assigneeIds,
                  onlyReportees: tasksSourceInfo.isTeamTasks,
                  onChange: (assigneeIds) =>
                    updateFormState("assigneeIds", assigneeIds),
                  isHidden: formState.isPrivate,
                },
                priority: {
                  priority: formState.priority,
                  onChange: (priority) => updateFormState("priority", priority),
                },
                label: {
                  labelId: formState.labelId,
                  onChange: (labelId) => updateFormState("labelId", labelId),
                },
                customer: {
                  customerId: formState.customerId,
                  onChange: (chosenCustomer) =>
                    updateFormState("customerId", chosenCustomer?.id || null),
                  isReadonly: tasksSourceInfo.isCustomerTasks,
                  addButton:
                    me?.role === UserRole.admin
                      ? {
                          label: t({
                            id: "tasks-page.add-new-customer",
                            defaultMessage: "Add customer",
                          }),
                          onClick: () => setIsAddNewCustomerDialogOpen(true),
                          startIcon: <AddCustomerIcon />,
                        }
                      : undefined,
                  isHidden: formState.isPrivate,
                },
                recurrence: {
                  state: formState.recurrence,
                  defaultTimezone: timeZone.name,
                  onChange: (recurrence) =>
                    updateFormState("recurrence", recurrence),
                },
                customFields: {
                  onFieldChange: (fieldId, rawValue) =>
                    updateFormState("customFields", {
                      ...formState.customFields,
                      [fieldId]: rawValue,
                    }),
                  valuesById: formState.customFields,
                },
              }}
              className="mt-8"
            />
            <TaskExtraPropertiesPanel
              entityId={entityId}
              config={{
                privateTask: {
                  type: "interactive",
                  isChecked: formState.isPrivate,
                  isHidden: tasksSourceInfo.isTeamTasks,
                  onChange: (isChecked) =>
                    updateFormState("isPrivate", isChecked),
                },
                saveTaskAsTemplate: {
                  templateFolder: formState.saveToTemplateFolder,
                  onTemplateFolderChange: (folder) =>
                    updateFormState("saveToTemplateFolder", folder),
                },
                completeChecklistInOrder: {
                  isChecked: formState.completeChecklistInOrder,
                  onChange: (isChecked) =>
                    updateFormState("completeChecklistInOrder", isChecked),
                },
              }}
              className="mt-8"
            />
          </TaskFormDialog.TitleSection>
          <TaskFormDialog.ChecklistSection
            className="grow-[unset]"
            onAddItem={() => $taskChecklistRef.current?.addItem()}
          >
            <TaskChecklist
              ref={$taskChecklistRef}
              entityId={entityId}
              meId={meId}
              items={formState.checklistItems}
              isCompletable={false}
              isAssignable={!formState.isPrivate}
              onlyReportees={tasksSourceInfo.isTeamTasks}
              {...taskChecklistHandlers}
            />
          </TaskFormDialog.ChecklistSection>
          <TaskFormDialog.AttachmentsSection
            onAddAttachment={() =>
              $taskAttachmentsRef.current?.openFilePicker()
            }
          >
            <TaskAttachments
              mode="controlled"
              ref={$taskAttachmentsRef}
              attachments={adaptedAttachments}
              entityId={entityId}
              onPreviewAttachment={handlePreviewAttachment}
              onFileAdded={handleFilesAdd}
              onAttachmentRename={handleFileRename}
              onAttachmentRemove={handleFileRemove}
            />
          </TaskFormDialog.AttachmentsSection>
        </TaskFormDialog.Content>
        <TaskFormDialog.Actions>
          <Button
            type="button"
            color="grey"
            variant="contained"
            isDisabled={isEmpty()}
            className="h-10 w-[200px]"
            onClick={() =>
              discardChangesConfirmationDialogState.open({
                onConfirm: resetFormState,
                metadata: { reason: "clear" },
              })
            }
          >
            {t({ id: "common.clear-all", defaultMessage: "Clear all" })}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            isDisabled={!isValid || isLoading}
            className="h-10 w-[200px]"
            onClick={handleSubmit}
          >
            {t({ id: "tasks-page.create-task", defaultMessage: "Create task" })}
          </Button>
        </TaskFormDialog.Actions>
      </TaskFormDialog>
      <DiscardChangesConfirmationAlert
        isOpen={discardChangesConfirmationDialogState.isOpen}
        reason={discardChangesConfirmationDialogState.metadata?.reason}
        onConfirm={() =>
          discardChangesConfirmationDialogState.confirm({
            closeOnceConfirmed: true,
          })
        }
        onClose={discardChangesConfirmationDialogState.close}
      />
      <OverrideFormValuesConfirmationAlert
        isOpen={overrideFormValuesConfirmationDialogState.isOpen}
        onConfirm={() =>
          overrideFormValuesConfirmationDialogState.confirm({
            closeOnceConfirmed: true,
          })
        }
        onClose={overrideFormValuesConfirmationDialogState.close}
      />
      <AddNewCustomerDialog
        isOpen={isAddNewCustomerDialogOpen}
        onClose={() => {
          setIsAddNewCustomerDialogOpen(false);
        }}
        onSuccess={(chosenCustomer) =>
          updateFormState("customerId", chosenCustomer.id)
        }
      />
    </>
  );
};

import { cx } from "@jugl-web/utils";
import { ReactNode, HTMLAttributes, forwardRef } from "react";

/**
 * Props for the FlexibleLayout component.
 */
export interface MultiSectionLayoutProps
  extends HTMLAttributes<HTMLDivElement> {
  /** The content to be rendered in the header section. */
  header?: ReactNode;
  /** The main content to be rendered. */
  children?: ReactNode;
  contentRef?: React.LegacyRef<HTMLDivElement>;
  /** The content to be rendered in the left side section. */
  leftSide?: ReactNode;
  /** The content to be rendered in the right side section. */
  rightSide?: ReactNode;
  /** The content to be rendered in the footer section. */
  footer?: ReactNode;
}

/**
 * A multi layout component with sections for header, content, left side, right side, and footer.
 * @param {FlexibleLayoutProps} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
export const MultiSectionLayout = forwardRef<
  HTMLDivElement,
  MultiSectionLayoutProps
>(
  (
    {
      header,
      leftSide,
      rightSide,
      footer,
      children,
      className,
      contentRef,
      ...rest
    },
    ref
  ) => (
    <div ref={ref} {...{ ...rest, className: cx("flex h-full", className) }}>
      {leftSide && <div className="flex-none">{leftSide}</div>}
      <div className="flex h-full w-full flex-auto flex-col">
        {header && <div className="w-full">{header}</div>}
        <div className="h-full flex-1 overflow-y-auto" ref={contentRef}>
          {children}
        </div>
        {footer && <div className="w-full">{footer}</div>}
      </div>
      {rightSide && (
        <div className="h-full flex-none overflow-y-auto">{rightSide}</div>
      )}
    </div>
  )
);

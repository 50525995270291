import { getUniqueId, HookOutOfContextError } from "@jugl-web/utils";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Subject } from "rxjs";
import {
  OrderFormFieldProperty,
  OrderFormFieldType,
  OrderFormFieldValue,
} from "@jugl-web/rest-api/orders/types";
import {
  TASK_ORDER_CUSTOMER_NAME,
  TASK_ORDER_EMAIL_ID,
  TASK_ORDER_PHONE_ID,
} from "@jugl-web/utils/consts";
import { InventoryItem } from "@jugl-web/rest-api";
import { useParams } from "react-router-dom";
import { OrderFormStep } from "../types";

interface OrderFormProviderProps {
  children: React.ReactNode;
}

type UpdateFieldByIdParams<T extends OrderFormFieldProperty["type"]> = {
  id: string;
  type: T;
  value: Extract<OrderFormFieldProperty, { type: T }>["value"];
};

export enum SubmitterContact {
  mobile = "mobile",
  email = "email",
}

const OrderFormContext = createContext<{
  title: string;
  description: string;
  image?: File | string;
  prefix: string;
  fields: OrderFormFieldValue[];
  allFields: OrderFormFieldValue[];
  orderStep: OrderFormStep;
  triggerValidate: Subject<void>;
  submitterContact: SubmitterContact;
  formId?: string;
  onSubmitterContactChange: (submitterContact: SubmitterContact) => void;
  changeOrderStep: (step: OrderFormStep) => void;
  updateFieldById: <T extends OrderFormFieldProperty["type"]>(
    params: UpdateFieldByIdParams<T>
  ) => void;
  getFieldById: (id: string) => OrderFormFieldValue;
  onTitleChange: (title: string) => void;
  onDescriptionChange: (description: string) => void;
  onPrefixChange: (prefix: string) => void;
  onImageChange: (image?: File | string) => void;
  onFieldsChange: (fields: OrderFormFieldValue[]) => void;
  changeRequireById: (id: string) => void;
  deleteFieldById: (id: string) => void;
  copyFieldById: (id: string) => void;
  isPreviewMode: boolean;
  setIsPreviewMode: (isPreviewMode: boolean) => void;
  inventoryItemsCache: InventoryItem[];
  addToInventoryItemsCache: (items: InventoryItem) => void;
} | null>(null);

const OrderFormProvider = ({ children }: OrderFormProviderProps) => {
  const [image, setImage] = useState<File | string>();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [prefix, setPrefix] = useState(getUniqueId().slice(0, 4).toUpperCase());
  const [fields, setFields] = useState<OrderFormFieldValue[]>([]);
  const [orderStep, setOrderStep] = useState<OrderFormStep>(OrderFormStep.form);
  const [submitterContact, setSubmitterContact] = useState<SubmitterContact>(
    SubmitterContact.email
  );
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [inventoryItemsCache, setInventoryItemsCache] = useState<
    InventoryItem[]
  >([]);

  const { formId } = useParams<{ formId: string }>();

  const addToInventoryItemsCache = useCallback(
    (inventoryItem: InventoryItem) => {
      setInventoryItemsCache((prev) =>
        !prev.find((item) => inventoryItem.id === item.id)
          ? [...prev, inventoryItem]
          : prev
      );
    },
    []
  );

  const triggerValidate = useMemo(() => new Subject<void>(), []);

  const changeRequireById = useCallback(
    (id: string) => {
      setFields((prev) =>
        prev.map((field) =>
          field.id === id ? { ...field, isRequired: !field.isRequired } : field
        )
      );
    },
    [setFields]
  );

  const deleteFieldById = useCallback(
    (id: string) => {
      setFields((prev) => prev.filter((field) => field.id !== id));
    },
    [setFields]
  );

  const copyFieldById = useCallback((id: string) => {
    setFields((prev) => {
      const idx = prev.findIndex((field) => field.id === id);

      if (idx !== -1) {
        const fieldToCopy = prev[idx];
        const copiedField = {
          ...fieldToCopy,
          id: getUniqueId(),
        };

        return [...prev.slice(0, idx + 1), copiedField, ...prev.slice(idx + 1)];
      }

      return prev;
    });
  }, []);

  const getFieldById = useCallback(
    (id: string) => {
      const foundedField = fields.find((field) => field.id === id);
      if (foundedField) {
        return foundedField;
      }

      throw new Error(`Order field with ID: ${id} does not exist`);
    },
    [fields]
  );

  const updateFieldById = useCallback(
    <T extends OrderFormFieldProperty["type"]>({
      id,
      type,
      value,
    }: UpdateFieldByIdParams<T>) => {
      setFields((prev) =>
        prev.map((el) =>
          el.id === id && el.property.type === type
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              { ...el, property: { ...el.property, value: value as any } }
            : el
        )
      );
    },
    []
  );

  const value = useMemo(
    () => ({
      title,
      description,
      image,
      fields,
      allFields: [
        ...fields,
        {
          id: TASK_ORDER_CUSTOMER_NAME,
          isRequired: true,
          property: {
            type: OrderFormFieldType.text,
            value: {
              label: "Your full name",
              placeholder: "Enter",
            },
          },
        } as OrderFormFieldValue,
        {
          id:
            submitterContact === SubmitterContact.email
              ? TASK_ORDER_EMAIL_ID
              : TASK_ORDER_PHONE_ID,
          isRequired: true,
          property: {
            type: OrderFormFieldType.text,
            value: {
              label:
                submitterContact === SubmitterContact.email
                  ? "Your email"
                  : "Your phone number",
              placeholder: "Enter",
            },
          },
        } as OrderFormFieldValue,
      ],
      orderStep,
      triggerValidate,
      prefix,
      submitterContact,
      onSubmitterContactChange: setSubmitterContact,
      onPrefixChange: setPrefix,
      changeOrderStep: setOrderStep,
      changeRequireById,
      deleteFieldById,
      copyFieldById,
      getFieldById,
      updateFieldById,
      onFieldsChange: setFields,
      onTitleChange: setTitle,
      onDescriptionChange: setDescription,
      onImageChange: setImage,
      isPreviewMode,
      setIsPreviewMode,
      inventoryItemsCache,
      formId,
      addToInventoryItemsCache,
    }),
    [
      title,
      description,
      image,
      fields,
      orderStep,
      triggerValidate,
      prefix,
      submitterContact,
      formId,
      getFieldById,
      updateFieldById,
      changeRequireById,
      deleteFieldById,
      copyFieldById,
      isPreviewMode,
      setIsPreviewMode,
      inventoryItemsCache,
      addToInventoryItemsCache,
    ]
  );

  return (
    <OrderFormContext.Provider value={value}>
      {children}
    </OrderFormContext.Provider>
  );
};

const useOrderForm = () => {
  const context = useContext(OrderFormContext);

  if (!context) {
    throw new HookOutOfContextError("useOrderForm", "OrderFormContext");
  }

  return context;
};

export { OrderFormProvider, useOrderForm };

import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskActivity } from "@jugl-web/rest-api/tasks";
import { User } from "@jugl-web/rest-api/users";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback, useEffect, useState } from "react";
import { useTaskActivityParser } from "../useTaskActivityParser";
import { useTaskFields } from "../useTaskFields";
import { PaginatedParsedActivity } from "./types";

export type TaskActivityFilter = "updates" | "actions" | "comments" | "guest";

interface UseTaskActivitiesOptions {
  meUser?: User;
  entityId?: string;
  taskId?: string;
  initialFilters?: TaskActivityFilter[];
  sorting?: "asc" | "desc";
}

const PAGE_SIZE = 20;

export const useTaskActivities = ({
  meUser,
  entityId,
  taskId,
  initialFilters = [],
  sorting = "asc",
}: UseTaskActivitiesOptions) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [combinedActivities, setCombinedActivities] = useState<
    PaginatedParsedActivity[]
  >([]);

  const [activitiesFilters, setActivitiesFilters] = useState(initialFilters);

  const { parseTaskActivity } = useTaskActivityParser({ entityId, meUser });

  const { tasksApi } = useRestApiProvider();

  const hasRequiredParams = entityId && taskId;
  const { getCustomFieldById } = useTaskFields({ entityId });

  const {
    data: taskActivitiesResponse,
    isFetching,
    isLoading,
  } = tasksApi.useGetTaskActivitiesQuery(
    hasRequiredParams
      ? {
          entityId,
          params: {
            type: activitiesFilters.length
              ? activitiesFilters.join(",")
              : "non_guest",
            task_id: taskId,
            page_size: PAGE_SIZE,
            page: currentPage,
          },
        }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [activitiesFilters]);

  const loadMore = useCallback(() => {
    if (
      taskActivitiesResponse &&
      currentPage < taskActivitiesResponse.total_pages &&
      !isFetching
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, isFetching, taskActivitiesResponse]);

  const addActivity = useCallback(
    (activity: TaskActivity) => {
      const activityType = activity.action_details.action;

      const matchActivityTypeWithItsFilterType: Record<
        TaskActivity["action_details"]["action"],
        "updates" | "actions" | "comments" | "guest"
      > = {
        created: "updates",
        deleted: "updates",
        viewed: "actions",
        commented: "comments",
        downloaded: "actions",
        updated: "updates",
        guest_comment: "guest",
        guest_downloaded: "guest",
        guest_comment_reply: "guest",
        guest_feedback: "guest",
        guest_opened: "guest",
        restored: "updates",
        archived: "updates",
      };

      if (
        activitiesFilters.length &&
        !activitiesFilters.includes(
          matchActivityTypeWithItsFilterType[activityType]
        )
      ) {
        return;
      }

      const parsedActivity = parseTaskActivity(activity);

      if (parsedActivity) {
        setCombinedActivities((prevActivities) => [
          ...prevActivities,
          { id: parsedActivity.id, data: parsedActivity },
        ]);
      }
    },
    [activitiesFilters, meUser, parseTaskActivity, taskId]
  );

  const modifyActivity = useCallback(
    (activity: TaskActivity) => {
      const parsedActivity = parseTaskActivity(activity);

      if (parsedActivity) {
        setCombinedActivities((prevActivities) =>
          prevActivities.map((item) => {
            if (item.data.raw.id === activity.id) {
              return { id: parsedActivity.id, data: parsedActivity };
            }
            return item;
          })
        );
      }
    },
    [parseTaskActivity]
  );

  const deleteActivity = useCallback((activityId: string) => {
    setCombinedActivities((prevActivities) =>
      prevActivities.filter((item) => item.data.raw.id !== activityId)
    );
  }, []);

  useEffect(() => {
    if (!taskActivitiesResponse) {
      return;
    }

    const transformedActivities = taskActivitiesResponse.data.reduce<
      PaginatedParsedActivity[]
    >((acc, activity) => {
      const parsedActivity = parseTaskActivity(activity);

      const isViewByMe =
        activity.action_details.action === "viewed" &&
        activity.action_by.user_id === meUser?.id;
      if (parsedActivity && !isViewByMe) {
        return [...acc, { id: parsedActivity.id, data: parsedActivity }];
      }

      return acc;
    }, []);

    const shouldResetInternalState = taskActivitiesResponse.page_number === 1;

    if (shouldResetInternalState) {
      setCurrentPage(1);
      setCombinedActivities(
        sorting === "asc"
          ? transformedActivities.reverse()
          : transformedActivities
      );
      return;
    }

    setCombinedActivities((prevActivities) => {
      if (sorting === "asc") {
        return [...transformedActivities.reverse(), ...prevActivities];
      }

      return [...prevActivities, ...transformedActivities];
    });
  }, [
    getCustomFieldById,
    meUser,
    parseTaskActivity,
    taskActivitiesResponse,
    taskId,
    sorting,
  ]);

  return {
    activities: combinedActivities,
    totalCount: taskActivitiesResponse?.total_entries || 0,
    isLoading: isFetching,
    activitiesFilters,
    isInitialLoading: currentPage === 1 && (isLoading || isFetching),
    setActivitiesFilters,
    loadMore,
    addActivity,
    modifyActivity,
    deleteActivity,
  };
};

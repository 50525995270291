import { cx } from "@jugl-web/utils";
import { FC } from "react";

interface TabContentFeedbackTextProps {
  text: string;
  className?: string;
}

export const TabContentFeedbackText: FC<TabContentFeedbackTextProps> = ({
  text,
  className,
}) => (
  <p
    className={cx(
      "font-secondary mt-0 mb-0 ml-0 mr-0 text-base leading-4 text-[#828282]",
      className
    )}
  >
    {text}
  </p>
);

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Portal,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { PlainButton } from "@jugl-web/ui-components/cross-platform";
import React, { Fragment, useEffect } from "react";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { MainImageButton } from "../MainImageButton";
import { DeleteButton } from "../DeleteButton";
import { AddImageTile } from "../AddImageTile";
import { ImageTile } from "../ImageTile";

export const ImagePreviewDialog: React.FC<{
  files: (File | string)[];
  selectedImageIndex: number;
  isOpen: boolean;
  imagesLimit: number;
  onDelete: () => void;
  onSetAsMain: () => void;
  onSelectImageIndex: (index: number) => void;
  onAddImage: () => void;
  onRequestClose: () => void;
}> = ({
  isOpen,
  files,
  selectedImageIndex,
  imagesLimit,
  onAddImage,
  onSelectImageIndex,
  onRequestClose,
  onDelete,
  onSetAsMain,
}) => {
  const { t } = useTranslations();

  useEffect(() => {
    if (files.length === 0) {
      onRequestClose();
    }
  }, [files.length, onRequestClose]);

  const selectedImage = files[selectedImageIndex];

  return (
    <Portal>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          unmount
          as="div"
          className="jugl__border-box-component"
          onClose={onRequestClose}
        >
          <TransitionChild
            as={Fragment}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogBackdrop className="fixed inset-0 z-50 bg-[rgba(14,14,14,0.66)] backdrop-blur-[1px]" />
          </TransitionChild>
          <DialogPanel className="fixed top-0 left-0 bottom-0 right-0 z-[1300] flex h-screen w-screen flex-col">
            <TransitionChild
              as={Fragment}
              enter="transition duration-200"
              enterFrom="opacity-0 -translate-y-full"
              enterTo="opacity-100 translate-y-0"
              leave="transition duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-full"
            >
              <div
                className="flex h-[120px] shrink-0 items-center justify-between px-20"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(22, 22, 23, 0.88) 0%, rgba(22, 22, 23, 0.00) 100%)",
                }}
              >
                <span className="font-secondary max-w-md truncate text-lg font-semibold text-white">
                  {t({
                    id: "common.images",
                    defaultMessage: "Images",
                  })}
                </span>
                <PlainButton
                  className="flex h-10 w-10 items-center justify-center rounded-lg bg-white/5 transition-colors hover:bg-white/20"
                  onClick={onRequestClose}
                >
                  <CloseIcon />
                </PlainButton>
              </div>
            </TransitionChild>
            <TransitionChild
              as={Fragment}
              enter="transition duration-200"
              enterFrom="opacity-0 scale-90"
              enterTo="opacity-100 scale-100"
              leave="transition duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-90"
            >
              <div
                className="flex items-center justify-center"
                style={{ height: "calc(100vh - 300px)" }}
              >
                <div className="relative flex h-[90%] w-[80%] items-center justify-center overflow-hidden">
                  {selectedImage && (
                    <img
                      src={
                        typeof selectedImage === "string"
                          ? selectedImage
                          : URL.createObjectURL(selectedImage)
                      }
                      alt={
                        typeof selectedImage === "string"
                          ? ""
                          : selectedImage.name
                      }
                      className="relative z-10 max-h-full max-w-full select-none rounded-lg"
                    />
                  )}
                </div>
              </div>
            </TransitionChild>
            <TransitionChild
              as={Fragment}
              enter="transition duration-200"
              enterFrom="opacity-0 -translate-y-full"
              enterTo="opacity-100 translate-y-0"
              leave="transition duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-full"
            >
              <div className="mx-auto flex w-max max-w-[90%] flex-col gap-2.5 overflow-hidden rounded-2xl bg-white p-4">
                <div className="flex items-center justify-between">
                  <MainImageButton
                    isMain={selectedImageIndex === 0}
                    onSetAsMain={onSetAsMain}
                    className="bg-grey-200"
                  />
                  <DeleteButton onDelete={onDelete} className="bg-grey-200" />
                </div>
                <div className="jugl__custom-scrollbar flex items-center gap-2.5 overflow-x-auto pb-1">
                  {files.map((file, idx) => (
                    <ImageTile
                      key={+idx}
                      file={file}
                      isSelected={selectedImageIndex === idx}
                      onClick={() => onSelectImageIndex(idx)}
                    />
                  ))}
                  {[...Array(imagesLimit - files.length)].map((_, idx) => (
                    <AddImageTile onClick={onAddImage} key={+idx} />
                  ))}
                </div>
              </div>
            </TransitionChild>
          </DialogPanel>
        </Dialog>
      </Transition>
    </Portal>
  );
};

import {
  PaginatedResponse,
  PaginatedRequestParams,
  RtkEmptySplitApi,
} from "../../types";
import { InventoryItemParams } from "./types";
import {
  InventoryItemDataDto,
  transformInventoryItemDtoToModel,
} from "./models/InventoryItem";
import { InventoryApiTags } from "./tags";
import { stringifyDimensionValue } from "./utils";

export const addInventoryApi = (emptySplitApi: RtkEmptySplitApi) => {
  const apiWithTags = emptySplitApi.enhanceEndpoints({
    addTagTypes: [InventoryApiTags.inventory],
  });
  const inventoryApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
      getInventoryItemsList: build.query<
        PaginatedResponse<InventoryItemDataDto>,
        {
          entityId: string;
          params: PaginatedRequestParams<{ search?: string }>;
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/${entityId}/inventory`,
          params,
        }),
        transformResponse: (
          response: PaginatedResponse<InventoryItemDataDto>
        ) => ({
          ...response,
          data: response.data.map((item) =>
            transformInventoryItemDtoToModel(item)
          ),
        }),
        providesTags: [InventoryApiTags.inventory],
      }),
      getInventoryItem: build.query<
        InventoryItemDataDto,
        {
          entityId: string;
          itemId: string;
          silentError?: boolean;
        }
      >({
        query: ({ entityId, itemId, silentError }) => ({
          url: `/api/auth/${entityId}/inventory/${itemId}`,
          silentError,
        }),
        transformResponse: (response: InventoryItemDataDto) => ({
          ...response,
          data: transformInventoryItemDtoToModel(response),
        }),
        providesTags: (e1, e2, { itemId }) => [
          { type: InventoryApiTags.inventory, id: itemId },
        ],
      }),
      addInventoryItem: build.mutation<
        unknown,
        { entityId: string; params: Partial<InventoryItemParams> }
      >({
        query: ({ entityId, params }) => {
          const formData = new FormData();
          const isItem = params.category === "item";
          formData.set(
            "inventory",
            JSON.stringify({
              name: params.inventoryItemName,
              category: params.category,
              price: params.price?.value,
              unit: params.unit,
              desc: params.description,
              info: {
                dim: isItem ? stringifyDimensionValue(params.dimensions) : null,
                weight:
                  params.category === "item" && params.weight?.value
                    ? `${params.weight?.value} ${params.weight?.unit}`
                    : null,
                sku: isItem ? params.sku : null,
                upc: isItem ? params.upc : null,
                mpn: isItem ? params.mpn : null,
                ean: isItem ? params.ean : null,
                isbn: isItem ? params.isbn : null,
              },
              stock: {
                count: 100,
                notes: "opening_stock",
              },
              warehouse: params.warehouse,
            })
          );

          if (params.files) {
            if (Array.isArray(params.files)) {
              params.files.forEach((file) => {
                formData.append(`files[]`, file);
              });
            } else {
              formData.append("files[]", params.files);
            }
          }
          return {
            url: `/api/auth/${entityId}/inventory`,
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            data: formData,
          };
        },
      }),
      updateInventoryItem: build.mutation<
        InventoryItemDataDto,
        { entityId: string; params: Partial<InventoryItemParams>; id: string }
      >({
        query: ({ entityId, params, id }) => {
          const formData = new FormData();
          const isItem = params.category === "item";
          formData.set(
            "inventory",
            JSON.stringify({
              name: params.inventoryItemName,
              category: params.category,
              price: params.price?.value,
              unit: params.unit,
              desc: params.description,
              info: {
                dim: isItem ? stringifyDimensionValue(params.dimensions) : null,
                weight:
                  params.category === "item" && params.weight?.value
                    ? `${params.weight?.value} ${params.weight?.unit}`
                    : null,
                sku: isItem ? params.sku : null,
                upc: isItem ? params.upc : null,
                mpn: isItem ? params.mpn : null,
                ean: isItem ? params.ean : null,
                isbn: isItem ? params.isbn : null,
              },
            })
          );

          if (params.files) {
            if (Array.isArray(params.files)) {
              params.files.forEach((file) => {
                formData.append(`files[]`, file);
              });
            } else {
              formData.append("files[]", params.files);
            }
          }
          return {
            url: `/api/auth/${entityId}/inventory/${id}`,
            method: "PUT",
            headers: { "Content-Type": "multipart/form-data" },
            data: formData,
          };
        },
        invalidatesTags: (e0, e1, { id }) => [
          InventoryApiTags.inventory,
          { type: InventoryApiTags.inventory, id },
        ],
      }),
      deleteInventoryItem: build.mutation<
        void,
        {
          entityId: string;
          itemId: string;
        }
      >({
        query: ({ entityId, itemId }) => ({
          url: `/api/auth/${entityId}/inventory/${itemId}`,
          method: "DELETE",
        }),
      }),
      updateAttachmentsOrder: build.mutation<
        void,
        {
          entityId: string;
          params: {
            ids: { id: string; order: number }[];
          };
        }
      >({
        query: ({ entityId, params }) => ({
          url: `/api/auth/${entityId}/inventory/img/order`,
          method: "POST",
          data: params,
        }),
      }),
      deleteInventoryAttachment: build.mutation<
        void,
        {
          entityId: string;
          imgId: string;
        }
      >({
        query: ({ entityId, imgId }) => ({
          url: `/api/auth/${entityId}/inventory/img/${imgId}`,
          method: "DELETE",
        }),
      }),
    }),
  });

  return inventoryApi;
};

export type InventoryApi = ReturnType<typeof addInventoryApi>;

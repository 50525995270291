import { DetailedTask } from "@jugl-web/rest-api/tasks";
import { HookOutOfContextError } from "@jugl-web/utils";
import { useContext } from "react";
import {
  TaskDetailsContext,
  TaskDetailsContextValue,
} from "../TaskDetailsProvider";

export const useTaskDetailsContext = () => {
  const context = useContext(TaskDetailsContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskDetailsContext",
      "TaskDetailsContext"
    );
  }

  return context;
};

type LoadedTaskDetailsContextValue = TaskDetailsContextValue & {
  task: DetailedTask;
};

export const useLoadedTaskDetailsContext =
  (): LoadedTaskDetailsContextValue => {
    const context = useTaskDetailsContext();

    if (!context.task) {
      throw new Error("Task is not loaded yet");
    }

    return context as LoadedTaskDetailsContextValue;
  };

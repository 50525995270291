import { TasksBoardId } from "@jugl-web/rest-api/tasks";
import { NavigateToPage } from "@web-src/modules/navigation/components/NavigateToPage";
import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { TaskDetails } from "@web-src/pages/TaskDetails";
import TaskTemplatesFoldersPage from "@web-src/pages/TaskTemplateFolders";
import TaskTemplatesPage from "@web-src/pages/TaskTemplates";
import TasksPage from "@web-src/pages/Tasks";
import { TasksArchive } from "@web-src/pages/TasksArchive";

export type TasksPagesNavigationParams = {
  tasksEntryPoint: PageNavParams;
  tasksTasks: PageNavParams<{ boardId: TasksBoardId }>;
  tasksTemplates: PageNavParams;
  tasksTemplatesFolder: PageNavParams<{ folderId: string }>;
  taskDetails: PageNavParams<{ taskId: string }>;
  tasksArchive: PageNavParams;
};

export const tasksPagesNavigationConfig: ModulePagesConfig<TasksPagesNavigationParams> =
  {
    tasksEntryPoint: {
      element: (
        <NavigateToPage page="tasksTasks" pathParams={{ boardId: "my" }} />
      ),
      path: "tasks",
      isPrivate: true,
    },
    tasksTasks: {
      element: <TasksPage />,
      path: "tasks/board/:boardId",
      isPrivate: true,
    },
    tasksTemplates: {
      element: <TaskTemplatesFoldersPage />,
      path: "tasks/templates",
      isPrivate: true,
    },
    tasksTemplatesFolder: {
      element: <TaskTemplatesPage />,
      path: "tasks/templates/:folderId",
      isPrivate: true,
    },
    taskDetails: {
      element: <TaskDetails />,
      path: "tasks/details/:taskId",
      isPrivate: true,
    },
    tasksArchive: {
      element: <TasksArchive />,
      path: "tasks/archive",
      isPrivate: true,
    },
  };

import { ListBoxItem } from "@jugl-web/ui-components";
import { useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";
import {
  InventoryItemCategory,
  InventoryItemDimensionUnit,
  InventoryItemUnit,
  InventoryItemWeightUnit,
} from "@jugl-web/rest-api";

export const useInventoryFormOptions = () => {
  const { t } = useTranslations();

  const weightUnits = useMemo<ListBoxItem<string>[]>(
    () => [
      {
        id: InventoryItemWeightUnit.kg,
        value: t({
          id: "inventory-item-form-page.kg",
          defaultMessage: "kg",
        }),
      },
      {
        id: InventoryItemWeightUnit.g,
        value: t({
          id: "inventory-item-form-page.g",
          defaultMessage: "g",
        }),
      },
      {
        id: InventoryItemWeightUnit.mg,
        value: t({
          id: "inventory-item-form-page.mg",
          defaultMessage: "mg",
        }),
      },
      {
        id: InventoryItemWeightUnit.lb,
        value: t({
          id: "inventory-item-form-page.lb",
          defaultMessage: "lb",
        }),
      },
      {
        id: InventoryItemWeightUnit.t,
        value: t({
          id: "inventory-item-form-page.ton",
          defaultMessage: "T",
        }),
      },
    ],
    [t]
  );
  const dimensionUnits = useMemo<ListBoxItem<string>[]>(
    () => [
      {
        id: InventoryItemDimensionUnit.mm,
        value: t({
          id: "inventory-item-form-page.mm",
          defaultMessage: "mm",
        }),
      },
      {
        id: InventoryItemDimensionUnit.cm,
        value: t({
          id: "inventory-item-form-page.cm",
          defaultMessage: "cm",
        }),
      },
      {
        id: InventoryItemDimensionUnit.m,
        value: t({
          id: "inventory-item-form-page.m",
          defaultMessage: "m",
        }),
      },
      {
        id: InventoryItemDimensionUnit.in,
        value: t({
          id: "inventory-item-form-page.in",
          defaultMessage: "in",
        }),
      },
      {
        id: InventoryItemDimensionUnit.ft,
        value: t({
          id: "inventory-item-form-page.ft",
          defaultMessage: "ft",
        }),
      },
    ],
    [t]
  );

  const categories = useMemo(
    () => [
      {
        id: "item",
        value: InventoryItemCategory.item,
        label: t({
          id: "inventory-item-form-page.item",
          defaultMessage: "Item",
        }),
      },
      {
        id: "service",
        value: InventoryItemCategory.service,
        label: t({
          id: "inventory-item-form-page.service",
          defaultMessage: "Service",
        }),
      },
    ],
    [t]
  );

  const units = useMemo<ListBoxItem<string>[]>(
    () => [
      {
        id: InventoryItemUnit.pcs,
        value: t({
          id: "inventory-item-form-page.pcs",
          defaultMessage: "pcs",
        }),
      },
      {
        id: InventoryItemUnit.qty,
        value: t({
          id: "inventory-item-form-page.qty",
          defaultMessage: "qty",
        }),
      },
      {
        id: InventoryItemUnit.kg,
        value: t({
          id: "inventory-item-form-page.kg",
          defaultMessage: "kg",
        }),
      },
      {
        id: InventoryItemUnit.dz,
        value: t({
          id: "inventory-item-form-page.dz",
          defaultMessage: "dz",
        }),
      },
      {
        id: InventoryItemUnit.cm,
        value: t({
          id: "inventory-item-form-page.cm",
          defaultMessage: "cm",
        }),
      },
      {
        id: InventoryItemUnit.in,
        value: t({
          id: "inventory-item-form-page.in",
          defaultMessage: "in",
        }),
      },
      {
        id: InventoryItemUnit.ft,
        value: t({
          id: "inventory-item-form-page.ft",
          defaultMessage: "ft",
        }),
      },
      {
        id: InventoryItemUnit.g,
        value: t({
          id: "inventory-item-form-page.g",
          defaultMessage: "g",
        }),
      },
      {
        id: InventoryItemUnit.km,
        value: t({
          id: "inventory-item-form-page.km",
          defaultMessage: "km",
        }),
      },
      {
        id: InventoryItemUnit.m,
        value: t({
          id: "inventory-item-form-page.m",
          defaultMessage: "m",
        }),
      },
      {
        id: InventoryItemUnit.lb,
        value: t({
          id: "inventory-item-form-page.lb",
          defaultMessage: "lb",
        }),
      },
      {
        id: InventoryItemUnit.mg,
        value: t({
          id: "inventory-item-form-page.mg",
          defaultMessage: "mg",
        }),
      },
    ],
    [t]
  );

  return { units, categories, dimensionUnits, weightUnits };
};

import { cx, useAppVariant } from "@jugl-web/utils";
import { FC, ReactNode } from "react";
import { AttachmentsLayout } from "../../types";

interface AttachmentsContainerProps {
  children: ReactNode;
  layout: AttachmentsLayout;
  className?: string;
}

export const AttachmentsContainer: FC<AttachmentsContainerProps> = ({
  children,
  layout,
  className,
}) => {
  const { isMobile } = useAppVariant();

  const isGrid = layout === "grid";

  if (isGrid) {
    return (
      <div
        className={cx(
          "gap-4",
          isMobile ? "mx-auto grid grid-cols-2" : "flex flex-wrap",
          className
        )}
      >
        {children}
      </div>
    );
  }

  return <div className={cx("flex flex-col gap-6", className)}>{children}</div>;
};

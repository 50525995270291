import { useRestApiProvider } from "@jugl-web/rest-api";
import { SidebarDrawer } from "@jugl-web/ui-components";
import { FC } from "react";
import Lottie from "react-lottie";
import loadingAnimation from "./assets/loading.json";
import { ReactComponent as AIIcon } from "./assets/ai.svg";
import "./AIUserPerformanceDialog.css";

export const AIUserPerformanceDialog: FC<{
  userId: string;
  entityId: string;
  isOpen: boolean;
  onRequestedClose: () => void;
}> = ({ userId, entityId, isOpen, onRequestedClose }) => {
  const { usersApi } = useRestApiProvider();
  const { data, isLoading } = usersApi.useGetUserPerformanceQuery(
    { userId, entityId },
    { skip: !isOpen }
  );
  return (
    <SidebarDrawer
      title={
        <div className="flex items-center gap-2">
          <div
            className="flex h-[26px] w-[26px] cursor-pointer items-center justify-center rounded-full"
            style={{
              background:
                "linear-gradient(237deg, #F33A9E 1.05%, #2196F3 86.49%)",
            }}
          >
            <AIIcon />
          </div>
          User Performance by AI
        </div>
      }
      isOpen={isOpen}
      onClose={onRequestedClose}
    >
      {isLoading ? (
        <div className="font-secondary flex flex-col items-center justify-center px-12 pt-[100px] text-center">
          <Lottie
            options={{ animationData: loadingAnimation }}
            height={150}
            width={150}
          />
          <div className="mt-10 mb-6 text-xl font-medium">Loading..</div>
          <div className="text-dark-800 text-sm">
            Please stay here for a while, we are creating best updates for You
          </div>
        </div>
      ) : data ? (
        <div
          className="ai-reply font-secondary max-h-full overflow-y-auto py-4 px-8"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: data }}
        />
      ) : (
        <div className="ai-reply font-secondary max-h-full overflow-y-auto py-4 px-8">
          No Data
        </div>
      )}
    </SidebarDrawer>
  );
};

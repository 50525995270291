import {
  TaskDefaultStatus,
  TaskPriority,
  TaskRecurrence,
  TasksSource,
} from "@jugl-web/rest-api/tasks";
import { TaskTemplateFolder } from "@jugl-web/rest-api/tasks-templates";
import { HookOutOfContextError } from "@jugl-web/utils";
import { omitUndefined } from "@jugl-web/utils/utils/omitUndefined";
import isEqual from "lodash/isEqual";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { TaskChecklistItem } from "../components/TaskChecklist";
import { TaskDueDateState } from "../types";

export interface TaskFormState {
  templateId: string | null;
  title: string;
  description: string;
  dueDate: TaskDueDateState | null;
  assigneeIds: string[];
  labelId: string | null;
  boardId: string | null;
  statusId: TaskDefaultStatus | string;
  priority: TaskPriority;
  customerId: string | null;
  attachments: { id: string; file: File }[];
  recurrence: TaskRecurrence | null;
  customFields: Record<string, string>;
  checklistItems: TaskChecklistItem[];
  saveToTemplateFolder: TaskTemplateFolder | null;
  completeChecklistInOrder: boolean;
  isPrivate: boolean;
}

export type UpdateTaskFormStateFn<
  TState extends Partial<TaskFormState> = Partial<TaskFormState>
> = <TField extends keyof TState, TValue extends TState[TField]>(
  field: TField,
  value: TValue
) => void;

export type ResetTaskFormStateFn<
  TState extends Partial<TaskFormState> = Partial<TaskFormState>
> = (newState?: TState) => void;

export const INITIAL_FORM_STATE: TaskFormState = {
  templateId: null,
  title: "",
  description: "",
  dueDate: null,
  assigneeIds: [],
  labelId: null,
  boardId: null,
  statusId: TaskDefaultStatus.notStarted,
  priority: TaskPriority.none,
  customerId: null,
  attachments: [],
  recurrence: null,
  customFields: {},
  checklistItems: [],
  saveToTemplateFolder: null,
  completeChecklistInOrder: false,
  isPrivate: false,
};

export const useTaskFormState = <TState extends Partial<TaskFormState>>(
  initialState?: Partial<TaskFormState>
) => {
  const [internalInitialState, setInternalInitialState] = useState(() => ({
    ...INITIAL_FORM_STATE,
    ...initialState,
  }));

  const [formState, setFormState] =
    useState<TaskFormState>(internalInitialState);

  const isDirty = useCallback(
    () => !isEqual(formState, internalInitialState),
    [formState, internalInitialState]
  );

  const isEmpty = useCallback(
    () => isEqual(formState, INITIAL_FORM_STATE),
    [formState]
  );

  const resetFormState = useCallback<ResetTaskFormStateFn<TState>>(
    (newState) => {
      const newInitialState = {
        ...INITIAL_FORM_STATE,
        ...omitUndefined(newState || {}),
      };

      setInternalInitialState(newInitialState);
      setFormState(newInitialState);
    },
    []
  );

  const updateFormState = useCallback<UpdateTaskFormStateFn<TState>>(
    (field, value) =>
      setFormState((prevFormState) => ({ ...prevFormState, [field]: value })),
    []
  );

  const updateChecklistItems = useCallback(
    (
      value:
        | TaskChecklistItem[]
        | ((previousValue: TaskChecklistItem[]) => TaskChecklistItem[])
    ) => {
      updateFormState(
        "checklistItems",
        typeof value === "function" ? value(formState.checklistItems) : value
      );
    },
    [formState.checklistItems, updateFormState]
  );

  const updateCustomField = useCallback(
    (customFieldId: string, value: string) => {
      updateFormState("customFields", {
        ...formState.customFields,
        [customFieldId]: value,
      });
    },
    [formState.customFields, updateFormState]
  );

  const formContext = {
    formState,
    isDirty,
    isEmpty,
    resetFormState,
    updateFormState,
    updateChecklistItems,
    updateCustomField,
  };

  return { ...formContext, formContext };
};

// #region Context-related stuff
type UseTaskFormStateContext = ReturnType<
  typeof useTaskFormState
>["formContext"];

const TaskFormStateContext = createContext<UseTaskFormStateContext | null>(
  null
);

interface TaskFormStateProviderProps {
  children: ReactNode;
  context: UseTaskFormStateContext;
}

export const TaskFormStateProvider: FC<TaskFormStateProviderProps> = ({
  children,
  context,
}) => (
  <TaskFormStateContext.Provider value={context}>
    {children}
  </TaskFormStateContext.Provider>
);

export const useTaskFormStateContext = () => {
  const context = useContext(TaskFormStateContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskFormStateContext",
      "TaskFormStateProvider"
    );
  }

  return context;
};
// #endregion

// #region Utils
/**
 * Returns default values for the task form state based on the source.
 *
 * For example:
 * - if we're in the customer tasks, the default value for `customerId` will be set
 * - if we're in the user-created board tasks, the default value for `boardId` will be set
 */
export const getTasksSourceSpecificDefaults = (source: TasksSource) => {
  const defaults: Partial<TaskFormState> = {};

  if (source.type === "customerTasks") {
    defaults.customerId = source.customerId;
  }

  if (
    source.type === "boardTasks" &&
    source.boardId !== "my" &&
    source.boardId !== "team"
  ) {
    defaults.boardId = source.boardId;
  }

  return defaults;
};
// #endregion

import { useEffect, useState, useMemo } from "react";

export const useInventoryTableCheckboxState = ({ ids }: { ids: string[] }) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const [checkedList, setCheckedList] = useState<{
    [key: string]: boolean;
  }>({});

  const isAllChecked = useMemo(
    () => Object.values(checkedList).every((value) => value),
    [checkedList]
  );

  const numbersOfChecked = useMemo(
    () => Object.values(checkedList).filter((value) => value).length,
    [checkedList]
  );

  const handleCheckboxChange = (id: string) => {
    setCheckedList((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleSelectAll = () => {
    setCheckedList((prevState) =>
      ids.reduce(
        (acc, id) => {
          acc[id] = true;
          return acc;
        },
        { ...prevState }
      )
    );
  };

  const handleClearAll = () => {
    setCheckedList((prevState) =>
      ids.reduce(
        (acc, id) => {
          acc[id] = false;
          return acc;
        },
        { ...prevState }
      )
    );
  };

  useEffect(() => {
    if (ids.length === 0 || isInitialized) {
      return;
    }
    const initialCheckedList = ids.reduce((acc, id) => {
      if (!checkedList[id]) {
        acc[id] = false;
      } else {
        acc[id] = checkedList[id];
      }
      return acc;
    }, {} as { [key: string]: boolean });
    setCheckedList(initialCheckedList);
    setIsInitialized(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  return {
    checkedList,
    isAllChecked,
    numbersOfChecked,
    handleCheckboxChange,
    handleSelectAll,
    handleClearAll,
  };
};

import React from "react";

export const SectionHeader: React.FC<{
  icon: React.ReactNode;
  title: string;
}> = ({ icon, title }) => (
  <div className="flex items-center gap-2">
    {icon}
    <span className="text-dark font-secondary text-xl font-medium">
      {title}
    </span>
  </div>
);

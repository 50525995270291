import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { ReactTyped } from "react-typed";
import { ReactComponent as RobotIcon } from "../../icons/robot.svg";
import { ReactComponent as HandleIncomingIcon } from "../../icons/handle-incoming.svg";
import { ReactComponent as HandleOutgoingIcon } from "../../icons/handle-outgoing.svg";
import { ReactComponent as EyeIcon } from "../../icons/eye.svg";
import typingAnimation from "../../animations/typing-animation.json";

export const ChatBotMessageBubble: React.FC<{
  message?: string;
  isSelf?: boolean;
  isLastInGroup?: boolean;
  isTyping?: boolean;
  onTypingStart?: () => void;
  onTypingComplete?: () => void;
}> = ({
  message,
  isTyping,
  isLastInGroup,
  isSelf,
  onTypingStart,
  onTypingComplete,
}) => {
  const displayAvatar = isLastInGroup || isTyping;
  const [isTypingStart, setIsTypingStart] = useState<boolean>();
  useEffect(() => {
    if (isTypingStart || isSelf) {
      return;
    }
    setIsTypingStart(true);
    onTypingStart?.();
  }, [isTypingStart, onTypingStart, isSelf]);
  if (isSelf) {
    return (
      <div className="relative ml-auto flex flex-col rounded-lg rounded-br-none bg-[#4E93CA] text-white">
        <div className="flex items-center gap-2 py-2 pr-7 pl-3">
          <span>{message}</span>
        </div>
        <span className="mr-2 mb-1 flex items-center gap-1 self-end text-sm">
          <EyeIcon />
        </span>
        <HandleOutgoingIcon className="absolute bottom-0 -right-[10px]" />
      </div>
    );
  }
  return (
    <div className="relative flex items-end">
      {displayAvatar && (
        <div className="bg-primary absolute bottom-0 -left-9 flex rounded-full">
          <RobotIcon className="m-1 h-[18px] w-[18px]" />
        </div>
      )}
      <div className="relative flex flex-col rounded-lg rounded-bl-none bg-white">
        {isTyping ? (
          <div className="p-2">
            <Lottie
              options={{
                animationData: typingAnimation,
              }}
              height={20}
              width={40}
            />
          </div>
        ) : (
          <>
            <span className="py-2 pr-7 pl-3">
              {message && (
                <ReactTyped
                  strings={[message]}
                  typeSpeed={20}
                  showCursor={false}
                  onComplete={onTypingComplete || (() => {})}
                />
              )}
            </span>
          </>
        )}
        {displayAvatar && (
          <HandleIncomingIcon className="absolute bottom-0 -left-[10px]" />
        )}
      </div>
    </div>
  );
};

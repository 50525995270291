import { Button, LoadingSpinner, SidebarDrawer } from "@jugl-web/ui-components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { OrderFormServicesFieldValue } from "@jugl-web/rest-api/orders/types";
import { InventoryItem, useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useInView } from "react-intersection-observer";
import { useEffectOnce, usePrevious } from "react-use";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import { useOrderForm } from "@web-src/modules/orders/pages/OrderFormWizardPage/providers/OrderFormProvider";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { ReactComponent as ServiceIcon } from "./assets/service.svg";
import { ServiceDrawerItem } from "./components/ServiceDrawerItem";

type PageState = {
  pageNumber: number;
  requestId: number;
  isError?: boolean;
  hasMore?: boolean;
};

export const ManageServiceDrawer: React.FC<{
  isOpen: boolean;
  items: OrderFormServicesFieldValue["items"];
  onRequestClose: (
    selectedItems?: OrderFormServicesFieldValue["items"]
  ) => void;
}> = ({ isOpen, items, onRequestClose }) => {
  const [selectedServices, setSelectedServices] =
    useState<OrderFormServicesFieldValue["items"]>(items);
  const { searchQuery, inputProps, reset } = useSearchInput();
  const { addToInventoryItemsCache } = useOrderForm();
  const { t } = useTranslations();
  const { inventoryApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();
  const { ref: moreInvwntoryItemsLoadingRef, inView } = useInView();
  const currentPageState = useRef<PageState | null>(null);
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [getInventoryItems, { isLoading, isFetching, isError }] =
    inventoryApi.useLazyGetInventoryItemsListQuery();

  const loadInventoryItemsNextPage = useCallback(async () => {
    let nextPageNumber = 1;
    if (currentPageState.current) {
      if (currentPageState.current.isError) {
        nextPageNumber = currentPageState.current.pageNumber;
      } else {
        nextPageNumber = currentPageState.current.pageNumber + 1;
      }
    }

    const nextPageState: PageState = {
      requestId: Math.random(),
      pageNumber: nextPageNumber,
    };
    currentPageState.current = nextPageState;
    const result = await getInventoryItems({
      entityId,
      params: {
        page: nextPageNumber,
        page_size: 10,
        search: searchQuery ? `%${searchQuery}%` : undefined,
      },
    });
    if (currentPageState.current?.requestId !== nextPageState.requestId) {
      return;
    }
    if ("error" in result && result.error) {
      nextPageState.isError = true;
      currentPageState.current = nextPageState;
      return;
    }
    if ("data" in result && result.data && result.data.data) {
      const responseData = result.data;
      setInventoryItems((prev) =>
        nextPageNumber === 1
          ? responseData.data
          : [...prev, ...responseData.data]
      );
      nextPageState.hasMore = result.data.total_pages > nextPageNumber;
      currentPageState.current = nextPageState;
    }
  }, [entityId, getInventoryItems, searchQuery]);

  const previousSearchQuery = usePrevious(searchQuery);
  useEffect(() => {
    if (
      (!previousSearchQuery && !searchQuery) ||
      searchQuery === previousSearchQuery
    ) {
      return;
    }
    currentPageState.current = null;
    loadInventoryItemsNextPage();
  }, [loadInventoryItemsNextPage, searchQuery, previousSearchQuery]);

  useEffect(() => {
    if (isOpen) {
      setSelectedServices(items);
    }
  }, [isOpen, items]);

  useEffect(() => {
    if (inView) {
      loadInventoryItemsNextPage();
    }
  }, [inView, loadInventoryItemsNextPage]);

  useEffectOnce(() => {
    loadInventoryItemsNextPage();
  });

  return (
    <SidebarDrawer
      isOpen={isOpen}
      onClose={() => onRequestClose(items)}
      title={
        <div className="flex items-center gap-3">
          <ServiceIcon />
          <span>
            {t({
              id: "order-form-wizard-page.select-services",
              defaultMessage: "Select Services",
            })}
          </span>
        </div>
      }
      className="w-[640px]"
    >
      <SidebarDrawer.Content className="flex flex-col gap-4 pt-6">
        {(() => {
          if (isLoading) {
            return (
              <div className="flex h-full w-full items-center justify-center">
                <LoadingSpinner />
              </div>
            );
          }

          if (isError) {
            return (
              <div className="flex h-full w-full flex-col items-center justify-center gap-4">
                <span className="text-tertiary-400 text-lg">
                  {t({
                    id: "order-form-wizard-page.something-went-wrong",
                    defaultMessage: "Something went wrong!",
                  })}
                </span>
                <Button onClick={loadInventoryItemsNextPage}>
                  {t({
                    id: "order-form-wizard-page.click-to-try-again",
                    defaultMessage: "Click to try again",
                  })}
                </Button>
              </div>
            );
          }

          if (!inventoryItems.length && !searchQuery && !isFetching) {
            return (
              <span className="font-secondary mx-8 leading-[160%] text-[#828282]">
                {t({
                  id: "order-form-wizard-page.no-services-created-yet",
                  defaultMessage: "No Services created yet 😔",
                })}
              </span>
            );
          }

          return (
            <>
              <div className="mx-8">
                <SearchInput
                  variant="filled"
                  color="grey"
                  onClear={reset}
                  {...inputProps}
                />
              </div>
              <div className="mb-1 flex flex-col">
                {inventoryItems.length
                  ? inventoryItems.map((item, idx) => (
                      <React.Fragment key={item.id}>
                        <div className="mx-8">
                          <ServiceDrawerItem
                            item={item}
                            searchQuery={searchQuery}
                            isChecked={selectedServices.includes(item.id)}
                            onChange={() => {
                              if (selectedServices.includes(item.id)) {
                                setSelectedServices((prev) =>
                                  prev.filter((el) => el !== item.id)
                                );
                                return;
                              }
                              setSelectedServices((prev) => [...prev, item.id]);
                              addToInventoryItemsCache(item);
                            }}
                          />
                        </div>
                        {inventoryItems.length - 1 !== idx && (
                          <div className="bg-grey-200 my-2.5 h-px w-full" />
                        )}
                      </React.Fragment>
                    ))
                  : !isFetching && (
                      <span className="font-secondary mx-8 text-center leading-[160%] text-[#828282]">
                        {t({
                          id: "order-form-wizard-page.no-results",
                          defaultMessage: "No Results 😔",
                        })}
                      </span>
                    )}
                {isFetching && (
                  <div className="flex h-14 items-center justify-center">
                    <LoadingSpinner />
                  </div>
                )}
                {currentPageState.current?.hasMore &&
                  !isLoading &&
                  !isFetching && (
                    <div ref={moreInvwntoryItemsLoadingRef} className="h-px" />
                  )}
              </div>
            </>
          );
        })()}
      </SidebarDrawer.Content>
      <SidebarDrawer.Actions className="flex items-center justify-center gap-4">
        {!isError && (
          <>
            {selectedServices.length > 0 && (
              <Button
                className="w-[250px]"
                onClick={() => setSelectedServices([])}
                color="grey"
              >
                {t({ id: "common.clear", defaultMessage: "Clear" })}
              </Button>
            )}
            <Button
              className="w-[250px]"
              onClick={() => {
                onRequestClose(selectedServices);
              }}
              isDisabled={!selectedServices.length}
            >
              {selectedServices.length
                ? t(
                    {
                      id: "order-form-wizard-page.select-services-amount",
                      defaultMessage: "Select {servicesAmount}",
                    },
                    { servicesAmount: selectedServices.length }
                  )
                : t({
                    id: "common.select",
                    defaultMessage: "Select",
                  })}
            </Button>
          </>
        )}
      </SidebarDrawer.Actions>
    </SidebarDrawer>
  );
};

import { CustomerRenderer } from "@jugl-web/domain-resources/customers/components/CustomerRenderer";
import {
  MY_TASKS_SOURCE,
  taskDefaultStatusColorById,
} from "@jugl-web/domain-resources/tasks";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { useTaskFiltersState } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { useTaskStatuses } from "@jugl-web/domain-resources/tasks/hooks/useTaskStatuses";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { ArchivedTask, TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import {
  Avatar,
  EmptyListContent,
  LoadingSpinner,
} from "@jugl-web/ui-components/cross-platform";
import { PILL_BASE_TEXT_COLOR } from "@jugl-web/ui-components/cross-platform/tasks/TaskCardPill";
import { HeaderBreadcrumbsItem } from "@jugl-web/ui-components/web";
import { cx, isoToLocalDate, useToast, useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { TaskTableColumnsProvider } from "@web-src/features/tasks/TaskTable/components/TaskTableColumnsProvider";
import { TaskTableLayout } from "@web-src/features/tasks/TaskTable/components/TaskTableLayout";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { colord } from "colord";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { AssigneeCell } from "./components/AssigneesCell";
import { ConfirmRemoveTaskDialog } from "./components/ConfirmRemoveTaskDialog";
import { TaskArchiveDetailsDrawer } from "./components/TaskArchiveDetailsDrawer";
import { TaskRow } from "./components/TaskRow";
import { TasksArchiveControlBar } from "./components/TasksArchiveControlBar";
import { TABLE_DIMENSIONS } from "./consts";
// import { ReactComponent as OrderIcon } from "./assets/order.svg";
import { ReactComponent as CompletedCheckIcon } from "./assets/completed-check.svg";

export const TasksArchive = () => {
  const { t } = useTranslations();
  const { toast, closeToast } = useToast({ variant: "web" });
  const { generateUrl, navigateToPage } = useNavigation();
  const { entity } = useEntitySelectedProvider();

  const [searchQuery, setSearchQuery] = useState("");
  const { filtersState, activeFiltersCount, resetFiltersState } =
    useTaskFiltersState();

  const { getStatusDetailsById } = useTaskStatuses({
    entityId: entity.id,
  });

  const { formatDateTimeLabel, formatDateLabel } = useFormattedDate();
  const { me } = useMe();
  const { tasksApi, customersApi } = useRestApiProvider();
  const { getLabelById } = useTaskFields({
    entityId: entity.id,
  });
  const { ref: inViewRef, inView } = useInView();

  const [taskToRemove, setTaskToRemove] = useState<ArchivedTask>();
  const [archivedTasks, setArchivedTasks] = useState<ArchivedTask[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFetchingInternal, setIsFetchingInternal] = useState(false);
  const [taskToOpenInDrawer, setTaskToOpenInDrawer] = useState<ArchivedTask>();

  const [deleteTask] = tasksApi.useDeleteArchivedTaskMutation();
  const [restoreTask] = tasksApi.useRestoreArchivedTaskMutation();

  const [loadArchivedTasks, { isLoading, isFetching }] =
    tasksApi.useLazyGetArchivedTasksQuery();

  const [loadCustomer] = customersApi.useLazyCustomerQuery();

  const [loadTaskDetails] = tasksApi.useLazyGetArchivedDetailedTaskQuery();

  const breadcrumbs = useMemo<HeaderBreadcrumbsItem[]>(
    () => [
      {
        title: t({
          id: "tasks-page.archive",
          defaultMessage: "Archive",
        }),
        href: generateUrl("tasksArchive"),
      },
    ],
    [generateUrl, t]
  );

  const doesCustomerExist = async (customerId: string) => {
    const response = await loadCustomer({
      entityId: entity.id,
      customerId,
    });

    return !!response.data;
  };

  const handleRestoreTask = async (taskId: string) => {
    const taskDetails = await loadTaskDetails({
      taskId,
      entityId: entity.id,
    });
    if ("data" in taskDetails && taskDetails.data) {
      const restoreTaskResponse = await restoreTask({
        entityId: entity.id,
        task: {
          ...taskDetails.data,
          cust_id:
            !!taskDetails.data.cust_id &&
            (await doesCustomerExist(taskDetails.data.cust_id))
              ? taskDetails.data.cust_id
              : null,
        },
      });
      if (!("error" in restoreTaskResponse)) {
        const toastRef = toast(
          t({
            id: "feedback.task-was-restored-tap-to-open",
            defaultMessage: "Task was restored. Tap to open it",
          }),
          {
            SnackbarProps: {
              style: {
                cursor: "pointer",
                userSelect: "none",
              },
              onClick: () => {
                navigateToPage("taskDetails", { taskId });
                closeToast(toastRef);
              },
            },
          }
        );
        const newTasksState = archivedTasks.filter(
          (task) => task.id !== taskId
        );
        setArchivedTasks(newTasksState);
      }
    }
  };

  const handleRemoveTask = async (taskId?: string) => {
    const taskIdToRemove = taskId || taskToRemove?.id;

    if (!taskIdToRemove) {
      return;
    }
    const removeTaskResponse = await deleteTask({
      entityId: entity.id,
      taskId: taskIdToRemove,
    });
    if (!("error" in removeTaskResponse)) {
      toast(
        t({
          id: "feedback.task-was-deleted",
          defaultMessage: "Task was deleted",
        })
      );
      const newTasksState = archivedTasks.filter(
        (task) => task.id !== taskIdToRemove
      );
      setArchivedTasks(newTasksState);
      setTaskToRemove(undefined);
    }
  };

  const columns = [
    {
      id: "task",
      title: t({ id: "common.task", defaultMessage: "Task" }),
      className: "font-medium text-sm",
      grow: true,
      content: ({
        id: taskId,
        name,
        // order_id: orderId,
        ...rest
      }: ArchivedTask) => (
        <div
          className="flex w-full cursor-pointer items-center justify-between overflow-hidden whitespace-nowrap"
          onClick={() =>
            setTaskToOpenInDrawer({
              id: taskId,
              name,
              // order_id: orderId,
              ...rest,
            })
          }
        >
          <div className="flex w-full items-center gap-2.5 truncate">
            {/* {!!orderId && <OrderIcon className="shrink-0" />} */}
            <span className="w-3/4 truncate">
              <span title={name}>{name}</span>
              {/* {!!orderId && (
                <span className="ml-2 text-xs tracking-[0.12px] text-[#828282]">
                  {orderId}
                </span>
              )} */}
            </span>
          </div>
          {/* Note: temporarily hidden as there's no way to check user permissions at this point */}
          {/* <Menu
            placement="bottom-start"
            renderTrigger={({ Trigger, triggerRef, isOpen }) => (
              <Trigger
                ref={triggerRef}
                as={PlainButton}
                className={cx(
                  "hover:bg-grey-500/5 hidden h-[34px] w-[34px] cursor-pointer items-center justify-center transition-colors group-hover/cell:flex",
                  {
                    "cell:flex bg-grey/5 block": isOpen,
                  }
                )}
                onClick={(event) => event.stopPropagation()}
              >
                <ThreeDots />
              </Trigger>
            )}
            sections={[
              [
                {
                  id: "restore",
                  icon: <RestoreIcon />,
                  label: t({
                    id: "tasks-page.restore-task",
                    defaultMessage: "Restore task",
                  }),
                  onSelect: (_, close) => {
                    handleRestoreTask(taskId);

                    close();
                  },
                },
                {
                  id: "delete",
                  icon: <BinIcon />,
                  label: t({
                    id: "tasks-page.delete-task",
                    defaultMessage: "Delete task",
                  }),
                  onSelect: (_, close) => {
                    setTaskToRemove({
                      id: taskId,
                      name,
                      // order_id: orderId,
                      ...rest,
                    });
                    close();
                  },
                },
              ],
            ]}
            className="z-[110]"
          /> */}
        </div>
      ),
      width: TABLE_DIMENSIONS.TABLE_TASK_TITLE_COLUMN_WIDTH,
    },
    {
      id: "due-date",
      title: t({ id: "common.due-date", defaultMessage: "Due Date" }),
      grow: true,
      content: ({ due_at: dueAt }: ArchivedTask) => (
        <div>{dueAt && formatDateTimeLabel(isoToLocalDate(dueAt))}</div>
      ),
      width: TABLE_DIMENSIONS.TABLE_DUE_DATE_COLUMN_WIDTH,
    },
    {
      id: "assignee",
      title: t({ id: "common.assignee", defaultMessage: "Assignee" }),
      grow: true,
      content: ({ assignees }: ArchivedTask, isExpanded: boolean) => (
        <div>
          <AssigneeCell
            assigneeIds={assignees}
            entityId={entity.id}
            isExpanded={isExpanded}
          />
        </div>
      ),
      width: TABLE_DIMENSIONS.TABLE_ASSIGNEE_COLUMN_WIDTH,
    },
    /* {
      id: "project",
      title: t({ id: "common.project", defaultMessage: "Project" }),
      grow: true,
      content: () => (
        <div className="overflow-hidden truncate whitespace-nowrap">
          Project name
        </div>
      ),
      width: TABLE_DIMENSIONS.TABLE_PROJECT_COLUMN_WIDTH,
    }, */
    {
      id: "date-of-archiving",
      title: t({
        id: "tasks-page.date-of-archiving",
        defaultMessage: "Date of archiving",
      }),
      grow: true,
      content: ({ inserted_at: insertedAt }: ArchivedTask) => (
        <div>
          {insertedAt && formatDateLabel(isoToLocalDate(insertedAt), "concise")}
        </div>
      ),
      width: TABLE_DIMENSIONS.TABLE_DATE_OF_ARCHIVING_COLUMN_WIDTH,
    },
    {
      id: "label",
      title: t({
        id: "common.label",
        defaultMessage: "Label",
      }),
      grow: true,
      content: ({ label_id: labelId }: ArchivedTask) => {
        const label = labelId ? getLabelById(labelId) : undefined;

        if (!label) return null;
        return (
          <div
            className="flex h-full w-full items-center justify-center"
            style={{
              backgroundColor: colord(label.color).alpha(0.12).toHex(),
              color: label.color,
            }}
            title={label.text}
          >
            <span className="flex w-1/2 justify-center overflow-hidden truncate whitespace-nowrap">
              {label.text}
            </span>
          </div>
        );
      },
      width: TABLE_DIMENSIONS.TABLE_LABEL_COLUMN_WIDTH,
    },
    {
      id: "status",
      title: t({
        id: "common.status",
        defaultMessage: "Status",
      }),
      grow: true,
      content: ({ status }: ArchivedTask) => {
        const statusDetails = getStatusDetailsById(status);

        return (
          <TaskTableLayout.ColoredCellContent
            text={
              <span className="flex items-center gap-1">
                {statusDetails.label}
                {statusDetails.id === TaskDefaultStatus.completed && (
                  <CompletedCheckIcon />
                )}
              </span>
            }
            textColor={
              taskDefaultStatusColorById[
                statusDetails.id as TaskDefaultStatus
              ] || PILL_BASE_TEXT_COLOR
            }
          />
        );
      },
      width: TABLE_DIMENSIONS.TABLE_STATUS_COLUMN_WIDTH,
    },
    {
      id: "client",
      title: t({
        id: "common.client",
        defaultMessage: "Client",
      }),
      grow: true,
      content: ({ cust_id }: ArchivedTask) => {
        if (!cust_id) {
          return null;
        }

        return (
          <CustomerRenderer entityId={entity.id} customerId={cust_id}>
            {({ safeCustomerDetails }) => (
              <div className="flex items-center gap-2 overflow-hidden whitespace-nowrap">
                <Avatar
                  username={safeCustomerDetails.fullName}
                  imageUrl={safeCustomerDetails.avatarUrl}
                  size="xs"
                  className="h-[22px] w-[22px]"
                />
                <span
                  title={safeCustomerDetails.fullName}
                  className="w-3/4 truncate"
                >
                  {safeCustomerDetails.fullName}
                </span>
              </div>
            )}
          </CustomerRenderer>
        );
      },
      width: TABLE_DIMENSIONS.TABLE_CUSTOMER_COLUMN_WIDTH,
    },
  ];

  const loadMoreArchivedTasks = useCallback(
    async ({
      reset,
      isQueryReset,
      isFiltersReset,
    }: {
      reset?: boolean;
      isQueryReset?: boolean;
      isFiltersReset?: boolean;
    }) => {
      if (!hasMore || isFetchingInternal) return;
      setIsFetchingInternal(true);
      const response = await loadArchivedTasks(
        {
          entityId: entity.id,
          page: reset ? 1 : page,
          page_size: 20,
          params: {
            search_term:
              searchQuery && !isQueryReset ? `%${searchQuery}%` : undefined,
            assignees:
              filtersState.assignees.length > 0 && !isFiltersReset
                ? filtersState.assignees.join(",")
                : undefined,
            labels:
              filtersState.labels.length > 0 && !isFiltersReset
                ? filtersState.labels.join(",")
                : undefined,
          },
        },
        false
      );
      if (!isInitialized) {
        setIsInitialized(true);
      }

      if (response.data) {
        const { data, total_pages: totalPages } = response.data;
        setPage((prevPage) => prevPage + 1);
        setHasMore(page < totalPages);
        setArchivedTasks((prevCombinedArchivedTasks) => [
          ...prevCombinedArchivedTasks,
          ...data,
        ]);
        setIsFetchingInternal(false);
      }
    },
    [
      entity.id,
      filtersState.assignees,
      filtersState.labels,
      hasMore,
      isFetchingInternal,
      isInitialized,
      loadArchivedTasks,
      page,
      searchQuery,
    ]
  );

  const onUpdate = useCallback(
    (resetSearchQuery?: boolean, resetFilters?: boolean) => {
      setHasMore(true);
      setArchivedTasks([]);
      setPage(1);
      loadMoreArchivedTasks({
        reset: true,
        isQueryReset: resetSearchQuery,
        isFiltersReset: resetFilters,
      });
    },
    [loadMoreArchivedTasks]
  );

  useEffect(() => {
    if (inView && !isLoading && !isFetching && !isFetchingInternal) {
      loadMoreArchivedTasks({
        reset: false,
      });
    }
  }, [
    inView,
    isFetching,
    isFetchingInternal,
    isLoading,
    loadMoreArchivedTasks,
  ]);

  return (
    <TasksPageLayout
      breadcrumbs={breadcrumbs}
      hideRightContent
      className="relative"
    >
      <TasksArchiveControlBar
        filters={filtersState}
        activeFiltersCount={activeFiltersCount}
        searchQuery={searchQuery}
        onChangeFilters={resetFiltersState}
        onResetFilters={resetFiltersState}
        onChangeSearchQuery={setSearchQuery}
        onUpdate={onUpdate}
      />
      {page === 1 && (isLoading || isFetching) && (
        <div className="flex h-full items-center justify-center">
          <LoadingSpinner />
        </div>
      )}
      {isInitialized && archivedTasks.length > 0 && (
        <TasksPageLayout.Content>
          <div className="flex h-full w-full flex-auto flex-col bg-[#EEF2F5]">
            <div className="h-full w-full flex-grow overflow-auto">
              <div
                className={cx(
                  "animate-fade-in flex w-full min-w-max flex-grow flex-col gap-8 overflow-auto px-8 pt-4 pb-6 text-xs",
                  TABLE_DIMENSIONS.TABLE_TASK_NEW_ITEM_ROW_HEIGHT
                )}
              >
                <div className="border-tertiary-400 shrink-0 overflow-auto rounded-xl border-l-4 border-t-0 border-b-0 border-r-0 border-solid bg-white">
                  <TaskTableLayout.Row
                    height={TABLE_DIMENSIONS.TABLE_HEADINGS_ROW_HEIGHT}
                    style={{
                      gap: 0,
                      marginTop: 0,
                    }}
                  >
                    <TaskTableLayout.Cell
                      key="empty"
                      width={TABLE_DIMENSIONS.TABLE_OUTERMOST_COLUMN_WIDTH}
                      shouldGrow={false}
                    />

                    {columns.map((column, index) => (
                      <TaskTableLayout.Cell
                        key={column.id}
                        width={column.width}
                        shouldGrow={index === columns.length - 1}
                        className="text-dark-700 flex items-center border-x-[1px] border-t-0  border-b-0 border-solid border-[#EEF2F5] bg-white px-4 font-normal"
                      >
                        <TaskTableLayout.CellLabel>
                          {column.title}
                        </TaskTableLayout.CellLabel>
                      </TaskTableLayout.Cell>
                    ))}
                  </TaskTableLayout.Row>
                  <TaskTableColumnsProvider
                    entityId={entity.id}
                    source={MY_TASKS_SOURCE}
                  >
                    {archivedTasks.map((task) => (
                      <TaskRow
                        task={task}
                        entityId={entity.id}
                        meId={me?.id || ""}
                        columns={columns}
                        key={task.id}
                      />
                    ))}
                  </TaskTableColumnsProvider>
                </div>
              </div>
              {hasMore && <LoadingSpinner />}
            </div>
          </div>
        </TasksPageLayout.Content>
      )}

      {!isLoading &&
        !isFetchingInternal &&
        !isFetching &&
        archivedTasks.length === 0 &&
        isInitialized && (
          <EmptyListContent
            type="noResults"
            customTitle={t({
              id: "tasks-page.no-archived-tasks-yet",
              defaultMessage: "No archived tasks yet",
            })}
          />
        )}

      {hasMore && <div ref={inViewRef} />}

      <ConfirmRemoveTaskDialog
        isOpen={Boolean(taskToRemove)}
        task={taskToRemove}
        onClose={() => setTaskToRemove(undefined)}
        onConfirm={() => handleRemoveTask()}
      />

      <TaskArchiveDetailsDrawer
        isOpen={Boolean(taskToOpenInDrawer)}
        onClose={() => setTaskToOpenInDrawer(undefined)}
        archivedTask={taskToOpenInDrawer}
        onRemoveTask={handleRemoveTask}
        onRestoreTask={handleRestoreTask}
      />
    </TasksPageLayout>
  );
};

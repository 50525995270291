import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  cx,
  useAppVariant,
  usePrevious,
  useTranslations,
} from "@jugl-web/utils";
import { Fab } from "@jugl-web/ui-components/cross-platform/Fab";
import { Button, Drawer, MultiSectionLayout } from "@jugl-web/ui-components";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { ReactComponent as CrossIcon } from "./icons/cross.svg";
import { ChatBotMessageBubble } from "./components/ChatBotMessageBubble.tsx";
import { ReactComponent as SendIcon } from "./assets/send.svg";
import { ReactComponent as MicroPhoneIcon } from "./assets/microphone.svg";
import { ReactComponent as AIIcon } from "./assets/ai.svg";

export const ChatBot: React.FC<{
  entityId: string;
  keyboardHeight?: number;
}> = ({ entityId, keyboardHeight }) => {
  const synth = window?.speechSynthesis;
  const speakMessage = useCallback(
    (message: string) => {
      if (!synth) {
        return;
      }
      if (synth.speaking) {
        synth.cancel();
      }
      synth.speak(new SpeechSynthesisUtterance(message));
    },
    [synth]
  );

  const { isMobile } = useAppVariant();
  const { entitiesApi } = useRestApiProvider();
  const [requestChatBot, { isLoading: isRequestLoading }] =
    entitiesApi.useAiBotRequestMutation();
  const [openChatBot, { isLoading: isOpenLoading }] =
    entitiesApi.useAiBotOpenMutation();
  const [closeChatBot, { isLoading: isCloseLoading }] =
    entitiesApi.useAiBotCloseMutation();
  const isBotThinking = isRequestLoading || isOpenLoading || isCloseLoading;
  const [isBotTyping, setIsBotTyping] = useState<boolean>();

  const { finalTranscript, resetTranscript } = useSpeechRecognition();
  const isVisible = true;
  const { t } = useTranslations();
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [isInitialized, setIsInitialized] = useState(false);

  const [messages, setMessages] = useState<
    { isSelf: boolean; message: string }[]
  >([]);
  const [isConversationFinished] = useState(false);

  useEffect(() => {
    if (isInitialized || !isWidgetOpen || !entityId) {
      return;
    }
    closeChatBot({ entityId }).then(() => {
      setIsInitialized(true);
      openChatBot({ entityId }).then((resp) => {
        if ("data" in resp && resp.data) {
          const message = resp.data?.[0]?.content;
          setMessages((prevMessages) => [
            ...prevMessages,
            { message, isSelf: false },
          ]);
          speakMessage(message);
        }
      });
    });
  }, [
    entityId,
    isInitialized,
    openChatBot,
    isWidgetOpen,
    closeChatBot,
    speakMessage,
  ]);

  const handleFabClick = () => {
    setIsWidgetOpen(!isWidgetOpen);
  };

  const handleCrossClick = () => {
    setIsWidgetOpen(!isWidgetOpen);
  };

  const previousIsWidgetOpen = usePrevious(isWidgetOpen);

  useEffect(() => {
    if (!previousIsWidgetOpen && isWidgetOpen) {
      if (!isMobile) {
        textareaRef.current?.focus();
      }
      document.body.style.overflow = "hidden";
    }
    if (previousIsWidgetOpen && !isWidgetOpen) {
      document.body.style.overflow = "auto";
      synth?.cancel();
      closeChatBot({ entityId });
      setMessages([]);
      setIsInitialized(false);
    }
  }, [
    closeChatBot,
    entityId,
    isWidgetOpen,
    previousIsWidgetOpen,
    synth,
    isMobile,
  ]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scroll({
        top: messagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const sendMessage = async () => {
    if (isBotThinking || isBotTyping) {
      return;
    }
    const requestMessage = textareaRef?.current?.value?.trim();
    if (!requestMessage) {
      return;
    }
    if (textareaRef.current) {
      textareaRef.current.value = "";
    }
    setMessages((prev) => [...prev, { message: requestMessage, isSelf: true }]);
    setTimeout(() => {
      scrollToBottom();
    }, 200);
    const resp = await requestChatBot({
      entityId,
      data: { input: { text: requestMessage } },
    });
    if ("data" in resp && resp.data) {
      const message = resp.data?.[0]?.content;
      if (!message) {
        return;
      }
      setMessages((prevMessages) => [
        ...prevMessages,
        { message, isSelf: false },
      ]);
      speakMessage(message);
    }
  };

  const [isRecording, setIsRecording] = useState(false);
  const startRecording = () => {
    setIsRecording(true);
    resetTranscript();
    SpeechRecognition.startListening({
      language: "en-US",
      continuous: true,
    });
  };

  const stopRecording = (shouldSend: boolean) => {
    if (shouldSend) {
      sendMessage();
    }
    setIsRecording(false);
    SpeechRecognition.stopListening();
  };

  useEffect(() => {
    if (!isBotTyping) {
      return () => {};
    }
    const intervalId = setInterval(() => {
      scrollToBottom();
    }, 100);
    return () => clearInterval(intervalId);
  }, [isBotTyping]);

  if (!isVisible) {
    return null;
  }
  const $chatbotContent = (
    <MultiSectionLayout
      className={cx(
        isWidgetOpen
          ? "scale-100 scroll-smooth opacity-100"
          : "pointer-events-none scale-95 opacity-0",
        "fixed z-10 origin-bottom transform-gpu bg-gray-100 shadow-lg transition-all duration-500",
        {
          "bottom-12 right-8 z-10 h-[80vh] w-[90vw] rounded-3xl md:w-[600px]":
            !isMobile,
          "inset-x-0 bottom-0 left-0 z-[10000] h-full w-full": isMobile,
        },
        "flex flex-col overflow-x-hidden scroll-smooth"
      )}
      contentRef={messagesContainerRef}
      header={
        <header
          className={cx(
            "z-10 flex h-[72px] w-full shrink-0 items-center rounded-t-3xl bg-white",
            {
              "rounded-none": isMobile,
            }
          )}
        >
          <div
            className="pointer-default ml-6 mr-4 h-9 w-9 rounded-full"
            style={{
              background:
                "linear-gradient(237deg, #F33A9E 1.05%, #2196F3 86.49%)",
            }}
          >
            <AIIcon className="ml-1.5 mt-px h-8 w-6" />
          </div>
          <span className="flex-1 text-lg font-semibold">
            {t({
              id: "task-page.chatbot-title",
              defaultMessage: "Jugl AI",
            })}
          </span>
          <button
            type="button"
            className="cursor-pointer rounded border-none bg-transparent"
            onClick={handleCrossClick}
          >
            <CrossIcon className="mt-1 mr-4 h-10 w-10" />
          </button>
        </header>
      }
      footer={
        <div className="p-2">
          <div className="overflow-hidden rounded-2xl bg-white shadow-lg">
            <textarea
              ref={textareaRef}
              style={{ fontSize: "16px" }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
              cols={1}
              className="font-primary w-full resize-none rounded-lg border-none p-4 leading-[120%] focus:outline-none"
              placeholder="Type a message..."
              value={isRecording ? finalTranscript : undefined}
            />
            <div className="flex items-center justify-end gap-2 px-2 pb-2">
              {isRecording && (
                <Button
                  size="small"
                  color="white"
                  className="text-gray-500"
                  onClick={() => stopRecording(false)}
                >
                  Cancel
                </Button>
              )}
              {!isMobile && (
                <Button
                  color="grey"
                  className={cx(
                    "flex h-[30px] items-center justify-center p-0",
                    { "px-2": isRecording }
                  )}
                  onClick={() =>
                    isRecording ? stopRecording(true) : startRecording()
                  }
                  isDisabled={isBotThinking || isBotTyping}
                >
                  {isRecording && (
                    <div className="bg-gradients-danger h-2 w-2 animate-pulse rounded-full" />
                  )}
                  {isRecording ? (
                    "Send"
                  ) : (
                    <MicroPhoneIcon className="scale-95" />
                  )}
                </Button>
              )}
              {!isRecording && (
                <Button
                  size="small"
                  className="flex h-[30px] w-[30px] items-center justify-center p-0"
                  onClick={sendMessage}
                  isDisabled={isBotThinking || isBotTyping}
                >
                  <SendIcon />
                </Button>
              )}
            </div>
          </div>
        </div>
      }
    >
      <div className="relative flex grow flex-col overflow-y-auto">
        <div
          ref={messagesContainerRef}
          className={cx(
            "flex w-full flex-col gap-4 overflow-y-auto py-5 pl-14 pr-7",
            isConversationFinished && "mb-20"
          )}
        >
          {messages.map((message, idx) => {
            let isLastInGroup = false;
            const nextMessage = messages[idx + 1];
            if (message.isSelf !== nextMessage?.isSelf) {
              isLastInGroup = true;
            }
            return (
              <ChatBotMessageBubble
                message={message.message}
                isSelf={message.isSelf}
                key={+idx}
                isLastInGroup={isLastInGroup}
                onTypingComplete={() => setIsBotTyping(false)}
                onTypingStart={() => setIsBotTyping(true)}
              />
            );
          })}
          {isBotThinking && <ChatBotMessageBubble isTyping />}
        </div>
      </div>
    </MultiSectionLayout>
  );
  return (
    <>
      <Fab
        icon={<AIIcon className={!isMobile ? "h-10 w-10" : "h-8 w-8"} />}
        size={isMobile ? "sm" : "lg"}
        variant="ai"
        onClick={handleFabClick}
        className={cx(
          isMobile
            ? "fixed bottom-4 left-4"
            : "absolute bottom-16 right-16 z-10"
        )}
      />
      {isMobile ? (
        <Drawer
          onClose={() => setIsWidgetOpen(false)}
          isOpen={isWidgetOpen}
          placement="right"
          size="full-screen"
        >
          {$chatbotContent}
        </Drawer>
      ) : (
        $chatbotContent
      )}
    </>
  );
};

import React, { useContext, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Buffer } from "buffer";
import { CallsContext } from "./providers";

const ConferencePage = () => {
  const { joinCall, leaveCall, activeCallProps } = useContext(CallsContext);
  const { channelId } = useParams();
  const [searchParams] = useSearchParams();

  // TODO:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const decodedMessage: any = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any;
    try {
      const base64String = searchParams.get("i") || "";
      const decodedString = Buffer.from(base64String, "base64").toString(
        "utf-8"
      );
      result = JSON.parse(decodedString);
      // eslint-disable-next-line no-empty
    } catch {}
    return result;
  }, [searchParams]);

  useEffect(() => {
    if (!decodedMessage && !channelId) return;
    if (channelId && joinCall) {
      if (activeCallProps?.channel !== channelId && leaveCall) {
        leaveCall?.();
      }
      if (!activeCallProps?.channel || activeCallProps?.channel !== channelId) {
        const conferenceMessage = decodedMessage || { channel: channelId };
        joinCall({
          channel: channelId,
          conferenceMessage,
        });
      }
    }
  }, [
    activeCallProps,
    activeCallProps?.channel,
    channelId,
    decodedMessage,
    decodedMessage?.channel,
    joinCall,
    leaveCall,
  ]);
  return <></>;
};

export default ConferencePage;

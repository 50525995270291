import { useCallback, useMemo } from "react";
import { RequiredTaskFields, useTaskRolesFactory } from "./useTaskRoles";

export interface UseTaskPermissionsFactoryOptions {
  entityId: string;
  meId: string | undefined;
}

export const DEFAULT_TASK_PERMISSIONS: TaskPermissions = {
  canEditTitle: false,
  canEditDescription: false,
  canEditBoard: false,
  canEditStatus: false,
  canEditDueDate: false,
  canEditAssignees: false,
  canEditLabel: false,
  canEditPriority: false,
  canEditCustomer: false,
  canEditTimeSpentOnTask: false,
  canEditRecurrence: false,
  canEditCustomFields: false,
  canManageCompleteInSpecificOrderOption: false,
  canSendUpdateToCustomer: false,
  canDuplicateTask: false,
  canArchiveTask: false,
  canRestoreTask: false,
  canDeleteTask: false,
  canManageChecklist: false,
  canEditChecklistItem: () => false,
  canEditOrDeleteComment: () => false,
  canManageAttachments: false,
};

export const useTaskPermissionsFactory = ({
  entityId,
  meId,
}: UseTaskPermissionsFactoryOptions) => {
  const getTaskRoles = useTaskRolesFactory({ entityId, meId });

  const getTaskPermissions = useCallback(
    (task: RequiredTaskFields) => {
      const { isTaskCreator, isTaskAssignee, isBoardMember } =
        getTaskRoles(task);

      return {
        // Task info
        canEditTitle: isTaskCreator || isTaskAssignee || isBoardMember,
        canEditDescription: isTaskCreator || isTaskAssignee || isBoardMember,
        canEditBoard: task.board_id
          ? isBoardMember
          : isTaskCreator || isTaskAssignee || isBoardMember,
        canEditStatus: isTaskCreator || isTaskAssignee || isBoardMember,
        canEditDueDate: isTaskCreator || isTaskAssignee || isBoardMember,
        canEditAssignees: isTaskCreator || isTaskAssignee,
        canEditLabel: isTaskCreator || isTaskAssignee || isBoardMember,
        canEditPriority: isTaskCreator || isTaskAssignee || isBoardMember,
        canEditCustomer: isTaskCreator || isTaskAssignee || isBoardMember,
        canEditTimeSpentOnTask:
          isTaskCreator || isTaskAssignee || isBoardMember,
        canEditRecurrence: isTaskCreator || isTaskAssignee || isBoardMember,
        canEditCustomFields: isTaskCreator || isTaskAssignee || isBoardMember,
        canManageCompleteInSpecificOrderOption:
          isTaskCreator || isTaskAssignee || isBoardMember,

        // Task actions
        canSendUpdateToCustomer: isTaskCreator || isTaskAssignee,
        canDuplicateTask: isTaskCreator || isTaskAssignee,
        canArchiveTask: isTaskCreator || isTaskAssignee,
        canRestoreTask: isTaskCreator || isTaskAssignee,
        canDeleteTask: isTaskCreator || isTaskAssignee,

        // Checklist
        /**
         * Manage checklist means the user can add, edit (also complete), reorder, or delete checklist items
         */
        canManageChecklist: isTaskCreator || isTaskAssignee || isBoardMember,
        /**
         * Checks whether the user can edit (also complete) a specific checklist item
         */
        canEditChecklistItem: (itemAssigneeId: string) =>
          itemAssigneeId === meId,

        // Comments
        canEditOrDeleteComment: (commentCreatorId: string) =>
          commentCreatorId === meId,

        // Attachments
        canManageAttachments: isTaskCreator || isTaskAssignee || isBoardMember,
      };
    },
    [getTaskRoles, meId]
  );

  return getTaskPermissions;
};

export type TaskPermissions = ReturnType<
  ReturnType<typeof useTaskPermissionsFactory>
>;

interface UseTaskPermissionsOptions extends UseTaskPermissionsFactoryOptions {
  task: RequiredTaskFields | undefined;
}

export const useTaskPermissions = (options: UseTaskPermissionsOptions) => {
  const getTaskPermissions = useTaskPermissionsFactory(options);

  const taskPermissions = useMemo(
    () =>
      options.task
        ? getTaskPermissions(options.task)
        : DEFAULT_TASK_PERMISSIONS,
    [getTaskPermissions, options.task]
  );

  return taskPermissions;
};

import { PreviewTaskTemplate } from "@jugl-web/rest-api/tasks-templates";
import reverse from "lodash/reverse";
import { TaskTemplateSorting } from "./types";

export const sortTaskTemplates = (
  templates: PreviewTaskTemplate[],
  sorting: TaskTemplateSorting
) => {
  switch (sorting) {
    case "createdAtAsc":
      return templates;
    case "createdAtDesc":
      return reverse(templates);
    case "alphabetical":
      return [...templates].sort((templateA, templateB) =>
        templateA.name.localeCompare(templateB.name)
      );
    default:
      throw new Error("Invalid sorting type");
  }
};

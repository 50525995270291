import { Alert, TextField, CountryPicker } from "@jugl-web/ui-components";
import { useTranslations, cx, useToast } from "@jugl-web/utils";
import { useForm, Controller } from "react-hook-form";
import { Country } from "@jugl-web/ui-components/cross-platform/PhoneInput/types";
import { useEffect } from "react";
import countries from "countries-phone-masks";
import { ReactComponent as WarehouseIcon } from "./assets/warehouse.svg";
import { ReactComponent as ChevronIcon } from "./assets/chevron-down.svg";

type Warehouse = {
  id: string;
  name: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
  address: string;
  phone: string;
  email: string;
};

export const AddWarehouseDialog = ({
  isOpen,
  warehouse,
  onClose,
}: {
  isOpen: boolean;
  warehouse?: Warehouse;
  onClose: () => void;
}) => {
  const { t } = useTranslations();
  const {
    handleSubmit,
    control,
    watch,
    reset: resetFormState,
    register,
  } = useForm<{
    warehouseName: string;
    state: string;
    country: Country | null;
    city: string;
    zipCode: string;
    address: string;
    phoneInput: string;
    email: string;
  }>({
    defaultValues: {
      warehouseName: undefined,
      state: undefined,
      zipCode: undefined,
      city: undefined,
      country: null,
      address: undefined,
      phoneInput: undefined,
      email: undefined,
    },
  });

  const formParams = watch();
  const isEdit = warehouse !== undefined;
  const onSubmit = () => {
    toast(
      isEdit
        ? t({
            id: "feedback.warehouse-info-updated",
            defaultMessage: "Warehouse info was updated",
          })
        : t({
            id: "feedback.new-warehouse-created",
            defaultMessage: "New Warehouse has been created",
          })
    );

    onClose();
  };
  const { toast } = useToast({
    variant: "web",
  });

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (warehouse) {
      resetFormState({
        warehouseName: warehouse.name,
        state: warehouse.state,
        zipCode: warehouse.zipCode,
        city: warehouse.city,
        country: {
          name: countries.find((c: Country) => c.iso === warehouse.country)
            ?.name,
          iso: warehouse.country,
        },
        address: warehouse.address,
        phoneInput: warehouse.phone,
        email: warehouse.email,
      });
    } else {
      resetFormState({
        warehouseName: undefined,
        state: undefined,
        zipCode: undefined,
        city: undefined,
        country: {
          name: countries.find((c: Country) => c.iso === "US")?.name,
          iso: "US",
        },
        address: undefined,
        phoneInput: undefined,
        email: undefined,
      });
    }
  }, [isOpen, resetFormState, warehouse]);

  return (
    <Alert
      className="z-50 w-[660px]"
      isCloseButtonVisible
      onRequestClose={onClose}
      buttonsContainerClassName="flex justify-center [&>button]:flex-none"
      contentContainerClassName="px-[56px] py-[32px]"
      content={
        <div className={cx("flex w-full flex-col gap-6")}>
          <TextField
            isFullWidth
            variant="outlined"
            inputClassName="font-secondary font-normal text-dark text-base border-dark-100 px-5 py-4 h-[56px] w-[548px]"
            labelClassName="font-secondary text-dark-800 text-[15px] text-tertiary-600 pb-2"
            isRequired
            label={t({
              id: "inventory-warehouses-page.warehouse-name",
              defaultMessage: "Warehouse Name",
            })}
            {...register("warehouseName", {
              required: true,
            })}
          />
          <div className="flex gap-6">
            <Controller
              control={control}
              name="country"
              render={({ field }) => (
                <CountryPicker
                  onSelect={(item) => field.onChange(item)}
                  placement="bottom"
                  countryIso={field.value?.iso}
                  className="z-[100]"
                >
                  {(isCountryPickerOpen) => (
                    <div className="relative">
                      <TextField
                        value={formParams.country?.name}
                        variant="outlined"
                        inputClassName="font-secondary font-normal text-dark text-base border-dark-100 px-6 py-5 h-[56px] cursor-pointer truncate w-[262px]"
                        labelClassName="font-secondary text-dark-800 text-[15px] text-tertiary-600 pb-2"
                        isRequired
                        label={t({
                          id: "common.country",
                          defaultMessage: "Country",
                        })}
                      />
                      <ChevronIcon
                        className={cx(
                          "absolute right-5 top-[52px] transform transition-transform",
                          isCountryPickerOpen && "rotate-180"
                        )}
                      />
                    </div>
                  )}
                </CountryPicker>
              )}
            />
            <TextField
              variant="outlined"
              label={t({
                id: "inventory-warehouses-page.state",
                defaultMessage: "State",
              })}
              inputClassName="font-secondary font-normal text-dark text-base border-dark-100 px-5 py-4 h-[56px] w-[262px]"
              labelClassName="font-secondary text-dark-800 text-[15px] pb-2"
              {...register("state")}
            />
          </div>
          <div className="flex gap-6">
            <TextField
              isFullWidth
              variant="outlined"
              label={t({
                id: "inventory-warehouses-page.state",
                defaultMessage: "City",
              })}
              inputClassName="font-secondary font-normal text-dark text-base border-dark-100 px-5 py-4 h-[56px] w-[262px]"
              labelClassName="font-secondary text-dark-800 text-[15px] pb-2"
              {...register("city")}
            />
            <TextField
              isFullWidth
              variant="outlined"
              label={t({
                id: "inventory-warehouses-page.zip-code",
                defaultMessage: "ZIP Code",
              })}
              inputClassName="font-secondary font-normal text-dark text-base border-dark-100 px-5 py-4 h-[56px] w-[262px]"
              labelClassName="font-secondary text-dark-800 text-[15px] pb-2"
              {...register("zipCode")}
            />
          </div>
          <label
            className="font-secondary text-dark-800 text-left text-[15px]"
            htmlFor="address"
          >
            <div className="pb-2 pl-2">
              {t({
                id: "inventory-warehouses-page.address",
                defaultMessage: "Address",
              })}
            </div>
            <textarea
              className="font-secondary text-dark border-dark-100 focus:border-primary-300 h-[56px] min-h-[56px] min-w-[548px] max-w-[548px] overflow-hidden rounded-md px-5 py-4 text-base font-normal outline-none transition-colors"
              {...register("address")}
            />
          </label>
          <div className="flex gap-6">
            <TextField
              variant="outlined"
              label={t({
                id: "common.phone-number",
                defaultMessage: "Phone Number",
              })}
              inputClassName="font-secondary font-normal text-dark text-base border-dark-100 px-5 py-4 h-[56px] w-[262px]"
              labelClassName="font-secondary text-dark-800 text-[15px] pb-2"
              {...register("phoneInput")}
            />
            <TextField
              variant="outlined"
              label={t({
                id: "common.email",
                defaultMessage: "Email",
              })}
              inputClassName="font-secondary font-normal text-dark text-base border-dark-100 px-5 py-4 h-[56px] w-[262px]"
              labelClassName="font-secondary text-dark-800 text-[15px] pb-2"
              {...register("email")}
            />
          </div>
        </div>
      }
      header={
        <div className="flex items-center gap-3">
          <WarehouseIcon />
          {isEdit
            ? t({
                id: "inventory-warehouses-page.edit-warehouse-info",
                defaultMessage: "Edit Warehouse Info",
              })
            : t({
                id: "inventory-warehouses-page.new-warehouse",
                defaultMessage: "New Warehouse",
              })}
        </div>
      }
      isOpen={isOpen}
      buttons={[
        {
          className: cx("w-[300px]"),
          text: t({
            id: "common.save",
            defaultMessage: "Save",
          }),
          color: "primary",
          onClick: handleSubmit(onSubmit),
        },
      ]}
    />
  );
};

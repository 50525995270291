export enum InventoryItemCategory {
  item = "item",
  service = "service",
}

export enum InventoryItemUnit {
  pcs = "pcs",
  qty = "qty",
  kg = "kg",
  dz = "dz",
  cm = "cm",
  in = "in",
  ft = "ft",
  g = "g",
  km = "km",
  m = "m",
  lb = "lb",
  mg = "mg",
}

export enum InventoryItemPriceUnit {
  usd = "usd",
}

export enum InventoryItemWeightUnit {
  kg = "kg",
  g = "g",
  lb = "lb",
  mg = "mg",
  t = "t",
}

export enum InventoryItemDimensionUnit {
  cm = "cm",
  in = "in",
  mm = "mm",
  m = "m",
  ft = "ft",
}

export type InventoryItemParams = {
  inventoryItemName: string;
  category: InventoryItemCategory;
  unit: InventoryItemUnit | null;
  price: {
    value: string;
  };
  files: File[];
  description: string;
  dimensions: {
    value: {
      width: string;
      height: string;
      depth: string;
    };
    unit: InventoryItemDimensionUnit;
  } | null;
  weight: {
    value: string;
    unit: InventoryItemWeightUnit;
  } | null;
  sku: string;
  upc: string;
  mpn: string;
  ean: string;
  isbn: string;
  stock: number;
  reorder_point: number;
  warehouse: string | null;
  isTrackInventory: boolean;
};

export type InventoryItem = {
  category: InventoryItemCategory;
  desc: string;
  id: string;
  img: {
    id: string;
    order: number | null;
    path: string;
  }[];
  info: {
    dim: string | null;
    weight: string | null;
    sku: string | null;
    upc: string | null;
    mpn: string | null;
    ean: string | null;
    isbn: string | null;
  };
  name: string;
  price: string;
  stock: {
    stock: number;
    notes: string;
  };
  unit: InventoryItemUnit;
  warehouse: string | null;
};

import React, { useEffect, useState, useRef } from "react";
import { Button, HeaderBreadcrumbs, Alert } from "@jugl-web/ui-components";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useTranslations, useToast, useBlockNavigation } from "@jugl-web/utils";
import { useForm } from "react-hook-form";
import {
  useRestApiProvider,
  InventoryItemParams,
  InventoryItemCategory,
  InventoryItemDimensionUnit,
  InventoryItemWeightUnit,
} from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useParams } from "react-router-dom";
import { InventoryItemDataDto } from "@jugl-web/rest-api/inventory/models/InventoryItem";
import { MainInfoSection } from "./components/MainInfoSection";
import { AdditionalInfoSection } from "./components/AdditionalInfoSection";
import { SectionDivider } from "./components/SectionDivider/SectionDivider";
import { parseDimensionsString } from "./utils";

export const InventoryItemFormPage: React.FC = () => {
  const { navigateToPage } = useNavigation();
  const { toast } = useToast();
  const { id } = useParams();
  const { t } = useTranslations();
  const { inventoryApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const [addInventoryItem, { isLoading: isAddingNewInventoryItem }] =
    inventoryApi.useAddInventoryItemMutation();
  const [updateInventoryItem, { isLoading: isUpdatingInventoryItem }] =
    inventoryApi.useUpdateInventoryItemMutation();
  const [loadItemData, { data: editItemData }] =
    inventoryApi.useLazyGetInventoryItemQuery();
  const [updateAttachmentsOrder, { isLoading: isUpdatingAttachmentsOrder }] =
    inventoryApi.useUpdateAttachmentsOrderMutation();
  const [deleteInventoryAttachment, { isLoading: isDeletingAttachments }] =
    inventoryApi.useDeleteInventoryAttachmentMutation();

  const confirmNavigation = useRef<(() => void) | null>(null);
  const skipNavigationAlert = useRef(false);
  const [internalFileState, setInternalFileState] = useState<(string | File)[]>(
    []
  );

  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    useState(false);

  useBlockNavigation(
    setIsDiscardChangesDialogOpen,
    confirmNavigation,
    skipNavigationAlert
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm<InventoryItemParams>({
    mode: "onChange",
    defaultValues: {
      category: InventoryItemCategory.item,
      description: "",
      files: [],
      ean: "",
      isbn: "",
      isTrackInventory: false,
      mpn: "",
      inventoryItemName: "",
      reorder_point: undefined,
      sku: "",
      stock: undefined,
      unit: undefined,
      upc: "",
      warehouse: "",
      price: { value: undefined },
      weight: { value: undefined, unit: InventoryItemWeightUnit.kg },
      dimensions: {
        value: {
          depth: undefined,
          height: undefined,
          width: undefined,
        },
        unit: InventoryItemDimensionUnit.cm,
      },
    },
  });
  const category = watch("category");
  const handleSubmitForm = handleSubmit(
    async (data: Partial<InventoryItemParams>) => {
      const isUpdate = !!id;
      if (data.category === InventoryItemCategory.service) {
        data.unit = null;
        data.weight = null;
        data.dimensions = null;
      }

      const files = internalFileState
        .filter((file) => file instanceof File)
        .map((file) => file as File);
      data.files = files;
      if (isUpdate) {
        const filesToDelete = editItemData?.img.filter(
          (img) => !internalFileState.includes(img.path)
        );
        if (filesToDelete?.length) {
          await Promise.all(
            filesToDelete?.map((img) =>
              deleteInventoryAttachment({
                entityId: entity.id,
                imgId: img.id,
              })
            )
          );
        }
      }
      const response = isUpdate
        ? await updateInventoryItem({
            entityId: entity.id,
            params: data,
            id,
          })
        : await addInventoryItem({
            entityId: entity.id,
            params: data,
          });

      if ("data" in response) {
        if (!isUpdate) {
          toast(
            t(
              {
                id: "feedback.inventory-info-has-been-created",
                defaultMessage: "New {category} has been created",
              },
              {
                category: data.category === "service" ? "Service" : "Item",
              }
            ),
            {
              variant: "success",
            }
          );
        }
        if (isUpdate) {
          const newFiles = (response.data as InventoryItemDataDto).img.filter(
            (img) => !internalFileState.includes(img.path)
          );
          let lastNewFileIndex = 0;
          const newAttachmentOrder = internalFileState.map((img, i) => {
            if (img instanceof File) {
              const fileId = newFiles[lastNewFileIndex]?.id;
              lastNewFileIndex += 1;
              return {
                id: fileId,
                order: i + 1,
              };
            }
            return {
              id:
                editItemData?.img?.find((item) => item.path === img)?.id || "",
              order: i + 1,
            };
          });
          await updateAttachmentsOrder({
            entityId: entity.id,
            params: {
              ids: newAttachmentOrder,
            },
          });
          toast(
            t(
              {
                id: "feedback.inventory-info-was-updated",
                defaultMessage: "{category} info was updated",
              },
              {
                category: data.category === "service" ? "Service" : "Item",
              }
            ),
            {
              variant: "success",
            }
          );
        }
        skipNavigationAlert.current = true;
        navigateToPage("inventoryItems");
      }
    },
    (submitErrors) => {
      const mandatoryFieldsCount = Object.entries(submitErrors).filter(
        (err) => err[1].message === "Mandatory field"
      ).length;
      if (mandatoryFieldsCount > 0) {
        toast(
          t(
            {
              id: "feedback.inventory-mandatory-fields-error",
              defaultMessage:
                "{count} Mandatory {count, plural, one {field} other {fields}} should be filled to proceed",
            },
            {
              count: mandatoryFieldsCount,
            }
          ),
          {
            variant: "error",
          }
        );
      }
    }
  );

  useEffect(() => {
    if (!id) {
      return;
    }
    const loadEditItemData = async () => {
      const response = await loadItemData({
        itemId: id,
        entityId: entity.id,
      });
      if ("data" in response && response.data) {
        const files = [...response.data.img]
          .sort((a, b) => {
            const imgArray = response?.data?.img ?? [];
            const orderA = a.order ?? imgArray.indexOf(a);
            const orderB = b.order ?? imgArray.indexOf(b);
            return orderA - orderB;
          })
          .map((file) => file.path);
        setInternalFileState(files);

        reset({
          category: response.data.category,
          description: response.data.desc,
          files: [],
          ean: response.data.info?.ean || undefined,
          isbn: response.data.info?.isbn || undefined,
          mpn: response.data.info?.mpn || undefined,
          inventoryItemName: response.data.name,
          sku: response.data.info?.sku || undefined,
          stock: response.data.stock?.stock,
          unit: response.data.unit,
          upc: response.data.info?.upc || undefined,
          warehouse: response.data.warehouse,
          price: {
            value: response.data.price,
          },
          weight: {
            value: response.data.info?.weight
              ?.replace(/[^0-9.,]/g, "")
              .toString(),
            unit:
              (response.data.info?.weight?.replace(
                /[^a-zA-Z]/g,
                ""
              ) as InventoryItemWeightUnit) || undefined,
          },
          dimensions: response.data.info?.dim
            ? parseDimensionsString(response.data.info?.dim)
            : {
                value: {
                  depth: undefined,
                  height: undefined,
                  width: undefined,
                },
                unit: InventoryItemDimensionUnit.cm,
              },
        });
      }
    };
    loadEditItemData();
  }, [entity.id, id, loadItemData, reset]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <HeaderBreadcrumbs
        items={[
          {
            title: t({
              id: "inventory-item-form-page.inventory",
              defaultMessage: "Inventory",
            }),
            onClick: () => navigateToPage("inventoryItems"),
          },
          {
            title: id
              ? t({
                  id: "inventory-item-form-page.edit-item/service",
                  defaultMessage: "Edit item / service",
                })
              : t({
                  id: "inventory-item-form-page.new-item/service",
                  defaultMessage: "New item / service",
                }),
          },
        ]}
      />
      <div className="jugl__custom-scrollbar flex grow flex-col gap-[50px] overflow-auto pt-[50px] pb-[100px]">
        <MainInfoSection
          register={register}
          errors={errors}
          control={control}
          name={watch("inventoryItemName")}
          description={watch("description")}
          isService={category === InventoryItemCategory.service}
          internalFileState={internalFileState}
          setInternalFileState={setInternalFileState}
        />
        {category === "item" && <SectionDivider />}
        {category === "item" && (
          <AdditionalInfoSection
            register={register}
            errors={errors}
            control={control}
            watch={watch}
          />
        )}
      </div>

      <div className="flex shrink-0 items-center gap-2.5 border-0 border-t-2 border-solid border-[#EEF2F5] py-6 px-[70px]">
        <Button
          color="grey"
          className="h-10 w-[140px]"
          onClick={() => navigateToPage("inventoryItems")}
        >
          {t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button
          className="h-10 w-[200px]"
          onClick={handleSubmitForm}
          isDisabled={
            isAddingNewInventoryItem ||
            isUpdatingInventoryItem ||
            isUpdatingAttachmentsOrder ||
            isDeletingAttachments
          }
        >
          {t({
            id: "common.save",
            defaultMessage: "Save",
          })}
        </Button>
      </div>
      <Alert
        isOpen={isDiscardChangesDialogOpen}
        title={t({
          id: "common.discard-changes-warning-title",
          defaultMessage: "Discard changes?",
        })}
        content={t({
          id: "inventory-item-form-page.discard-changes-description",
          defaultMessage: "If you discard, entered info will not be saved",
        })}
        buttons={[
          {
            text: "Cancel",
            role: "close",
            onClick: () => {
              confirmNavigation.current = null;
              setIsDiscardChangesDialogOpen(false);
            },
          },
          {
            text: "Discard",
            color: "tertiary",
            onClick: (_, actions) => {
              confirmNavigation.current?.();
              actions.closeAlert();
            },
          },
        ]}
        onRequestClose={() => setIsDiscardChangesDialogOpen(false)}
      />
    </div>
  );
};

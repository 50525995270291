import { cx, useUniqueDOMId } from "@jugl-web/utils";
import { useMemo } from "react";
import { FormGroup, FormGroupProps } from "../FormGroup";
import { ReactComponent as ArrowUpIcon } from "./assets/chevron.svg";
import { Menu } from "../../Menu";
import { PlainButton } from "../../PlainButton";

export type SelectProps = FormGroupProps & {
  placeholder?: string;
  items: { name: string; value: string; triggerClassName?: string }[];
  classNames?: {
    wrapperClassName?: string;
  };
  onSelect: (value: string) => void;
  value?: string;
};

export const Select = (props: SelectProps) => {
  const uniqueId = useUniqueDOMId();
  const {
    items,
    value,
    onSelect,
    placeholder,
    isInvalid,
    isDisabled,
    classNames,
  } = props;
  const inputId = useMemo(() => uniqueId, [uniqueId]);
  const menuSections = useMemo(() => {
    if (!items) return [];
    return [
      items.map((item) => ({
        id: item.value,
        label: item.name,
        isSelected: item.value === value,
        labelClassName: "text-base text-dark p-2 truncate",
        triggerClassName: item.triggerClassName,
        onSelect: () => onSelect(item.value),
      })),
    ];
  }, [items, value, onSelect]);
  const selectedValue = useMemo(
    () => items.find((item) => item.value === value)?.name,
    [items, value]
  );
  return (
    <FormGroup {...{ ...props, labelFor: inputId }}>
      <Menu
        placement="bottom-end"
        adjustToTriggerWidth
        sections={menuSections}
        autoClose
        renderTrigger={({ Trigger, triggerRef, isOpen }) => (
          <Trigger
            ref={triggerRef}
            as={PlainButton}
            disabled={isDisabled}
            className={cx(
              "border-grey-400 font-secondary flex w-full items-center justify-between rounded-[10px] border-[1px] border-solid px-5 py-4 text-base leading-[24px] transition-colors focus-within:border-transparent",
              "focus-within:border-[#90C9F9] focus-within:outline-none focus-within:ring-1 focus-within:ring-[#90C9F9] hover:border-[#90C9F9] hover:ring-1 hover:ring-[#90C9F9] focus:border-[#90C9F9] focus:outline-none",
              isOpen && "border-[#90C9F9]",
              !value && "text-dark-600",
              isDisabled &&
                "bg-grey-[#FAFAFA] border-grey-400 hover:border-grey-400 border-dashed",
              isInvalid &&
                "border-gradients-danger focus-within:ring-gradients-danger hover:border-gradients-danger focus-within:border-gradients-danger border-solid",
              classNames?.wrapperClassName
            )}
          >
            {selectedValue || placeholder}
            <ArrowUpIcon className={cx(!isOpen && "rotate-180")} />
          </Trigger>
        )}
      />
    </FormGroup>
  );
};

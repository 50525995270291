import { MY_TASKS_SOURCE } from "@jugl-web/domain-resources/tasks";
import { useMarkTaskAsViewed } from "@jugl-web/domain-resources/tasks/hooks/useMarkTaskAsViewed";
import { useTaskUnreadIndicator } from "@jugl-web/domain-resources/tasks/hooks/useTaskUnreadIndicator";
import { useUpdateTask } from "@jugl-web/domain-resources/tasks/hooks/useUpdateTask";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { DetailedTask, TasksSource } from "@jugl-web/rest-api/tasks";
import { isAPIError } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useLocation, useParams } from "react-router-dom";

export interface TaskDetailsContextValue {
  taskId: string;
  task: DetailedTask | undefined;
  isTaskLoading: boolean;
  isError: boolean;
  isNotFoundError: boolean;
  tasksSource: TasksSource;
  updateTask: (attributes: Partial<DetailedTask>) => Promise<DetailedTask>;
  navigateBackToTaskList: () => void;
}

export const TaskDetailsContext = createContext<TaskDetailsContextValue | null>(
  null
);

interface TaskDetailsProviderProps {
  children: ReactNode;
}

export const TaskDetailsProvider: FC<TaskDetailsProviderProps> = ({
  children,
}) => {
  const { taskId } = useParams<{ taskId: string; customerId: string }>();

  if (!taskId) {
    throw new Error("Task ID is required to render TaskDetails page");
  }

  const { entityId } = useEntitySelectedProvider();
  const location = useLocation();
  const { navigateToPage } = useNavigation();

  const { tasksApi } = useRestApiProvider();

  const {
    data: task,
    isLoading,
    error,
  } = tasksApi.useGetTaskQuery({ entityId, taskId });

  const isError = !!error || !!task?._is_deleted;

  const isNotFoundError =
    task?._is_deleted ||
    (isAPIError(error) && (error.status === 404 || error.status === 403));

  const updateTask = useUpdateTask({ entityId, taskId });

  const inferredTasksSource = useMemo(
    () =>
      (location.state?.source as TasksSource | undefined) || MY_TASKS_SOURCE,
    [location.state?.source]
  );

  const { isTaskUnread, readTask } = useTaskUnreadIndicator({
    entityId,
    source: inferredTasksSource,
  });

  const navigateBackToTaskList = useCallback(() => {
    if (inferredTasksSource.type === "customerTasks") {
      navigateToPage("customersTasks", {
        customerId: inferredTasksSource.customerId,
      });
      return;
    }

    navigateToPage("tasksTasks", { boardId: inferredTasksSource.boardId });
  }, [inferredTasksSource, navigateToPage]);

  useEffect(() => {
    if (task && isTaskUnread(task.id)) {
      readTask(task.id);
    }
  }, [isTaskUnread, readTask, task]);

  useMarkTaskAsViewed({ entityId, taskId: task?.id });

  const contextValue = useMemo<TaskDetailsContextValue>(
    () => ({
      taskId,
      task,
      isTaskLoading: isLoading,
      isError,
      isNotFoundError,
      tasksSource: inferredTasksSource,
      updateTask,
      navigateBackToTaskList,
    }),
    [
      taskId,
      task,
      isLoading,
      isError,
      isNotFoundError,
      inferredTasksSource,
      updateTask,
      navigateBackToTaskList,
    ]
  );

  return (
    <TaskDetailsContext.Provider value={contextValue}>
      {children}
    </TaskDetailsContext.Provider>
  );
};

import { HookOutOfContextError } from "@jugl-web/utils";
import { OpenConfirmationDialogFn } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { createContext, useContext } from "react";
import { Mention } from "../../../common/components/RichTextarea";
import { TaskChecklistItem, TaskChecklistProps } from "./types";

interface TaskChecklistContextValue
  extends Required<
    Pick<
      TaskChecklistProps,
      | "entityId"
      | "meId"
      | "isCompleteInSpecificOrder"
      | "searchQuery"
      | "onlyReportees"
      | "displayDueDateAs"
      | "isManageable"
      | "isCompletable"
      | "isAssignable"
      | "canEditItem"
    >
  > {
  mentions: Mention[];
  onTriggerSaveBeforeLeavingDialog: OpenConfirmationDialogFn;
  onTriggerDeleteConfirmationDialog: OpenConfirmationDialogFn<{
    item: TaskChecklistItem;
  }>;
  isSelectable: boolean;
  isSelectMode: boolean;
  selectedChecklistIds: string[];
  taskId?: string;
  onSelectModeChange: (isSelectMode: boolean) => void;
  onSelectedChecklistIdsChange: (selectedChecklistIds: string[]) => void;
  onNavigateToTask?: (taskId: string) => void;
}

const TaskChecklistContext = createContext<TaskChecklistContextValue | null>(
  null
);

export const useTaskChecklistContext = () => {
  const context = useContext(TaskChecklistContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskChecklistItem",
      "TaskChecklistItemContext"
    );
  }

  return context;
};

export const TaskChecklistProvider = TaskChecklistContext.Provider;

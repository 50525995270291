import { Alert } from "@jugl-web/ui-components/cross-platform/Alert";
import { ConfirmationPopup } from "@jugl-web/ui-components/cross-platform/ConfirmationPopup";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import Lottie from "react-lottie";
import alertAnimation from "./assets/alert-animation.json";

interface OverrideFormValuesConfirmationAlertProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const OverrideFormValuesConfirmationAlert: FC<
  OverrideFormValuesConfirmationAlertProps
> = ({ isOpen, onConfirm, onClose }) => {
  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const title = t({
    id: "tasks-page.template-override-warning-title",
    defaultMessage: "Save changes",
  });

  const message = t({
    id: "tasks-page.template-override-warning-description",
    defaultMessage:
      "Please note that applying the selected template to the task may override some of the entered info.",
  });

  const cancelLabel = t({ id: "common.cancel", defaultMessage: "Cancel" });

  const proceedLabel = t({ id: "common.proceed", defaultMessage: "Proceed" });

  if (isMobile) {
    return (
      <ConfirmationPopup
        variant="mobile"
        isOpen={isOpen}
        title={title}
        message={message}
        hasCancelButton
        buttons={[
          {
            label: proceedLabel,
            variant: "contained",
            color: "tertiary",
            onClick: onConfirm,
          },
        ]}
        onRequestClose={onClose}
      />
    );
  }

  return (
    <Alert
      isOpen={isOpen}
      img={
        <Lottie
          options={{ animationData: alertAnimation }}
          width={70}
          height={70}
        />
      }
      title={title}
      content={message}
      buttons={[
        { text: cancelLabel, role: "close" },
        { text: proceedLabel, color: "primary", onClick: onConfirm },
      ]}
      onRequestClose={onClose}
    />
  );
};

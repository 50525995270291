import React, { useState } from "react";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput, useTranslations, cx } from "@jugl-web/utils";
import {
  Button,
  EmptyListContent,
  TableGrid,
  Menu,
  PlainButton,
  Checkbox,
  InteractiveContainer,
} from "@jugl-web/ui-components";
import Lottie from "react-lottie";
import { InventoryTabsLayout } from "../components/InventoryTabsLayout";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as MoreIcon } from "./assets/more.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as RedDeleteIcon } from "./assets/red-delete.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ReactComponent as GreenDotIcon } from "./assets/green-dot.svg";
import { ReactComponent as RedDotIcon } from "./assets/red-dot.svg";
import emptyAnimation from "./assets/empty-warehouse.json";
import { useInventoryTableCheckboxState } from "../hooks/useInventoryTableCheckboxState";
import { ConfirmRemoveWarehouseAlert } from "./components/ConfirmRemoveWarehouseAlert";
import { AddWarehouseDialog } from "./components/AddWarehouseDialog";

const data = [
  {
    address: {
      country: "IN",
      state: "TN",
      city: "Chennai",
      zipcode: "02",
      address: "25, abc street",
    },
    email: "nandhini@gmail.com",
    id: "f67b887e-eedb-4126-9834-f0937e396e8a",
    mobile: "+9145678",
    name: "warehouse_1",
    status: "active",
  },
  {
    address: {
      country: "IN",
      state: "TN",
      city: "Chennai",
      zipcode: "02",
      address: "25, abc street",
    },
    email: "nandhini@gmail.com",
    id: "16374dd5-6825-433b-a051-1b80a9e2d84d",
    mobile: "+9145678",
    name: "warehouse_2",
    status: "inactive",
  },
];

export const InventoryWarehousesPage: React.FC = () => {
  const { t } = useTranslations();
  const { inputProps, reset } = useSearchInput();
  const warehouseIds = React.useMemo(() => data.map(({ id }) => id), []);
  const {
    checkedList,
    isAllChecked,
    numbersOfChecked,
    handleCheckboxChange,
    handleClearAll,
    handleSelectAll,
  } = useInventoryTableCheckboxState({ ids: warehouseIds });

  const [isConfirmRemoveWarehouseOpen, setIsConfirmRemoveWarehouseOpen] =
    useState(false);
  const [isAddWarehouseDialogOpen, setIsAddWarehouseDialogOpen] =
    useState(false);

  return (
    <InventoryTabsLayout
      selectedTab="inventory-warehouses"
      rightContent={
        <div className="flex items-center gap-4">
          <SearchInput
            className="w-[280px]"
            variant="filled"
            color="white"
            onClear={reset}
            {...inputProps}
          />
          {numbersOfChecked === 0 && (
            <Button
              className="h-10"
              iconEnd={<AddIcon />}
              onClick={() => setIsAddWarehouseDialogOpen(true)}
            >
              {t({
                id: "inventory-warehouses-page.add-warehouse",
                defaultMessage: "Add Warehouse",
              })}
            </Button>
          )}
          {numbersOfChecked > 0 && (
            <Button
              iconStart={<RedDeleteIcon />}
              className="font-secondary text-dark h-10 font-normal"
              color="white"
              onClick={() => {
                setIsConfirmRemoveWarehouseOpen(true);
              }}
            >
              {t(
                {
                  id: "inventory-warehouses-page.delete-item",
                  defaultMessage:
                    "Delete {count} {count, plural, one {Item} other {Items}}",
                },
                {
                  count: numbersOfChecked,
                }
              )}
            </Button>
          )}
          {numbersOfChecked > 0 && (
            <PlainButton
              onClick={() => {
                handleClearAll();
              }}
            >
              <CloseIcon />
            </PlainButton>
          )}
        </div>
      }
    >
      {data && (
        <div className="w-fit p-8">
          <TableGrid
            unstyled
            data={data}
            className="rounded-xl border-[1px] border-solid border-[#EEF2F5]"
            headerCellClassName="px-4 py-3 text-xs text-dark-700 font-normal leading-[140%] border-0 border-r border-solid border-[#EEF2F5] flex items-center"
            cellClassName="px-4 text-dark-800 font-normal flex items-center text-sm py-4 border-0 border-t border-r border-solid border-[#EEF2F5] leading-[140%] tracking-[0.14px] hover:bg-grey-200"
            rowHoverClassName="bg-grey-100"
            onReachEnd={() => {}}
            columns={[
              {
                title: (
                  <Checkbox
                    size="sm"
                    isChecked={isAllChecked}
                    onChange={() => {
                      if (isAllChecked) {
                        handleClearAll();
                      } else {
                        handleSelectAll();
                      }
                    }}
                  />
                ),
                grow: false,
                width: 50,
                content: ({ id }) => (
                  <Checkbox
                    size="sm"
                    isChecked={checkedList[id]}
                    onChange={() => {
                      handleCheckboxChange(id);
                    }}
                  />
                ),
              },
              {
                title: t({
                  id: "inventory-warehouses-page.warehouse-name",
                  defaultMessage: "Warehouse Name",
                }),
                grow: true,
                width: 320,
                content: ({ name }) => (
                  <div
                    className="font-secondary flex h-full w-full items-center truncate text-base font-medium"
                    title={name}
                  >
                    {name}
                  </div>
                ),
              },
              {
                title: t({
                  id: "common.status",
                  defaultMessage: "Status",
                }),
                grow: false,
                className: "p-[1px]",
                width: 90,
                content: ({ status }) => (
                  <Menu
                    placement="bottom-end"
                    className="flex w-[120px] min-w-[0] flex-col gap-2 p-2"
                    autoClose
                    renderTrigger={({ Trigger, triggerRef }) => (
                      <Trigger
                        ref={triggerRef}
                        as={InteractiveContainer}
                        className={cx(
                          "flex h-[52px] w-[86px] items-center justify-center capitalize",
                          status === "active"
                            ? "bg-secondary-50 text-secondary-800"
                            : "bg-tertiary-50 text-tertiary-800"
                        )}
                      >
                        {status}
                      </Trigger>
                    )}
                    sections={[
                      [
                        {
                          id: "active",
                          label: t({
                            id: "common.active",
                            defaultMessage: "Active",
                          }),
                          className: cx(
                            "h-[40px] rounded-xl text-sm font-secondary text-dark",
                            status === "active" ? "bg-[#E3F2FD]" : ""
                          ),
                          icon: <GreenDotIcon />,
                          triggerClassName: "p-2",
                          onSelect: () => {},
                        },
                        {
                          id: "inactive",
                          label: t({
                            id: "common.inactive",
                            defaultMessage: "Inactive",
                          }),
                          className: cx(
                            "h-[40px] rounded-xl text-sm font-secondary text-dark",
                            status === "inactive" ? "bg-[#E3F2FD]" : ""
                          ),
                          triggerClassName: "p-2",
                          onSelect: () => {},
                          icon: <RedDotIcon />,
                        },
                      ],
                    ]}
                  />
                ),
              },
              {
                title: t({
                  id: "common.country",
                  defaultMessage: "Country",
                }),
                grow: false,
                width: 150,
                className: "truncate",
                content: ({ address }) => (
                  <div title={address.country}>{address.country}</div>
                ),
              },
              {
                title: t({
                  id: "common.state",
                  defaultMessage: "State",
                }),
                grow: false,
                width: 150,
                className: "truncate",
                content: ({ address }) => (
                  <div title={address.state}>{address.state}</div>
                ),
              },
              {
                title: t({
                  id: "common.city",
                  defaultMessage: "City",
                }),
                grow: false,
                width: 150,
                className: "truncate",
                content: ({ address }) => (
                  <div title={address.city}>{address.city}</div>
                ),
              },
              {
                title: t({
                  id: "common.zipcode",
                  defaultMessage: "ZIP code",
                }),
                grow: false,
                width: 100,
                content: ({ address }) => (
                  <div title={address.zipcode}>{address.zipcode}</div>
                ),
              },
              {
                title: t({
                  id: "common.address",
                  defaultMessage: "Address",
                }),
                grow: false,
                width: 200,
                className: "truncate",
                content: ({ address }) => (
                  <div title={address.address}>{address.address}</div>
                ),
              },
              {
                title: t({
                  id: "common.mobile-number",
                  defaultMessage: "Mobile Number",
                }),
                grow: false,
                width: 150,
                content: ({ mobile }) => <div title="mobile">{mobile}</div>,
              },
              {
                title: t({
                  id: "common.email",
                  defaultMessage: "Email",
                }),
                grow: false,
                width: 200,
                className: "truncate",
                content: ({ email }) => <div title={email}>{email}</div>,
              },
              {
                title: t({
                  id: "order-forms-page.settings",
                  defaultMessage: "Settings",
                }),
                className: "flex justify-center w-full border-r-0",
                width: 100,
                grow: false,
                headerClassName: "border-r-0",
                content: () => (
                  <Menu
                    placement="bottom-end"
                    autoClose
                    renderTrigger={({ Trigger, triggerRef }) => (
                      <Trigger
                        as={PlainButton}
                        ref={triggerRef}
                        className="mt-1"
                      >
                        <MoreIcon />
                      </Trigger>
                    )}
                    className="py-2"
                    sections={[
                      [
                        {
                          id: "edit",
                          label: t({
                            id: "common.edit",
                            defaultMessage: "Edit",
                          }),
                          icon: <EditIcon />,
                          onSelect: () => {},
                        },
                        {
                          id: "delete",
                          label: t({
                            id: "common.delete",
                            defaultMessage: "Delete",
                          }),
                          onSelect: () => {},
                          icon: <DeleteIcon />,
                        },
                      ],
                    ]}
                  />
                ),
              },
            ]}
          />
        </div>
      )}
      {!data && (
        <EmptyListContent
          type="custom"
          className="w-[400px]"
          customImg={
            <Lottie
              options={{ animationData: emptyAnimation }}
              height={300}
              width={300}
            />
          }
          customTitle={t({
            id: "inventory-warehouses-page.warehouse-storage",
            defaultMessage: "Warehouse Storage ✨",
          })}
          customSubtitle={t({
            id: "inventory-warehouses-page.warehouse-benefits-message",
            defaultMessage:
              "Add warehouses to streamline inventory management and optimize storage",
          })}
          customButton={{
            text: t({
              id: "inventory-warehouses-page.add-warehouse",
              defaultMessage: "Add Warehouse",
            }),
            iconEnd: <AddIcon />,
            fullWidth: false,
            className: "h-10",
            onClick: () => {},
          }}
        />
      )}
      <ConfirmRemoveWarehouseAlert
        isOpen={isConfirmRemoveWarehouseOpen}
        onAccept={() => {
          setIsConfirmRemoveWarehouseOpen(false);
        }}
        onClose={() => {
          setIsConfirmRemoveWarehouseOpen(false);
          <Button
            className="h-10"
            iconEnd={<AddIcon />}
            onClick={() => setIsAddWarehouseDialogOpen(true)}
          >
            {t({
              id: "inventory-warehouses-page.add-warehouse",
              defaultMessage: "Add Warehouse",
            })}
          </Button>;
        }}
      />
      <AddWarehouseDialog
        isOpen={isAddWarehouseDialogOpen}
        onClose={() => setIsAddWarehouseDialogOpen(false)}
      />
    </InventoryTabsLayout>
  );
};

import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import {
  TaskFiltersStateProvider,
  useTaskFiltersState,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { Alert } from "@jugl-web/ui-components/cross-platform/Alert";
import { ScreenTransitionWrapper } from "@jugl-web/ui-components/cross-platform/ScreenTransitionWrapper";
import {
  BottomCenteredDrawer,
  BottomCenteredDrawerProps,
} from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import {
  DRAWER_HEADER_HEIGHT_PX,
  DrawerHeaderNavButton,
} from "@jugl-web/ui-components/web/DrawerHeader";
import {
  assert,
  useAppVariant,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import {
  Screen,
  ScreenTransitionManagerProvider,
  useScreenTransitionManager,
} from "@jugl-web/utils/utils/ScreenTransitionManager";
import isEqual from "lodash/isEqual";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as RefreshIcon } from "./assets/refresh.svg";
import { CreateFilterSetScreen } from "./components/CreateFilterSetScreen";
import { FiltersScreen } from "./components/FiltersScreen";
import { ManageFilterSetButton } from "./components/ManageFilterSetButton";
import { TaskFiltersDialogScreenToParametersMap } from "./types";

const INITIAL_SCREEN: Screen<TaskFiltersDialogScreenToParametersMap> = {
  name: "filters",
};

interface TaskFiltersDialogProps {
  entityId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const InnerTaskFiltersDialog: FC<TaskFiltersDialogProps> = ({
  entityId,
  isOpen,
  onClose,
}) => {
  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    useState(false);

  const { updateFilterSet, isUpdatingFilterSet } = useTaskFields({ entityId });
  const { filters, activeFilterSet } = useTaskFiltering();

  const {
    filtersState: internalFiltersState,
    filtersStateContext: internalFiltersStateContext,
    isDirty: isInternalFiltersStateDirty,
    activeFiltersCount: internalActiveFiltersCount,
    resetFiltersState: resetInternalFiltersState,
  } = useTaskFiltersState();

  const { screen, transitionTo, renderContent } =
    useScreenTransitionManager<TaskFiltersDialogScreenToParametersMap>();

  const { t } = useTranslations();
  const { variant } = useAppVariant();
  const { toast } = useToast({ variant });

  const hasFilterSetChanged = useMemo(
    () =>
      activeFilterSet
        ? !isEqual(internalFiltersState, activeFilterSet.filters)
        : false,
    [internalFiltersState, activeFilterSet]
  );

  const handleUpdateActiveFilterSet = useCallback(async () => {
    assert(
      !!activeFilterSet,
      "Active filter set must be defined at this point"
    );

    try {
      await updateFilterSet(
        activeFilterSet.id,
        activeFilterSet.name,
        internalFiltersState
      );

      toast(
        t({
          id: "feedback.filter-set-updated",
          defaultMessage: "Filter set has been updated",
        }),
        { variant: "success" }
      );

      onClose();
    } catch {
      // Do nothing
    }
  }, [
    activeFilterSet,
    internalFiltersState,
    onClose,
    t,
    toast,
    updateFilterSet,
  ]);

  const handleSafeClose = useCallback(() => {
    if (isInternalFiltersStateDirty()) {
      setIsDiscardChangesDialogOpen(true);
      return;
    }

    onClose();
  }, [isInternalFiltersStateDirty, onClose]);

  const handleResetState = () => {
    if (!isOpen) {
      transitionTo(INITIAL_SCREEN, { force: true });
    }
  };

  const header = useMemo<BottomCenteredDrawerProps["header"]>(() => {
    if (screen.name === "createFilterSet") {
      return {
        type: "breadcrumbs",
        breadcrumbs: [
          {
            id: "filters",
            title: activeFilterSet
              ? activeFilterSet.name
              : t({
                  id: "tasks-page.filters",
                  defaultMessage: "Filters",
                }),
            subtitle: activeFilterSet
              ? t({
                  id: "tasks-page.filter-set",
                  defaultMessage: "Filter set",
                })
              : undefined,
            onClick: () => transitionTo({ name: "filters" }),
          },
          {
            id: "create-filter-set",
            title: t({
              id: "tasks-page.create-filter-set",
              defaultMessage: "Create filter set",
            }),
          },
        ],
      };
    }

    if (!activeFilterSet) {
      return {
        type: "title" as const,
        title: t({ id: "tasks-page.filters", defaultMessage: "Filters" }),
        navigationSlots:
          internalActiveFiltersCount > 0
            ? [
                <ManageFilterSetButton
                  label={t({
                    id: "tasks-page.create-filter-set-with-emoji",
                    defaultMessage: "Create filter set ✨",
                  })}
                  tip={t({
                    id: "tasks-page.create-filter-set-tip",
                    defaultMessage: "Based on applied filters",
                  })}
                  onClick={() => transitionTo({ name: "createFilterSet" })}
                />,
              ]
            : undefined,
      };
    }

    return {
      type: "title",
      title: activeFilterSet.name,
      subtitle: t({
        id: "tasks-page.filter-set",
        defaultMessage: "Filter set",
      }),
      navigationSlots: [
        {
          element: (
            <ManageFilterSetButton
              label={t({
                id: "tasks-page.update-my-filter-set",
                defaultMessage: "Update my set",
              })}
              onClick={handleUpdateActiveFilterSet}
              isDisabled={isUpdatingFilterSet}
              menuConfig={{
                sections: [
                  [
                    {
                      id: "create-new-set",
                      label: t({
                        id: "tasks-page.create-new-filter-set",
                        defaultMessage: "Create new set",
                      }),
                      onSelect: () => transitionTo({ name: "createFilterSet" }),
                    },
                  ],
                ],
              }}
            />
          ),
          isHidden: internalActiveFiltersCount === 0 || !hasFilterSetChanged,
        },
        {
          element: (
            <DrawerHeaderNavButton
              tip={t({
                id: "common.undo-changes",
                defaultMessage: "Undo changes",
              })}
              onClick={() => resetInternalFiltersState(activeFilterSet.filters)}
            >
              <RefreshIcon />
            </DrawerHeaderNavButton>
          ),
          isHidden: !hasFilterSetChanged,
        },
      ]
        .filter((slot) => !slot.isHidden)
        .map((slot) => slot.element),
    };
  }, [
    activeFilterSet,
    handleUpdateActiveFilterSet,
    hasFilterSetChanged,
    internalActiveFiltersCount,
    isUpdatingFilterSet,
    resetInternalFiltersState,
    screen.name,
    t,
    transitionTo,
  ]);

  const content = useMemo<JSX.Element>(
    () =>
      renderContent({
        filters: (
          <FiltersScreen
            entityId={entityId}
            onDialogClose={onClose}
            onDialogSafeClose={handleSafeClose}
          />
        ),
        createFilterSet: (
          <CreateFilterSetScreen entityId={entityId} onDialogClose={onClose} />
        ),
      }),
    [entityId, handleSafeClose, onClose, renderContent]
  );

  useEffect(() => {
    if (isOpen) {
      resetInternalFiltersState(filters);
    }
  }, [filters, isOpen, resetInternalFiltersState]);

  return (
    <>
      <BottomCenteredDrawer
        isOpen={isOpen}
        header={header}
        onClose={handleSafeClose}
        onTransitionEnd={handleResetState}
      >
        <TaskFiltersStateProvider context={internalFiltersStateContext}>
          <ScreenTransitionWrapper
            screenName={screen.name}
            style={{ height: `calc(100% - ${DRAWER_HEADER_HEIGHT_PX}px)` }}
          >
            {content}
          </ScreenTransitionWrapper>
        </TaskFiltersStateProvider>
      </BottomCenteredDrawer>
      <Alert
        isOpen={isDiscardChangesDialogOpen}
        title={t({
          id: "common.discard-changes-warning-title",
          defaultMessage: "Discard changes",
        })}
        content={t({
          id: "common.discard-changes-warning-description",
          defaultMessage: "If you discard, all changes will be lost",
        })}
        buttons={[
          {
            text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
            role: "close",
          },
          {
            text: t({ id: "common.discard", defaultMessage: "Discard" }),
            color: "primary",
            onClick: (_, actions) => {
              onClose();
              actions.closeAlert();
            },
          },
        ]}
        onRequestClose={() => setIsDiscardChangesDialogOpen(false)}
      />
    </>
  );
};

export const TaskFiltersDialog: FC<TaskFiltersDialogProps> = (props) => (
  <ScreenTransitionManagerProvider initialScreen={INITIAL_SCREEN}>
    <InnerTaskFiltersDialog {...props} />
  </ScreenTransitionManagerProvider>
);

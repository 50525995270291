import { PreviewTaskTemplate } from "@jugl-web/rest-api/tasks-templates";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as TemplateIcon } from "./assets/template.svg";

interface RecentlyUsedTaskTemplatesSectionProps {
  templates: PreviewTaskTemplate[];
  className?: string;
  onTemplateSelect: (template: PreviewTaskTemplate) => void;
  onShowAllTemplates?: () => void;
}

export const RecentlyUsedTaskTemplatesSection: FC<
  RecentlyUsedTaskTemplatesSectionProps
> = ({ templates, className, onTemplateSelect, onShowAllTemplates }) => {
  const { t } = useTranslations();

  return (
    <div className={cx("flex flex-col gap-2", className)}>
      <div className="flex items-center justify-between px-2">
        <span className="font-secondary text-dark-700 text-xs">
          {t({
            id: "tasks-page.recent-templates",
            defaultMessage: "Recent Templates",
          }).concat(":")}
        </span>
        {onShowAllTemplates && (
          <Button
            variant="text"
            color="primary"
            size="small"
            className="text-primary-800 text-xs font-normal tracking-[0.12px]"
            onClick={onShowAllTemplates}
          >
            {t({ id: "common.see-all", defaultMessage: "See all" })}
          </Button>
        )}
      </div>
      <div className="flex flex-col gap-2.5">
        {templates.map((template) => (
          <button
            key={template.id}
            type="button"
            className="jugl__focusable-outline hover:bg-grey-100 flex h-10 cursor-pointer items-center gap-3 overflow-hidden rounded border-0 bg-transparent px-2 transition-colors"
            onClick={() => onTemplateSelect(template)}
          >
            <TemplateIcon className="shrink-0" />
            <span className="text-dark font-secondary truncate text-sm leading-[21px]">
              {template.name}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

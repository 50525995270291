import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  PreviewTaskTemplate,
  previewTasksTemplatesAdapter,
} from "@jugl-web/rest-api/tasks-templates";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback, useMemo } from "react";
import { sortTaskTemplates } from "./utils";
import { TaskTemplateSorting } from "./types";

export enum TemplateFilter {
  AllTemplates = "allTemplates",
  MyTemplates = "myTemplates",
  CreatedByJugl = "createdByJugl",
  CreatedByOthers = "createdByOthers",
}

export interface UseTaskTemplatesProps {
  entityId: string | undefined;
  meId: string;
  searchQuery?: string;
  filter?: TemplateFilter;
  folderId?: string;
  sorting?: TaskTemplateSorting;
}

const matchesFolder = (folderId?: string) => (template: PreviewTaskTemplate) =>
  folderId ? template.folder_id === folderId : true;

const matchesSearchQuery =
  (searchQuery: UseTaskTemplatesProps["searchQuery"]) =>
  (template: PreviewTaskTemplate) =>
    searchQuery
      ? template.name.toLowerCase().includes(searchQuery.toLowerCase())
      : true;

const matchesFilter =
  (filter: UseTaskTemplatesProps["filter"], meId: string) =>
  (template: PreviewTaskTemplate) => {
    switch (filter) {
      case TemplateFilter.MyTemplates:
        return template.created_by === meId;
      case TemplateFilter.CreatedByJugl:
        return template.created_by === null;
      case TemplateFilter.CreatedByOthers:
        return template.created_by !== null && template.created_by !== meId;
      default:
        return true;
    }
  };

export const useTaskTemplates = ({
  entityId,
  meId,
  folderId,
  searchQuery,
  filter,
  sorting = "createdAtAsc",
}: UseTaskTemplatesProps) => {
  const { tasksTemplatesApi } = useRestApiProvider();

  const { data, isLoading, isError, refetch } =
    tasksTemplatesApi.useGetTemplatesQuery(entityId ? { entityId } : skipToken);

  const adapterSelectors = useMemo(
    () => previewTasksTemplatesAdapter.getSelectors(),
    []
  );

  const allTemplates = useMemo(
    () =>
      adapterSelectors.selectAll(
        data ?? previewTasksTemplatesAdapter.getInitialState()
      ),
    [adapterSelectors, data]
  );

  const allSortedTemplates = useMemo(
    () => sortTaskTemplates(allTemplates, sorting),
    [allTemplates, sorting]
  );

  const filteredTemplates = useMemo(
    () =>
      allSortedTemplates.filter(
        (template) =>
          matchesFolder(folderId)(template) &&
          matchesSearchQuery(searchQuery)(template) &&
          matchesFilter(filter, meId)(template)
      ),
    [allSortedTemplates, filter, folderId, meId, searchQuery]
  );

  const selectTemplatesByFolderId = useCallback(
    (fId: string) => filteredTemplates.filter(matchesFolder(fId)),
    [filteredTemplates]
  );

  return {
    templates: filteredTemplates,
    isLoading,
    isError,
    refetch,
    selectTemplatesByFolderId,
  };
};

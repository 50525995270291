import { cx } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as MessageIcon } from "../../assets/message.svg";

interface TaskCommentsFabProps {
  isVisible: boolean;
  unreadCount: number;
  className?: string;
  onClick: () => void;
}

export const TaskCommentsFab: FC<TaskCommentsFabProps> = ({
  isVisible,
  unreadCount,
  className,
  onClick,
}) => (
  <button
    type="button"
    className={cx(
      "border-grey-100 flex h-[74px] w-[74px] cursor-pointer items-center justify-center rounded-2xl border-4 border-solid bg-white shadow-[0px_12px_16px_0px_rgba(25,25,25,0.14)] transition",
      isVisible
        ? "scale-100 opacity-100 hover:scale-110 active:scale-[1.2]"
        : "scale-0 opacity-0",
      className
    )}
    onClick={onClick}
  >
    <div className="relative">
      <MessageIcon />
      {unreadCount > 0 && (
        <div className="bg-gradients-danger absolute -right-[3px] -top-px flex h-[16px] w-[25px] items-center justify-center rounded-[10px] border border-solid border-white">
          <span className="font-secondary text-[10px] font-bold text-white">
            {unreadCount}
          </span>
        </div>
      )}
    </div>
  </button>
);

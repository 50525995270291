import { Alert } from "@jugl-web/ui-components/cross-platform/Alert";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { MobileDrawer } from "@jugl-web/ui-components/mobile/MobileDrawer";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { Mentionify } from "@jugl-web/utils/utils/Mentionify";
import { FC } from "react";

interface TaskChecklistItemDeleteConfirmationDialogProps {
  isOpen: boolean;
  itemContent?: string;
  onDelete: () => void;
  onClose: () => void;
}

export const TaskChecklistItemDeleteConfirmationDialog: FC<
  TaskChecklistItemDeleteConfirmationDialogProps
> = ({ isOpen, itemContent, onDelete, onClose }) => {
  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const labels = {
    title: t({
      id: "tasks-page.delete-subtask-confirmation-title",
      defaultMessage: "Delete subtask",
    }),
    message: itemContent
      ? t(
          {
            id: "tasks-page.delete-subtask-with-name-confirmation-description",
            defaultMessage: "Do you really want to delete subtask {name}?",
          },
          {
            name: (
              <span className="text-primary-800 font-medium">
                <Mentionify isUnstyled>{itemContent}</Mentionify>
              </span>
            ),
          }
        )
      : t({
          id: "tasks-page.delete-subtask-confirmation-description",
          defaultMessage: "Do you really want to delete this subtask?",
        }),
    delete: t({
      id: "common.delete",
      defaultMessage: "Delete",
    }),
    cancel: t({
      id: "common.cancel",
      defaultMessage: "Cancel",
    }),
  };

  if (isMobile) {
    return (
      <MobileDrawer
        isOpen={isOpen}
        header={{ title: labels.title }}
        onClose={onClose}
      >
        <MobileDrawer.Content>
          <p className="text-onyx mt-2 mb-10 text-sm leading-[21px]">
            {labels.message}
          </p>
          <div className="flex flex-col gap-2.5">
            <Button
              color="primary"
              variant="contained"
              onClick={onDelete}
              uppercase
            >
              {labels.delete}
            </Button>
            <Button color="primary" variant="text" onClick={onClose} uppercase>
              {labels.cancel}
            </Button>
          </div>
        </MobileDrawer.Content>
      </MobileDrawer>
    );
  }

  return (
    <Alert
      isOpen={isOpen}
      title={labels.title}
      content={labels.message}
      buttons={[
        {
          text: labels.cancel,
          role: "close",
        },
        {
          text: labels.delete,
          color: "primary",
          onClick: onDelete,
        },
      ]}
      onRequestClose={onClose}
    />
  );
};

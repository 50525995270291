import { cx } from "@jugl-web/utils";
import React, { Fragment, ReactNode } from "react";
import { Link, To } from "react-router-dom";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";
import { HeaderBreadcrumbsItemContainer } from "./components/HeaderBreadcrumbsItemContainer";
import { HeaderBreadcrumbsItemContent } from "./components/HeaderBreadcrumbsItemContent";

export interface HeaderBreadcrumbsItem {
  title?: ReactNode;
  icon?: ReactNode;
  href?: To;
  isHidden?: boolean;
  onClick?: () => void;
  customRenderer?: () => ReactNode;
}

export interface HeaderBreadcrumbsProps {
  icon?: React.ReactElement;
  items: HeaderBreadcrumbsItem[];
  rightContent?: React.ReactElement;
  disableShadow?: boolean;
  className?: string;
}

export const HEADER_BREADCRUMBS_HEIGHT_PX = 60;

export const HeaderBreadcrumbs: React.FC<HeaderBreadcrumbsProps> = ({
  icon,
  items,
  rightContent,
  disableShadow,
  className,
}) => (
  <div
    className={cx(
      "relative z-50 flex w-full shrink-0 select-none items-center justify-between bg-white px-8",
      className
    )}
    style={{
      height: HEADER_BREADCRUMBS_HEIGHT_PX,
      boxShadow: disableShadow
        ? undefined
        : "2px -6px 24px rgba(18, 22, 34, 0.16)",
    }}
  >
    <div className="flex items-center">
      {icon && <span className="mr-5 flex items-center">{icon}</span>}
      {items.map((item, index) => {
        if (item.isHidden) {
          return null;
        }

        const isLast = index === items.length - 1;

        const itemContent = (
          <HeaderBreadcrumbsItemContent>
            {item.icon}
            {item.title}
          </HeaderBreadcrumbsItemContent>
        );

        return (
          <Fragment key={+index}>
            {index > 0 && <ChevronRightIcon className="mx-4" />}
            {item.customRenderer ? (
              item.customRenderer()
            ) : (
              <HeaderBreadcrumbsItemContainer
                isBold={isLast}
                onClick={item.onClick}
              >
                {item.href ? (
                  <Link to={item.href} className="text-inherit no-underline">
                    {itemContent}
                  </Link>
                ) : (
                  itemContent
                )}
              </HeaderBreadcrumbsItemContainer>
            )}
          </Fragment>
        );
      })}
    </div>
    <div className="flex flex-1 justify-end">{rightContent}</div>
  </div>
);

export const currencyCodeToSymbol = (currencyCode: string) => {
  const formatter = new Intl.NumberFormat(
    Intl.DateTimeFormat().resolvedOptions().locale,
    {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      signDisplay: "never",
      currencyDisplay: "narrowSymbol",
    }
  );
  return formatter.format(0).replace(/\d/g, "").trim();
};

import { InventoryItemParams } from "./types";

export const stringifyDimensionValue = (
  dimension: InventoryItemParams["dimensions"] | undefined
) => {
  if (!dimension) {
    return null;
  }

  return `${dimension.value.width || ""};${dimension.value.height || ""};${
    dimension.value.depth || ""
  };${dimension.unit}`;
};

import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { TextAreaInput } from "@jugl-web/ui-components/cross-platform/forms/TextAreaInput";
import { useAutoResizedTextarea, useTranslations } from "@jugl-web/utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ORDER_FORM_DESCRIPTION_MAX_LENGTH,
  ORDER_FORM_TITLE_MAX_LENGTH,
} from "./consts";
import { useOrderForm } from "../../../../providers/OrderFormProvider";
import { useOrderFormValidators } from "../../hooks/useOrderFormValidators";
import { ReactComponent as PenIcon } from "./assets/pen.svg";
import { OrderFormPrefixField } from "./components/OrderFormPrefixField";

export const OrderFormDescriptionSection: React.FC = () => {
  const { t } = useTranslations();
  const [shouldShowError, setShouldShowError] = useState(false);
  const {
    title,
    description,
    triggerValidate,
    onTitleChange,
    onDescriptionChange,
  } = useOrderForm();
  const { isOrderTitleValid, isOrderDescriptionValid } =
    useOrderFormValidators();

  const handleValidateField = useCallback(() => {
    setShouldShowError(!isOrderTitleValid || !isOrderDescriptionValid);
  }, [isOrderTitleValid, isOrderDescriptionValid]);

  useEffect(() => {
    const subscription = triggerValidate.subscribe(() => {
      handleValidateField();
    });
    return () => subscription.unsubscribe();
  }, [triggerValidate, handleValidateField]);

  useEffect(() => {
    if (shouldShowError) {
      handleValidateField();
    }
  }, [title, description, shouldShowError, handleValidateField]);

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { textareaProps } = useAutoResizedTextarea(textareaRef);

  return (
    <div className="flex flex-col gap-4 overflow-hidden rounded-xl border-l-[6px] border-solid border-white bg-white p-6 transition-colors focus-within:border-[rgba(33,150,243,0.90)]">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <PenIcon />
          <span className="text-dark font-secondary text-lg font-medium leading-[27px]">
            {t({
              id: "order-form-wizard-page.form-description",
              defaultMessage: "Form Description",
            })}
          </span>
        </div>
        <OrderFormPrefixField />
      </div>
      <TextInput
        label={t({
          id: "order-form-wizard-page.form-title",
          defaultMessage: "Form Title",
        })}
        isRequired
        classNames={{ inputClassName: "text-xl leading-7" }}
        placeholder={t({
          id: "order-form-wizard-page.untitled-form",
          defaultMessage: "Untitled Form",
        })}
        value={title}
        onChange={(e) => onTitleChange(e.target.value.trimStart())}
        lengthIndicator={{
          max: ORDER_FORM_TITLE_MAX_LENGTH,
        }}
        isInvalid={shouldShowError && !isOrderTitleValid}
        errorMessage={(() => {
          if (!shouldShowError) return undefined;
          if (!title) {
            return t({
              id: "order-form-wizard-page.field-cant-be-empty",
              defaultMessage: "This field cannot be left empty",
            });
          }
          if (title.length > ORDER_FORM_TITLE_MAX_LENGTH) {
            return t(
              {
                id: "order-form-wizard-page.order-title-is-too-long",
                defaultMessage:
                  "Your title is too long. It can be no longer than {maxCharacters} characters",
              },
              { maxCharacters: ORDER_FORM_TITLE_MAX_LENGTH }
            );
          }
          return undefined;
        })()}
      />
      <TextAreaInput
        label={t({
          id: "order-form-wizard-page.form-description",
          defaultMessage: "Form Description",
        })}
        placeholder={t({
          id: "common.enter",
          defaultMessage: "Enter",
        })}
        classNames={{ inputClassName: "resize-none" }}
        ref={textareaRef}
        {...textareaProps}
        isRequired
        value={description}
        onChange={(e) => onDescriptionChange(e.target.value.trimStart())}
        lengthIndicator={{
          max: ORDER_FORM_DESCRIPTION_MAX_LENGTH,
        }}
        isInvalid={shouldShowError && !isOrderDescriptionValid}
        errorMessage={(() => {
          if (!shouldShowError) return undefined;
          if (!description) {
            return t({
              id: "order-form-wizard-page.field-cant-be-empty",
              defaultMessage: "This field cannot be left empty",
            });
          }
          if (description.length > ORDER_FORM_DESCRIPTION_MAX_LENGTH) {
            return t(
              {
                id: "order-form-wizard-page.order-description-is-too-long",
                defaultMessage:
                  "Your description is too long. It can be no longer than {maxCharacters} characters",
              },
              { maxCharacters: ORDER_FORM_DESCRIPTION_MAX_LENGTH }
            );
          }
          return undefined;
        })()}
      />
    </div>
  );
};

import React, { useEffect } from "react";
import { LoadingSpinner, PlainButton } from "@jugl-web/ui-components";
import { InventoryItemCategory, useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { priceDisplay, useTranslations } from "@jugl-web/utils";
import { useOrderForm } from "@web-src/modules/orders/pages/OrderFormWizardPage/providers/OrderFormProvider";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as ImageIcon } from "./assets/image.svg";

export const ServiceItem: React.FC<{ id: string; onDelete?: () => void }> = ({
  id,
  onDelete,
}) => {
  const { inventoryApi } = useRestApiProvider();
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();
  const { addToInventoryItemsCache } = useOrderForm();
  const { data, isFetching } = inventoryApi.useGetInventoryItemQuery({
    entityId,
    itemId: id,
    silentError: true,
  });
  const { entity } = useEntitySelectedProvider();
  useEffect(() => {
    if (data) {
      addToInventoryItemsCache(data);
    }
  }, [addToInventoryItemsCache, data]);

  if (isFetching) {
    return (
      <div className="bg-grey-100 flex w-full animate-pulse items-center justify-center rounded-2xl p-4">
        <LoadingSpinner />
      </div>
    );
  }
  if (!data) return null;
  const mainImage =
    (data.img?.length && data.img.find((item) => item.order === 1)?.path) ||
    data.img[0]?.path;
  return (
    <div className="border-grey-400 flex w-full items-center justify-between gap-5 overflow-hidden rounded-[10px] border border-dashed bg-[#FAFAFA] py-4 px-5">
      <div className="flex w-full items-center gap-5 overflow-hidden">
        <div className="border-grey-400 bg-grey-200 flex h-[72px] w-[72px] shrink-0 items-center justify-center overflow-hidden rounded-[10px] border border-solid">
          {mainImage ? (
            <img
              src={mainImage}
              className="h-full w-full object-cover"
              alt={data.name}
            />
          ) : (
            <ImageIcon />
          )}
        </div>
        <div className="flex w-full flex-col gap-0.5 overflow-hidden">
          <span className="text-dark font-secondary break-words text-sm font-medium leading-[140%] tracking-[0.14px]">
            {data.name}
          </span>
          <span className="text-grey-800 font-secondary break-words text-xs leading-[140%]">
            {data.desc}
          </span>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-4">
        <span className="text-dark flex shrink-0 items-center text-sm font-medium leading-[140%] tracking-[0.14px]">
          {priceDisplay(parseFloat(data.price) * 100, entity.currency)}
          <span className="text-grey-700 font-normal">
            /
            {data.category === InventoryItemCategory.item
              ? data.unit
              : t({
                  id: "order-form-wizard-page.service",
                  defaultMessage: "service",
                })}
          </span>
        </span>
        {onDelete && (
          <PlainButton onClick={onDelete}>
            <DeleteIcon />
          </PlainButton>
        )}
      </div>
    </div>
  );
};

import { useTranslations } from "@jugl-web/utils";
import { Tab } from "@web-src/modules/common/components/TabsLayout/components/Tab";
import { FC, useMemo, useState } from "react";
import { TaskDetailsTabId } from "../../types";
import { TabSearchPersistenceProvider } from "../TabSearchPersistenceProvider";
import { TaskDetailsAttachmentsTabContent } from "../TaskDetailsAttachmentsTabContent";
import { TaskDetailsHistoryTabContent } from "../TaskDetailsHistoryTabContent/TaskDetailsHistoryTabContent";
import { TaskDetailsOrderTabContent } from "../TaskDetailsOrderTabContent";
import { TaskDetailsSubtasksTabContent } from "../TaskDetailsSubtasksTabContent";
import { useLoadedTaskDetailsContext } from "../../hooks/useTaskDetailsContext";

interface TaskDetailsTab {
  id: TaskDetailsTabId;
  title: string;
  Component: FC;
}

export const TaskDetailsTabs: FC = () => {
  const [selectedTab, setSelectedTab] = useState<TaskDetailsTabId>("subtasks");
  const { task } = useLoadedTaskDetailsContext();

  const { t } = useTranslations();

  const tabs = useMemo<TaskDetailsTab[]>(
    () => [
      {
        id: "subtasks",
        title: t({
          id: "tasks-page.subtasks",
          defaultMessage: "Subtasks",
        }),
        Component: TaskDetailsSubtasksTabContent,
      },
      {
        id: "attachments",
        title: t({
          id: "tasks-page.attachments",
          defaultMessage: "Attachments",
        }),
        Component: TaskDetailsAttachmentsTabContent,
      },
      ...(task.order_resp
        ? [
            {
              id: "order-details",
              title: t({
                id: "tasks-page.order-details",
                defaultMessage: "Order details",
              }),
              Component: TaskDetailsOrderTabContent,
            } as TaskDetailsTab,
          ]
        : []),
      {
        id: "history",
        title: t({
          id: "tasks-page.history",
          defaultMessage: "History",
        }),
        Component: TaskDetailsHistoryTabContent,
      },
    ],
    [t, task]
  );
  const activeTab = useMemo(
    () => tabs.find((tab) => tab.id === selectedTab) ?? tabs[0],
    [tabs, selectedTab]
  );

  return (
    <div className="mt-12">
      <div className="border-b-grey-300 flex items-center border border-l-0 border-r-0 border-t-0 border-solid">
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            title={tab.title}
            isSelected={selectedTab === tab.id}
            className="w-auto"
            labelClassName="px-5"
            onClick={() => setSelectedTab(tab.id)}
          />
        ))}
      </div>
      <TabSearchPersistenceProvider>
        <div key={activeTab.id} className="animate-fade-in py-8">
          <activeTab.Component />
        </div>
      </TabSearchPersistenceProvider>
    </div>
  );
};

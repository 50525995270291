import { cx } from "@jugl-web/utils";
import React, { forwardRef, SelectHTMLAttributes } from "react";

interface NativeSelectUnstyledProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  children: React.ReactNode;
}

export const NativeSelectUnstyled = forwardRef<
  HTMLSelectElement,
  NativeSelectUnstyledProps
>(({ children, className, ...props }, ref) => (
  <select
    ref={ref}
    {...{
      ...props,
      className: cx(
        "outline-none border-0 bg-transparent text-default-400 text-small",
        className
      ),
    }}
  >
    {children}
  </select>
));

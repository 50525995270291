import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import React from "react";
import { ReactComponent as AddIcon } from "./assets/add.svg";

export const AddImageTile: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <InteractiveContainer
    className="border-grey-400 bg-whit flex h-[46px] w-[46px] shrink-0 items-center justify-center rounded-[10px] border border-solid"
    onClick={onClick}
  >
    <AddIcon />
  </InteractiveContainer>
);

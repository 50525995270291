import { taskChecklistNameValidator } from "@jugl-web/domain-resources/tasks";
import { Tooltip } from "@jugl-web/ui-components";
import { Checkbox } from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { Mentionify } from "@jugl-web/utils/utils/Mentionify";
import { FC, useLayoutEffect, useRef, useState } from "react";
import { ChecklistTableCellComponentProps } from "../../types";
import { ChecklistItemNumber } from "../ChecklistItemNumber";
import { TaskTableLayout } from "../TaskTableLayout";
import { TextCellEditor, TextCellEditorHandle } from "../TextCellEditor";

export const ChecklistItemNameCell: FC<ChecklistTableCellComponentProps> = ({
  item,
  index,
  specificOrderCompletionState,
  mentions,
  permissions,
  isFutureTask,
  cellProps,
  onUpdate,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const $textCellEditorRef = useRef<TextCellEditorHandle | null>(null);

  const { t } = useTranslations();

  const hasPermissionToEdit =
    permissions.canManageChecklist ||
    (item.user_id && permissions.canEditChecklistItem(item.user_id));

  const isEditable = !isFutureTask && hasPermissionToEdit;

  const richItemText = (
    <Linkify>
      <Mentionify>{item.name}</Mentionify>
    </Linkify>
  );

  const handleCellClick = () => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }

    $textCellEditorRef.current?.focus();
  };

  const handleSubmit = (richText: string) => {
    onUpdate({ name: richText });
    setIsEditing(false);
  };

  useLayoutEffect(() => {
    if (isEditing) {
      $textCellEditorRef.current?.setContent(item.name);
    }
  }, [isEditing, item.name]);

  return (
    <Tooltip
      placement="bottom"
      renderTrigger={({ props, ref }) => (
        <TaskTableLayout.Cell
          ref={ref}
          isFocused={isEditing}
          isHoverable
          isDisabled={!isEditable}
          className={cx("gap-2", isEditing && "cursor-text")}
          onClick={handleCellClick}
          {...cellProps}
          {...props}
        >
          {!isFutureTask && (
            <Checkbox
              isChecked={item.is_completed}
              isDisabled={
                !isEditable || specificOrderCompletionState.isDisabled
              }
              className="mr-0.5 h-[18px] w-[18px]"
              onClick={(event) => event.stopPropagation()}
              onChange={() => onUpdate({ is_completed: !item.is_completed })}
            />
          )}
          <ChecklistItemNumber
            index={index}
            isHighlighted={specificOrderCompletionState.isCurrent}
          />
          {isEditing ? (
            <TextCellEditor
              ref={$textCellEditorRef}
              mentions={{ type: "manual", mentions }}
              placeholder={t({
                id: "form-controls.task-subtask.placeholder",
                defaultMessage: "Enter subtask title",
              })}
              validator={taskChecklistNameValidator}
              onSubmit={handleSubmit}
              onCancel={() => setIsEditing(false)}
            />
          ) : (
            <TaskTableLayout.CellText
              isFaded={
                isFutureTask
                  ? false
                  : item.is_completed || specificOrderCompletionState.isDisabled
              }
              isCrossedOut={isFutureTask ? false : item.is_completed}
            >
              {richItemText}
            </TaskTableLayout.CellText>
          )}
        </TaskTableLayout.Cell>
      )}
      className="max-w-[424px] whitespace-pre-wrap break-words"
    >
      {richItemText}
    </Tooltip>
  );
};

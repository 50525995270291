import { cx, useTranslations } from "@jugl-web/utils";
import { FC } from "react";

interface EmptyStateProps {
  customMessage?: string;
  className?: string;
}

export const EmptyState: FC<EmptyStateProps> = ({
  customMessage,
  className,
}) => {
  const { t } = useTranslations();

  return (
    <div
      className={cx(
        "mt-2 text-center text-sm leading-[21px] text-[#4F4F4F]",
        className
      )}
    >
      {customMessage ||
        t({
          id: "list-box-component.no-results",
          defaultMessage: "No results 😔",
        })}
    </div>
  );
};

import {
  Button,
  ButtonProps,
  InteractiveContainer,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import React, { useMemo } from "react";
import { cx } from "@jugl-web/utils";
import {
  HeaderBreadcrumbs,
  HeaderBreadcrumbsProps,
} from "../HeaderBreadcrumbs";

export type StepperLayoutProps = {
  breadcrumbProps?: HeaderBreadcrumbsProps;
  children: React.ReactNode;
  steps: { id: string; label: string; onClick?: () => void }[];
  activeStep: string;
  rightContent?: React.ReactNode;
  hideSteps?: boolean;
  hideFooter?: boolean;
  className?: string;
  buttons: Array<
    Pick<
      ButtonProps,
      | "variant"
      | "color"
      | "isDisabled"
      | "className"
      | "onClick"
      | "iconStart"
      | "iconEnd"
    > & {
      text: string;
    }
  >;
};

export const StepperLayout: React.FC<StepperLayoutProps> = ({
  steps,
  children,
  breadcrumbProps,
  activeStep,
  buttons,
  rightContent,
  hideSteps,
  hideFooter,
  className,
}) => {
  const activeStepIndex = useMemo(
    () => steps.findIndex((step) => step.id === activeStep),
    [steps, activeStep]
  );

  return (
    <MultiSectionLayout
      header={
        <>
          {breadcrumbProps && (
            <HeaderBreadcrumbs disableShadow {...breadcrumbProps} />
          )}
          {!hideSteps && (
            <div
              className={cx(
                "border-grey-300 grid grid-cols-4 items-start border-0 border-b border-solid bg-white px-8",
                className
              )}
            >
              <div />
              <div className="col-span-2 flex w-full items-center justify-center">
                {steps.map((step, idx) => (
                  <InteractiveContainer
                    className={cx(
                      "flex items-center gap-2.5 border-0 border-b-4 border-solid px-8 pt-2 pb-3 transition-colors",
                      step.onClick ? "hover:border-grey-400" : "cursor-default",
                      idx === activeStepIndex
                        ? "border-primary hover:border-primary"
                        : "border-white"
                    )}
                    key={step.id}
                    onClick={step.onClick}
                  >
                    <div
                      className={cx(
                        "flex h-[28px] w-[28px] items-center justify-center rounded-full transition-colors",
                        idx === activeStepIndex
                          ? "bg-primary text-white"
                          : "bg-[#E1E1E6] text-[#8D8D99]"
                      )}
                    >
                      <span className="text-sm font-bold leading-[21px]">
                        {idx + 1}
                      </span>
                    </div>
                    <span
                      className={cx(
                        "font-secondary font-medium leading-4 transition-colors",
                        idx === activeStepIndex
                          ? "text-primary"
                          : "text-[#8D8D99]"
                      )}
                    >
                      {step.label}
                    </span>
                  </InteractiveContainer>
                ))}
              </div>
              <div className="flex justify-end">{rightContent}</div>
            </div>
          )}
        </>
      }
      footer={
        !hideFooter && (
          <div className="border-grey-300 flex h-[88px] items-center justify-center gap-[51px] border-0 border-t border-solid px-[70px] py-6">
            {buttons.map(({ text, ...buttonProps }, idx) => (
              <Button key={+idx} {...buttonProps}>
                {text}
              </Button>
            ))}
          </div>
        )
      }
    >
      {children}
    </MultiSectionLayout>
  );
};

import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { useTaskListPreferences } from "@jugl-web/domain-resources/tasks/hooks/useTaskListPreferences";
import { TasksSource } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { FC, ReactNode, createContext, useCallback, useMemo } from "react";
import { TABLE_DIMENSIONS } from "../../consts";
import { TaskTableColumn } from "../../types";
import { ActivityCell } from "../ActivityCell";
import { AssigneeCell } from "../AssigneeCell";
import { BoardCell } from "../BoardCell";
import { ChecklistCell } from "../ChecklistCell";
import { ChecklistItemAssigneeCell } from "../ChecklistItemAssigneeCell";
import { ChecklistItemDueDateCell } from "../ChecklistItemDueDateCell";
import { ChecklistItemNameCell } from "../ChecklistItemNameCell";
import { CustomerCell } from "../CustomerCell";
import { CustomFieldCell } from "../CustomFieldCell";
import { DueDateCell } from "../DueDateCell";
import { LabelCell } from "../LabelCell";
import { PriorityCell } from "../PriorityCell";
import { StatusCell } from "../StatusCell";
import { TaskNameCell } from "../TaskNameCell";
import { TaskTableLayout } from "../TaskTableLayout";

type CustomFieldColumnsMap = Record<string, boolean>;

interface TaskTableColumnsContextValue {
  columns: TaskTableColumn[];
  customFieldColumnsMap: CustomFieldColumnsMap;
  changeCustomFieldColumnsMap: (
    updatedColumnsMap: CustomFieldColumnsMap
  ) => void;
}

export const TaskTableColumnsContext =
  createContext<TaskTableColumnsContextValue | null>(null);

interface TaskTableColumnsProviderProps {
  entityId: string;
  source: TasksSource;
  children: ReactNode;
}

export const TaskTableColumnsProvider: FC<TaskTableColumnsProviderProps> = ({
  entityId,
  source,
  children,
}) => {
  const { customFields } = useTaskFields({ entityId });

  const { taskListPreferences, updateTaskListPreference } =
    useTaskListPreferences({ entityId, source });

  const { t } = useTranslations();

  const defaultCustomFieldColumnsMap = useMemo<CustomFieldColumnsMap>(
    () =>
      customFields.reduce<CustomFieldColumnsMap>((acc, customField) => {
        acc[customField.id] = false;
        return acc;
      }, {}),
    [customFields]
  );

  const customFieldColumnsMap = useMemo(
    () => ({
      ...defaultCustomFieldColumnsMap,
      ...taskListPreferences.visibleCustomFieldTableColumnsMap,
    }),
    [
      defaultCustomFieldColumnsMap,
      taskListPreferences.visibleCustomFieldTableColumnsMap,
    ]
  );

  const customFieldBasedColumns = useMemo<TaskTableColumn[]>(() => {
    const activeCustomFields = customFields.filter(
      (customField) => !!customFieldColumnsMap[customField.id]
    );

    return activeCustomFields.map((customField) => ({
      id: customField.id,
      title: customField.name,
      width: TABLE_DIMENSIONS.TABLE_CUSTOM_FIELD_COLUMN_WIDTH,
      taskCellRenderer: (props) => (
        <CustomFieldCell field={customField} {...props} />
      ),
      checklistCellRenderer: () => null,
    }));
  }, [customFieldColumnsMap, customFields]);

  const changeCustomFieldColumnsMap = useCallback(
    (updatedColumnsMap: CustomFieldColumnsMap) =>
      updateTaskListPreference(
        "visibleCustomFieldTableColumnsMap",
        updatedColumnsMap
      ),
    [updateTaskListPreference]
  );

  const columns = useMemo<TaskTableColumn[]>(
    () => [
      {
        id: "task",
        title: t({
          id: "tasks-page.task-table-header",
          defaultMessage: "Task",
        }),
        width: TABLE_DIMENSIONS.TABLE_TASK_TITLE_COLUMN_WIDTH,
        taskCellRenderer: (props) => <TaskNameCell {...props} />,
        checklistCellRenderer: (props) => <ChecklistItemNameCell {...props} />,
      },
      {
        id: "due_date",
        title: t({
          id: "tasks-page.due-date-table-header",
          defaultMessage: "Due Date",
        }),
        width: TABLE_DIMENSIONS.TABLE_DUE_DATE_COLUMN_WIDTH,
        taskCellRenderer: (props) => <DueDateCell {...props} />,
        checklistCellRenderer: (props) => (
          <ChecklistItemDueDateCell {...props} />
        ),
      },
      {
        id: "assignee",
        title: t({
          id: "tasks-page.assignee-table-header",
          defaultMessage: "Assignee",
        }),
        width: TABLE_DIMENSIONS.TABLE_ASSIGNEE_COLUMN_WIDTH,
        taskCellRenderer: (props) => <AssigneeCell {...props} />,
        checklistCellRenderer: (props) => (
          <ChecklistItemAssigneeCell {...props} />
        ),
      },
      {
        id: "activity",
        title: t({
          id: "tasks-page.activity-table-header",
          defaultMessage: "Task Activity",
        }),
        width: TABLE_DIMENSIONS.TABLE_ACTIVITY_COLUMN_WIDTH,
        taskCellRenderer: (props) => <ActivityCell {...props} />,
        checklistCellRenderer: ({ cellProps }) => (
          <TaskTableLayout.Cell {...cellProps} shouldGrow />
        ),
      },
      {
        id: "board",
        title: t({
          id: "tasks-page.board-table-header",
          defaultMessage: "Board",
        }),
        width: TABLE_DIMENSIONS.TABLE_BOARD_COLUMN_WIDTH,
        taskCellRenderer: (props) => <BoardCell {...props} />,
        checklistCellRenderer: () => null,
      },
      {
        id: "checklist",
        title: t({
          id: "tasks-page.subtasks-table-header",
          defaultMessage: "Subtasks",
        }),
        width: TABLE_DIMENSIONS.TABLE_CHECKLIST_COLUMN_WIDTH,
        taskCellRenderer: (props) => <ChecklistCell {...props} />,
        checklistCellRenderer: () => null,
      },
      {
        id: "priority",
        title: t({
          id: "tasks-page.priority-table-header",
          defaultMessage: "Priority",
        }),
        width: TABLE_DIMENSIONS.TABLE_PRIORITY_COLUMN_WIDTH,
        taskCellRenderer: (props) => <PriorityCell {...props} />,
        checklistCellRenderer: () => null,
      },
      {
        id: "customer",
        title: t({
          id: "tasks-page.client-table-header",
          defaultMessage: "Client",
        }),
        width: TABLE_DIMENSIONS.TABLE_CUSTOMER_COLUMN_WIDTH,
        taskCellRenderer: (props) => <CustomerCell {...props} />,
        checklistCellRenderer: () => null,
      },
      {
        id: "label",
        title: t({
          id: "tasks-page.label-table-header",
          defaultMessage: "Label",
        }),
        width: TABLE_DIMENSIONS.TABLE_LABEL_COLUMN_WIDTH,
        taskCellRenderer: (props) => <LabelCell {...props} />,
        checklistCellRenderer: () => null,
      },
      {
        id: "status",
        title: t({
          id: "tasks-page.status-table-header",
          defaultMessage: "Status",
        }),
        width: TABLE_DIMENSIONS.TABLE_STATUS_COLUMN_WIDTH,
        taskCellRenderer: (props) => <StatusCell {...props} />,
        checklistCellRenderer: () => null,
      },
      ...customFieldBasedColumns,
    ],
    [customFieldBasedColumns, t]
  );

  const contextValue = useMemo(
    () => ({
      columns,
      customFieldColumnsMap,
      changeCustomFieldColumnsMap,
    }),
    [changeCustomFieldColumnsMap, columns, customFieldColumnsMap]
  );

  return (
    <TaskTableColumnsContext.Provider value={contextValue}>
      {children}
    </TaskTableColumnsContext.Provider>
  );
};

import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import {
  MY_TASKS_SOURCE,
  TaskChecklist,
  TaskChecklistHandle,
  taskNameValidator,
} from "@jugl-web/domain-resources/tasks";
import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import {
  TaskAttachments,
  TaskAttachmentsHandle,
} from "@jugl-web/domain-resources/tasks/components/TaskAttachments";
import { adaptFilesToTaskAttachmentsComponentItems } from "@jugl-web/domain-resources/tasks/components/TaskAttachments/adapters";
import { TaskAttachmentsUploadScreen } from "@jugl-web/domain-resources/tasks/components/TaskAttachmentsUploadScreen";
import { TaskExtraPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskExtraPropertiesPanel";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { useCreateTask } from "@jugl-web/domain-resources/tasks/hooks/useCreateTask";
import { useLocalTaskChecklistHandlers } from "@jugl-web/domain-resources/tasks/hooks/useLocalTaskChecklistHandlers";
import { useRecentlyUsedTaskTemplates } from "@jugl-web/domain-resources/tasks/hooks/useRecentlyUsedTaskTemplates";
import { useTaskFormState } from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { mapTaskTemplateToFormState } from "@jugl-web/domain-resources/tasks/utils/mapTaskTemplateToFormState";
import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import { DetailedTaskTemplate } from "@jugl-web/rest-api/tasks-templates";
import { Dialog } from "@jugl-web/ui-components";
import { Alert } from "@jugl-web/ui-components/cross-platform/Alert";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { getUniqueId, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { TaskFormDialog } from "@web-src/features/tasks/TaskFormDialog";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { useTimeZone } from "@web-src/modules/settings/providers/TimeZoneProvider";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { TemplatePreviewDialogScreenToParametersMap } from "../../types";

interface TaskCreationScreenProps {
  template: DetailedTaskTemplate;
  entityId: string;
  meId: string;
  onDialogClose: () => void;
}

export const TaskCreationScreen: FC<TaskCreationScreenProps> = ({
  template,
  entityId,
  meId,
  onDialogClose,
}) => {
  const {
    formState,
    isDirty,
    resetFormState,
    updateFormState,
    updateChecklistItems,
    updateCustomField,
  } = useTaskFormState();

  const taskChecklistHandlers = useLocalTaskChecklistHandlers({
    itemsSetter: updateChecklistItems,
  });

  const [discardChangesDialogState, setDiscardChangesDialogState] = useState<{
    onDiscard: () => void;
  } | null>(null);
  const isDiscardChangesDialogOpen = !!discardChangesDialogState?.onDiscard;

  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [createdTaskId, setCreatedTaskId] = useState<string>();
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const { transitionTo, transitionRequest$ } =
    useScreenTransitionManager<TemplatePreviewDialogScreenToParametersMap>();

  const { timeZone } = useTimeZone();

  const { t } = useTranslations();

  const { navigateToPage } = useNavigation();
  const { isOnboardingActive, completeOnboardingStep } = useOnboarding();
  const { previewFile } = useFilePreview();

  const { createTask, showTaskCreationToast, isLoading } = useCreateTask({
    entityId,
    onNavigate: (taskId) => navigateToPage("taskDetails", { taskId }),
  });

  const { addRecentlyUsedTaskTemplate } = useRecentlyUsedTaskTemplates({
    entityId,
    meId,
  });

  const $titleInputRef = useRef<HTMLInputElement | null>(null);
  const $taskChecklistRef = useRef<TaskChecklistHandle | null>(null);
  const $taskAttachmentsRef = useRef<TaskAttachmentsHandle | null>(null);

  const isDescriptionBoxVisible = formState.description || isEditingDescription;
  const isValid = taskNameValidator(formState.title);

  const adaptedAttachments = useMemo(
    () => adaptFilesToTaskAttachmentsComponentItems(formState.attachments),
    [formState.attachments]
  );

  const handlePreviewAttachment = (attachment: TaskAttachment) =>
    previewFile({
      url: attachment.stream_url,
      name: attachment.name,
      mimeType: attachment.content_type,
    });

  const handleFilesAdd = (files: File[]) => {
    if (!files) return;

    const filesWithId = files.map((file) => ({
      id: getUniqueId(),
      file,
    }));
    updateFormState("attachments", formState.attachments.concat(filesWithId));
  };

  const handleFileRename = (file: TaskAttachment, newName: string) => {
    const attachmentsNewState = formState.attachments.map((item) => {
      if (item.id === file.id) {
        return {
          ...item,
          file: new File([item.file], newName, { type: item.file.type }),
        };
      }
      return item;
    });
    updateFormState("attachments", attachmentsNewState);
  };

  const handleFileRemove = (id: string) => {
    const attachmentsNewState = formState.attachments.filter(
      (item) => item.id !== id
    );
    updateFormState("attachments", [...attachmentsNewState]);
  };

  const handleSubmit = async () => {
    try {
      const createdTask = await createTask(formState, MY_TASKS_SOURCE);

      if (formState.templateId) {
        addRecentlyUsedTaskTemplate(formState.templateId);
      }

      if (formState.attachments.length > 0) {
        setCreatedTaskId(createdTask.id);
        setIsUploadDialogOpen(true);
      } else {
        showTaskCreationToast(
          createdTask.id,
          t({
            id: "feedback.task-created-from-template-tap-to-open",
            defaultMessage:
              "Task has been created based on the template. Tap to open it",
          })
        );
        onDialogClose();
        if (isOnboardingActive) {
          completeOnboardingStep("task");
        }
      }
    } catch {
      // Do nothing
    }
  };

  useEffect(() => {
    resetFormState(mapTaskTemplateToFormState(template));
  }, [resetFormState, template]);

  useEffect(() => {
    const subscription = transitionRequest$.subscribe((request) => {
      if (isDirty()) {
        setDiscardChangesDialogState({ onDiscard: request.resolve });
      } else {
        request.resolve();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [isDirty, transitionRequest$]);

  return (
    <>
      <Dialog
        isOpen={isUploadDialogOpen}
        onClose={() => {}}
        className="w-[480px]"
      >
        <TaskAttachmentsUploadScreen
          taskId={createdTaskId || ""}
          entityId={entityId}
          files={formState.attachments}
          onClose={() => {
            setIsUploadDialogOpen(false);
            resetFormState();
            onDialogClose();
            if (isOnboardingActive) {
              completeOnboardingStep("task");
            }
          }}
        />
      </Dialog>
      <TaskFormDialog.Content>
        <TaskFormDialog.TitleSection>
          <TitleBox
            title={formState.title}
            inputRef={$titleInputRef}
            classes={{ titleText: "py-3" }}
            onInternalValueChange={(title) => updateFormState("title", title)}
          />
          {isDescriptionBoxVisible && (
            <DescriptionBox
              description={formState.description}
              isEditing={isEditingDescription}
              classes={{ editingBox: "mt-2", descriptionText: "mt-2 py-2" }}
              onChange={(description) =>
                updateFormState("description", description)
              }
              onStartEditing={() => setIsEditingDescription(true)}
              onFinishEditing={() => setIsEditingDescription(false)}
            />
          )}
          <TaskPropertiesPanel
            entityId={entityId}
            config={{
              description: {
                isHidden: !!formState.description || isEditingDescription,
                onClick: () => setIsEditingDescription(true),
              },
              board: {
                boardId: formState.boardId,
                onChange: (boardId) => updateFormState("boardId", boardId),
                isHidden: formState.isPrivate,
              },
              status: {
                statusId: formState.statusId,
                onChange: (statusId) => updateFormState("statusId", statusId),
                hideCompleted: true,
              },
              dueDate: {
                state: formState.dueDate,
                defaultTimezone: timeZone.name,
                displayAs: "date",
                withAdvancedOptions: true,
                onChange: (state) => updateFormState("dueDate", state),
              },
              assignees: {
                assigneeIds: formState.assigneeIds,
                onChange: (assigneeIds) =>
                  updateFormState("assigneeIds", assigneeIds),
                isHidden: formState.isPrivate,
              },
              label: {
                labelId: formState.labelId,
                onChange: (labelId) => updateFormState("labelId", labelId),
              },
              priority: {
                priority: formState.priority,
                onChange: (priority) => updateFormState("priority", priority),
              },
              customer: {
                customerId: formState.customerId,
                onChange: (customer) =>
                  updateFormState("customerId", customer?.id || null),
                isHidden: formState.isPrivate,
              },
              recurrence: {
                state: formState.recurrence,
                defaultTimezone: timeZone.name,
                onChange: (recurrence) =>
                  updateFormState("recurrence", recurrence),
              },
              customFields: {
                valuesById: formState.customFields,
                onFieldChange: updateCustomField,
              },
            }}
            className="mt-8"
          />
          <TaskExtraPropertiesPanel
            entityId={entityId}
            config={{
              privateTask: {
                type: "interactive",
                isChecked: formState.isPrivate,
                onChange: (isChecked) =>
                  updateFormState("isPrivate", isChecked),
              },
              completeChecklistInOrder: {
                isChecked: formState.completeChecklistInOrder,
                onChange: (isChecked) =>
                  updateFormState("completeChecklistInOrder", isChecked),
              },
            }}
            className="mt-8"
          />
        </TaskFormDialog.TitleSection>
        <TaskFormDialog.ChecklistSection
          onAddItem={() => $taskChecklistRef.current?.addItem()}
        >
          <TaskChecklist
            ref={$taskChecklistRef}
            entityId={entityId}
            meId={meId}
            items={formState.checklistItems}
            isCompletable={false}
            isAssignable={!formState.isPrivate}
            {...taskChecklistHandlers}
          />
        </TaskFormDialog.ChecklistSection>
        <TaskFormDialog.AttachmentsSection
          onAddAttachment={() => $taskAttachmentsRef.current?.openFilePicker()}
        >
          <TaskAttachments
            mode="controlled"
            ref={$taskAttachmentsRef}
            attachments={adaptedAttachments}
            entityId={entityId}
            onPreviewAttachment={handlePreviewAttachment}
            onFileAdded={handleFilesAdd}
            onAttachmentRename={handleFileRename}
            onAttachmentRemove={handleFileRemove}
          />
        </TaskFormDialog.AttachmentsSection>
      </TaskFormDialog.Content>
      <TaskFormDialog.Actions>
        <Button
          variant="contained"
          color="grey"
          className="h-10 w-[200px]"
          onClick={() => transitionTo({ name: "templatePreview" })}
        >
          {t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          isDisabled={!isValid || isLoading}
          className="h-10 w-[200px]"
          onClick={handleSubmit}
        >
          {t({
            id: "tasks-page.create-task",
            defaultMessage: "Create task",
          })}
        </Button>
      </TaskFormDialog.Actions>
      <Alert
        isOpen={isDiscardChangesDialogOpen}
        title={t({
          id: "tasks-page.discard-changes-warning-title",
          defaultMessage: "Discard changes",
        })}
        content={t({
          id: "tasks-page.discard-changes-details",
          defaultMessage: "If you discard, the task information won't be saved",
        })}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
          },
          {
            text: t({
              id: "common.discard",
              defaultMessage: "Discard",
            }),
            color: "primary",
            onClick: (_, actions) => {
              discardChangesDialogState?.onDiscard();
              actions.closeAlert();
            },
          },
        ]}
        onRequestClose={() => setDiscardChangesDialogState(null)}
      />
    </>
  );
};

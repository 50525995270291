import {
  InteractiveContainer,
  Menu,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { FC } from "react";
import { ReactComponent as MoreIcon } from "../../assets/more.svg";
import { AttachmentLayoutItemProps } from "../../types";

export const AttachmentGridItem: FC<AttachmentLayoutItemProps> = ({
  image,
  name,
  highlightedName,
  updatedAtLabel,
  sizeLabel,
  menuSections,
  isEditable,
  onClick,
}) => (
  <InteractiveContainer
    title={name}
    onClick={onClick}
    className="bg-grey-100 flex w-[168px] shrink-0 flex-col gap-3 rounded-xl p-4"
  >
    <div className="relative">
      {isEditable && (
        <Menu
          placement="bottom"
          sections={menuSections}
          className="min-w-[unset]"
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger
              as={PlainButton}
              ref={triggerRef}
              onClick={(event) => event.stopPropagation()}
              className="absolute right-0 top-0 z-10 flex h-6 w-6 translate-x-1/4 -translate-y-1/4 items-center justify-center rounded-full bg-white"
            >
              <MoreIcon />
            </Trigger>
          )}
        />
      )}
      <div className="relative h-16 overflow-hidden rounded-[6px]">{image}</div>
    </div>
    <div className="flex flex-col gap-1">
      <span className="text-dark truncate text-[15px] font-medium leading-[18px]">
        {highlightedName}
      </span>
      <span className="text-grey text-[13px] font-semibold leading-[12px]">
        {updatedAtLabel.concat(" • ").concat(sizeLabel)}
      </span>
    </div>
  </InteractiveContainer>
);

import React, { useCallback, useMemo } from "react";
import { cx, useTranslations } from "@jugl-web/utils";
import { OrderResponseModel } from "@jugl-web/rest-api/orders/models/OrderResponse";
import {
  TASK_ORDER_CUSTOMER_NAME,
  TASK_ORDER_EMAIL_ID,
  TASK_ORDER_PHONE_ID,
} from "@jugl-web/utils/consts";
import format from "date-fns/format";
import { isoToLocalDate } from "@web-src/utils/helper";
import { OrderResponseField } from "@jugl-web/rest-api/orders";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { SectionHeader } from "../SectionHeader";
import { ReactComponent as InfoIcon } from "./assets/info.svg";

const specialFieldsIds = [
  TASK_ORDER_EMAIL_ID,
  TASK_ORDER_PHONE_ID,
  TASK_ORDER_CUSTOMER_NAME,
];

export const OrderInfo: React.FC<{
  orderResponse: OrderResponseModel;
}> = ({ orderResponse }) => {
  const { t } = useTranslations();

  const labels: Record<string, string> = useMemo(
    () => ({
      [TASK_ORDER_EMAIL_ID]: t({
        id: "tasks-page.email",
        defaultMessage: "Email",
      }),
      [TASK_ORDER_PHONE_ID]: t({
        id: "tasks-page.phone-number",
        defaultMessage: "Phone number",
      }),
      [TASK_ORDER_CUSTOMER_NAME]: t({
        id: "tasks-page.name",
        defaultMessage: "Name",
      }),
    }),
    [t]
  );

  const formatValue = useCallback((item: OrderResponseField) => {
    if (item.id === TASK_ORDER_PHONE_ID) {
      const [, code, number] = item.value.split(",");
      return `(${code?.replace("+", "")}) ${number}`;
    }
    if (item.type === "date") {
      return format(isoToLocalDate(item.value), "dd MMM yyyy");
    }
    return <Linkify>{item.value}</Linkify>;
  }, []);

  const infoFields = useMemo(
    () => [
      {
        id: "order-form-id",
        label: t({
          id: "tasks-page.order-form-id",
          defaultMessage: "Order Form №",
        }),
        value: orderResponse.order_id,
        isUnderline: true,
      },
      ...orderResponse.resp
        .filter((item) => item.name && item.value)
        .map((item) => ({
          id: item.id,
          label: labels[item.id] ? labels[item.id] : item.name,
          value: formatValue(item),
          isUnderline: specialFieldsIds.includes(item.id),
        }))
        .sort(
          (a, b) =>
            +specialFieldsIds.includes(b.id) - +specialFieldsIds.includes(a.id)
        ),
    ],

    [orderResponse, t, formatValue, labels]
  );

  return (
    <div>
      <SectionHeader
        icon={<InfoIcon />}
        title={t({
          id: "tasks-page.info",
          defaultMessage: "Info",
        })}
      />
      <div className="mt-[18px] grid grid-cols-5 gap-x-14 gap-y-4">
        {infoFields.map((el, idx) => (
          <React.Fragment key={el.id}>
            <span className="text-dark-700 font-secondary col-span-2 break-words">
              {el.label}
            </span>
            <span
              className={cx("font-secondary col-span-3 break-words", {
                "text-primary-800 underline": el.isUnderline,
              })}
            >
              {el.value}
            </span>
            {infoFields.length - 1 !== idx && (
              <div className="bg-grey-200 col-span-5 h-px" />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

import React, { useCallback } from "react";
import { Menu, PlainButton } from "@jugl-web/ui-components";
import { cx, reorder, useTranslations, getUniqueId } from "@jugl-web/utils";
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  OrderFormFieldType,
  OrderFormFieldValue,
} from "@jugl-web/rest-api/orders/types";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as TextIcon } from "./assets/text.svg";
import { ReactComponent as ParagraphIcon } from "./assets/paragraph.svg";
import { ReactComponent as DropdownIcon } from "./assets/dropdown.svg";
import { ReactComponent as DateIcon } from "./assets/date.svg";
import { OrderFormStep } from "../../types";
import { ShortTextField } from "./components/ShortTextField";
import { OrderFormStepper } from "../OrderFormStepper";
import { useOrderForm } from "../../providers/OrderFormProvider";
import { ParagraphField } from "./components/ParagraphField";
import { DropdownField } from "./components/DropdownField";
import { OrderFormDescriptionSection } from "./components/OrderFormDescriptionSection";
import { useOrderFormValidators } from "./hooks/useOrderFormValidators";
import { DateField } from "./components/DateField";
import { OrderFormCoverSelector } from "./components/OrderFormCoverSelector";
import { ReactComponent as SingleIcon } from "./assets/single.svg";
import { ReactComponent as MultiIcon } from "./assets/multi.svg";
import { ReactComponent as ServicesIcon } from "./assets/services.svg";
import { OrderFormSubmitterInfoSection } from "./components/OrderFormSubmitterInfoSection";
import { ServicesField } from "./components/ServicesField";

export const OrderFormFieldsStep: React.FC = () => {
  const { t } = useTranslations();

  const { fields, triggerValidate, onFieldsChange, changeOrderStep } =
    useOrderForm();
  const { isOrderFormValid } = useOrderFormValidators();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(mouseSensor);

  const handleOnDragEnd = (event: DragEndEvent) => {
    const { active: draggable, over: droppable } = event;
    if (!draggable.data.current || !droppable || droppable.disabled) {
      return;
    }
    const activeIndex = draggable.data.current.index;
    const overIndex = droppable.data.current?.index;
    if (
      typeof activeIndex !== "number" ||
      typeof overIndex !== "number" ||
      activeIndex === overIndex
    ) {
      return;
    }
    onFieldsChange(reorder(fields, activeIndex, overIndex));
  };

  const handleCreateOrderField = useCallback(
    (type: OrderFormFieldType) => {
      let property: OrderFormFieldValue["property"];

      switch (type) {
        case OrderFormFieldType.text:
        case OrderFormFieldType.paragraph:
          property = {
            type,
            value: {
              label: "",
              placeholder: "",
            },
          };
          break;
        case OrderFormFieldType.date:
          property = {
            type,
            value: {
              label: "",
            },
          };
          break;
        case OrderFormFieldType.services:
          property = {
            type,
            value: {
              label: "",
              items: [],
            },
          };
          break;
        case OrderFormFieldType.dropdown:
          property = {
            type,
            value: {
              label: "",
              items: [
                {
                  id: getUniqueId(),
                  label: t(
                    {
                      id: "order-form-wizard-page.option-number",
                      defaultMessage: "Option {number}",
                    },
                    { number: 1 }
                  ),
                },
              ],
            },
          };
          break;
        case OrderFormFieldType.singleChoice:
        case OrderFormFieldType.multiChoice:
          property = {
            type,
            value: {
              label: "",
              items: [
                {
                  id: getUniqueId(),
                  label: t(
                    {
                      id: "order-form-wizard-page.option-number",
                      defaultMessage: "Option {number}",
                    },
                    { number: 1 }
                  ),
                },
              ],
              hasOtherOption: false,
            },
          };
          break;
        default:
          throw new Error(`Unsupported order field type: ${type}`);
      }

      onFieldsChange([
        ...fields,
        {
          id: getUniqueId(),
          isRequired: false,
          property,
        },
      ]);
    },
    [fields, onFieldsChange, t]
  );

  return (
    <>
      <OrderFormStepper
        onMoveToDetails={() => {
          if (isOrderFormValid) {
            changeOrderStep(OrderFormStep.details);
            return;
          }
          triggerValidate.next();
        }}
      >
        <div className="relative flex gap-2 pl-14">
          <div className="flex w-full flex-col gap-2">
            <OrderFormCoverSelector />
            <OrderFormDescriptionSection />
            <OrderFormSubmitterInfoSection />
            <DndContext sensors={sensors} onDragEnd={handleOnDragEnd}>
              <SortableContext
                items={fields}
                strategy={verticalListSortingStrategy}
              >
                {fields.map((el, index) => {
                  const commonProps = {
                    key: el.id,
                    id: el.id,
                    index,
                  };

                  switch (el.property.type) {
                    case OrderFormFieldType.text:
                      return <ShortTextField {...commonProps} />;
                    case OrderFormFieldType.paragraph:
                      return <ParagraphField {...commonProps} />;
                    case OrderFormFieldType.dropdown:
                      return <DropdownField {...commonProps} />;
                    case OrderFormFieldType.singleChoice:
                      return (
                        <DropdownField {...commonProps} choiceMode="single" />
                      );
                    case OrderFormFieldType.multiChoice:
                      return (
                        <DropdownField {...commonProps} choiceMode="multi" />
                      );
                    case OrderFormFieldType.date:
                      return <DateField {...commonProps} />;
                    case OrderFormFieldType.services:
                      return <ServicesField {...commonProps} />;
                    default:
                      return null;
                  }
                })}
              </SortableContext>
            </DndContext>
          </div>
          <Menu
            placement="bottom"
            onSelect={(_item, _event, close) => close()}
            renderTrigger={({ Trigger, triggerRef, isOpen }) => (
              <Trigger
                as={PlainButton}
                ref={triggerRef}
                className={cx(
                  "hover:border-primary-200 sticky top-[calc(100%-70px)] flex h-12 w-12 items-center justify-center rounded-xl border border-solid bg-white transition-all hover:border-2",
                  isOpen ? "border-primary-200 border-2" : "border-grey-300"
                )}
              >
                <AddIcon />
              </Trigger>
            )}
            sections={[
              [
                {
                  id: "services",
                  label: t({
                    id: "order-form-wizard-page.service-items",
                    defaultMessage: "Service/Items",
                  }),
                  icon: <ServicesIcon />,
                  isHidden: fields.some(
                    (field) =>
                      field.property.type === OrderFormFieldType.services
                  ),
                  onSelect: () =>
                    handleCreateOrderField(OrderFormFieldType.services),
                },
                {
                  id: "short-text",
                  label: t({
                    id: "order-form-wizard-page.short-text",
                    defaultMessage: "Short text",
                  }),
                  icon: <TextIcon />,
                  onSelect: () =>
                    handleCreateOrderField(OrderFormFieldType.text),
                },
                {
                  id: "paragraph",
                  label: t({
                    id: "order-form-wizard-page.paragraph",
                    defaultMessage: "Paragraph",
                  }),
                  icon: <ParagraphIcon />,
                  onSelect: () =>
                    handleCreateOrderField(OrderFormFieldType.paragraph),
                },
                {
                  id: "single-choice",
                  label: t({
                    id: "order-form-wizard-page.single-choice",
                    defaultMessage: "Single choice",
                  }),
                  icon: <SingleIcon />,
                  onSelect: () =>
                    handleCreateOrderField(OrderFormFieldType.singleChoice),
                },
                {
                  id: "multi-choice",
                  label: t({
                    id: "order-form-wizard-page.multi-choice",
                    defaultMessage: "Multi choice",
                  }),
                  icon: <MultiIcon />,
                  onSelect: () =>
                    handleCreateOrderField(OrderFormFieldType.multiChoice),
                },
                {
                  id: "dropdown",
                  label: t({
                    id: "order-form-wizard-page.dropdown",
                    defaultMessage: "Dropdown",
                  }),
                  icon: <DropdownIcon />,
                  onSelect: () =>
                    handleCreateOrderField(OrderFormFieldType.dropdown),
                },
                {
                  id: "date",
                  label: t({
                    id: "order-form-wizard-page.date",
                    defaultMessage: "Date",
                  }),
                  icon: <DateIcon />,
                  onSelect: () =>
                    handleCreateOrderField(OrderFormFieldType.date),
                },
              ],
            ]}
          />
        </div>
      </OrderFormStepper>
    </>
  );
};

import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { LoadingSpinner } from "@jugl-web/ui-components";
import { OrderItemsTable } from "./components/OrderItemsTable";
import { OrderInfo } from "./components/OrderInfo";
import { useLoadedTaskDetailsContext } from "../../hooks/useTaskDetailsContext";

export const TaskDetailsOrderTabContent = () => {
  const { task } = useLoadedTaskDetailsContext();
  const { entityId } = useEntitySelectedProvider();
  const { ordersApi } = useRestApiProvider();
  const { data, isLoading } = ordersApi.useGetOrderResponseQuery(
    task.order_resp
      ? {
          entityId,
          orderResponseId: task.order_resp,
        }
      : skipToken
  );

  if (isLoading) {
    return (
      <div className="bg-grey-100 flex h-[284px] animate-pulse items-center justify-center rounded-2xl">
        <LoadingSpinner />
      </div>
    );
  }

  if (!data) return null;
  return (
    <div className="flex flex-col gap-14">
      {data.invoice && <OrderItemsTable invoice={data.invoice} />}
      <OrderInfo orderResponse={data} />
    </div>
  );
};

import { cx, useUniqueDOMId } from "@jugl-web/utils";
import { useMemo, useRef } from "react";
import { FormGroup, FormGroupProps } from "../FormGroup";
import { Radio } from "../../Radio";

export type RadioGroupValue = {
  selectedOption?: string;
  isOtherSelected?: boolean;
  otherValue?: string;
};

export type RadioGroupProps = FormGroupProps & {
  items: { name: string; value: string }[];
  classNames?: {
    wrapperClassName?: string;
  };
  onSelect: (value: RadioGroupValue) => void;
  value?: RadioGroupValue;
  hasOtherOption?: boolean;
};

export const RadioGroup = (props: RadioGroupProps) => {
  const uniqueId = useUniqueDOMId();
  const { items, value, onSelect, isDisabled, hasOtherOption, isInvalid } =
    props;
  const inputId = useMemo(() => uniqueId, [uniqueId]);
  const $inputRef = useRef<HTMLInputElement>(null);
  return (
    <FormGroup {...{ ...props, labelFor: inputId }}>
      <div className="space-y-4 pt-2">
        {items.map((item) => (
          <label
            key={item.value}
            className="flex cursor-pointer items-center gap-2 px-[10px] py-2"
          >
            <Radio
              value={item.value}
              isChecked={
                !value?.otherValue && item.value === value?.selectedOption
              }
              isDisabled={isDisabled}
              onChange={(e) =>
                e.target.value
                  ? onSelect({
                      selectedOption: item.value,
                      otherValue: "",
                      isOtherSelected: false,
                    })
                  : null
              }
              size="lg"
            />
            <div className="text-md font-secondary">{item.name}</div>
          </label>
        ))}
        {hasOtherOption && (
          <label className="flex cursor-pointer items-center gap-2 px-[10px] py-2">
            <Radio
              value="other"
              isChecked={value?.isOtherSelected}
              isDisabled={isDisabled}
              onChange={(e) => {
                if (e.target.value) {
                  onSelect({
                    otherValue: value?.otherValue,
                    isOtherSelected: true,
                  });
                  $inputRef.current?.focus();
                }
              }}
              size="lg"
            />
            <div className="w-full flex-1">
              <input
                ref={$inputRef}
                className={cx(
                  "focus:border-primary-500 font-secondary w-full flex-1 appearance-none border-0 border-b border-solid border-transparent bg-transparent text-base font-normal focus:outline-none",
                  value?.isOtherSelected && "border-grey-400",
                  value?.isOtherSelected &&
                    isInvalid &&
                    "border-gradients-danger"
                )}
                placeholder="Other"
                onChange={(e) =>
                  onSelect({
                    otherValue: e.target.value,
                    isOtherSelected: value?.isOtherSelected,
                  })
                }
                onClick={() =>
                  onSelect({
                    otherValue: value?.otherValue,
                    isOtherSelected: true,
                  })
                }
                value={value?.otherValue}
              />
            </div>
          </label>
        )}
      </div>
    </FormGroup>
  );
};

import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import TasksPage from "@web-src/pages/Tasks";
import { TaskDetails } from "@web-src/pages/TaskDetails";
import CustomersPage from "./CustomersPage";

export type CustomersPagesNavigationParams = {
  customersCustomers: PageNavParams;
  customersTasks: PageNavParams<{ customerId: string }>;
  customersTaskDetails: PageNavParams<{ customerId: string; taskId: string }>;
};

export const customersPagesNavigationConfig: ModulePagesConfig<CustomersPagesNavigationParams> =
  {
    customersCustomers: {
      element: <CustomersPage />,
      path: "customers",
      isPrivate: true,
      subscriptionModuleRequired: SubscriptionPlanModuleId.clients,
      isAdminRequired: true,
    },
    customersTasks: {
      element: <TasksPage />,
      path: "/customers/:customerId/tasks",
      isPrivate: true,
      subscriptionModuleRequired: SubscriptionPlanModuleId.clients,
      isAdminRequired: true,
    },
    customersTaskDetails: {
      element: <TaskDetails />,
      path: "/customers/:customerId/tasks/details/:taskId",
      isPrivate: true,
      subscriptionModuleRequired: SubscriptionPlanModuleId.clients,
      isAdminRequired: true,
    },
  };

import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { ClickAwayListener, cx, useTranslations } from "@jugl-web/utils";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { TITLE_MAX_LENGTH } from "../../consts";
import { taskNameValidator } from "../../validators";
import { ReactComponent as OrderIcon } from "./assets/order-icon.svg";

interface TitleBoxProps {
  title: string;
  prefix?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  isEditable?: boolean;
  isOrder?: boolean;
  classes?: {
    titleText?: string;
    editingBox?: string;
  };
  onChange?: (title: string) => void;
  onInternalValueChange?: (title: string) => void;
}

export const TitleBox: FC<TitleBoxProps> = ({
  title,
  prefix,
  inputRef: forwardedInputRef,
  isEditable = true,
  isOrder,
  classes,
  onChange,
  onInternalValueChange,
}) => {
  const hasTitle = !!title;

  const [internalTitle, setInternalTitle] = useState("");
  const [isEditing, setIsEditing] = useState(!hasTitle);
  const { t } = useTranslations();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInternalTitle(value);
    onInternalValueChange?.(value);
  };

  const handleSubmit = () => {
    if (!taskNameValidator(internalTitle)) {
      return;
    }

    if (internalTitle !== title) {
      onChange?.(internalTitle);
    }

    setIsEditing(false);
  };

  useEffect(() => {
    if (!hasTitle) {
      setIsEditing(true);
    }
  }, [hasTitle]);

  useEffect(() => {
    if (isEditing) {
      setInternalTitle(title);
    }
  }, [title, isEditing]);

  if (isEditable && (isEditing || !hasTitle)) {
    return (
      <ClickAwayListener
        triggerEvent={{ mouse: "click" }}
        onClickAway={handleSubmit}
      >
        <div
          className={cx(
            "bg-grey-100 min-h-[92px] overflow-y-auto rounded-lg p-4",
            classes?.editingBox
          )}
        >
          {isOrder && !isEditing && <OrderIcon className="mr-2" />}
          <div className="flex items-start gap-2">
            <input
              type="text"
              ref={(element) => {
                inputRef.current = element;

                if (forwardedInputRef) {
                  forwardedInputRef.current = element;
                }
              }}
              onFocus={(e) =>
                e.target.setSelectionRange(title.length, title.length)
              }
              autoFocus
              className="text-dark max-h-[300px] w-full resize-none border-none bg-transparent font-[Roboto] text-[22px] font-medium outline-none placeholder:text-[#BDBDBD]"
              placeholder={t({
                id: "form-controls.task-title.placeholder",
                defaultMessage: "Task title",
              })}
              maxLength={TITLE_MAX_LENGTH}
              value={internalTitle}
              onChange={handleChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  handleSubmit();
                }
              }}
            />
            {prefix && (
              <span className="text-grey-800 shrink-0 text-lg font-medium leading-[26px]">
                {prefix}
              </span>
            )}
          </div>
          <div className="mt-4">
            <span className="leading-2 text-sm font-medium text-[#BDBDBD]">
              {internalTitle.length}/{TITLE_MAX_LENGTH}
            </span>
          </div>
        </div>
      </ClickAwayListener>
    );
  }

  return (
    <InteractiveContainer
      className={cx(
        "transition-color group rounded pr-4 duration-300",
        isEditable && "hover:bg-grey-100",
        classes?.titleText
      )}
      isDisabled={!isEditable}
      onClick={() => setIsEditing(true)}
    >
      <div
        className={cx(
          "flex items-center overflow-hidden break-words transition-transform duration-300",
          isEditable && "group-hover:translate-x-2"
        )}
      >
        {isOrder && <OrderIcon className="mr-2 h-5 w-5 shrink-0" />}
        <span className="text-dark text-[22px] font-medium">
          {hasTitle
            ? title
            : t({
                id: "tasks-page.no-title",
                defaultMessage: "(no title)",
              })}
          {prefix && (
            <span className="text-grey-800 ml-2 shrink-0 text-lg font-medium">
              {prefix}
            </span>
          )}
        </span>
      </div>
    </InteractiveContainer>
  );
};

import { Alert, Button } from "@jugl-web/ui-components/cross-platform";
import { MobileDrawer } from "@jugl-web/ui-components/mobile/MobileDrawer";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC } from "react";

interface CompleteTaskSuggestionDialogProps {
  isOpen: boolean;
  onComplete: () => void;
  onClose: () => void;
}

export const CompleteTaskSuggestionDialog: FC<
  CompleteTaskSuggestionDialogProps
> = ({ isOpen, onComplete, onClose }) => {
  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const title = t({
    id: "tasks-page.complete-task",
    defaultMessage: "Complete task",
  });

  const content = t(
    {
      id: "tasks-page.complete-task-suggestion-after-finishing-subtasks",
      defaultMessage:
        "Well done! You have finished all the subtasks 🥳{br}Would you like to mark the task as complete?",
    },
    { br: <br /> }
  );

  const completeLabel = t({
    id: "common.complete",
    defaultMessage: "Complete",
  });

  const dismissLabel = t({ id: "common.later", defaultMessage: "Later" });

  if (isMobile) {
    return (
      <MobileDrawer isOpen={isOpen} header={{ title }} onClose={onClose}>
        <MobileDrawer.Content>
          <p className="text-onyx mt-2 mb-10 text-sm leading-[21px]">
            {content}
          </p>
          <div className="flex flex-col gap-2.5">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                onComplete();
                onClose();
              }}
            >
              {completeLabel}
            </Button>
            <Button color="primary" variant="text" onClick={onClose}>
              {dismissLabel}
            </Button>
          </div>
        </MobileDrawer.Content>
      </MobileDrawer>
    );
  }

  return (
    <Alert
      isOpen={isOpen}
      title={title}
      content={content}
      buttons={[
        { text: dismissLabel, role: "close" },
        {
          text: completeLabel,
          color: "primary",
          onClick: () => {
            onComplete();
            onClose();
          },
        },
      ]}
      onRequestClose={onClose}
    />
  );
};

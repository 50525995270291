export const TaskDetailsLoadingSkeleton = () => (
  <div className="flex animate-pulse flex-col px-8 py-10">
    <div className="bg-grey-100 mb-8 h-[80px] w-[60%] rounded-2xl" />
    <div className="bg-grey-100 mb-8 h-[80px] w-[50%] rounded-2xl" />
    <div className="bg-grey-100 mb-12 h-[60px] w-[25%] rounded-2xl" />
    <div className="bg-grey-100 mb-8 h-[80px] w-full rounded-2xl" />
    <div className="bg-grey-100 mb-8 h-[80px] w-[50%] rounded-2xl" />
    <div className="bg-grey-100 h-[60px] w-[25%] rounded-2xl" />
  </div>
);

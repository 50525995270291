import { Pill } from "@jugl-web/ui-components/cross-platform/Pill";
import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { ReactComponent as PlusMdIcon } from "./icons/plus-md.svg";
import { ReactComponent as PlusSmIcon } from "./icons/plus-sm.svg";

interface AddNewItemButtonProps {
  onClick: () => void;
}

export const AddNewItemButton: FC<AddNewItemButtonProps> = ({ onClick }) => {
  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  return (
    <PlainButton onClick={onClick}>
      <Pill
        size="md"
        endIcon={isMobile ? <PlusMdIcon /> : <PlusSmIcon />}
        label={t({
          id: "tasks-page.add-new-subtask",
          defaultMessage: "Add new subtask",
        })}
      />
    </PlainButton>
  );
};

import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { getFileSizeLabel } from "@jugl-web/utils/utils/files";
import { FC, useState } from "react";
import Highlighter from "react-highlight-words";
import { AttachmentsLayout } from "../../types";
import { AttachmentGridItem } from "../AttachmentGridItem";
import { AttachmentImage } from "../AttachmentImage";
import { AttachmentListItem } from "../AttachmentListItem";
import { RemoveAttachmentDialog } from "../RemoveAttachmentDialog";
import { RenameTaskAttachmentDialog } from "../RenameAttachmentDialog";

export interface TaskAttachmentItemProps {
  attachment: TaskAttachment;
  layout: AttachmentsLayout;
  isEditable: boolean;
  highlightedText?: string;
  onPreview: () => void;
  onRename?: (fileName: string) => void;
  onRemove?: () => void;
  onDownload?: () => void;
}

export const TaskAttachmentItem: FC<TaskAttachmentItemProps> = ({
  attachment,
  layout,
  isEditable,
  highlightedText,
  onPreview,
  onRename,
  onRemove,
  onDownload,
}) => {
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [isRemoveConfirmationDialogOpen, setIsRemoveConfirmationDialogOpen] =
    useState(false);

  const { localeAwareFormat } = useFormattedDate();
  const { t } = useTranslations();

  const AttachmentItemComponent =
    layout === "grid" ? AttachmentGridItem : AttachmentListItem;

  return (
    <>
      <AttachmentItemComponent
        image={<AttachmentImage attachment={attachment} layout={layout} />}
        name={attachment.name}
        highlightedName={
          highlightedText ? (
            <Highlighter
              autoEscape
              highlightClassName="font-semibold text-primary bg-transparent"
              searchWords={[highlightedText]}
              textToHighlight={attachment.name}
            />
          ) : (
            attachment.name
          )
        }
        updatedAtLabel={localeAwareFormat(
          new Date(attachment.updated_at),
          "MMM d, yyyy"
        )}
        sizeLabel={getFileSizeLabel(attachment.size)}
        menuSections={[
          [
            {
              id: "rename",
              label: t({
                id: "common.rename-file",
                defaultMessage: "Rename file",
              }),
              isHidden: !onRename,
              onSelect: (event, close) => {
                event.stopPropagation();
                close();
                setIsRenameDialogOpen(true);
              },
            },
            {
              id: "delete",
              label: t({
                id: "common.delete-file",
                defaultMessage: "Delete file",
              }),
              isHidden: !onRemove,
              onSelect: (event, close) => {
                event.stopPropagation();
                close();
                setIsRemoveConfirmationDialogOpen(true);
              },
            },
            {
              id: "download",
              label: t({
                id: "common.download-file",
                defaultMessage: "Download file",
              }),
              isHidden: !onDownload,
              onSelect: (event, close) => {
                event.stopPropagation();
                close();
                onDownload?.();
              },
            },
          ],
        ]}
        isEditable={isEditable}
        onClick={onPreview}
      />
      {onRemove && (
        <RemoveAttachmentDialog
          isOpen={isRemoveConfirmationDialogOpen}
          attachment={attachment}
          onRemove={onRemove}
          onClose={() => setIsRemoveConfirmationDialogOpen(false)}
        />
      )}
      {onRename && (
        <RenameTaskAttachmentDialog
          isOpen={isRenameDialogOpen}
          attachment={attachment}
          onSubmit={onRename}
          onClose={() => setIsRenameDialogOpen(false)}
        />
      )}
    </>
  );
};

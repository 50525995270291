import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import React from "react";
import { cx } from "@jugl-web/utils";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";

export const DeleteButton: React.FC<{
  className?: string;
  onDelete: () => void;
}> = ({ className, onDelete }) => (
  <InteractiveContainer
    className={cx(
      "flex h-10 w-10 items-center justify-center rounded-[10px]",
      className
    )}
    onClick={(e) => {
      e.stopPropagation();
      onDelete();
    }}
  >
    <DeleteIcon />
  </InteractiveContainer>
);

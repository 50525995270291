import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskAttachment } from "@jugl-web/rest-api/tasks";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform/InteractiveContainer";
import { forwardRef, ReactNode, useMemo } from "react";
import {
  ControlledProps,
  TaskAttachmentsHandle,
  TaskAttachmentsUpload,
  UncontrolledProps,
} from "./TaskAttachmentsUpload";
import { ReactComponent as AttachmentIcon } from "./assets/attachment.svg";
import { AttachmentsContainer } from "./components/AttachmentsContainer";
import { TaskAttachmentItem } from "./components/TaskAttachmentItem";
import { AttachmentsLayout } from "./types";

type TaskAttachmentProps = {
  attachments: TaskAttachment[];
  entityId: string;
  layout?: AttachmentsLayout;
  isEditable?: boolean;
  searchQuery?: string;
  emptyContent?: ReactNode;
  noSearchResultsContent?: ReactNode;
  className?: string;
  onPreviewAttachment: (attachment: TaskAttachment) => void;
  onAddAttachmentTileClick?: () => void;
} & (ControlledProps | UncontrolledProps);

export const TaskAttachments = forwardRef<
  TaskAttachmentsHandle,
  TaskAttachmentProps
>(
  (
    {
      attachments,
      entityId,
      layout = "grid",
      isEditable = true,
      emptyContent,
      noSearchResultsContent,
      searchQuery,
      className,
      onPreviewAttachment,
      onAddAttachmentTileClick,
      ...modeSpecificProps
    },
    ref
  ) => {
    const { tasksApi } = useRestApiProvider();

    const [deleteTaskAttachment] = tasksApi.useDeleteTaskAttachmentMutation();
    const [renameTaskAttachment] = tasksApi.useRenameTaskAttachmentMutation();

    const validAttachments = useMemo(
      () => attachments.filter((attachment) => attachment.status === "valid"),
      [attachments]
    );

    const visibleAttachments = useMemo(() => {
      if (!searchQuery) {
        return validAttachments;
      }

      return validAttachments.filter((attachment) =>
        attachment.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }, [searchQuery, validAttachments]);

    const shouldShowAddAttachmentTile =
      !!onAddAttachmentTileClick && isEditable && !searchQuery;

    const attachmentTilesCount =
      visibleAttachments.length + (shouldShowAddAttachmentTile ? 1 : 0);

    const handleRenameAttachment = (
      attachment: TaskAttachment,
      name: string
    ) => {
      if (modeSpecificProps.mode === "uncontrolled") {
        renameTaskAttachment({
          entityId,
          taskId: modeSpecificProps.taskId,
          attachmentId: attachment.id,
          attachmentName: name,
        });
      }
      if (modeSpecificProps.mode === "controlled") {
        modeSpecificProps.onAttachmentRename?.(attachment, name);
      }
    };

    const handleAttachmentRemove = (attachmentId: string) => {
      if (modeSpecificProps.mode === "uncontrolled") {
        deleteTaskAttachment({
          entityId,
          taskId: modeSpecificProps.taskId,
          attachmentId,
        });
      }
      if (modeSpecificProps.mode === "controlled") {
        modeSpecificProps.onAttachmentRemove?.(attachmentId);
      }
    };

    return (
      <>
        {attachmentTilesCount > 0 ? (
          <AttachmentsContainer layout={layout} className={className}>
            {shouldShowAddAttachmentTile && (
              <InteractiveContainer
                className="bg-grey-100 hover:bg-grey-200 flex h-full min-h-[142px] w-[168px] shrink-0 items-center justify-center rounded-xl transition-colors"
                onClick={onAddAttachmentTileClick}
              >
                <div className="flex flex-col items-center gap-2">
                  <AttachmentIcon />
                  <span className="font-secondary text-dark text-xs leading-[140%]">
                    Tap to add
                    <br />
                    Attachment
                  </span>
                </div>
              </InteractiveContainer>
            )}
            {visibleAttachments.map((attachment) => (
              <TaskAttachmentItem
                key={attachment.id}
                attachment={attachment}
                layout={layout}
                highlightedText={searchQuery}
                isEditable={isEditable}
                onRename={(name) => handleRenameAttachment(attachment, name)}
                onRemove={() => handleAttachmentRemove(attachment.id)}
                onPreview={() => onPreviewAttachment(attachment)}
              />
            ))}
          </AttachmentsContainer>
        ) : searchQuery ? (
          noSearchResultsContent
        ) : (
          emptyContent
        )}
        <TaskAttachmentsUpload
          ref={ref}
          entityId={entityId}
          {...modeSpecificProps}
        />
      </>
    );
  }
);

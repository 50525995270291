import { Button, Dialog, InteractiveContainer } from "@jugl-web/ui-components";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { useTranslations } from "@jugl-web/utils";
import { useOrderForm } from "@web-src/modules/orders/pages/OrderFormWizardPage/providers/OrderFormProvider";
import React, { useEffect, useRef, useState } from "react";

const ORDER_FORM_PREFIX_MAX_LENGTH = 6;
const ORDER_FORM_PREFIX_MIN_LENGTH = 2;

export const OrderFormPrefixField: React.FC = () => {
  const { t } = useTranslations();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { prefix, onPrefixChange } = useOrderForm();
  const [updatedPrefix, setUpdatedPrefix] = useState(prefix);

  useEffect(() => {
    if (isDialogOpen) {
      setUpdatedPrefix(prefix);
    }
  }, [prefix, isDialogOpen]);

  return (
    <>
      <InteractiveContainer
        className="bg-grey-100 rounded-[10px] py-[11px] px-5"
        onClick={() => setIsDialogOpen(true)}
      >
        <span className="text-dark font-secondary font-medium leading-4">
          {t(
            {
              id: "order-form-create-page.order-form-id",
              defaultMessage: "Form ID: {id}",
            },
            {
              id: (
                <span className="text-messages-chatBubbleSender">{prefix}</span>
              ),
            }
          )}
        </span>
      </InteractiveContainer>
      <Dialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        className="flex flex-col items-center py-10 px-6 text-center"
        initialFocus={inputRef}
      >
        <span className="text-dark font-secondary text-lg font-medium leading-[27px]">
          {t({
            id: "order-form-create-page.form-id",
            defaultMessage: "Form ID",
          })}
        </span>
        <span className="text-dark-800 font-secondary mt-1 text-sm leading-[21px]">
          {t(
            {
              id: "order-form-create-page.unique-form-id-message",
              defaultMessage:
                "Create a unique Form ID. This will help to differentiate Order{br} Tasks that will be created from Clients Submitting the Form ✨",
            },
            { br: <br /> }
          )}
        </span>
        <TextInput
          label=""
          ref={inputRef}
          value={updatedPrefix}
          classNames={{ wrapperClassName: "my-10 w-full" }}
          onChange={(e) => {
            const { value } = e.target;
            if (/^[a-zA-Z0-9]*$/.test(value)) {
              setUpdatedPrefix(value.trim());
            }
          }}
          placeholder={t({
            id: "common.enter",
            defaultMessage: "Enter",
          })}
          lengthIndicator={{
            max: ORDER_FORM_PREFIX_MAX_LENGTH,
          }}
        />
        <div className="flex w-full gap-4 px-4">
          <Button
            color="grey"
            fullWidth
            onClick={() => setIsDialogOpen(false)}
            className="h-10"
          >
            {t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            })}
          </Button>
          <Button
            fullWidth
            onClick={() => {
              onPrefixChange(updatedPrefix);
              setIsDialogOpen(false);
            }}
            isDisabled={
              updatedPrefix.length < ORDER_FORM_PREFIX_MIN_LENGTH ||
              updatedPrefix.length > ORDER_FORM_PREFIX_MAX_LENGTH
            }
            className="h-10"
          >
            {t({
              id: "order-form-create-page.proceed",
              defaultMessage: "Proceed",
            })}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

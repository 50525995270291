import { useTaskTemplates } from "@jugl-web/domain-resources/tasks/hooks/useTaskTemplates";
import { PreviewTaskTemplate } from "@jugl-web/rest-api/tasks-templates";
import { ListBoxItem, PopoverProps } from "@jugl-web/ui-components";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { ReactComponent as TemplateIcon } from "./assets/template.svg";

interface TaskTemplatePickerPopoverProps
  extends Pick<PopoverProps, "renderTrigger"> {
  entityId: string;
  meId: string;
  selectedTemplateId?: string;
  onSelect: (template: PreviewTaskTemplate) => void;
}

export const TaskTemplatePickerPopover: FC<TaskTemplatePickerPopoverProps> = ({
  entityId,
  meId,
  selectedTemplateId,
  onSelect,
  renderTrigger,
}) => {
  const { templates } = useTaskTemplates({
    entityId,
    meId,
    sorting: "alphabetical",
  });

  const { t } = useTranslations();

  const templatesAsListItems = useMemo<ListBoxItem<PreviewTaskTemplate>[]>(
    () => templates.map((template) => ({ id: template.id, value: template })),
    [templates]
  );

  return (
    <ResourcePickerPopover
      placement="bottom"
      autoPosition
      hasBackdrop
      backdropStyle="transparent"
      items={templatesAsListItems}
      hasSearch
      emptyStateMessage={t({
        id: "tasks-page.no-created-templates",
        defaultMessage: "No created templates yet",
      })}
      shouldScrollToFirstSelectedItem
      selectionBehavior={{ mode: "readonly" }}
      defaultSelectedIds={selectedTemplateId ? [selectedTemplateId] : undefined}
      maxVisibleItems={6}
      itemSize="md"
      spaceBetweenItems="compact"
      renderLabel={(item) => (
        <span className="font-secondary text-sm">{item.value.name}</span>
      )}
      renderSearchLabel={(item) => item.value.name}
      renderStartIcon={() => <TemplateIcon />}
      className="w-[375px]"
      renderTrigger={renderTrigger}
      onSelect={({ item, onClose }) => {
        onSelect(item.value);
        onClose();
      }}
    />
  );
};

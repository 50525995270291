import { getImagePathForSharedLibrary } from "@jugl-web/utils";
import imageFileSrc from "./assets/image-file.png";
import imageFileSmSrc from "./assets/image-file-sm.png";
import docFileSrc from "./assets/doc-file.png";
import docFileSmSrc from "./assets/doc-file-sm.png";
import excelFileSrc from "./assets/excel-file.png";
import excelFileSmSrc from "./assets/excel-file-sm.png";
import pdfFileSrc from "./assets/pdf-file.png";
import pdfFileSmSrc from "./assets/pdf-file-sm.png";
import textFileSrc from "./assets/text-file.png";
import textFileSmSrc from "./assets/text-file-sm.png";
import unknownFileSrc from "./assets/unknown-file.png";
import unknownFileSmSrc from "./assets/unknown-file-sm.png";
import zipFileSrc from "./assets/zip-file.png";
import zipFileSmSrc from "./assets/zip-file-sm.png";
import audioFileSrc from "./assets/audio-file.png";
import audioFileSmSrc from "./assets/audio-file-sm.png";
import videoFileSrc from "./assets/video-file.png";
import videoFileSmSrc from "./assets/video-file-sm.png";

type SupportedFileType =
  | "image"
  | "audio"
  | "video"
  | "pdf"
  | "document"
  | "archive"
  | "sheet"
  | "text";

type FileType = SupportedFileType | "unknown";

const fileExtensionToType: Record<string, SupportedFileType> = {
  // image
  jpg: "image",
  jpeg: "image",
  png: "image",
  svg: "image",
  webp: "image",
  gif: "image",
  bmp: "image",
  tiff: "image",

  // audio
  mp3: "audio",
  wav: "audio",
  flac: "audio",
  aac: "audio",
  ogg: "audio",
  wma: "audio",
  m4a: "audio",

  // video
  mp4: "video",
  avi: "video",
  mov: "video",
  mkv: "video",
  wmv: "video",
  webm: "video",

  // pdf
  pdf: "pdf",

  // document
  doc: "document",
  docx: "document",
  odt: "document",

  // archive
  zip: "archive",
  rar: "archive",
  "7z": "archive",

  // sheet
  xls: "sheet",
  xlsx: "sheet",
  ods: "sheet",

  // text
  txt: "text",
};

type ImageSrcBySize = {
  sm: unknown;
  md: unknown;
};

const fileTypeToImageSources: Record<SupportedFileType, ImageSrcBySize> = {
  image: {
    sm: imageFileSmSrc,
    md: imageFileSrc,
  },
  audio: {
    sm: audioFileSmSrc,
    md: audioFileSrc,
  },
  video: {
    sm: videoFileSmSrc,
    md: videoFileSrc,
  },
  pdf: {
    sm: pdfFileSmSrc,
    md: pdfFileSrc,
  },
  document: {
    sm: docFileSmSrc,
    md: docFileSrc,
  },
  archive: {
    sm: zipFileSmSrc,
    md: zipFileSrc,
  },
  sheet: {
    sm: excelFileSmSrc,
    md: excelFileSrc,
  },
  text: {
    sm: textFileSmSrc,
    md: textFileSrc,
  },
};

const unknownFileTypeImageSources: ImageSrcBySize = {
  sm: unknownFileSmSrc,
  md: unknownFileSrc,
};

export const extractFileNameParts = (fileName: string) => {
  const parts = fileName.split(".");
  const name = parts.slice(0, -1).join(".");

  if (parts.length === 1) {
    return { name: fileName, extension: "" };
  }

  return {
    name,
    extension: parts[parts.length - 1].toLowerCase(),
  };
};

export const getFileType = (filePath: string): FileType =>
  fileExtensionToType[extractFileNameParts(filePath).extension] || "unknown";

export const getFileImagePath = (
  filePath: string,
  imageSize: "sm" | "md"
): string => {
  const fileType = getFileType(filePath);

  const fileImageSrc =
    fileTypeToImageSources[fileType as SupportedFileType]?.[imageSize] ||
    unknownFileTypeImageSources[imageSize];

  return getImagePathForSharedLibrary(fileImageSrc);
};

import { LiveUpdateEvent } from "@jugl-web/domain-resources/live-updates";
import {
  TaskCommentInput,
  TaskCommentsProvider,
  useTaskCommentsProvider,
} from "@jugl-web/domain-resources/tasks-comments";
import { useManageCommentsListener } from "@jugl-web/domain-resources/tasks-comments/hooks/useManageCommentsListener";
import {
  isTaskClientFeedbackCreatedEvent,
  isTaskClientFeedbackDeletedEvent,
  isTaskClientFeedbackUpdatedEvent,
  isTaskCommentCreatedEvent,
  isTaskCommentDeletedEvent,
  isTaskCommentUpdatedEvent,
  TaskClientFeedbackLiveUpdateEvent,
  TaskCommentLiveUpdateEvent,
  useTaskLiveUpdatesContext,
} from "@jugl-web/domain-resources/tasks/components/TaskLiveUpdatesProvider";
import {
  TaskActivityFilter,
  useTaskActivities,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskActivities";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import {
  LoadingSpinner,
  Menu,
  parseActivityType,
  PlainButton,
  TaskActivityItem,
} from "@jugl-web/ui-components";
import {
  cx,
  isoToLocalDate,
  PaginationComponent,
  PaginationComponentHandle,
  useTranslations,
} from "@jugl-web/utils";
import { tasksCommentsApi } from "@web-src/features/api/createApi";
import { useLoadedMe } from "@web-src/features/app/hooks/useMe";
import { Tab } from "@web-src/modules/common/components/TabsLayout/components/Tab";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactComponent as CollapseIcon } from "../../assets/collapse.svg";
import { ReactComponent as CommentMenuIcon } from "../../assets/comment-menu.svg";
import { useTaskDetailsContext } from "../../hooks/useTaskDetailsContext";

interface TaskDetailsCommentsSidebarProps {
  isExpanded: boolean;
  onCollapse: () => void;
}

type CommentsSidebarTabId = "comments" | "client-feedback";

interface CommentsSidebarTab {
  id: CommentsSidebarTabId;
  title: ReactNode;
  filter: TaskActivityFilter;
}

const InnerTaskDetailsCommentsSidebar: FC<TaskDetailsCommentsSidebarProps> = ({
  isExpanded,
  onCollapse,
}) => {
  const { taskId, task } = useTaskDetailsContext();
  const { me, meId } = useLoadedMe();
  const { entityId } = useEntitySelectedProvider();

  const [selectedTabId, setSelectedTabId] =
    useState<CommentsSidebarTabId>("comments");

  const {
    activities,
    isLoading,
    isInitialLoading,
    loadMore,
    setActivitiesFilters,
    addActivity,
    modifyActivity,
    deleteActivity,
  } = useTaskActivities({
    meUser: me,
    entityId,
    taskId,
    initialFilters: ["comments"],
    sorting: "asc",
  });

  const { manageComments$, setCommentAction } = useTaskCommentsProvider();
  const { taskCommentEvents$, taskClientFeedbackEvents$ } =
    useTaskLiveUpdatesContext();
  const permissions = useTaskPermissions({ task, entityId, meId });

  const paginationRef = useRef<PaginationComponentHandle | null>(null);

  const { t } = useTranslations();

  const tabs = useMemo<CommentsSidebarTab[]>(
    () => [
      {
        id: "comments",
        title: t({
          id: "tasks-page.comments",
          defaultMessage: "Comments",
        }),
        filter: "comments",
      },
      {
        id: "client-feedback",
        title: t({
          id: "tasks-page.client-feedback",
          defaultMessage: "Client feedback",
        }),
        filter: "guest",
      },
    ],
    [t]
  );

  useManageCommentsListener({
    taskId,
    entityId,
    tasksCommentsApi,
    manageComments$,
  });

  const isLiveUpdateEventForCurrentTask = useCallback(
    <TEvent extends LiveUpdateEvent<string, { task_id: string }>>(
      event: TEvent
    ) => event.data.task_id === taskId,
    [taskId]
  );

  const handleTaskCommentLiveUpdateEvent = useCallback(
    (event: TaskCommentLiveUpdateEvent) => {
      if (
        isTaskCommentCreatedEvent(event) &&
        isLiveUpdateEventForCurrentTask(event)
      ) {
        addActivity(event.data);
        return;
      }

      if (
        isTaskCommentUpdatedEvent(event) &&
        isLiveUpdateEventForCurrentTask(event)
      ) {
        modifyActivity(event.data);
        return;
      }

      if (
        isTaskCommentDeletedEvent(event) &&
        isLiveUpdateEventForCurrentTask(event)
      ) {
        deleteActivity(event.data.id);
      }
    },
    [
      isLiveUpdateEventForCurrentTask,
      addActivity,
      deleteActivity,
      modifyActivity,
    ]
  );

  const handleTaskClientFeedbackLiveUpdateEvent = useCallback(
    (event: TaskClientFeedbackLiveUpdateEvent) => {
      if (
        isTaskClientFeedbackCreatedEvent(event) &&
        isLiveUpdateEventForCurrentTask(event)
      ) {
        addActivity(event.data);
        return;
      }

      if (
        isTaskClientFeedbackUpdatedEvent(event) &&
        isLiveUpdateEventForCurrentTask(event)
      ) {
        modifyActivity(event.data);
        return;
      }

      if (
        isTaskClientFeedbackDeletedEvent(event) &&
        isLiveUpdateEventForCurrentTask(event)
      ) {
        deleteActivity(event.data.id);
      }
    },
    [
      addActivity,
      deleteActivity,
      isLiveUpdateEventForCurrentTask,
      modifyActivity,
    ]
  );

  useEffect(() => {
    if (selectedTabId !== "comments") {
      return undefined;
    }

    const commentsSubscription = taskCommentEvents$.subscribe(
      handleTaskCommentLiveUpdateEvent
    );

    return () => {
      commentsSubscription.unsubscribe();
    };
  }, [handleTaskCommentLiveUpdateEvent, selectedTabId, taskCommentEvents$]);

  useEffect(() => {
    if (selectedTabId !== "client-feedback") {
      return undefined;
    }

    const clientFeedbackSubscription = taskClientFeedbackEvents$.subscribe(
      handleTaskClientFeedbackLiveUpdateEvent
    );

    return () => {
      clientFeedbackSubscription.unsubscribe();
    };
  }, [
    handleTaskClientFeedbackLiveUpdateEvent,
    selectedTabId,
    taskClientFeedbackEvents$,
  ]);

  // Scroll to the bottom when new comment is added
  useEffect(() => {
    const virtuoso = paginationRef.current?.getVirtuosoRef();

    if (!virtuoso) {
      return;
    }

    virtuoso.scrollToIndex({
      index: activities.length - 1,
      align: "end",
    });
  }, [activities.length]);

  const isEmpty = activities.length === 0;
  const hasAssociatedCustomer = !!task?.cust_id;

  return (
    <div
      className={cx(
        "relative flex flex-col rounded-tl-3xl bg-white transition-all",
        isExpanded ? "w-[480px]" : "w-0"
      )}
    >
      <PlainButton
        className="hover:bg-grey-100 absolute top-3 left-[18px] flex h-8 w-8 items-center justify-center rounded transition"
        onClick={onCollapse}
      >
        <CollapseIcon />
      </PlainButton>
      <div className="flex min-w-[440px] shrink-0 items-center">
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            title={tab.title}
            isSelected={selectedTabId === tab.id}
            className="pt-2 text-center"
            indicatorClassName={(isSelected) => !isSelected && "bg-grey-300"}
            onClick={() => {
              setSelectedTabId(tab.id);
              setActivitiesFilters([tab.filter]);
            }}
          />
        ))}
      </div>
      <div key={selectedTabId} className="animate-fade-in flex-1">
        {isInitialLoading ? (
          <LoadingSpinner className="mt-6" />
        ) : isEmpty ? (
          <div className="p-[80px] text-center">
            <span className="font-secondary text-sm leading-[22px] text-[#828282]">
              {selectedTabId === "client-feedback"
                ? hasAssociatedCustomer
                  ? t({
                      id: "tasks-page.no-feedback",
                      defaultMessage: "No feedback",
                    })
                  : t(
                      {
                        id: "tasks-page.empty-client-feedback-column-message",
                        defaultMessage:
                          "<b>Associate and share</b> the task with the client to receive feedback",
                      },
                      {
                        b: (mention: (string | JSX.Element)[]) => (
                          <span className="text-primary-800">{mention}</span>
                        ),
                      }
                    )
                : t(
                    {
                      id: "tasks-page.empty-activity-column-message",
                      defaultMessage:
                        "Post a comment to start a discussion. Use <b>@mention</b> to notify someone",
                    },
                    {
                      b: (mention: (string | JSX.Element)[]) => (
                        <span className="text-primary-800">{mention}</span>
                      ),
                    }
                  )}
            </span>
          </div>
        ) : (
          <PaginationComponent
            ref={paginationRef}
            items={activities}
            isLoading={isLoading}
            endReached={loadMore}
            reverse
            extraVirtuosoParams={{
              alignToBottom: false,
              className: "jugl__custom-scrollbar",
            }}
            renderer={(index, item) => {
              const { data: parsedActivity } = item;

              const isComment =
                item.data.raw.action_details.action === "commented" ||
                item.data.raw.action_details.action === "guest_comment_reply";

              const canManageComment =
                isComment &&
                permissions.canEditOrDeleteComment(
                  item.data.raw.action_by.user_id
                );

              const isFirst = index === 0;
              const isLast = activities.length - 1 === index;

              return (
                <div
                  className={cx(
                    isFirst && "mt-3",
                    !isLast &&
                      "border-grey-200 border-t-0 border-b border-r-0 border-l-0 border-solid"
                  )}
                >
                  <TaskActivityItem
                    variant="web"
                    action={parsedActivity.raw.action_details.action}
                    type={parseActivityType(
                      parsedActivity.raw.action_details.action
                    )}
                    isExternalClient={parsedActivity.user.isExternalClient}
                    username={parsedActivity.user.username}
                    userImageUrl={parsedActivity.user.avatarUrl}
                    message={parsedActivity.message}
                    date={isoToLocalDate(parsedActivity.raw.inserted_at)}
                    ratingRate={parsedActivity.rating?.rate}
                    ratingTags={parsedActivity.rating?.tags}
                    contextMenuSlot={
                      canManageComment && (
                        <Menu
                          placement="bottom-end"
                          hasBackdrop
                          backdropStyle="transparent"
                          className="min-w-[150px]"
                          sections={[
                            [
                              {
                                id: "edit",
                                label: t({
                                  id: "common.edit",
                                  defaultMessage: "Edit",
                                }),
                                onSelect: (_, close) => {
                                  setCommentAction({
                                    type: "edit",
                                    comment: item.data.raw,
                                  });
                                  close();
                                },
                              },
                              {
                                id: "delete",
                                label: t({
                                  id: "common.delete",
                                  defaultMessage: "Delete",
                                }),
                                onSelect: (_, close) => {
                                  setCommentAction({
                                    type: "delete",
                                    comment: item.data.raw,
                                  });
                                  manageComments$.next({
                                    action: "delete",
                                    taskId,
                                    comment: item.data.raw,
                                  });
                                  close();
                                },
                              },
                            ],
                          ]}
                          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
                            <Trigger
                              as={PlainButton}
                              ref={triggerRef}
                              className={cx(
                                "hover:bg-grey-100 flex h-8 w-8 items-center justify-center rounded-md transition",
                                isOpen && "bg-grey-100"
                              )}
                            >
                              <CommentMenuIcon />
                            </Trigger>
                          )}
                        />
                      )
                    }
                  />
                </div>
              );
            }}
          />
        )}
      </div>
      <div
        className="flex shrink-0 items-center justify-center gap-2 bg-white py-6 px-4"
        style={{ boxShadow: "0px -2px 0px 0px #ECEFF0" }}
      >
        <TaskCommentInput
          entityId={entityId}
          meId={me?.id || ""}
          apiAction={
            selectedTabId === "client-feedback"
              ? "guest_comment_reply"
              : undefined
          }
        />
      </div>
    </div>
  );
};

export const TaskDetailsCommentsSidebar: FC<TaskDetailsCommentsSidebarProps> = (
  props
) => {
  const { entityId } = useEntitySelectedProvider();
  const { meId } = useLoadedMe();
  const { taskId, task } = useTaskDetailsContext();

  return (
    <TaskCommentsProvider
      userId={meId}
      entityId={entityId}
      taskId={taskId}
      task={task}
    >
      <InnerTaskDetailsCommentsSidebar {...props} />
    </TaskCommentsProvider>
  );
};

import { OrderSubmitForm } from "@jugl-web/domain-resources/orders/components/OrderSubmitForm";
import { HeaderBreadcrumbs, MultiSectionLayout } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import React from "react";
import { useParams } from "react-router-dom";

export const OrderFormSubmitPage: React.FC = () => {
  const { entityId } = useEntitySelectedProvider();
  const { formId } = useParams();
  const { generateUrl } = useNavigation();
  const { t } = useTranslations();

  return (
    <MultiSectionLayout
      header={
        <HeaderBreadcrumbs
          items={[
            {
              title: t({
                id: "order-form-submit-page.forms",
                defaultMessage: "Forms",
              }),
              href: generateUrl("orderForms"),
            },
            {
              title: t({
                id: "order-form-submit-page.place-an-order",
                defaultMessage: "Place an Order",
              }),
            },
          ]}
        />
      }
      className="bg-[#E8EDF4]"
    >
      <div className="mx-auto h-full w-full px-4 pt-[60px]">
        <div className="mx-auto flex w-full max-w-[720px] flex-col items-center rounded-xl pb-12 md:min-h-full md:rounded-t-xl">
          <OrderSubmitForm entityId={entityId} formId={formId || ""} />
        </div>
      </div>
    </MultiSectionLayout>
  );
};

import { TaskViewColumn } from "@jugl-web/domain-resources/tasks/hooks/useTasksViewColumns";
import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { Avatar, BoardAvatar } from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC, useState } from "react";
import { AIUserPerformanceDialog } from "@web-src/features/users/components/AIUserPerformanceDialog";
import { ReactComponent as MagicWandIcon } from "./assets/ai.svg";
import { tableColorSetToClasses } from "../../consts";

interface TaskTableTitleProps {
  entityId: string;
  meId: string | undefined;
  viewColumn: TaskViewColumn;
}

export const TaskTableTitle: FC<TaskTableTitleProps> = ({
  entityId,
  meId,
  viewColumn,
}) => {
  const { t } = useTranslations();
  const [isUserPerformanceDialogOpen, setIsUserPerformanceDialogOpen] =
    useState(false);
  const textClasses = cx(
    "font-secondary text-base font-semibold leading-4 tracking-[0.18px]",
    tableColorSetToClasses[viewColumn.tableView.tableColorSet].label
  );

  if (viewColumn.title.type === "text") {
    return <span className={textClasses}>{viewColumn.title.value}</span>;
  }

  if (viewColumn.title.type === "board") {
    return (
      <div className="flex items-center gap-2.5">
        <BoardAvatar
          size="md"
          name={viewColumn.title.name}
          color={viewColumn.title.color}
        />
        <span className={textClasses}>{viewColumn.title.name}</span>
      </div>
    );
  }

  if (viewColumn.title.type === "user") {
    return (
      <UserGeneralProfile entityId={entityId} userId={viewColumn.title.userId}>
        {({ safeProfile, profile }) => (
          <>
            <div className="flex items-center gap-2.5">
              <Avatar
                size="sm"
                username={safeProfile.displayName}
                imageUrl={safeProfile.avatar}
              />
              <span className={textClasses}>
                {safeProfile.displayName}{" "}
                {profile &&
                  profile.id === meId &&
                  `(${t({ id: "common.me", defaultMessage: "Me" })})`}
              </span>
              <div
                className="flex h-[26px] w-[26px] cursor-pointer items-center justify-center rounded-full"
                style={{
                  background:
                    "linear-gradient(237deg, #F33A9E 1.05%, #2196F3 86.49%)",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsUserPerformanceDialogOpen(true);
                }}
              >
                <MagicWandIcon className="h-[16px] w-[16px]" />
              </div>
            </div>
            {viewColumn.title.type === "user" && (
              <AIUserPerformanceDialog
                isOpen={isUserPerformanceDialogOpen}
                onRequestedClose={() => setIsUserPerformanceDialogOpen(false)}
                userId={viewColumn.title.userId}
                entityId={entityId}
              />
            )}
          </>
        )}
      </UserGeneralProfile>
    );
  }

  if (viewColumn.title.type === "customer") {
    return (
      <div className="flex items-center gap-2.5">
        <Avatar
          imageUrl={viewColumn.title.avatar}
          username={viewColumn.title.name}
          size="sm"
        />
        <span className={textClasses}>{viewColumn.title.name}</span>
      </div>
    );
  }

  throw new Error("Invalid title type");
};

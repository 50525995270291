import { useTaskActivities } from "@jugl-web/domain-resources/tasks/hooks/useTaskActivities";
import { LoadingSpinner } from "@jugl-web/ui-components";
import {
  parseActivityType,
  TaskActivityItem,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskActivityItem";
import {
  cx,
  isoToLocalDate,
  PaginationComponent,
  useTranslations,
} from "@jugl-web/utils";
import { useLoadedMe } from "@web-src/features/app/hooks/useMe";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { MAIN_SECTION_SCROLLABLE_CONTAINER_ID } from "../../consts";
import { useLoadedTaskDetailsContext } from "../../hooks/useTaskDetailsContext";
import { TabContentFeedbackText } from "../TabContentFeedbackText";

export const TaskDetailsHistoryTabContent = () => {
  const { task } = useLoadedTaskDetailsContext();
  const { me } = useLoadedMe();
  const { entityId } = useEntitySelectedProvider();

  const { activities, isLoading, isInitialLoading, loadMore } =
    useTaskActivities({
      meUser: me,
      entityId,
      taskId: task.id,
      initialFilters: ["actions", "updates"],
      sorting: "desc",
    });

  const { t } = useTranslations();

  if (isInitialLoading) {
    return <LoadingSpinner />;
  }

  if (activities.length === 0) {
    return (
      <TabContentFeedbackText
        text={t({
          id: "tasks-page.no-activity",
          defaultMessage: "No activity yet",
        })}
      />
    );
  }

  return (
    <PaginationComponent
      items={activities}
      isLoading={isLoading}
      endReached={loadMore}
      extraVirtuosoParams={{
        className: "jugl__custom_scrollbar",
        customScrollParent:
          document.getElementById(MAIN_SECTION_SCROLLABLE_CONTAINER_ID) ||
          undefined,
      }}
      renderer={(index, item) => {
        const { data: parsedActivity } = item;
        const isLast = activities.length - 1 === index;

        return (
          <div
            className={cx(
              !isLast &&
                "border-grey-200 border-t-0 border-b border-r-0 border-l-0 border-solid"
            )}
          >
            <TaskActivityItem
              variant="web"
              action={parsedActivity.raw.action_details.action}
              type={parseActivityType(parsedActivity.raw.action_details.action)}
              username={parsedActivity.user.username}
              userImageUrl={parsedActivity.user.avatarUrl}
              message={parsedActivity.message}
              date={isoToLocalDate(parsedActivity.raw.inserted_at)}
            />
          </div>
        );
      }}
    />
  );
};

import { PreviewTask } from "@jugl-web/rest-api/tasks";
import {
  InteractiveContainer,
  Popover,
  PopoverProps,
} from "@jugl-web/ui-components";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { cx, useSearchInput, useTranslations } from "@jugl-web/utils";
import { FC, useMemo, useState } from "react";
import Highlighter from "react-highlight-words";
import { useTaskBoards } from "../../hooks/useTaskBoards";
import { useTaskPermissionsFactory } from "../../hooks/useTaskPermissions";
import { useTasks } from "../../hooks/useTasks";
import { ReactComponent as OrderIcon } from "./icons/order.svg";
import { ReactComponent as TaskIcon } from "./icons/task.svg";

type MoveToTaskPopoverProps = {
  entityId: string;
  taskId: string;
  meId: string;
  onSelect: (targetTask: PreviewTask) => void;
} & Pick<PopoverProps, "placement" | "renderTrigger">;

export const MoveToTaskPopover: FC<MoveToTaskPopoverProps> = ({
  entityId,
  taskId,
  meId,
  onSelect,
  placement = "bottom",
  renderTrigger,
}) => {
  const { boards, getBoardById } = useTaskBoards({ entityId });
  const [selectedTaskBoardId, setSelectedTaskBoardId] = useState("my");

  const { searchQuery, inputProps, reset } = useSearchInput({
    debounce: 500,
  });

  const { t } = useTranslations();

  const { tasks } = useTasks({
    entityId,
    source: { type: "boardTasks", boardId: selectedTaskBoardId },
    searchQuery,
  });

  const taskBoardItems = useMemo(
    () => [
      {
        id: "my",
        title: t({
          id: "home-sidebar-component.my-tasks",
          defaultMessage: "My Tasks",
        }),
      },
      ...boards.map((board) => ({
        id: board.id,
        title: board.name,
      })),
    ],
    [t, boards]
  );

  const getTaskPermissions = useTaskPermissionsFactory({ entityId, meId });

  const filteredTasks = useMemo(
    () =>
      tasks.filter(
        (task) =>
          task.id !== taskId && getTaskPermissions(task).canManageChecklist
      ),
    [taskId, tasks, getTaskPermissions]
  );

  return (
    <Popover
      className="w-[375px]"
      placement={placement}
      renderTrigger={renderTrigger}
    >
      {({ onClose }) => (
        <div className="p-3">
          <SearchInput
            variant="filled"
            color="grey"
            {...inputProps}
            onClear={reset}
          />
          <div className="jugl__custom-scrollbar-horizontal mt-2 flex gap-2 overflow-x-auto py-1.5">
            {taskBoardItems.map((board) => {
              const isSelected = selectedTaskBoardId === board.id;
              return (
                <InteractiveContainer
                  className={cx(
                    "bg-grey-100 max-w-[140px] shrink-0 truncate rounded-lg px-4 py-[5px] transition-colors",
                    isSelected && "bg-primary"
                  )}
                  key={board.id}
                  onClick={() => setSelectedTaskBoardId(board.id)}
                >
                  <span
                    className={cx(
                      "text-dark-700 font-secondary leading-2 text-[13px] transition-colors",
                      isSelected && "font-medium text-white"
                    )}
                  >
                    {board.title}
                  </span>
                </InteractiveContainer>
              );
            })}
          </div>
          <div className="jugl__custom-scrollbar mt-7 flex h-[320px] flex-col gap-3 overflow-y-auto">
            {filteredTasks.length ? (
              filteredTasks.map((task) => (
                <InteractiveContainer
                  className="hover:bg-grey-100 flex items-start justify-between gap-1 rounded-xl py-2 px-3 transition-colors"
                  key={task.id}
                  onClick={() => {
                    onSelect(task);
                    onClose();
                  }}
                >
                  <div className="flex items-center gap-3 overflow-hidden">
                    <div className="shrink-0">
                      {task.order_id ? <OrderIcon /> : <TaskIcon />}
                    </div>
                    <div className="flex flex-col gap-0.5 overflow-hidden">
                      <span className="text-dark font-secondary truncate text-sm leading-[21px]">
                        <Highlighter
                          autoEscape
                          highlightClassName="text-primary bg-transparent"
                          searchWords={[searchQuery || ""]}
                          textToHighlight={task.name}
                        />
                      </span>
                      <span className="text-grey-800 font-secondary truncate text-sm leading-[140%]">
                        {task.board_id
                          ? getBoardById(task.board_id)?.name
                          : "No Board"}
                      </span>
                    </div>
                  </div>
                  <span className="font-secondary shrink-0 text-sm leading-[21px] text-[#828282]">
                    {task.order_id}
                  </span>
                </InteractiveContainer>
              ))
            ) : (
              <span className="font-secondary text-center text-sm leading-[160%] text-[#828282]">
                {t({
                  id: "tasks-page.no-results",
                  defaultMessage: "No results 😔",
                })}
              </span>
            )}
          </div>
        </div>
      )}
    </Popover>
  );
};

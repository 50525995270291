import {
  TaskModuleLog,
  TaskModuleLogAction,
  TaskModuleLogChanges,
} from "@jugl-web/rest-api";
import { addSearchParamsToURL, isoToLocalDate } from "@jugl-web/utils";
import { useCallback } from "react";
import { useTaskActivityMessageRenderers } from "../../tasks/hooks/useTaskActivityMessageRenderers";

interface UseTaskLogParserOptions {
  entityId: string | undefined;
}

export const useTaskLogParser = ({ entityId }: UseTaskLogParserOptions) => {
  const renderers = useTaskActivityMessageRenderers({ entityId });

  const messageRenderer = useCallback(
    (
      username: string,
      action: TaskModuleLogAction,
      changes: TaskModuleLogChanges,
      isExternalClient?: boolean
    ) => {
      if (action === "created") {
        if (isExternalClient) {
          return renderers.taskCreatedByExternalUserMessageRenderer();
        }
        return renderers.taskCreatedMessageRenderer(username);
      }

      if (action === "updated") {
        return renderers.taskUpdatedMessageRenderer(username, changes);
      }

      if (action === "deleted") {
        return renderers.taskDeletedMessageRenderer(username);
      }

      if (action === "commented") {
        return renderers.taskCommentedMessageRenderer(username);
      }

      if (action === "viewed") {
        return renderers.taskViewedMessageRenderer(username);
      }

      if (action === "restored") {
        return renderers.taskRestoredMessageRenderer(username);
      }

      if (action === "archived") {
        return renderers.taskArchivedMessageRenderer(username);
      }

      throw new Error(`Unsupported task log action: ${action}`);
    },
    [renderers]
  );

  const parseTaskLog = useCallback(
    (log: TaskModuleLog) => {
      const isExternalClient = !log.action_by;
      const username = [
        log.action_by?.first_name,
        log.action_by?.last_name,
      ].join(" ");

      return {
        id: log.id,
        user: {
          name: username,
          isExternalClient,
          avatarUrl: log.action_by?.img
            ? addSearchParamsToURL(log.action_by?.img, {
                t: log.action_by.updated_at,
              })
            : null,
        },
        message: messageRenderer(
          username,
          log.action,
          log.changes,
          isExternalClient
        ),
        date: new Date(isoToLocalDate(log.inserted_at)),
      };
    },
    [messageRenderer]
  );

  return { parseTaskLog };
};

import {
  MentionTextarea,
  MentionTextareaHandle,
  MentionTextareaProps,
} from "@jugl-web/domain-resources/common/components/RichTextarea";
import { assert, onEnter } from "@jugl-web/utils";
import {
  forwardRef,
  Fragment,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ReactComponent as SubmitChecklistIcon } from "../../assets/submit-checklist.svg";
import { TaskTableLayout } from "../TaskTableLayout";

interface TextCellEditorProps
  extends Pick<MentionTextareaProps, "mentions" | "placeholder"> {
  isDisabled?: boolean;
  extraControlSlots?: ReactNode[];
  validator: (rawText: string) => boolean;
  onSubmit: (richText: string) => void;
  onCancel: () => void;
}

export interface TextCellEditorHandle {
  cancel: () => void;
  focus: () => void;
  setContent: (richText: string) => void;
}

export const TextCellEditor = forwardRef<
  TextCellEditorHandle,
  TextCellEditorProps
>(
  (
    {
      isDisabled = false,
      extraControlSlots = [],
      validator,
      onSubmit,
      onCancel,
      ...mentionTextareaProps
    },
    ref
  ) => {
    const [isValid, setIsValid] = useState(false);

    const $mentionTextareaRef = useRef<MentionTextareaHandle | null>(null);

    useImperativeHandle(ref, () => ({
      cancel: onCancel,
      focus: () => $mentionTextareaRef.current?.focus(),
      setContent: (richText) => {
        $mentionTextareaRef.current?.setRichText(richText);
        setIsValid(validator(richText));
      },
    }));

    useEffect(() => {
      $mentionTextareaRef.current?.focus();
    }, []);

    return (
      <>
        <MentionTextarea
          ref={$mentionTextareaRef}
          mentions={{ type: "manual", mentions: [] }}
          mentionListType="caret-pinned"
          isDisabled={isDisabled}
          onRawTextChange={(rawText) => setIsValid(validator(rawText))}
          onEscape={onCancel}
          classes={{
            container:
              "w-full text-sm font-primary text-dark-800 tracking-[0.14px] py-4 leading-3",
            placeholder: "left-0 top-4 text-grey-background",
            contentEditable: "outline-none p-0 max-h-[80px] overflow-y-auto",
          }}
          {...mentionTextareaProps}
        />
        <div className="flex shrink-0 items-center gap-2">
          {extraControlSlots.length > 0 && (
            <>
              {extraControlSlots.map((slot, index) => (
                <Fragment key={+index}>{slot}</Fragment>
              ))}
              <div className="bg-dark-100 h-6 w-px" />
            </>
          )}
          <TaskTableLayout.IconButton
            className="text-messages-chatBubbleSender"
            isDisabled={!isValid || isDisabled}
            onKeyDown={onEnter((event) => event.stopPropagation())}
            onClick={(event) => {
              assert(
                !!$mentionTextareaRef.current,
                "MentionTextarea ref is not set"
              );

              event.stopPropagation();
              onSubmit($mentionTextareaRef.current.getRichText());
            }}
          >
            <SubmitChecklistIcon className="transition-colors" />
          </TaskTableLayout.IconButton>
          <TaskTableLayout.ClearCellValueButton
            isDisabled={isDisabled}
            onKeyDown={onEnter((event) => event.stopPropagation())}
            onClick={(event) => {
              event.stopPropagation();
              onCancel();
            }}
          />
        </div>
      </>
    );
  }
);

import {
  Children,
  cloneElement,
  FC,
  Fragment,
  isValidElement,
  ReactNode,
} from "react";

const escapeRegExp = (string: string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const highlightRenderer = (part: string, highlightClassName: string) => (
  <span className={highlightClassName}>{part}</span>
);

export const highlightifyString = (
  text: string,
  searchWord: string,
  highlightClassName: string
) => {
  if (!searchWord) {
    return text;
  }

  const escapedSearchWord = escapeRegExp(searchWord);
  const HIGHLIGHT_REGEXP = new RegExp(`(${escapedSearchWord})`, "gi");

  const parts = text.split(HIGHLIGHT_REGEXP);

  return parts.map((part, index) => {
    if (index % 2 === 1) {
      return (
        <Fragment key={+index}>
          {highlightRenderer(part, highlightClassName)}
        </Fragment>
      );
    }

    return part;
  });
};

export const highlightifyNode = (
  node: ReactNode,
  searchWord: string,
  highlightClassName: string
): ReactNode => {
  if (typeof node === "string") {
    return highlightifyString(node, searchWord, highlightClassName);
  }

  // handle arrays of nodes
  if (Array.isArray(node)) {
    return node.map((child) =>
      highlightifyNode(child, searchWord, highlightClassName)
    );
  }

  // traverse children of the node recursively
  if (isValidElement(node)) {
    const nodeChildren = node.props.children
      ? Children.map(node.props.children, (child) =>
          highlightifyNode(child, searchWord, highlightClassName)
        )
      : node.props.children;

    return cloneElement(node, { key: node.key }, nodeChildren);
  }

  if (node === null || node === undefined) {
    return node;
  }

  return node.toString();
};

interface HighlightifyProps {
  children: ReactNode;
  searchWord: string;
  highlightClassName: string;
}

export const Highlightify: FC<HighlightifyProps> = ({
  children,
  searchWord,
  highlightClassName,
}) => (
  <>
    {Children.map(children, (node) =>
      highlightifyNode(node, searchWord, highlightClassName)
    )}
  </>
);

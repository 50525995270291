import {
  DatePicker,
  DatePickerProps,
} from "@jugl-web/ui-components/cross-platform/DatePicker";
import { Popover } from "@jugl-web/ui-components/cross-platform/Popover";
import { MobileDrawer } from "@jugl-web/ui-components/mobile/MobileDrawer";
import { ResourcePickerPopoverHeader } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { FC, useMemo, useState } from "react";
import { isValidDate } from "@jugl-web/utils/date-time/isValidDate";
import format from "date-fns/format";
import { TextInput } from "../TextInput";
import { FormGroupProps } from "../FormGroup";
import { ReactComponent as DateIcon } from "./assets/date-icon.svg";

export type DateTimeInputProps = FormGroupProps & {
  isMobile: boolean;
  value?: string;
  onChange?: (value: string) => void;
};

export const DateTimeInput: FC<DateTimeInputProps> = ({
  isMobile,
  value,
  onChange,
  ...formProps
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const initialDate = useMemo(() => {
    if (value && isValidDate(value)) {
      return new Date(value);
    }

    return new Date();
  }, [value]);

  const visibleValue = useMemo(() => {
    if (!value || !isValidDate(value)) {
      return "";
    }
    return format(new Date(value), "MM / dd / yyyy");
  }, [value]);

  const commonDatePickerProps: DatePickerProps = {
    initialDate,
    isDateRemovable: true,
    onSubmit: (date) => {
      onChange?.(date ? date.toISOString() : "");
    },
  };

  const $input = (
    <TextInput
      {...formProps}
      startContent={<DateIcon />}
      readOnly
      value={visibleValue}
      placeholder="Month / day / year"
      classNames={{
        startContentWrapperClassName: "bg-transparent",
        inputClassName: "pl-0",
      }}
    />
  );

  if (isMobile) {
    return (
      <>
        <div className="cursor-pointer" onClick={() => setIsDialogOpen(true)}>
          {$input}
        </div>
        <MobileDrawer
          isOpen={isDialogOpen}
          header={{ title: formProps.label }}
          onClose={() => {
            setIsDialogOpen(false);
          }}
        >
          <MobileDrawer.Content>
            <DatePicker
              onClose={() => {
                setIsDialogOpen(false);
              }}
              {...commonDatePickerProps}
            />
          </MobileDrawer.Content>
        </MobileDrawer>
      </>
    );
  }

  return (
    <Popover
      className="z-[999999] w-[375px]"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          className="m-0 block w-full cursor-pointer border-none bg-transparent p-0 text-left outline-none ring-0 focus:border-0 focus:ring-0"
        >
          {$input}
        </Trigger>
      )}
    >
      {({ onClose }) => (
        <>
          <ResourcePickerPopoverHeader title="Date field" onClose={onClose} />
          <DatePicker
            className="p-4"
            onClose={onClose}
            {...commonDatePickerProps}
          />
        </>
      )}
    </Popover>
  );
};

import {
  assignRefs,
  cx,
  useAutoResizedTextarea,
  useUniqueDOMId,
} from "@jugl-web/utils";
import {
  forwardRef,
  TextareaHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormGroupProps } from "../FormGroup";
import { UtilityTextInputBase } from "../UtilityTextInputBase";

export interface TextAreaInputProps
  extends Pick<
      TextareaHTMLAttributes<HTMLTextAreaElement>,
      | "placeholder"
      | "value"
      | "autoComplete"
      | "onBlur"
      | "onChange"
      | "onKeyDown"
      | "cols"
      | "id"
    >,
    FormGroupProps {
  classNames?: FormGroupProps["classNames"] & {
    inputClassName?: string;
    inputWrapperClassName?: string;
  };
}

export const TextAreaInput = forwardRef<
  HTMLTextAreaElement,
  TextAreaInputProps
>((props, ref) => {
  const uniqueId = useUniqueDOMId();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { textareaProps } = useAutoResizedTextarea(textareaRef);
  const {
    label,
    isInvalid,
    isRequired,
    tooltip,
    errorMessage,
    isDisabled,
    classNames: { inputClassName, inputWrapperClassName } = {},
    ...htmlInputProps
  } = props;
  const inputId = useMemo(
    () => htmlInputProps.id || uniqueId,
    [htmlInputProps.id, uniqueId]
  );
  const [internalInputValue, setInternalInputValue] = useState<string>(
    typeof htmlInputProps.value === "string" ? htmlInputProps.value : ""
  );
  useEffect(() => {
    if (htmlInputProps.value === "string") {
      setInternalInputValue(htmlInputProps.value);
    }
  }, [htmlInputProps.value]);
  return (
    <UtilityTextInputBase
      {...{
        ...props,
        lengthIndicator: {
          ...props.lengthIndicator,
          current: internalInputValue.length,
        },
      }}
      inputWrapperClassName={inputWrapperClassName}
      inputId={inputId}
    >
      <textarea
        ref={assignRefs([ref, textareaRef])}
        disabled={isDisabled}
        id={inputId}
        {...{
          ...htmlInputProps,
          className: cx(
            "w-full h-full flex-1 font-secondary text-base leading-4 outline-none py-4 px-5 border-none rounded-[10px] text-dark resize-none",
            inputClassName
          ),
          onChange: (e) => {
            setInternalInputValue(e.target.value || "");
            if (htmlInputProps.onChange) {
              htmlInputProps.onChange(e);
            }
          },
          ...textareaProps,
        }}
        rows={1}
        {...textareaProps}
      />
    </UtilityTextInputBase>
  );
});

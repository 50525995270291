import React, { useCallback, useRef, useState } from "react";
import {
  TaskFormStateProvider,
  useTaskFormState,
  useTaskFormStateContext,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  CreateOrderFormPayload,
  OrderFormFieldType,
  OrderFormServicesFieldValue,
} from "@jugl-web/rest-api/orders/types";
import {
  getDueDateBasedOnDays,
  getDueDateBasedOnDaysAndTime,
  getDueDateInDays,
  templateChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useBlockNavigation, useToast, useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { EmptyListContent, PageLoaderFull } from "@jugl-web/ui-components";
import { useEffectOnce } from "react-use";
import {
  TASK_ORDER_CUSTOMER_NAME,
  TASK_ORDER_EMAIL_ID,
  TASK_ORDER_PHONE_ID,
} from "@jugl-web/utils/consts";
import {
  OrderFormProvider,
  SubmitterContact,
  useOrderForm,
} from "./providers/OrderFormProvider";
import { OrderFormFieldsStep } from "./components/OrderFormFieldsStep";
import { OrderFormStep } from "./types";
import { OrderFormTaskDetailsStep } from "./components/OrderFormTaskDetailsStep";
import { NavigationAlert } from "./components/NavigationAlert";

export const OrderFormWizardPageContent: React.FC = () => {
  const {
    orderStep,
    title,
    description,
    image,
    fields,
    allFields,
    prefix,
    formId,
    onSubmitterContactChange,
    onPrefixChange,
    onDescriptionChange,
    onTitleChange,
    onImageChange,
    onFieldsChange,
  } = useOrderForm();
  const { ordersApi } = useRestApiProvider();
  const { formState, resetFormState } = useTaskFormStateContext();
  const { entityId, showPlanLimitsReachedAlert } = useEntitySelectedProvider();
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const [createOrderForm, { isLoading: isCreateFormLoading }] =
    ordersApi.useCreateOrderFormMutation();
  const [updateOrderForm, { isLoading: isUpdateFormLoading }] =
    ordersApi.useUpdateOrderFormMutation();

  const [getOrderForm, { isError, isLoading: isGetFormLoading }] =
    ordersApi.useLazyGetOrderFormQuery();

  const confirmNavigation = useRef<(() => void) | null>(null);
  const skipNavigationAlert = useRef(false);
  const [isNavigationAlertOpen, setIsNavigationAlertOpen] = useState(false);

  useBlockNavigation(
    setIsNavigationAlertOpen,
    confirmNavigation,
    skipNavigationAlert
  );

  const fetchOrderForm = useCallback(async () => {
    if (!formId) return;
    const resp = await getOrderForm({
      entityId,
      formId,
    });

    if (resp && "data" in resp && resp.data) {
      const { data } = resp;
      if (data.version === 1) {
        skipNavigationAlert.current = true;
        navigateToPage("orderForms");
        return;
      }
      onDescriptionChange(data.form_desc);
      onTitleChange(data.title);
      onImageChange(data.banner_img);
      onFieldsChange(
        data.fields
          .map((field) => {
            if (field.property.type === OrderFormFieldType.services) {
              const filteredItems = field.property.value.items.filter((id) =>
                data.services.some((service) => service.id === id)
              );

              return {
                ...field,
                property: {
                  ...field.property,
                  value: {
                    ...field.property.value,
                    items: filteredItems,
                  },
                },
              };
            }
            return field;
          })
          .filter(
            (field) =>
              field.property.type !== OrderFormFieldType.services ||
              field.property.value.items.length > 0
          )
          .filter(
            (field) =>
              field.id !== TASK_ORDER_CUSTOMER_NAME &&
              field.id !== TASK_ORDER_PHONE_ID &&
              field.id !== TASK_ORDER_EMAIL_ID
          )
      );
      onSubmitterContactChange(
        data.fields.find((field) => field.id === TASK_ORDER_PHONE_ID)
          ? SubmitterContact.mobile
          : SubmitterContact.email
      );
      onPrefixChange(data.prefix);
      resetFormState({
        title: data.name,
        assigneeIds: data.assignees,
        checklistItems: data.checklist.map(
          templateChecklistItemAdapters.toInternalModel
        ),
        dueDate:
          data.due_in !== null
            ? {
                date: data.due_at
                  ? getDueDateBasedOnDaysAndTime(data.due_at, data.due_in)
                  : getDueDateBasedOnDays(data.due_in),
              }
            : null,
        priority: data.priority,
        description: data.desc || "",
        completeChecklistInOrder: data.has_chklist_chk,
        labelId: data.label_id,
        boardId: data.board_id,
      });
    }
  }, [
    formId,
    entityId,
    getOrderForm,
    onDescriptionChange,
    onTitleChange,
    onImageChange,
    onFieldsChange,
    onPrefixChange,
    resetFormState,
    onSubmitterContactChange,
    navigateToPage,
  ]);

  useEffectOnce(() => {
    if (!formId) return;
    fetchOrderForm();
  });

  const handleCreateOrderForm = useCallback(async () => {
    const {
      labelId,
      boardId,
      checklistItems,
      priority,
      dueDate,
      assigneeIds,
      completeChecklistInOrder,
      title: name,
      description: desc,
    } = formState;

    const payload: {
      opts: CreateOrderFormPayload;
      banner?: File;
    } = {
      opts: {
        title,
        form_desc: description,
        prefix,
        type: "apt",
        services: fields
          .filter(
            (field) => field.property.type === OrderFormFieldType.services
          )
          .flatMap(
            (el) => (el.property.value as OrderFormServicesFieldValue).items
          ),
        fields: allFields,
        name,
        due_in: dueDate ? getDueDateInDays(dueDate.date) : null,
        due_at: dueDate?.date.toISOString() || null,
        priority,
        checklist: checklistItems.map(
          templateChecklistItemAdapters.toBackendModel
        ),
        assignees: assigneeIds,
        desc,
        label_id: labelId,
        board_id: boardId,
        has_chklist_chk: completeChecklistInOrder,
      },
      banner: typeof image === "object" ? image : undefined,
    };

    const response = formId
      ? await updateOrderForm({
          entityId,
          formId: formId || "",
          ...payload,
        })
      : await createOrderForm({
          entityId,
          ...payload,
        });

    if ("data" in response) {
      toast(
        formId
          ? t({
              id: "order-form-wizard-page.form-was-updated",
              defaultMessage: "Form was updated",
            })
          : t({
              id: "order-form-wizard-page.new-form-was-created",
              defaultMessage: "New Form was created",
            })
      );
      skipNavigationAlert.current = true;
      navigateToPage("orderForms");
      return;
    }
    if ("error" in response) {
      if (
        /* eslint-disable @typescript-eslint/no-explicit-any */
        (response.error as any).data?.errors?.[0]?.message ===
        "prefix_already_exists"
      ) {
        toast(
          t({
            id: "order-form-create-page.form-id-already-exist",
            defaultMessage: "Form Id already exist",
          }),
          { variant: "error" }
        );
        return;
      }
      if (
        /* eslint-disable @typescript-eslint/no-explicit-any */
        (response.error as any).data?.errors === "quota_limit_exceeded"
      ) {
        showPlanLimitsReachedAlert("orderForms");
        return;
      }
      toast(
        t({
          id: "feedback.something-went-wrong",
          defaultMessage: "Something went wrong",
        }),
        { variant: "error" }
      );
    }
  }, [
    allFields,
    createOrderForm,
    description,
    entityId,
    fields,
    formId,
    formState,
    image,
    navigateToPage,
    prefix,
    showPlanLimitsReachedAlert,
    t,
    title,
    toast,
    updateOrderForm,
  ]);

  return (
    <>
      <PageLoaderFull
        isActive={
          isCreateFormLoading || isUpdateFormLoading || isGetFormLoading
        }
        isTransparent
      />
      {(() => {
        if (isError)
          return <EmptyListContent type="error" onRefetch={fetchOrderForm} />;
        if (orderStep === OrderFormStep.form) return <OrderFormFieldsStep />;
        if (orderStep === OrderFormStep.details)
          return (
            <OrderFormTaskDetailsStep
              onOrderFormCreate={handleCreateOrderForm}
            />
          );
        return null;
      })()}
      <NavigationAlert
        isOpen={isNavigationAlertOpen}
        onRequestClose={() => {
          setIsNavigationAlertOpen(false);
          confirmNavigation.current = null;
        }}
        onConfirm={() => confirmNavigation.current?.()}
      />
    </>
  );
};

export const OrderFormWizardPage: React.FC = () => {
  const { formContext } = useTaskFormState();

  return (
    <OrderFormProvider>
      <TaskFormStateProvider context={formContext}>
        <OrderFormWizardPageContent />
      </TaskFormStateProvider>
    </OrderFormProvider>
  );
};

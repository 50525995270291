import { useAppVariant, usePrevious, useTranslations } from "@jugl-web/utils";
import isEqual from "lodash/isEqual";
import { FC, useCallback, useEffect, useRef } from "react";
import {
  CommentsInput,
  CommentsInputHandle,
  CommentsInputParams,
} from "../CommentsInput";
import { useTaskMentions } from "../../../tasks/hooks/useTaskMentions";
import { useTaskCommentsProvider } from "../../providers/TaskCommentsProvider";

interface TaskCommentInputProps {
  entityId: string;
  meId: string;
  apiAction?: string;
}

export const TaskCommentInput: FC<TaskCommentInputProps> = ({
  entityId,
  meId,
  apiAction,
}) => {
  const { manageComments$, taskId, commentAction, cancelCommentAction, task } =
    useTaskCommentsProvider();

  const previousCommentAction = usePrevious(commentAction);

  const { variant } = useAppVariant();
  const { t } = useTranslations();

  const $inputRef = useRef<CommentsInputHandle>(null);

  const { mentions } = useTaskMentions({
    entityId,
    meId,
    taskAssigneeIds: task?.assignees || [],
    taskCreatorId: task?.created_by || "",
  });

  const handleCommentSubmit: CommentsInputParams["onSubmit"] = useCallback(
    ({ body }) => {
      manageComments$.next({
        action: commentAction?.type === "edit" ? "update" : "create",
        taskId,
        data: { content: body, action: apiAction },
        comment: commentAction?.comment,
      });
      $inputRef.current?.clear();
      cancelCommentAction();
    },
    [
      apiAction,
      cancelCommentAction,
      commentAction?.comment,
      commentAction?.type,
      manageComments$,
      taskId,
    ]
  );

  useEffect(() => {
    if (!commentAction || !["edit", "reply"].includes(commentAction.type)) {
      return;
    }
    const isActionEqual = isEqual(commentAction, previousCommentAction);
    if (isActionEqual) {
      return;
    }
    if (
      commentAction.type === "edit" &&
      "changes" in commentAction.comment.action_details &&
      "content" in commentAction.comment.action_details.changes
    ) {
      $inputRef.current?.setValue({
        body:
          commentAction.comment.action_details.action === "guest_feedback"
            ? commentAction.comment.action_details.changes.content.feedback
            : commentAction.comment.action_details.changes.content,
      });
      $inputRef.current?.focus();
    }
  }, [commentAction, previousCommentAction]);

  return (
    <div className="flex w-full items-center gap-2">
      {commentAction && (
        <span>
          {commentAction.type === "edit" && (
            <span className="text-primary">
              {t({
                id: "common.edit",
                defaultMessage: "Edit",
              })}
              :
            </span>
          )}
        </span>
      )}
      <CommentsInput
        variant={variant}
        ref={$inputRef}
        onSubmit={handleCommentSubmit}
        mentions={mentions}
        placeholder={
          apiAction === "guest_comment_reply"
            ? t({
                id: "tasks-page.add-response-to-client",
                defaultMessage: "Add response to the client",
              })
            : t({
                id: "tasks-page.add-your-comments",
                defaultMessage: "Add your comments",
              })
        }
      />
    </div>
  );
};

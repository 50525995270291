import React, { useState } from "react";
import {
  Text,
  Menu,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { useTranslations } from "@jugl-web/utils";
import { getFirstSymbol } from "@jugl-web/utils/utils/getFirstSymbol";
import { ReactComponent as MinimizeIcon } from "./assets/minimize.svg";
import { ReactComponent as HamburgerIcon } from "./assets/hamburger.svg";
import { ToggleButton } from "../../../ToggleButton";
import { Info } from "../Info/Info";
import { CallAvatar } from "../../../CallAvatar";
import { ShareCallToChatSidebar } from "../ShareCallToChatSidebar/ShareCallToChatSidebar";

export const Header: React.FC<{
  formattedDuration: string;
  participants: string[];
  conferenceName: string;
  handleMinimize: () => void;
  handleToggleFullscreen?: () => void;
  variant?: "fullscreenScreenShare" | "default";
  screenSharingUserId?: string;
}> = ({
  formattedDuration,
  conferenceName,
  handleMinimize,
  handleToggleFullscreen,
  variant = "default",
  screenSharingUserId,
}) => {
  const [isShareToChatSidebarOpen, setShareToChatSidebarOpen] =
    useState<boolean>(false);
  const { t } = useTranslations();
  const nameLetters = conferenceName ? getFirstSymbol(conferenceName) : "";

  return (
    <div className="relative flex h-[80px] flex-row items-center gap-3 bg-[#3D3D3E] px-14 py-4">
      <ShareCallToChatSidebar
        isOpen={isShareToChatSidebarOpen}
        onClose={() => setShareToChatSidebarOpen(false)}
      />
      {variant === "fullscreenScreenShare" && (
        <div className="flex flex-row items-center gap-4">
          <CallAvatar
            size={32}
            userId={screenSharingUserId || ""}
            isSpeaking={false}
          />
          <Text
            variant="body2"
            className="max-w-[200px] overflow-hidden font-medium text-white"
          >
            <UserProfileName userId={screenSharingUserId || ""} />
          </Text>
          <Text variant="body3" className=" text-dark-200 overflow-hidden">
            {t({
              id: "call-conference.presenting",
              defaultMessage: "Presenting",
            })}
          </Text>
        </div>
      )}
      {variant === "default" && nameLetters && (
        <div className="text-dark flex h-[48px] w-[48px] items-center justify-center rounded-full bg-gradient-to-b from-[#CECED3] to-[#ECEFF0] text-base font-semibold uppercase leading-3">
          {nameLetters}
        </div>
      )}
      {variant === "default" && (
        <Info {...{ formattedDuration, conferenceName }} />
      )}
      <div className="absolute right-14 flex flex-row gap-2">
        <ToggleButton
          onClick={
            variant === "fullscreenScreenShare"
              ? handleToggleFullscreen
              : handleMinimize
          }
          isOn={false}
          onIcon={MinimizeIcon}
          offIcon={MinimizeIcon}
          noBgChange
          className="h-[48px] w-[48px]"
        />

        <Menu
          placement="bottom-end"
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger as={PlainButton} ref={triggerRef}>
              <ToggleButton
                isOn={false}
                onIcon={HamburgerIcon}
                offIcon={HamburgerIcon}
                noBgChange
                className="h-[48px] w-[48px]"
                hide={variant === "fullscreenScreenShare"}
              />
            </Trigger>
          )}
          className="py-2"
          sections={[
            [
              {
                id: "0",
                label: t({
                  id: "call-conference.share-call-to-chat",
                  defaultMessage: "Share Call to Chat",
                }),
                onSelect: () => setShareToChatSidebarOpen(true),
              },
            ],
          ]}
        />
      </div>
    </div>
  );
};

import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import {
  BottomCenteredDrawer,
  BottomCenteredDrawerProps,
} from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { FC, forwardRef, ReactNode } from "react";
import { cx, useTranslations } from "@jugl-web/utils";
import { ReactComponent as AddIcon } from "./assets/add.svg";

export const TaskFormDialog = (props: BottomCenteredDrawerProps) => (
  <BottomCenteredDrawer {...props} />
);

TaskFormDialog.Content = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <BottomCenteredDrawer.Content
    className={cx("bg-dark-100 flex flex-col gap-1", className)}
  >
    {children}
  </BottomCenteredDrawer.Content>
);

TaskFormDialog.TitleSection = ({ children }: { children: ReactNode }) => (
  <div className="bg-white px-8 pt-10 pb-8">{children}</div>
);

interface ChecklistSectionProps {
  children: ReactNode;
  className?: string;
  onAddItem?: () => void;
}

TaskFormDialog.ChecklistSection = (({ children, className, onAddItem }) => {
  const { t } = useTranslations();

  return (
    <div className={cx("flex grow flex-col gap-4 bg-white p-8", className)}>
      <div className="flex items-center justify-between">
        <span className="text-onyx text-sm leading-[21px]">
          {t({
            id: "tasks-page.subtask",
            defaultMessage: "Subtasks",
          })}
        </span>
        {onAddItem && (
          <Button
            color="grey"
            className="p-2 font-normal"
            iconEnd={<AddIcon />}
            onClick={onAddItem}
          >
            {t({
              id: "common.add",
              defaultMessage: "Add",
            })}
          </Button>
        )}
      </div>
      {children}
    </div>
  );
}) as FC<ChecklistSectionProps>;

TaskFormDialog.AttachmentsSection = forwardRef<
  HTMLDivElement,
  { children: ReactNode; onAddAttachment?: () => void }
>(({ children, onAddAttachment }, ref) => {
  const { t } = useTranslations();

  return (
    <div ref={ref} className="flex grow flex-col gap-4 bg-white p-8">
      <div className="flex items-center justify-between">
        <span className="text-onyx text-sm leading-[21px]">
          {t({
            id: "tasks-page.attachments",
            defaultMessage: "Attachments",
          })}
        </span>
        {onAddAttachment && (
          <Button
            color="grey"
            className="p-2 font-normal"
            iconEnd={<AddIcon />}
            onClick={onAddAttachment}
          >
            {t({
              id: "common.add",
              defaultMessage: "Add",
            })}
          </Button>
        )}
      </div>
      {children}
    </div>
  );
});

TaskFormDialog.Actions = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <BottomCenteredDrawer.Actions
    className={cx("flex items-center justify-center gap-3.5", className)}
  >
    {children}
  </BottomCenteredDrawer.Actions>
);

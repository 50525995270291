import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { InventoryItemsPage } from "./InventoryItemsPage";
import { InventoryDiscountsPage } from "./InventoryDiscountsPage";
import { InventoryWarehousesPage } from "./InventoryWarehousesPage";
import { InventoryItemFormPage } from "./InventoryItemFormPage";

export type InventoryPagesNavigationParams = {
  inventoryItems: PageNavParams;
  inventoryDiscounts: PageNavParams;
  inventoryWarehouses: PageNavParams;
  inventoryItemForm: PageNavParams;
  inventoryItemEditForm: PageNavParams<{
    id: string;
  }>;
};

export const inventoryPagesNavigationConfig: ModulePagesConfig<InventoryPagesNavigationParams> =
  {
    inventoryItems: {
      element: <InventoryItemsPage />,
      path: "inventory/items",
      isPrivate: true,
      isAdminRequired: true,
    },
    inventoryItemForm: {
      element: <InventoryItemFormPage />,
      path: "/inventory/items/create",
      isPrivate: true,
      isAdminRequired: true,
    },
    inventoryItemEditForm: {
      element: <InventoryItemFormPage />,
      path: "/inventory/items/edit/:id",
      isPrivate: true,
      isAdminRequired: true,
    },
    inventoryDiscounts: {
      element: <InventoryDiscountsPage />,
      path: "inventory/discounts",
      isPrivate: true,
      isAdminRequired: true,
    },
    inventoryWarehouses: {
      element: <InventoryWarehousesPage />,
      path: "inventory/warehouses",
      isPrivate: true,
      isAdminRequired: true,
    },
  };

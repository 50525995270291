import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import { cx, isoToLocalDate, useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { FC, useMemo } from "react";
import { useUserGeneralProfile } from "../../../users/hooks/useUserGeneralProfile";

export interface TaskCreatorProps {
  entityId: string;
  userId: string;
  createdAt: string;
  className?: string;
}

export const TaskCreator: FC<TaskCreatorProps> = ({
  entityId,
  userId,
  createdAt,
  className,
}) => {
  const { safeProfile } = useUserGeneralProfile({ entityId, userId });

  const { t } = useTranslations();
  const { localeAwareFormat } = useFormattedDate();

  const [createdAtTime, createdAtDate] = useMemo(
    () => [
      localeAwareFormat(isoToLocalDate(createdAt), "h:mm a"),
      localeAwareFormat(isoToLocalDate(createdAt), "MMM d, yy"),
    ],
    [createdAt, localeAwareFormat]
  );

  const isCreatedByOrderForm =
    userId === "00000000-0000-0000-0000-000000000000";

  return (
    <div className={cx("flex items-center gap-2", className)}>
      {isCreatedByOrderForm ? (
        <div className="inline-block rounded bg-[#F2F2F4] px-2 py-1 text-xs text-[#828282]">
          {t({
            id: "tasks-page.created-by-order-form",
            defaultMessage: "Created by order form",
          })}
        </div>
      ) : (
        <>
          <Avatar
            size="xs"
            username={safeProfile.displayName}
            imageUrl={safeProfile.avatar}
          />
          <span className="text-dark text-xs">{safeProfile.displayName}</span>
        </>
      )}
      <span className="text-grey-background text-xs">
        {createdAtTime} | {createdAtDate}
      </span>
    </div>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { useTranslations } from "@jugl-web/utils";
import { OrderFormFieldType } from "@jugl-web/rest-api/orders/types";
import { OrderFormField } from "../OrderFormField";
import { useOrderForm } from "../../../../providers/OrderFormProvider";
import { useOrderFormValidators } from "../../hooks/useOrderFormValidators";
import { ServiceItem } from "./components/ServiceItem";
import { ManageServiceDrawer } from "./components/ManageServiceDrawer";

export const ServicesField: React.FC<{ id: string; index: number }> = ({
  id,
  index,
}) => {
  const { t } = useTranslations();
  const { getFieldById, updateFieldById, triggerValidate, deleteFieldById } =
    useOrderForm();
  const [shouldShowError, setShouldShowError] = useState(false);
  const {
    isRequired,
    property: { value, type },
  } = getFieldById(id);
  const { checkIsServicesFieldValid } = useOrderFormValidators();

  if (type !== OrderFormFieldType.services)
    throw new Error("Bad order field type");

  const [isExpanded, setIsExpanded] = useState(
    !checkIsServicesFieldValid(value)
  );

  const [isManageServicesDrawerOpen, setIsManageServicesDrawerOpen] = useState(
    !value.items.length
  );

  const handleValidateField = useCallback(() => {
    setShouldShowError(!checkIsServicesFieldValid(value));
  }, [value, checkIsServicesFieldValid]);

  useEffect(() => {
    const subscription = triggerValidate.subscribe(() => {
      handleValidateField();
    });
    return () => subscription.unsubscribe();
  }, [triggerValidate, handleValidateField]);

  useEffect(() => {
    if (shouldShowError) {
      handleValidateField();
    }
  }, [value, shouldShowError, handleValidateField]);

  return (
    <>
      <OrderFormField
        id={id}
        index={index}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(true)}
        onClickAway={() => {
          if (checkIsServicesFieldValid(value)) {
            setIsExpanded(false);
          }
        }}
        onAdditionalAction={() => setIsManageServicesDrawerOpen(true)}
        isError={shouldShowError && value.items.length === 0}
      >
        {isExpanded ? (
          <>
            <TextInput
              label={t({
                id: "order-form-wizard-page.services-field-label",
                defaultMessage: "Services field label",
              })}
              placeholder={t({
                id: "order-form-wizard-page.enter-services-field-label",
                defaultMessage: "Enter services field label",
              })}
              isRequired
              onChange={(e) =>
                updateFieldById({
                  id,
                  type,
                  value: { ...value, label: e.target.value.trimStart() },
                })
              }
              value={value.label}
              isInvalid={shouldShowError && !value.label}
              autoFocus
              errorMessage={
                shouldShowError && !value.label
                  ? t({
                      id: "order-form-wizard-page.field-cant-be-empty",
                      defaultMessage: "This field cannot be left empty",
                    })
                  : undefined
              }
            />
          </>
        ) : (
          <div>
            <span className="text-dark-900 font-secondary text-15px">
              {value.label}
            </span>
            {isRequired && (
              <span className="font-secondary text-gradients-danger">*</span>
            )}
          </div>
        )}
        {value.items.map((item) => (
          <ServiceItem
            id={item}
            key={item}
            onDelete={
              value.items.length > 1 && isExpanded
                ? () =>
                    updateFieldById({
                      id,
                      type,
                      value: {
                        ...value,
                        items: value.items.filter((el) => el !== item),
                      },
                    })
                : undefined
            }
          />
        ))}
      </OrderFormField>
      <ManageServiceDrawer
        isOpen={isManageServicesDrawerOpen}
        items={value.items}
        onRequestClose={(items) => {
          setIsManageServicesDrawerOpen(false);
          if (!items?.length) {
            deleteFieldById(id);
          } else {
            updateFieldById({
              id,
              type,
              value: {
                ...value,
                items,
              },
            });
          }
        }}
      />
    </>
  );
};

import { currencyCodeToSymbol } from "./currencyCodeToSymbol";

export const priceDisplay = (amountInCents: number, currencyCode: string) => {
  const formatter = new Intl.NumberFormat(
    Intl.DateTimeFormat().resolvedOptions().locale,
    {
      style: "decimal",
      currency: currencyCode,
      minimumFractionDigits: 2,
      currencyDisplay: "narrowSymbol",
    }
  );

  return (
    <span>
      <span className="font-primary">{currencyCodeToSymbol(currencyCode)}</span>
      {formatter.format(
        amountInCents !== 0 ? amountInCents / 100 : amountInCents
      )}
    </span>
  );
};

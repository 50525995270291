import { Avatar, BoardAvatar } from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import { useTranslations } from "@jugl-web/utils";
import { colord } from "colord";
import { FC, ReactNode, useMemo } from "react";
import Highlighter from "react-highlight-words";
import { getBoardFieldColors } from "../TaskCard/utils";
import { ReactComponent as CustomDateFieldIcon } from "./assets/custom-date-field.svg";
import { ReactComponent as CustomDropdownFieldIcon } from "./assets/custom-dropdown-field.svg";
import { ReactComponent as CustomNumberFieldIcon } from "./assets/custom-number-field.svg";
import { ReactComponent as CustomTextFieldIcon } from "./assets/custom-text-field.svg";
import { TaskTemplateCardContainer } from "./components/TaskTemplateCardContainer";
import { TaskTemplateCardFieldComponent } from "./components/TaskTemplateCardFieldComponent";
import {
  TaskTemplateCardCustomField,
  TaskTemplateCardField,
  TaskTemplateCardProps,
} from "./types";

const MAX_VISIBLE_FIELDS = 5;

const customFieldTypeToIconMap: Record<
  TaskTemplateCardCustomField["type"],
  ReactNode
> = {
  text: <CustomTextFieldIcon />,
  number: <CustomNumberFieldIcon />,
  date: <CustomDateFieldIcon />,
  dropdown: <CustomDropdownFieldIcon />,
};

export const TaskTemplateCard: FC<TaskTemplateCardProps> = ({
  title,
  avatar,
  checklistItemsCount,
  dueDateInDays,
  attachmentsCount,
  board,
  label,
  customFields,
  menuConfig,
  highlightedText,
  className,
  shouldShowMoreFieldsTooltip,
  ...divProps
}) => {
  const { t } = useTranslations();

  const fields = useMemo<TaskTemplateCardField[]>(() => {
    const output: TaskTemplateCardField[] = [];

    if (board) {
      output.push({
        id: "board",
        text: board.name,
        icon: <BoardAvatar name={board.name} color={board.color} size="xs" />,
        ...getBoardFieldColors(board.color),
      });
    }

    if (label) {
      output.push({
        id: "label",
        text: t(
          {
            id: "tasks-page.label-with-text",
            defaultMessage: "Label: {text}",
          },
          { text: label.text }
        ),
        textColor: label.color,
        backgroundColor: colord(label.color).alpha(0.12).toHex(),
      });
    }

    if (dueDateInDays) {
      output.push({
        id: "due-date",
        text: t(
          {
            id: "common.due-in-in-days",
            defaultMessage:
              "Due in: {days, plural, one {# day} other {# days}}",
          },
          { days: dueDateInDays }
        ),
      });
    }

    if (checklistItemsCount > 0) {
      output.push({
        id: "checklist",
        text: t(
          {
            id: "common.subtasks-with-count",
            defaultMessage: "Subtasks: {count}",
          },
          { count: checklistItemsCount }
        ),
      });
    }

    if (attachmentsCount > 0) {
      output.push({
        id: "attachments",
        text: t(
          {
            id: "common.attachments-with-count",
            defaultMessage: "Attachments: {count}",
          },
          { count: attachmentsCount }
        ),
      });
    }

    output.push(
      ...customFields.map<TaskTemplateCardField>((field) => ({
        id: field.id,
        text: field.text,
        icon: customFieldTypeToIconMap[field.type],
      }))
    );

    return output;
  }, [
    attachmentsCount,
    board,
    checklistItemsCount,
    customFields,
    dueDateInDays,
    label,
    t,
  ]);

  const [visibleFields, overflowingFields] = useMemo(
    () => [
      fields.slice(0, MAX_VISIBLE_FIELDS),
      fields.slice(MAX_VISIBLE_FIELDS),
    ],
    [fields]
  );

  const hasOverflowingFields = overflowingFields.length > 0;

  return (
    <TaskTemplateCardContainer
      gradientColor="primary"
      avatarSlot={<Avatar className="h-10 w-10" {...avatar} />}
      menuConfig={menuConfig}
      className={className}
      {...divProps}
    >
      <div className="flex flex-col gap-2.5">
        <span className="text-dark mb-2 truncate text-sm font-medium leading-3">
          {highlightedText ? (
            <Highlighter
              autoEscape
              highlightClassName="text-primary bg-primary-50"
              highlightTag="span"
              textToHighlight={title}
              searchWords={[highlightedText]}
            />
          ) : (
            title
          )}
        </span>
        <div className="flex flex-wrap gap-2">
          {visibleFields.map((field) => (
            <TaskTemplateCardFieldComponent
              key={field.id}
              title={field.text}
              {...field}
            />
          ))}
          {hasOverflowingFields && (
            <Tooltip
              placement="bottom"
              renderTrigger={({ ref, props }) => (
                <TaskTemplateCardFieldComponent
                  ref={ref}
                  id="more-count"
                  text={t(
                    {
                      id: "common.more-count",
                      defaultMessage: "+{count} more",
                    },
                    { count: fields.length - MAX_VISIBLE_FIELDS }
                  )}
                  {...props}
                />
              )}
              isDisabled={!shouldShowMoreFieldsTooltip}
              className="px-4 py-3"
            >
              <div className="flex flex-col gap-3">
                {overflowingFields.map((field) => (
                  <div key={field.id} className="flex items-center gap-3">
                    {field.icon && (
                      <span className="shrink-0 leading-[0]">{field.icon}</span>
                    )}
                    <span className="truncate text-sm leading-3 text-white">
                      {field.text}
                    </span>
                  </div>
                ))}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </TaskTemplateCardContainer>
  );
};

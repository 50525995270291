import React from "react";
import { ReactComponent as ImageIcon } from "./assets/image.svg";

export const OrderNameTile: React.FC<{
  title: string;
  img?: string;
}> = ({ title, img }) => (
  <div className="flex items-center gap-3">
    {img ? (
      <div className="h-8 w-8 shrink-0 overflow-hidden rounded-[10px]">
        <img src={img} alt={title} className="h-full w-full object-cover" />
      </div>
    ) : (
      <div className="border-grey-400 flex h-8 w-8 shrink-0 items-center justify-center rounded-[10px] border border-solid bg-[#F2F2F4]">
        <ImageIcon />
      </div>
    )}
    <span className="text-dark-800 font-secondary font-medium leading-[140%] tracking-[0.16px]">
      {title}
    </span>
  </div>
);

import React, { useState, useMemo, useCallback } from "react";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import {
  Button,
  EmptyListContent,
  LoadingAnimation,
  PlainButton,
} from "@jugl-web/ui-components";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import Lottie from "react-lottie";
import { InventoryItem, useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useEffectOnce } from "react-use";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { InventoryTabsLayout } from "../components/InventoryTabsLayout";
import emptyInventoryAnimation from "./assets/empty-inventory-animation.json";
import { InventoryItemDetailsSidebar } from "./components/InventoryItemDetailsSidebar";
import { InventoryItemsTable } from "./components/InventoryItemsTable/InventoryItemsTable";
import { useInventoryTableCheckboxState } from "../hooks/useInventoryTableCheckboxState";
import { InventoryTableProvider } from "./InventoryTableContext";
import { ReactComponent as RedDeleteIcon } from "./assets/delete-items.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { ConfirmRemoveItemsAlert } from "./components/ConfirmRemoveItemsAlert";

export const InventoryItemsPage: React.FC = () => {
  const [itemIdToOpen, setItemIdToOpen] = useState<string>();

  const { t } = useTranslations();

  const { navigateToPage } = useNavigation();
  const { inventoryApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();

  const [isFetchingInternal, setIsFetchingInternal] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [items, setItems] = useState<InventoryItem[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isRemoveItemsConfirmAlertOpen, setIsRemoveItemsConfirmAlertOpen] =
    useState(false);

  const [loadMoreItems, { isLoading }] =
    inventoryApi.useLazyGetInventoryItemsListQuery({});

  const itemIds = useMemo(() => items.map(({ id }) => id), [items]);
  const {
    checkedList,
    handleCheckboxChange,
    numbersOfChecked,
    handleClearAll,
  } = useInventoryTableCheckboxState({
    ids: itemIds || [],
  });
  const { searchQuery, inputProps, reset } = useSearchInput({
    debounce: 500,
    onSearch: (value) => {
      if (value) {
        loadMoreInventoryItems(true, false, value);
      }
      if (value === "" || !value) {
        loadMoreInventoryItems(true, true, undefined);
      }
    },
  });

  useEffectOnce(() => {
    setIsFetchingInternal(true);
    const loadItems = async () => {
      const response = await loadMoreItems({
        params: {
          page: 1,
          page_size: 10,
        },
        entityId: entity.id,
      });
      if (response?.data?.data) {
        setItems(response.data?.data);
        if (response.data.total_pages === 1) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      }
      setIsInitialized(true);
      setIsFetchingInternal(false);
    };
    loadItems();
  });

  const loadMoreInventoryItems = useCallback(
    async (
      shouldReset?: boolean,
      clearSearchValue?: boolean,
      searchValue?: string
    ) => {
      if (
        !shouldReset &&
        (isLoading || !hasMore || isFetchingInternal || !isInitialized)
      ) {
        return;
      }
      setIsFetchingInternal(true);

      if (shouldReset) {
        setPage(1);
        setItems([]);
        setHasMore(true);
      }

      const response = await loadMoreItems({
        params: {
          page: shouldReset ? 1 : page + 1,
          page_size: 10,
          search: clearSearchValue
            ? undefined
            : searchValue || searchQuery || undefined,
        },
        entityId: entity.id,
      });
      if (response?.data?.data) {
        const { data } = response.data;
        if (response.data.page_number === response.data.total_pages) {
          setHasMore(false);
        }
        setItems((prev) => {
          const uniqueItems = new Map(
            [...prev, ...data].map((item) => [item.id, item])
          );
          return Array.from(uniqueItems.values());
        });
        setPage(response.data.page_number);
      }
      setIsFetchingInternal(false);
    },
    [
      isLoading,
      hasMore,
      isFetchingInternal,
      isInitialized,
      loadMoreItems,
      page,
      searchQuery,
      entity.id,
    ]
  );

  const idsOfItemsToRemove = useMemo(
    () => Object.keys(checkedList).filter((key) => checkedList[key]),
    [checkedList]
  );

  const handleRemoveSingleItem = (itemId: string) => {
    setItems((prev) => prev.filter((item) => item.id !== itemId));
  };

  const handleRemoveItems = () => {
    const itemsToRemove = Object.keys(checkedList).filter(
      (key) => checkedList[key]
    );
    setItems((prev) => prev.filter((item) => !itemsToRemove.includes(item.id)));
    handleClearAll();
  };

  return (
    <InventoryTableProvider>
      <InventoryTabsLayout
        selectedTab="inventory-items"
        rightContent={
          <div className="flex items-center gap-4">
            <SearchInput
              className="w-[280px]"
              variant="filled"
              color="white"
              onClear={reset}
              {...inputProps}
            />
            {numbersOfChecked === 0 && (
              <Button
                className="h-10"
                iconEnd={<AddIcon />}
                onClick={() => navigateToPage("inventoryItemForm")}
              >
                {t({
                  id: "inventory-items-page.add-item/service",
                  defaultMessage: "Add Item / service",
                })}
              </Button>
            )}
            {numbersOfChecked > 0 && (
              <Button
                iconStart={<RedDeleteIcon />}
                className="font-secondary text-dark h-10 font-normal"
                color="white"
                onClick={() => {
                  setIsRemoveItemsConfirmAlertOpen(true);
                }}
              >
                {t(
                  {
                    id: "inventory-warehouses-page.delete-item",
                    defaultMessage:
                      "Delete {count} {count, plural, one {Item} other {Items}}",
                  },
                  {
                    count: numbersOfChecked,
                  }
                )}
              </Button>
            )}
            {numbersOfChecked > 0 && (
              <PlainButton
                onClick={() => {
                  handleClearAll();
                }}
              >
                <CloseIcon />
              </PlainButton>
            )}
          </div>
        }
      >
        {!isInitialized && (
          <div className="flex h-full w-full items-center justify-center">
            <LoadingAnimation />
          </div>
        )}
        {items.length === 0 &&
          !isLoading &&
          isInitialized &&
          !isFetchingInternal &&
          !searchQuery && (
            <EmptyListContent
              type="custom"
              className="w-[400px]"
              customImg={
                <Lottie
                  options={{ animationData: emptyInventoryAnimation }}
                  height={300}
                  width={300}
                />
              }
              customTitle={t({
                id: "inventory-items-page.tell-us-what-you-got",
                defaultMessage: "Tell us what you got ✨",
              })}
              customSubtitle={t({
                id: "inventory-items-page.services-benefits-message",
                defaultMessage:
                  "Indicate what Services or Goods you can provide to you're Clients",
              })}
              customButton={{
                text: t({
                  id: "inventory-items-page.add-item/service",
                  defaultMessage: "Add Item / service",
                }),
                iconEnd: <AddIcon />,
                fullWidth: false,
                className: "h-10",
                onClick: () => navigateToPage("inventoryItemForm"),
              }}
            />
          )}
        {(isInitialized && (items.length > 0 || searchQuery) && (
          <div>
            <InventoryItemsTable
              data={items}
              searchQuery={searchQuery}
              checkedList={checkedList}
              handleCheckboxChange={handleCheckboxChange}
              onItemDetailsOpen={setItemIdToOpen}
              onItemDelete={handleRemoveSingleItem}
              onReachEnd={loadMoreInventoryItems}
              isLoading={isFetchingInternal}
            />
          </div>
        )) ||
          ""}
        {isInitialized && (isLoading || isFetchingInternal) && (
          <LoadingAnimation />
        )}

        <InventoryItemDetailsSidebar
          isOpen={itemIdToOpen !== undefined}
          onClose={() => setItemIdToOpen(undefined)}
          onRemove={(itemId) => {
            setItems((prev) => prev.filter((item) => item.id !== itemId));
          }}
          itemId={itemIdToOpen}
        />
      </InventoryTabsLayout>
      <ConfirmRemoveItemsAlert
        isOpen={isRemoveItemsConfirmAlertOpen}
        itemIds={idsOfItemsToRemove}
        onRemove={() => handleRemoveItems()}
        onClose={() => setIsRemoveItemsConfirmAlertOpen(false)}
      />
    </InventoryTableProvider>
  );
};

import {
  InteractiveContainer,
  PlainButton,
  Tooltip,
} from "@jugl-web/ui-components";
import {
  ClickAwayListener,
  cx,
  getUniqueId,
  useTranslations,
} from "@jugl-web/utils";
import React, { PropsWithChildren, useMemo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import { OrderFormFieldType } from "@jugl-web/rest-api/orders/types";
import { ReactComponent as BinIcon } from "./assets/bin.svg";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";
import { ReactComponent as ReorderIcon } from "./assets/reorder.svg";
import { ReactComponent as TextIcon } from "./assets/text.svg";
import { ReactComponent as ParagraphIcon } from "./assets/paragraph.svg";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as DateIcon } from "./assets/date.svg";
import { ReactComponent as DropdownIcon } from "./assets/dropdown.svg";
import { ReactComponent as SingleIcon } from "./assets/single.svg";
import { ReactComponent as MultiIcon } from "./assets/multi.svg";
import { ReactComponent as OtherIcon } from "./assets/other.svg";
import { ReactComponent as ServicesIcon } from "./assets/services.svg";
import { useOrderForm } from "../../../../providers/OrderFormProvider";

export type OrderFormFieldProps = PropsWithChildren<{
  id: string;
  index: number;
  isExpanded: boolean;
  isError?: boolean;
  onClickAway: () => void;
  onClick: () => void;
  onAdditionalAction?: () => void;
}>;

export const OrderFormField: React.FC<OrderFormFieldProps> = ({
  id,
  index,
  isExpanded,
  isError,
  children,
  onClickAway,
  onClick,
  onAdditionalAction,
}) => {
  const { t } = useTranslations();
  const {
    getFieldById,
    changeRequireById,
    copyFieldById,
    deleteFieldById,
    updateFieldById,
  } = useOrderForm();
  const {
    isRequired,
    property: { value, type },
  } = getFieldById(id);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      data: {
        index,
      },
    });

  const { icon, label, submenu } = useMemo(() => {
    switch (type) {
      case OrderFormFieldType.text:
        return {
          icon: <TextIcon />,
          label: t({
            id: "order-form-wizard-page.short-text",
            defaultMessage: "Short text",
          }),
        };
      case OrderFormFieldType.paragraph:
        return {
          icon: <ParagraphIcon />,
          label: t({
            id: "order-form-wizard-page.long-text-answer",
            defaultMessage: "Long text answer",
          }),
        };
      case OrderFormFieldType.date:
        return {
          icon: <DateIcon />,
          label: t({
            id: "order-form-wizard-page.date",
            defaultMessage: "Date",
          }),
        };
      case OrderFormFieldType.services:
        return {
          icon: <ServicesIcon />,
          label: t({
            id: "order-form-wizard-page.services",
            defaultMessage: "Services",
          }),
          submenu: [
            {
              id: "manage-services",
              icon: <AddIcon />,
              label: t({
                id: "order-form-wizard-page.manage-services",
                defaultMessage: "Manage Services",
              }),
              onClick: onAdditionalAction,
            },
          ],
        };
      case OrderFormFieldType.dropdown:
      case OrderFormFieldType.singleChoice:
      case OrderFormFieldType.multiChoice: {
        const addOptionSubmenuElement = {
          id: "add",
          icon: <AddIcon />,
          label: t({
            id: "order-form-wizard-page.add-option",
            defaultMessage: "Add Option",
          }),
          onClick: () => {
            updateFieldById({
              id,
              type,
              value: {
                ...value,
                items: [
                  ...value.items,
                  {
                    id: getUniqueId(),
                    label: t(
                      {
                        id: "order-form-wizard-page.option-number",
                        defaultMessage: "Option {number}",
                      },
                      { number: value.items.length + 1 }
                    ),
                  },
                ],
              },
            });
          },
        };

        const addOtherSubmenuElement = {
          id: "add-other",
          icon: <OtherIcon />,
          label: t({
            id: "order-form-wizard-page.add-other",
            defaultMessage: `Add "Other"`,
          }),
          onClick: () => {
            updateFieldById({
              id,
              type,
              value: {
                ...value,
                hasOtherOption: true,
              },
            });
          },
        };

        if (type === OrderFormFieldType.dropdown) {
          return {
            icon: <DropdownIcon />,
            label: t({
              id: "order-form-wizard-page.dropdown",
              defaultMessage: "Dropdown",
            }),
            submenu: [addOptionSubmenuElement],
          };
        }

        if (type === OrderFormFieldType.singleChoice) {
          return {
            icon: <SingleIcon />,
            label: t({
              id: "order-form-wizard-page.single-choice",
              defaultMessage: "Single choice",
            }),
            submenu: [
              addOptionSubmenuElement,
              ...(!value.hasOtherOption ? [addOtherSubmenuElement] : []),
            ],
          };
        }

        return {
          icon: <MultiIcon />,
          label: t({
            id: "order-form-wizard-page.multi-choice",
            defaultMessage: "Multi choice",
          }),
          submenu: [
            addOptionSubmenuElement,
            ...(!value.hasOtherOption ? [addOtherSubmenuElement] : []),
          ],
        };
      }
      default:
        throw new Error(`Unsupported order field type: ${type}`);
    }
  }, [type, value, updateFieldById, id, t, onAdditionalAction]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        style={{
          transform: CSS.Translate.toString(transform),
          transition,
        }}
        ref={setNodeRef}
        onClick={onClick}
      >
        <div
          className={cx(
            "focus-within:border-primary-300 relative flex flex-col gap-4 overflow-hidden rounded-xl border border-l-4 border-solid border-white bg-white p-6 transition-all",
            isError &&
              "focus-within:border-gradients-danger border-gradients-danger"
          )}
        >
          <div className="flex items-center gap-2">
            {icon}
            <span className="text-dark font-secondary leading-4">{label}</span>
          </div>
          {children}
          {isExpanded && (
            <div
              className={cx(
                "flex items-center gap-5",
                submenu ? "justify-between" : "justify-end"
              )}
            >
              {submenu && (
                <div className="flex items-center gap-5">
                  {submenu.map((el) => (
                    <InteractiveContainer
                      key={el.id}
                      onClick={el.onClick}
                      className="bg-primary-50 flex h-8 items-center gap-2.5 rounded-lg px-2.5 transition-colors hover:bg-[#CCE6F8]"
                    >
                      {el.icon}
                      <span className="text-dark font-secondary text-sm leading-[21px]">
                        {el.label}
                      </span>
                    </InteractiveContainer>
                  ))}
                </div>
              )}
              <div className="flex items-center gap-5">
                <Tooltip
                  placement="top"
                  renderTrigger={({ props, ref }) => (
                    <PlainButton
                      onClick={() => deleteFieldById(id)}
                      className="hover:bg-grey-200 rounded-lg p-1.5 transition-colors"
                      ref={ref}
                      {...props}
                    >
                      <BinIcon />
                    </PlainButton>
                  )}
                >
                  {t({
                    id: "common.delete",
                    defaultMessage: "Delete",
                  })}
                </Tooltip>
                {type !== OrderFormFieldType.services && (
                  <Tooltip
                    placement="top"
                    renderTrigger={({ props, ref }) => (
                      <PlainButton
                        onClick={() => copyFieldById(id)}
                        className="hover:bg-grey-200 rounded-lg p-1.5 transition-colors"
                        ref={ref}
                        {...props}
                      >
                        <CopyIcon />
                      </PlainButton>
                    )}
                  >
                    {t({
                      id: "common.copy",
                      defaultMessage: "Copy",
                    })}
                  </Tooltip>
                )}
                <InteractiveContainer
                  className="hover:bg-grey-200 mx-4 flex items-center gap-1.5 rounded-lg p-1.5 transition-colors"
                  onClick={() => changeRequireById(id)}
                >
                  <span className="text-dark font-secondary text-sm leading-[21px]">
                    {t({
                      id: "order-form-wizard-page.required-field",
                      defaultMessage: "Required Field",
                    })}
                  </span>
                  <Switch isChecked={!!isRequired} />
                </InteractiveContainer>
              </div>
            </div>
          )}
          <PlainButton
            className="absolute top-2.5 right-2.5"
            {...listeners}
            {...attributes}
          >
            <ReorderIcon />
          </PlainButton>
        </div>
      </div>
    </ClickAwayListener>
  );
};

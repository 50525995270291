import { useSubscriptionInfo } from "@jugl-web/domain-resources/entities/hooks/useSubscriptionInfo";
import { TaskArchiveConfirmationDialog } from "@jugl-web/domain-resources/tasks/components/TaskArchiveConfirmationDialog";
import { TaskDeleteConfirmationDialog } from "@jugl-web/domain-resources/tasks/components/TaskDeleteConfirmationDialog";
import { CUSTOMER_FIELD_BUTTON_ID } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel/components/CustomerField";
import { useDuplicateTask } from "@jugl-web/domain-resources/tasks/hooks/useDuplicateTask";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { Menu, MenuItem } from "@jugl-web/ui-components/cross-platform/Menu";
import { PageLoaderFull } from "@jugl-web/ui-components/cross-platform/PageLoaderFull";
import { TaskHoverableButton } from "@jugl-web/ui-components/cross-platform/tasks/TaskHoverableButton";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useLoadedMe } from "@web-src/features/app/hooks/useMe";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useCallback, useMemo, useState } from "react";
import { ReactComponent as ArchiveIcon } from "../../assets/archive.svg";
import { ReactComponent as BinIcon } from "../../assets/bin.svg";
import { ReactComponent as CustomerUpdateIcon } from "../../assets/customer-update.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/duplicate.svg";
import { ReactComponent as HorizDotsIcon } from "../../assets/horiz-dots.svg";
import { useLoadedTaskDetailsContext } from "../../hooks/useTaskDetailsContext";

export const TaskDetailsMoreMenu = () => {
  const { task, tasksSource, navigateBackToTaskList } =
    useLoadedTaskDetailsContext();

  const { entityId } = useEntitySelectedProvider();
  const { meId } = useLoadedMe();

  const [
    isArchiveTaskConfirmationDialogOpen,
    setIsArchiveTaskConfirmationDialogOpen,
  ] = useState(false);

  const [
    isDeleteTaskConfirmationDialogOpen,
    setIsDeleteTaskConfirmationDialogOpen,
  ] = useState(false);

  const permissions = useTaskPermissions({ task, entityId, meId });

  const { isModuleAvailable } = useSubscriptionInfo({ entityId });

  const { tasksApi } = useRestApiProvider();

  const [deleteTask] = tasksApi.useDeleteTaskMutation();
  const [sendUpdateToCustomer] = tasksApi.useSendUpdateToCustomerMutation();

  const { toast } = useToast();
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();

  const {
    duplicateTask,
    showTaskDuplicatedToast,
    isLoading: isDuplicatingTask,
  } = useDuplicateTask({
    entityId,
    onNavigate: (duplicatedTaskId) =>
      navigateToPage(
        "taskDetails",
        { taskId: duplicatedTaskId },
        { state: { source: tasksSource } }
      ),
  });

  const isSendUpdateToCustomerOptionVisible = useMemo(
    () =>
      isModuleAvailable(SubscriptionPlanModuleId.clients) &&
      (!!task?.cust_id || permissions.canEditCustomer),
    [task, permissions, isModuleAvailable]
  );

  const handleSendUpdateToCustomer = useCallback(async () => {
    if (task.cust_id) {
      const response = await sendUpdateToCustomer({
        entityId,
        taskId: task.id,
      });

      if ("data" in response) {
        toast(
          t({
            id: "tasks-page.update-sent-to-customer",
            defaultMessage: "Update was shared with Customer",
          })
        );
      }
      return;
    }

    document
      .querySelector<HTMLElement>(`[data-id='${CUSTOMER_FIELD_BUTTON_ID}']`)
      ?.click();

    toast(
      t({
        id: "tasks-page.assign-customer-to-send-update",
        defaultMessage: "To send update, assign Customer to the Task first",
      }),
      { variant: "error" }
    );
  }, [entityId, sendUpdateToCustomer, t, task.cust_id, task.id, toast]);

  const handleDuplicateTask = useCallback(async () => {
    if (!task) return;

    try {
      const duplicatedTask = await duplicateTask(task.id, tasksSource);
      showTaskDuplicatedToast(duplicatedTask.id);
    } catch {
      toast(
        t({
          id: "feedback.failed-duplicate-task",
          defaultMessage: "Failed to duplicate the task",
        }),
        { variant: "error" }
      );
    }
  }, [duplicateTask, showTaskDuplicatedToast, t, task, tasksSource, toast]);

  const handleRemoveTask = useCallback(async () => {
    const response = await deleteTask({
      entityId,
      taskId: task.id,
      source: tasksSource,
    });

    if (!("data" in response)) {
      return;
    }

    toast(
      t({
        id: "feedback.task-deleted",
        defaultMessage: "Task has been deleted",
      })
    );

    setIsDeleteTaskConfirmationDialogOpen(false);
    navigateBackToTaskList();
  }, [
    deleteTask,
    entityId,
    navigateBackToTaskList,
    t,
    task.id,
    tasksSource,
    toast,
  ]);

  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        id: "send-update-to-customer",
        label: t({
          id: "tasks-page.send-update-to-the-customer",
          defaultMessage: "Send update to the customer",
        }),
        icon: <CustomerUpdateIcon />,
        isHidden:
          !isSendUpdateToCustomerOptionVisible ||
          !permissions.canSendUpdateToCustomer ||
          task.is_self,
        onSelect: (_, close) => {
          handleSendUpdateToCustomer();
          close();
        },
      },
      {
        id: "duplicate-task",
        label: t({
          id: "tasks-page.duplicate-task",
          defaultMessage: "Duplicate task",
        }),
        icon: <DuplicateIcon />,
        isHidden: !permissions.canDuplicateTask,
        onSelect: (_, close) => {
          close();
          handleDuplicateTask();
        },
      },
      {
        id: "archive-task",
        label: t({
          id: "tasks-page.archive-task",
          defaultMessage: "Archive task",
        }),
        icon: <ArchiveIcon />,
        isHidden: !permissions.canArchiveTask,
        onSelect: (_, close) => {
          close();
          setIsArchiveTaskConfirmationDialogOpen(true);
        },
      },
      {
        id: "delete-task",
        label: t({
          id: "tasks-page.delete-task",
          defaultMessage: "Delete task",
        }),
        icon: <BinIcon />,
        isHidden: !permissions.canDeleteTask,
        onSelect: (_, close) => {
          close();
          setIsDeleteTaskConfirmationDialogOpen(true);
        },
      },
    ],
    [
      handleDuplicateTask,
      handleSendUpdateToCustomer,
      isSendUpdateToCustomerOptionVisible,
      permissions.canArchiveTask,
      permissions.canDeleteTask,
      permissions.canDuplicateTask,
      permissions.canSendUpdateToCustomer,
      t,
      task.is_self,
    ]
  );

  const isMenuVisible = useMemo(
    () => menuItems.some((item) => !item.isHidden),
    [menuItems]
  );

  if (!isMenuVisible) {
    return null;
  }

  return (
    <>
      <Menu
        hasBackdrop
        backdropStyle="transparent"
        sections={[menuItems]}
        className="py-2"
        placement="bottom-end"
        renderTrigger={({ Trigger, triggerRef }) => (
          <Trigger as={TaskHoverableButton} ref={triggerRef} className="w-8">
            <HorizDotsIcon />
          </Trigger>
        )}
      />
      <TaskArchiveConfirmationDialog
        isOpen={isArchiveTaskConfirmationDialogOpen}
        entityId={entityId}
        task={task}
        onClose={() => setIsArchiveTaskConfirmationDialogOpen(false)}
        onSuccess={navigateBackToTaskList}
      />
      <TaskDeleteConfirmationDialog
        isOpen={isDeleteTaskConfirmationDialogOpen}
        onDelete={handleRemoveTask}
        onClose={() => setIsDeleteTaskConfirmationDialogOpen(false)}
      />
      <PageLoaderFull isTransparent isActive={isDuplicatingTask} />
    </>
  );
};

import { useCallback, useMemo } from "react";
import {
  OrderFormDateFieldValue,
  OrderFormDropdownFieldValue,
  OrderFormParagraphFieldValue,
  OrderFormTextFieldValue,
  OrderFormFieldType,
  OrderFormServicesFieldValue,
} from "@jugl-web/rest-api/orders/types";
import { useOrderForm } from "../../../providers/OrderFormProvider";
import {
  ORDER_FORM_DESCRIPTION_MAX_LENGTH,
  ORDER_FORM_TITLE_MAX_LENGTH,
} from "../components/OrderFormDescriptionSection/consts";
import { ORDER_FORM_DROPDOWN_OPTION_MAX_LENGTH } from "../components/DropdownField/components/DropdownOption/consts";

export const useOrderFormValidators = () => {
  const { title, description, fields } = useOrderForm();

  const isOrderTitleValid = useMemo(
    () => title && title.length <= ORDER_FORM_TITLE_MAX_LENGTH,
    [title]
  );

  const isOrderDescriptionValid = useMemo(
    () =>
      description && description.length <= ORDER_FORM_DESCRIPTION_MAX_LENGTH,
    [description]
  );

  const checkIsOrderTextFieldValid = useCallback(
    (value: OrderFormTextFieldValue) => !!value.label,
    []
  );

  const checkIsOrderParagraphFieldValid = useCallback(
    (value: OrderFormParagraphFieldValue) => !!value.label,
    []
  );

  const checkIsOrderDropdownFieldValid = useCallback(
    (value: OrderFormDropdownFieldValue) =>
      !!(
        value.label &&
        value.items.every(
          (el) =>
            el.label && el.label.length <= ORDER_FORM_DROPDOWN_OPTION_MAX_LENGTH
        )
      ),
    []
  );

  const checkIsOrderDateFieldValid = useCallback(
    (value: OrderFormDateFieldValue) => !!value.label,
    []
  );

  const checkIsServicesFieldValid = useCallback(
    (value: OrderFormServicesFieldValue) =>
      !!(value.label && value.items.length),
    []
  );

  const isOrderFormValid = useMemo(() => {
    if (!isOrderTitleValid) {
      return false;
    }
    if (!isOrderDescriptionValid) {
      return false;
    }
    const isOrderFieldInvalid = fields.some((field) => {
      switch (field.property.type) {
        case OrderFormFieldType.text:
          return !checkIsOrderTextFieldValid(field.property.value);
        case OrderFormFieldType.paragraph:
          return !checkIsOrderParagraphFieldValid(field.property.value);
        case OrderFormFieldType.dropdown:
        case OrderFormFieldType.singleChoice:
        case OrderFormFieldType.multiChoice:
          return !checkIsOrderDropdownFieldValid(field.property.value);
        case OrderFormFieldType.date:
          return !checkIsOrderDateFieldValid(field.property.value);
        case OrderFormFieldType.services:
          return !checkIsServicesFieldValid(field.property.value);
        default:
          throw new Error("Unsupported order field type");
      }
    });
    if (isOrderFieldInvalid) {
      return false;
    }

    return true;
  }, [
    fields,
    isOrderTitleValid,
    isOrderDescriptionValid,
    checkIsOrderDropdownFieldValid,
    checkIsOrderParagraphFieldValid,
    checkIsOrderTextFieldValid,
    checkIsOrderDateFieldValid,
    checkIsServicesFieldValid,
  ]);

  return {
    isOrderFormValid,
    isOrderTitleValid,
    isOrderDescriptionValid,
    checkIsOrderDropdownFieldValid,
    checkIsOrderParagraphFieldValid,
    checkIsOrderTextFieldValid,
    checkIsOrderDateFieldValid,
    checkIsServicesFieldValid,
  };
};

import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import alertGif from "./assets/alert.gif";
import { getCurrenciesList } from "../../utils/getCurrenciesList";

export const ConfirmCurrencyChange: FC<{
  isOpen: boolean;
  currencyCode: string | undefined;
  onClose: () => void;
  onSave: () => void;
}> = ({ isOpen, currencyCode, onClose, onSave }) => {
  const { t } = useTranslations();
  const currency = useMemo(
    () =>
      getCurrenciesList().find(
        (currencyObj) => currencyObj.code === currencyCode
      ),
    [currencyCode]
  );
  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      title={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      img={<img src={alertGif} alt="Alert" className="h-[70px] w-[70px]" />}
      contentContainerClassName="px-6"
      buttonsContainerClassName="px-10 mt-8"
      content={
        <div className="text-sm text-[#4F4F4F]">
          {t(
            {
              id: "workspace-page.confirm-currency-change-popup-description",
              defaultMessage:
                "Selected Currency will be used in 'Inventory' and 'Order Forms' modules. Do you really want to change Workspace Currency to <fancyText>{currencyLabel}</fancyText>?",
            },
            {
              currencyLabel: `${currency?.name} (${currency?.code})`,
              fancyText: (text: (string | JSX.Element)[]) => (
                <span className="text-primary-800 font-medium">{text}</span>
              ),
            }
          )}
        </div>
      }
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          color: "grey",
          onClick: onClose,
        },
        {
          text: t({
            id: "common.save",
            defaultMessage: "Save",
          }),
          color: "primary",
          onClick: onSave,
        },
      ]}
    />
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { reorder, useTranslations } from "@jugl-web/utils";
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { OrderFormFieldType } from "@jugl-web/rest-api/orders/types";
import { OrderFormField } from "../OrderFormField";
import { useOrderForm } from "../../../../providers/OrderFormProvider";
import { DropdownOption } from "./components/DropdownOption";
import { useOrderFormValidators } from "../../hooks/useOrderFormValidators";
import { ORDER_FORM_DROPDOWN_OPTION_MAX_LENGTH } from "./components/DropdownOption/consts";

export const ORDER_FORM_OTHER_OPTION_ID = "other";

export const DropdownField: React.FC<{
  id: string;
  index: number;
  choiceMode?: "single" | "multi";
}> = ({ id, index, choiceMode }) => {
  const { t } = useTranslations();
  const { getFieldById, updateFieldById, triggerValidate } = useOrderForm();
  const [shouldShowError, setShouldShowError] = useState(false);
  const {
    isRequired,
    property: { value, type },
  } = getFieldById(id);
  const { checkIsOrderDropdownFieldValid } = useOrderFormValidators();

  if (
    type !== OrderFormFieldType.dropdown &&
    type !== OrderFormFieldType.singleChoice &&
    type !== OrderFormFieldType.multiChoice
  )
    throw new Error("Bad order field type");

  const [isExpanded, setIsExpanded] = useState(
    !checkIsOrderDropdownFieldValid(value)
  );

  const handleValidateField = useCallback(() => {
    setShouldShowError(!checkIsOrderDropdownFieldValid(value));
  }, [value, checkIsOrderDropdownFieldValid]);

  useEffect(() => {
    const subscription = triggerValidate.subscribe(() => {
      handleValidateField();
    });
    return () => subscription.unsubscribe();
  }, [triggerValidate, handleValidateField]);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(mouseSensor);

  const handleOnDragEnd = (event: DragEndEvent) => {
    const { active: draggable, over: droppable } = event;
    if (!draggable.data.current || !droppable || droppable.disabled) {
      return;
    }
    const activeIndex = draggable.data.current.index;
    const overIndex = droppable.data.current?.index;
    if (
      typeof activeIndex !== "number" ||
      typeof overIndex !== "number" ||
      activeIndex === overIndex
    ) {
      return;
    }

    updateFieldById({
      id,
      type,
      value: { ...value, items: reorder(value.items, activeIndex, overIndex) },
    });
  };

  useEffect(() => {
    if (shouldShowError) {
      handleValidateField();
    }
  }, [value, shouldShowError, handleValidateField]);

  return (
    <OrderFormField
      id={id}
      index={index}
      isExpanded={isExpanded}
      onClick={() => setIsExpanded(true)}
      onClickAway={() => {
        if (checkIsOrderDropdownFieldValid(value)) {
          setIsExpanded(false);
        }
      }}
    >
      {isExpanded && (
        <TextInput
          label={t({
            id: "order-form-wizard-page.field-label",
            defaultMessage: "Field label",
          })}
          placeholder={t({
            id: "order-form-wizard-page.enter-field-label",
            defaultMessage: "Enter field label",
          })}
          isRequired
          onChange={(e) =>
            updateFieldById({
              id,
              type,
              value: { ...value, label: e.target.value.trimStart() },
            })
          }
          value={value.label}
          isInvalid={shouldShowError && !value.label}
          autoFocus
          errorMessage={
            shouldShowError && !value.label
              ? t({
                  id: "order-form-wizard-page.field-cant-be-empty",
                  defaultMessage: "This field cannot be left empty",
                })
              : undefined
          }
        />
      )}
      <DndContext sensors={sensors} onDragEnd={handleOnDragEnd}>
        <SortableContext
          items={value.items}
          strategy={verticalListSortingStrategy}
        >
          {value.items.map((el, idx) => (
            <DropdownOption
              id={el.id}
              key={el.id}
              index={idx}
              label={!isExpanded && idx === 0 ? value.label : undefined}
              isFieldRequired={isRequired}
              isExpanded={isExpanded}
              value={el.label}
              choiceMode={choiceMode}
              isError={
                shouldShowError &&
                (!el.label ||
                  el.label.length > ORDER_FORM_DROPDOWN_OPTION_MAX_LENGTH)
              }
              onLabelChange={(label) =>
                updateFieldById({
                  id,
                  type,
                  value: {
                    ...value,
                    items: value.items.map((item) =>
                      item.id === el.id ? { ...item, label } : item
                    ),
                  },
                })
              }
              onDelete={
                value.items.length > 1
                  ? () =>
                      updateFieldById({
                        id,
                        type,
                        value: {
                          ...value,
                          items: value.items.filter(
                            (item) => item.id !== el.id
                          ),
                        },
                      })
                  : undefined
              }
            />
          ))}
        </SortableContext>
      </DndContext>
      {(type === OrderFormFieldType.singleChoice ||
        type === OrderFormFieldType.multiChoice) &&
        value.hasOtherOption && (
          <DropdownOption
            id={ORDER_FORM_OTHER_OPTION_ID}
            index={-1}
            isDisabled
            isExpanded={isExpanded}
            value={t({
              id: "order-form-wizard-page.other",
              defaultMessage: "Other...",
            })}
            choiceMode={choiceMode}
            onDelete={() =>
              updateFieldById({
                id,
                type,
                value: {
                  ...value,
                  hasOtherOption: false,
                },
              })
            }
          />
        )}
    </OrderFormField>
  );
};

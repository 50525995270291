import {
  TaskChecklist,
  TaskChecklistHandle,
  taskChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks";
import { CompleteTaskSuggestionDialog } from "@jugl-web/domain-resources/tasks/components/CompleteTaskSuggestionDialog";
import { TaskCompletionCelebrationDialog } from "@jugl-web/domain-resources/tasks/components/TaskCompletionCelebrationDialog";
import { useCompleteTaskSuggestionDialog } from "@jugl-web/domain-resources/tasks/hooks/useCompleteTaskSuggestionDialog";
import { useRemoteTaskChecklistHandlers } from "@jugl-web/domain-resources/tasks/hooks/useRemoteTaskChecklistHandlers";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { useTaskPreferences } from "@jugl-web/domain-resources/tasks/hooks/useTaskPreferences";
import { TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { Checkbox } from "@jugl-web/ui-components";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput, useTranslations } from "@jugl-web/utils";
import { useLoadedMe } from "@web-src/features/app/hooks/useMe";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useMemo, useRef, useState } from "react";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { ReactComponent as AddSubtaskIcon } from "../../assets/add-subtask.svg";
import { useLoadedTaskDetailsContext } from "../../hooks/useTaskDetailsContext";
import { TabContentButton } from "../TabContentButton";
import { TabContentFeedbackText } from "../TabContentFeedbackText";
import { useTabSearchPersistenceContext } from "../TabSearchPersistenceProvider";

export const TaskDetailsSubtasksTabContent = () => {
  const { task, updateTask } = useLoadedTaskDetailsContext();
  const { meId } = useLoadedMe();
  const { entityId } = useEntitySelectedProvider();

  const permissions = useTaskPermissions({ task, entityId, meId });
  const { preferences, updatePreference } = useTaskPreferences({
    taskId: task.id,
  });

  const { getInitialSearchQuery, saveSearchQuery } =
    useTabSearchPersistenceContext();

  const {
    searchQuery,
    inputProps: searchInputProps,
    reset: resetSearchInput,
  } = useSearchInput({
    defaultValue: getInitialSearchQuery("subtasks"),
    onSearch: (query) => saveSearchQuery("subtasks", query),
  });

  const taskChecklistHandlers = useRemoteTaskChecklistHandlers({
    entityId,
    taskId: task.id,
    checklistItems: task.checklist,
  });

  const $taskChecklistRef = useRef<TaskChecklistHandle | null>(null);

  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();

  const [
    isCompletionCelebrationDialogOpen,
    setIsCompletionCelebrationDialogOpen,
  ] = useState(false);

  const { dialogProps: completeTaskSuggestionDialogProps } =
    useCompleteTaskSuggestionDialog({
      task,
      entityId,
      meId,
      onComplete: () => {
        updateTask({ status: TaskDefaultStatus.completed });
        setTimeout(() => setIsCompletionCelebrationDialogOpen(true), 100);
      },
    });

  const adaptedChecklistItems = useMemo(
    () => task.checklist.map(taskChecklistItemAdapters.toInternalModel),
    [task?.checklist]
  );

  return (
    <>
      <div className="flex items-center justify-between gap-4">
        <SearchInput
          variant="filled"
          color="grey"
          containerClassName="w-full max-w-[320px]"
          onClear={resetSearchInput}
          {...searchInputProps}
        />
        <div className="flex items-center gap-4">
          <TabContentButton
            label={t({
              id: "tasks-page.hide-completed",
              defaultMessage: "Hide completed",
            })}
            icon={
              <Checkbox
                readOnly
                size="sm"
                className="pointer-events-none"
                isChecked={preferences.areCompletedChecklistItemsHidden}
              />
            }
            onClick={() =>
              updatePreference(
                "areCompletedChecklistItemsHidden",
                (previousState) => !previousState
              )
            }
          />
          {permissions.canManageChecklist && (
            <TabContentButton
              label={t({ id: "common.add", defaultMessage: "Add" })}
              icon={<AddSubtaskIcon />}
              onClick={() => $taskChecklistRef.current?.addItem()}
            />
          )}
        </div>
      </div>
      <TaskChecklist
        ref={$taskChecklistRef}
        entityId={entityId}
        taskId={task.id}
        meId={meId}
        isSelectable
        items={adaptedChecklistItems}
        taskCreatorId={task.created_by}
        taskAssigneeIds={task.assignees}
        isCompleteInSpecificOrder={task.has_chklist_chk}
        areCompletedItemsHidden={preferences.areCompletedChecklistItemsHidden}
        searchQuery={searchQuery}
        emptyStateContent={
          <TabContentFeedbackText
            className="mt-8"
            text={t({
              id: "tasks-page.no-subtasks-added",
              defaultMessage: "No subtasks added yet",
            })}
          />
        }
        noSearchResultsContent={
          <TabContentFeedbackText
            className="mt-8"
            text={t({
              id: "tasks-page.no-results",
              defaultMessage: "No results 😔",
            })}
          />
        }
        isManageable={permissions.canManageChecklist}
        isAssignable={!task.is_self}
        canEditItem={(item) =>
          item.assigneeId
            ? permissions.canEditChecklistItem(item.assigneeId)
            : false
        }
        className="mt-8"
        onNavigateToTask={(taskId) => navigateToPage("taskDetails", { taskId })}
        {...taskChecklistHandlers}
      />
      <CompleteTaskSuggestionDialog {...completeTaskSuggestionDialogProps} />
      <TaskCompletionCelebrationDialog
        isOpen={isCompletionCelebrationDialogOpen}
        taskTitle={task.name}
        onClose={() => setIsCompletionCelebrationDialogOpen(false)}
      />
    </>
  );
};

import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useTranslations, useToast } from "@jugl-web/utils";
import { FC } from "react";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import alertGif from "./assets/alert.gif";

export const ConfirmRemoveItemsAlert: FC<{
  isOpen: boolean;
  itemIds: string[];
  onClose: () => void;
  onRemove?: () => void;
}> = ({ isOpen, itemIds, onClose, onRemove }) => {
  const { t } = useTranslations();
  const { inventoryApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const { toast } = useToast({ variant: "web" });
  const [deleteItem] = inventoryApi.useDeleteInventoryItemMutation();

  const handleRemoveItems = async () => {
    const itemsToRemove = itemIds;
    const promises = itemsToRemove.map(async (id) => {
      deleteItem({ entityId: entity.id, itemId: id });
    });
    await Promise.all(promises).then(() => {
      onRemove?.();
      onClose();
      toast(
        t(
          {
            id: "inventory-items-page.items-deleted",
            defaultMessage:
              "{count} {count, plural, one {item} other {items}} was deleted",
          },
          {
            count: itemsToRemove.length,
          }
        )
      );
    });
  };

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      title={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      img={<img src={alertGif} alt="Alert" className="h-[70px] w-[70px]" />}
      contentContainerClassName="px-6"
      buttonsContainerClassName="px-10 mt-8"
      content={
        <div className="text-sm text-[#4F4F4F]">
          {t(
            {
              id: "inventory-items-page.items-remove-confirm-description-2",
              defaultMessage:
                "If you delete this {count, plural, one {item} other {items}}, you will no longer be able to restore it",
            },
            {
              count: itemIds.length,
            }
          )}
        </div>
      }
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          color: "grey",
          onClick: onClose,
        },
        {
          text: t({
            id: "common.delete",
            defaultMessage: "Delete",
          }),
          color: "tertiary",
          onClick: handleRemoveItems,
        },
      ]}
    />
  );
};

import { cx } from "@jugl-web/utils";
import { forwardRef, ReactNode } from "react";
import { FormGroup, FormGroupProps } from "../FormGroup";

export interface UtilityTextInputBaseProps extends FormGroupProps {
  children: ReactNode;
  mask?: string;
  inputId?: string;
  inputWrapperClassName?: string;
}

export const UtilityTextInputBase = forwardRef<
  HTMLInputElement,
  UtilityTextInputBaseProps
>((props) => {
  const { isInvalid, isDisabled, children, inputWrapperClassName, inputId } =
    props;
  return (
    <FormGroup {...{ ...props, labelFor: inputId }}>
      <div
        className={cx(
          "flex items-center overflow-hidden border-[1px]",
          "border-grey-400 font-secondary rounded-[10px] border-solid text-base leading-4 transition-colors focus-within:border-transparent",
          "focus-within:border-[#90C9F9] focus-within:outline-none focus-within:ring-2 focus-within:ring-[#90C9F9] hover:border-[#90C9F9] hover:ring-1 hover:ring-[#90C9F9] focus:ring-2 active:ring-2 active:ring-[#90C9F9]",
          isDisabled &&
            "bg-grey-[#FAFAFA] border-grey-400 hover:border-grey-400 border-dashed",
          isInvalid &&
            "border-gradients-danger focus-within:ring-gradients-danger hover:border-gradients-danger focus-within:border-gradients-danger border-solid",
          inputWrapperClassName
        )}
      >
        {children}
      </div>
    </FormGroup>
  );
});

import countryToCurrency, { Countries } from "country-to-currency";
import { currencyList } from "./currencyList";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const countries = require("countries-phone-masks");

type PhoneMasksCountry = {
  name: string;
  code: string;
  iso: string;
  flag: string;
  mask: string;
};

export type CurrenciesListItem = {
  code: string;
  name: string;
  symbol: string;
  flag: string;
  country_code: string;
};

export const getCurrenciesList = () => {
  const currenciesList: CurrenciesListItem[] = [];
  countries.forEach((item: PhoneMasksCountry) => {
    const currencyCode = countryToCurrency[item.iso as Countries];
    if (!currencyCode) {
      return;
    }
    const currencyInfo = currencyList[currencyCode];
    if (!currencyInfo) {
      return;
    }
    currenciesList.push({
      code: currencyCode,
      name: currencyInfo.name,
      symbol: currencyInfo.symbol,
      flag: item.flag,
      country_code: item.iso,
    });
  });
  return currenciesList;
};

import {
  OrderFormDropdownFieldValue,
  OrderFormFieldType,
  OrderFormFieldValue,
  OrderFormServicesFieldValue,
  OrderFormSingleChoiceFieldValue,
} from "@jugl-web/rest-api/orders/types";
import { FC, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  TASK_ORDER_CUSTOMER_NAME,
  TASK_ORDER_EMAIL_ID,
  TASK_ORDER_PHONE_ID,
} from "@jugl-web/utils/consts";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { TextAreaInput } from "@jugl-web/ui-components/cross-platform/forms/TextAreaInput";
import {
  RadioGroup,
  RadioGroupValue,
} from "@jugl-web/ui-components/cross-platform/forms/RadioGroup";
import {
  CheckboxGroup,
  CheckboxGroupValue,
} from "@jugl-web/ui-components/cross-platform/forms/CheckboxGroup";
import { Select } from "@jugl-web/ui-components/cross-platform/forms/Select";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { useSpotlight } from "@jugl-web/utils/hooks/useSpotlight";
import { ORDER_FORM_PREVIEW_SPOTLIGHT_KEY } from "@jugl-web/utils/storage";
import {
  Button,
  customerPhoneStringToInputValue,
  DateTimeInput,
  FormGroup,
  inputValueToCustomerPhoneString,
  PhoneInput,
} from "@jugl-web/ui-components";
import { InventoryItem } from "@jugl-web/rest-api";
import {
  cx,
  useAppVariant,
  useFormFieldsValidators,
  useTranslations,
} from "@jugl-web/utils";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import defaultBannerSrc from "./assets/default-banner.png";
import { FormSubmissionSection } from "./components/FormSubmissionSection";
import {
  OrderFormInventorySelect,
  OrderFormInventorySelectValueItem,
} from "./components/OrderFormInventorySelect";

const TEXT_MAX_LENGTH = 128;
const PARAGRAPH_MAX_LENGTH = 250;

export const OrderFormSubmission: FC<{
  bannerImg?: string;
  title: string;
  description?: string | null;
  fields: OrderFormFieldValue[];
  services: InventoryItem[];
  onSubmit: (fieldsValues?: { [key: string]: string }) => void;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  isPreview?: boolean;
  onAddAnotherAnswer?: () => void;
  currency: string;
}> = ({
  fields,
  bannerImg,
  title,
  description,
  onSubmit,
  services,
  isSubmitting,
  isSubmitted,
  isPreview,
  onAddAnotherAnswer,
  currency,
}) => {
  const { t } = useTranslations();
  const { register, formState, handleSubmit, control, reset } = useForm();
  const { requiredValidator, emailValidator, lengthValidator } =
    useFormFieldsValidators();
  const { isMobile } = useAppVariant();
  const { contactEmailField, customFields, contactPhoneField, fullNameField } =
    useMemo(() => {
      const customFieldsResult: OrderFormFieldValue[] = [];
      let contactEmailFieldResult: OrderFormFieldValue | undefined;
      let contactPhoneFieldResult: OrderFormFieldValue | undefined;
      let fullNameFieldResult: OrderFormFieldValue | undefined;

      fields.forEach((field) => {
        if (field.id === TASK_ORDER_EMAIL_ID) {
          contactEmailFieldResult = field;
          return;
        }
        if (field.id === TASK_ORDER_PHONE_ID) {
          contactPhoneFieldResult = field;
          return;
        }
        if (field.id === TASK_ORDER_CUSTOMER_NAME) {
          fullNameFieldResult = field;
          return;
        }
        customFieldsResult.push(field);
      });

      return {
        customFields: customFieldsResult,
        contactEmailField: contactEmailFieldResult,
        contactPhoneField: contactPhoneFieldResult,
        fullNameField: fullNameFieldResult,
      };
    }, [fields]);

  const { isActive: isSpotlightActive, markAsSeen: markSpotlightAsSeen } =
    useSpotlight({
      id: ORDER_FORM_PREVIEW_SPOTLIGHT_KEY,
      delay: 200,
      isDisabled: !isPreview,
    });

  return (
    <div className={cx("w-full space-y-2", { "pt-[200px]": isSubmitted })}>
      <div className="relative h-[120px] overflow-hidden rounded-xl bg-white bg-cover bg-center">
        <img
          src={bannerImg || defaultBannerSrc}
          className="h-full w-full object-cover object-center"
          alt={title}
        />
      </div>
      {isSubmitted ? (
        <>
          <FormSubmissionSection>
            <div className="flex flex-col items-center gap-2 text-center">
              <h1 className="font-secondary m-0 text-[24px] font-[500] leading-[150%]">
                Thanks for submitting the Form!
              </h1>
              <p className="font-secondary text-dark-600 m-0 leading-[150%]">
                Your response has been recorded. <br /> We will get in touch
                with you very soon! 🙌
              </p>
            </div>
          </FormSubmissionSection>

          {onAddAnotherAnswer && (
            <div className="flex items-center">
              <Button
                className="font-secondary text-grey-700 mx-auto mt-6 h-10 w-[240px] shrink-0 text-sm font-normal"
                isDisabled={isSubmitting}
                variant="contained"
                color="white"
                onClick={() => {
                  reset();
                  onAddAnotherAnswer();
                }}
              >
                Add another answer
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          <FormSubmissionSection>
            <div className="flex w-full flex-col items-center gap-2 overflow-hidden text-center">
              <h1 className="font-secondary jugl__break-word m-0 text-[24px] font-normal leading-[150%]">
                {title || "Untitled Form"}
              </h1>
              {description && (
                <p className="font-secondary text-dark-600 jugl__break-word m-0 leading-[150%]">
                  <Linkify>{description}</Linkify>
                </p>
              )}
            </div>
          </FormSubmissionSection>
          {contactPhoneField || fullNameField || contactEmailField ? (
            <FormSubmissionSection>
              <div className="flex flex-col gap-6">
                <SpotlightTooltip
                  isOpen={isSpotlightActive}
                  placement="right"
                  onDismiss={markSpotlightAsSeen}
                  tip={t(
                    {
                      id: "tasks-page.order-form-preview-info",
                      defaultMessage:
                        "After Client will Submit the Form - New task will be created inside of this Workspace Automaticaly ✅ {br}{br} All selected assignees of the Task will receive a notification about new Order✨",
                    },
                    { br: <br /> }
                  )}
                  showButton
                  renderTrigger={({ ref, props }) => (
                    <div ref={ref} {...props}>
                      {contactPhoneField && (
                        <Controller
                          control={control}
                          name={contactPhoneField.id}
                          rules={{
                            ...requiredValidator(),
                            validate: (value) =>
                              !customerPhoneStringToInputValue(value).isValid
                                ? "Phone number is invalid"
                                : true,
                          }}
                          render={({ field: { value, onChange } }) => (
                            <FormGroup
                              label="Your Phone number"
                              isInvalid={
                                !!formState.errors[contactPhoneField.id]
                              }
                              errorMessage={formState.errors[
                                contactPhoneField.id
                              ]?.message?.toString()}
                              isRequired
                            >
                              <PhoneInput
                                value={
                                  value
                                    ? customerPhoneStringToInputValue(value)
                                    : undefined
                                }
                                wrapperClassName={cx(
                                  "border-grey-400 rounded-[10px] border-[2px] border-solid h-[56px] focus-within:border-[#90C9F9]",
                                  !!formState.errors[contactPhoneField.id] &&
                                    "border-gradients-danger focus-within:border-gradients-danger"
                                )}
                                placeholder={t({
                                  id: "form-controls.mobile-number.placeholder",
                                  defaultMessage: "Mobile Number",
                                })}
                                onChange={(phoneNumber) => {
                                  onChange(
                                    phoneNumber.phone
                                      ? inputValueToCustomerPhoneString(
                                          phoneNumber
                                        )
                                      : ""
                                  );
                                }}
                                listButtonClassName="text-xs py-2 pl-2"
                              />
                            </FormGroup>
                          )}
                        />
                      )}
                      {contactEmailField && (
                        <TextInput
                          {...register(contactEmailField.id, {
                            ...requiredValidator(),
                            ...emailValidator(),
                          })}
                          isRequired
                          label="Your email"
                          placeholder="Enter"
                          isInvalid={!!formState.errors[contactEmailField.id]}
                          errorMessage={formState.errors[
                            contactEmailField.id
                          ]?.message?.toString()}
                        />
                      )}
                    </div>
                  )}
                />
                {fullNameField && (
                  <TextInput
                    {...register(fullNameField.id, {
                      ...requiredValidator(),
                    })}
                    isRequired
                    label="Your full name"
                    placeholder="Enter"
                    isInvalid={!!formState.errors[fullNameField.id]}
                    errorMessage={formState.errors[
                      fullNameField.id
                    ]?.message?.toString()}
                  />
                )}
              </div>
            </FormSubmissionSection>
          ) : null}
          {customFields.map((field) => {
            switch (field.property.type) {
              case OrderFormFieldType.text:
                return (
                  <FormSubmissionSection key={field.id}>
                    <TextInput
                      {...register(field.id, {
                        ...(field.isRequired ? requiredValidator() : {}),
                        ...lengthValidator(TEXT_MAX_LENGTH),
                      })}
                      isRequired={field.isRequired}
                      label={field.property.value.label}
                      placeholder={field.property.value.placeholder || "Enter"}
                      isInvalid={!!formState.errors[field.id]}
                      errorMessage={formState.errors[
                        field.id
                      ]?.message?.toString()}
                      lengthIndicator={{ max: TEXT_MAX_LENGTH }}
                    />
                  </FormSubmissionSection>
                );
              case OrderFormFieldType.paragraph:
                return (
                  <FormSubmissionSection key={field.id}>
                    <TextAreaInput
                      {...register(field.id, {
                        ...(field.isRequired ? requiredValidator() : {}),
                        ...lengthValidator(PARAGRAPH_MAX_LENGTH),
                      })}
                      isRequired={field.isRequired}
                      label={field.property.value.label}
                      placeholder={field.property.value.placeholder || "Enter"}
                      isInvalid={!!formState.errors[field.id]}
                      errorMessage={formState.errors[
                        field.id
                      ]?.message?.toString()}
                      lengthIndicator={{ max: PARAGRAPH_MAX_LENGTH }}
                    />
                  </FormSubmissionSection>
                );
              case OrderFormFieldType.date:
                return (
                  <FormSubmissionSection key={field.id}>
                    <Controller
                      control={control}
                      name={field.id}
                      rules={{
                        ...(field.isRequired ? requiredValidator() : {}),
                      }}
                      render={({ field: { value, onChange } }) => (
                        <DateTimeInput
                          onChange={onChange}
                          value={value}
                          isRequired={field.isRequired}
                          isMobile={isMobile}
                          label={field.property.value.label}
                          isInvalid={!!formState.errors[field.id]}
                          errorMessage={formState.errors[
                            field.id
                          ]?.message?.toString()}
                        />
                      )}
                    />
                  </FormSubmissionSection>
                );
              case OrderFormFieldType.dropdown:
                return (
                  <FormSubmissionSection key={field.id}>
                    <Controller
                      control={control}
                      name={field.id}
                      rules={{
                        ...(field.isRequired ? requiredValidator() : {}),
                      }}
                      render={({ field: { value, onChange } }) => {
                        const fieldPropertyValue = field.property
                          .value as OrderFormDropdownFieldValue;
                        return (
                          <Select
                            label={fieldPropertyValue.label}
                            placeholder="Select"
                            value={value}
                            items={fieldPropertyValue.items.map((item) => ({
                              value: item.label,
                              name: item.label,
                            }))}
                            isRequired={field.isRequired}
                            onSelect={onChange}
                            isInvalid={!!formState.errors[field.id]}
                            errorMessage={formState.errors[
                              field.id
                            ]?.message?.toString()}
                          />
                        );
                      }}
                    />
                  </FormSubmissionSection>
                );
              case OrderFormFieldType.singleChoice:
                return (
                  <FormSubmissionSection key={field.id}>
                    <Controller
                      control={control}
                      name={field.id}
                      rules={{
                        validate: (value: RadioGroupValue) => {
                          const otherCorrectlyFilled = value?.isOtherSelected
                            ? !!value.otherValue?.trim()
                            : true;
                          if (!otherCorrectlyFilled) {
                            return "Please fill in the other field";
                          }

                          if (!field.isRequired) {
                            return true;
                          }

                          if (value?.selectedOption || value?.isOtherSelected) {
                            return true;
                          }

                          return "This field is required";
                        },
                      }}
                      render={({ field: { value, onChange } }) => {
                        const fieldPropertyValue = field.property
                          .value as OrderFormSingleChoiceFieldValue;
                        return (
                          <RadioGroup
                            label={fieldPropertyValue.label}
                            value={value}
                            items={fieldPropertyValue.items.map((item) => ({
                              value: item.label,
                              name: item.label,
                            }))}
                            isRequired={field.isRequired}
                            onSelect={onChange}
                            isInvalid={!!formState.errors[field.id]}
                            errorMessage={formState.errors[
                              field.id
                            ]?.message?.toString()}
                            hasOtherOption={fieldPropertyValue.hasOtherOption}
                          />
                        );
                      }}
                    />
                  </FormSubmissionSection>
                );
              case OrderFormFieldType.multiChoice:
                return (
                  <FormSubmissionSection key={field.id}>
                    <Controller
                      control={control}
                      name={field.id}
                      rules={{
                        validate: (value: CheckboxGroupValue) => {
                          const otherCorrectlyFilled = value?.isOtherSelected
                            ? !!value.otherValue?.trim()
                            : true;
                          if (!otherCorrectlyFilled) {
                            return "Please fill in the other field";
                          }

                          if (!field.isRequired) {
                            return true;
                          }

                          if (
                            value?.selectedOptions?.length ||
                            value?.isOtherSelected
                          ) {
                            return true;
                          }

                          return "This field is required";
                        },
                      }}
                      render={({ field: { value, onChange } }) => {
                        const fieldPropertyValue = field.property
                          .value as OrderFormSingleChoiceFieldValue;
                        return (
                          <CheckboxGroup
                            label={fieldPropertyValue.label}
                            value={value}
                            items={fieldPropertyValue.items.map((item) => ({
                              value: item.label,
                              name: item.label,
                            }))}
                            isRequired={field.isRequired}
                            onSelect={(e) => {
                              onChange(e);
                            }}
                            isInvalid={!!formState.errors[field.id]}
                            errorMessage={formState.errors[
                              field.id
                            ]?.message?.toString()}
                            hasOtherOption={fieldPropertyValue.hasOtherOption}
                          />
                        );
                      }}
                    />
                  </FormSubmissionSection>
                );
              case OrderFormFieldType.services:
                return (
                  <FormSubmissionSection key={field.id}>
                    <Controller
                      control={control}
                      name={field.id}
                      rules={{
                        ...(field.isRequired ? requiredValidator() : {}),
                        validate: (
                          value: OrderFormInventorySelectValueItem[]
                        ) => {
                          if (!field.isRequired) {
                            return true;
                          }
                          if (!value || !value.find((v) => v.qty > 0)) {
                            return "This field is required";
                          }
                          return true;
                        },
                      }}
                      render={({ field: { value, onChange } }) => {
                        const fieldPropertyValue = field.property
                          .value as OrderFormServicesFieldValue;
                        return (
                          <OrderFormInventorySelect
                            label={fieldPropertyValue.label}
                            isRequired={field.isRequired}
                            services={services}
                            items={fieldPropertyValue.items}
                            value={value}
                            onChange={onChange}
                            isInvalid={!!formState.errors[field.id]}
                            currency={currency}
                            errorMessage={formState.errors[
                              field.id
                            ]?.message?.toString()}
                          />
                        );
                      }}
                    />
                  </FormSubmissionSection>
                );
              default:
                return null;
            }
          })}
          <div className="flex items-center">
            <Button
              className="mx-auto mt-6 h-10 w-[240px] shrink-0"
              isDisabled={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

import {
  InteractiveContainer,
  Text,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import React from "react";
import mime from "mime";
import { ReactComponent as UnknownFileIcon } from "./assets/unknown-file-icon.svg";

const UNKNOWN_FILE_EXTENSION = "bin";

export const FileTile: React.FC<{
  file: File | string;
  isSelected: boolean;
  mimeType: string;
  hasOverlay?: boolean;
  overlayText?: string;
  onClick: () => void;
}> = ({ isSelected, file, mimeType, hasOverlay, overlayText, onClick }) => {
  const fileExtension = mime.getExtension(mimeType);
  const allowedImageMimetypes = [
    "image/gif",
    "image/jpeg",
    "image/jpg",
    "image/png",
    // Wildcard for inventory images, since we know its JPG or PNG but don't know the exact mimetype
    "image/*",
  ];
  return (
    <InteractiveContainer
      className={cx(
        "border-grey-400 relative !box-content h-[46px] w-[46px] shrink-0 overflow-hidden rounded-[10px] border border-solid bg-white transition-colors",
        isSelected && "border-primary border-[3px]"
      )}
      onClick={onClick}
    >
      {!allowedImageMimetypes.includes(mimeType) && (
        <Text
          variant="body3"
          className="text-primary absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform font-medium"
        >
          {fileExtension === UNKNOWN_FILE_EXTENSION ? (
            <UnknownFileIcon />
          ) : (
            fileExtension
          )}
        </Text>
      )}
      {allowedImageMimetypes.includes(mimeType) && (
        <img
          src={typeof file === "string" ? file : URL.createObjectURL(file)}
          alt={typeof file === "string" ? "" : file.name}
          className="h-full w-full object-cover object-center"
        />
      )}
      {hasOverlay && (
        <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-black/50">
          <span className="font-secondary text-sm font-semibold leading-[150%] tracking-[0.14px] text-white">
            {overlayText}
          </span>
        </div>
      )}
    </InteractiveContainer>
  );
};

import { useTranslations } from "@jugl-web/utils";
import { useCallback, useEffect, useState } from "react";
import { TextInput } from "@jugl-web/ui-components/cross-platform/forms/TextInput";
import { OrderFormFieldType } from "@jugl-web/rest-api/orders/types";
import { useOrderFormValidators } from "../../hooks/useOrderFormValidators";
import { OrderFormField } from "../OrderFormField";
import { useOrderForm } from "../../../../providers/OrderFormProvider";
import { ReactComponent as DateIcon } from "./assets/date.svg";

export const DateField: React.FC<{ id: string; index: number }> = ({
  id,
  index,
}) => {
  const { t } = useTranslations();
  const { getFieldById, updateFieldById, triggerValidate } = useOrderForm();
  const [shouldShowError, setShouldShowError] = useState(false);
  const {
    isRequired,
    property: { value, type },
  } = getFieldById(id);
  const { checkIsOrderDateFieldValid } = useOrderFormValidators();

  if (type !== OrderFormFieldType.date) throw new Error("Bad order field type");

  const [isExpanded, setIsExpanded] = useState(
    !checkIsOrderDateFieldValid(value)
  );

  const handleValidateField = useCallback(() => {
    setShouldShowError(!checkIsOrderDateFieldValid(value));
  }, [value, checkIsOrderDateFieldValid]);

  useEffect(() => {
    const subscription = triggerValidate.subscribe(() => {
      handleValidateField();
    });
    return () => subscription.unsubscribe();
  }, [triggerValidate, handleValidateField]);

  useEffect(() => {
    if (shouldShowError) {
      handleValidateField();
    }
  }, [value, shouldShowError, handleValidateField]);

  return (
    <OrderFormField
      id={id}
      index={index}
      isExpanded={isExpanded}
      onClick={() => setIsExpanded(true)}
      onClickAway={() => {
        if (checkIsOrderDateFieldValid(value)) {
          setIsExpanded(false);
        }
      }}
    >
      {isExpanded && (
        <TextInput
          label={t({
            id: "order-form-wizard-page.field-label",
            defaultMessage: "Field label",
          })}
          placeholder={t({
            id: "order-form-wizard-page.enter-field-label",
            defaultMessage: "Enter field label",
          })}
          onChange={(e) =>
            updateFieldById({
              id,
              type,
              value: { ...value, label: e.target.value.trimStart() },
            })
          }
          isRequired
          value={value.label}
          isInvalid={shouldShowError && !value.label}
          autoFocus
          errorMessage={
            shouldShowError && !value.label
              ? t({
                  id: "order-form-wizard-page.field-cant-be-empty",
                  defaultMessage: "This field cannot be left empty",
                })
              : undefined
          }
        />
      )}
      <TextInput
        label={!isExpanded ? value.label : ""}
        isRequired={isRequired}
        isDisabled
        classNames={{
          inputClassName: "pointer-events-none",
          startContentWrapperClassName: "bg-transparent",
        }}
        value=""
        placeholder={t({
          id: "order-form-wizard-page.month-day-year",
          defaultMessage: "Month / day / year",
        })}
        startContent={<DateIcon />}
      />
    </OrderFormField>
  );
};

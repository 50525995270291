import { useRestApiProvider } from "@jugl-web/rest-api";
import { DetailedTask, TasksSource } from "@jugl-web/rest-api/tasks";
import { useCallback, useMemo } from "react";

interface UseUpdateTaskFactoryOptions {
  entityId: string;
  /**
   * If not provided, the task from "My tasks" collection will be updated
   */
  source?: TasksSource;
}

export const useUpdateTaskFactory = ({
  entityId,
  source = { type: "boardTasks", boardId: "my" },
}: UseUpdateTaskFactoryOptions) => {
  const { tasksApi } = useRestApiProvider();
  const [updateTaskMutation] = tasksApi.useUpdateTaskMutation();

  const updateTaskFactory = useCallback(
    (taskId: string) => (attributes: Partial<DetailedTask>) =>
      new Promise<DetailedTask>((resolve, reject) => {
        updateTaskMutation({
          entityId,
          taskId,
          attributes,
          source,
        }).then((response) => {
          if ("data" in response) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        });
      }),
    [entityId, source, updateTaskMutation]
  );

  return updateTaskFactory;
};

interface UseUpdateTaskOptions extends UseUpdateTaskFactoryOptions {
  taskId: string;
}

export const useUpdateTask = (options: UseUpdateTaskOptions) => {
  const updateTaskFactory = useUpdateTaskFactory(options);

  const updateTask = useMemo(
    () => updateTaskFactory(options.taskId),
    [options.taskId, updateTaskFactory]
  );

  return updateTask;
};

import { DetailedTaskTemplate } from "@jugl-web/rest-api/tasks-templates";
import { getDueDateBasedOnDays } from ".";
import { templateChecklistItemAdapters } from "../components/TaskChecklist";
import { TaskFormState } from "../hooks/useTaskFormState";

export const mapTaskTemplateToFormState = (
  template: DetailedTaskTemplate
): Partial<TaskFormState> => ({
  templateId: template.id,
  title: template.name || "",
  description: template.desc || "",
  boardId: template.board_id,
  dueDate:
    typeof template.due_in === "number"
      ? { date: getDueDateBasedOnDays(template.due_in) }
      : null,
  labelId: template.label_id,
  priority: template.priority,
  checklistItems: template.checklist.map(
    templateChecklistItemAdapters.toInternalModel
  ),
  customFields: template.custom_fields || {},
});

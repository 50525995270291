import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import alertGif from "./assets/alert.gif";

export const ConfirmRemoveWarehouseAlert: FC<{
  isOpen: boolean;
  onAccept: () => void;
  onClose: () => void;
}> = ({ isOpen, onAccept, onClose }) => {
  const { t } = useTranslations();
  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      title={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      img={<img src={alertGif} alt="Alert" className="h-[70px] w-[70px]" />}
      contentContainerClassName="px-6"
      buttonsContainerClassName="px-10 mt-8"
      content={
        <div className="text-sm text-[#4F4F4F]">
          {t({
            id: "inventory-warehouses-page.warehouse-remove-confirm-description",
            defaultMessage:
              "If you delete this items, you will no longer be able to restore it",
          })}
        </div>
      }
      buttons={[
        {
          text: t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          }),
          color: "grey",
          onClick: onClose,
        },
        {
          text: t({
            id: "common.delete",
            defaultMessage: "Delete",
          }),
          color: "tertiary",
          onClick: onAccept,
        },
      ]}
    />
  );
};

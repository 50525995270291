import { Alert } from "@jugl-web/ui-components/cross-platform";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { MobileDrawer } from "@jugl-web/ui-components/mobile";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC } from "react";

export interface SaveBeforeLeavingDialogProps {
  isOpen: boolean;
  onSave: () => void;
  onDiscard: () => void;
  onCancel: () => void;
}

export const SaveBeforeLeavingDialog: FC<SaveBeforeLeavingDialogProps> = ({
  isOpen,
  onSave,
  onDiscard,
  onCancel,
}) => {
  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const labels = {
    title: t({
      id: "tasks-page.save-subtask-confirmation-title",
      defaultMessage: "Save subtask",
    }),
    message: t({
      id: "tasks-page.save-subtask-confirmation-description",
      defaultMessage:
        "If you proceed without saving, all the changes will be lost",
    }),
    save: t({
      id: "common.save",
      defaultMessage: "Save",
    }),
    discard: t({
      id: "common.discard",
      defaultMessage: "Discard",
    }),
  };

  if (isMobile) {
    return (
      <MobileDrawer
        isOpen={isOpen}
        header={{ title: labels.title }}
        onClose={onCancel}
      >
        <MobileDrawer.Content>
          <p className="text-onyx mt-2 mb-10 text-sm leading-[21px]">
            {labels.message}
          </p>
          <div className="flex flex-col gap-2.5">
            <Button
              color="primary"
              variant="contained"
              onClick={onSave}
              uppercase
            >
              {labels.save}
            </Button>
            <Button
              color="primary"
              variant="text"
              onClick={onDiscard}
              uppercase
            >
              {labels.discard}
            </Button>
          </div>
        </MobileDrawer.Content>
      </MobileDrawer>
    );
  }

  return (
    <Alert
      isOpen={isOpen}
      title={labels.title}
      content={labels.message}
      buttons={[
        {
          text: labels.save,
          variant: "contained",
          onClick: onSave,
        },
        {
          text: labels.discard,
          variant: "outlined",
          onClick: onDiscard,
        },
      ]}
      onRequestClose={onCancel}
    />
  );
};
